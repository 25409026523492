import _ from "lodash";
import { FrownIcon, SearchXIcon, SlidersHorizontal } from "lucide-react";
import React from "react";
import { ImperativePanelHandle, Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

import { PanelExpandButton, PanelHeader } from "@/components/ResizablePanel";
import { cn } from "@/lib/utils";

import { Divider } from "./Divider";
import { useFilterContext } from "./Filters";
import { VirtualizedList } from "./VirtualizedList";

type PlotComponentType<T> = React.FC<{
  className?: string;
  results: T[];
}>;

type KeyFn<T> = (result: T) => string;

export const PlotView = <T,>({
  autoSaveId,
  filters,
  toolbar,
  config,
  PlotComponent,
  getKey,
  plotHeight,
}: {
  autoSaveId?: string;
  filters: React.ReactNode;
  toolbar?: React.ReactNode;
  config: React.ReactNode;
  PlotComponent: PlotComponentType<T>;
  getKey: KeyFn<T>;
  plotHeight: number;
}) => {
  const filterPanel = React.useRef<ImperativePanelHandle>(null);
  const plotConfigPanel = React.useRef<ImperativePanelHandle>(null);
  const [filterPanelIsOpen, setFilterPanelIsOpen] = React.useState<boolean>(true);
  const [plotConfigPanelIsOpen, setPlotConfigPanelIsOpen] = React.useState<boolean>(true);

  const plotResults = React.useCallback(generateResultPlotter<T>(PlotComponent, getKey), [PlotComponent, getKey]);

  return (
    <PanelGroup autoSaveId={autoSaveId} direction="horizontal" className="px-4">
      <Panel
        minSizePercentage={10}
        defaultSizePercentage={15}
        collapsible
        ref={filterPanel}
        onCollapse={() => setFilterPanelIsOpen(false)}
        onExpand={() => setFilterPanelIsOpen(true)}
      >
        <div className="overflow-y-auto h-full divide-y divide-gray-700 pr-3 pt-4">
          <PanelHeader title={"Filters"} panelRef={filterPanel} />
          {filters}
        </div>
      </Panel>
      <PanelResizeHandle>{filterPanelIsOpen && <Divider orientation="vertical" margin="thin" />}</PanelResizeHandle>
      <Panel className="flex flex-col">
        <div className="flex flex-wrap items-end gap-2 pt-2 pb-4 mx-3 border-b border-gray-700">
          <PanelExpandButton panelRef={filterPanel}>
            <SlidersHorizontal size={16} strokeWidth={1.5} />
            <span>Filters</span>
          </PanelExpandButton>
          {toolbar}
          <PanelExpandButton panelRef={plotConfigPanel}>
            <SlidersHorizontal size={16} strokeWidth={1.5} />
            <span>Plot configuration</span>
          </PanelExpandButton>
        </div>
        <FilteredResultsPlot renderResults={plotResults} plotHeight={plotHeight} />
      </Panel>
      <PanelResizeHandle>{plotConfigPanelIsOpen && <Divider orientation="vertical" margin="thin" />}</PanelResizeHandle>
      <Panel
        minSizePercentage={10}
        defaultSizePercentage={10}
        collapsible
        ref={plotConfigPanel}
        onCollapse={() => setPlotConfigPanelIsOpen(false)}
        onExpand={() => setPlotConfigPanelIsOpen(true)}
      >
        <div className="overflow-y-auto h-full divide-y divide-gray-700 pl-3 pt-4">
          <PanelHeader title={"Plot config"} panelRef={plotConfigPanel} />
          {config}
        </div>
      </Panel>
    </PanelGroup>
  );
};

const FilteredResultsPlot = <T,>({
  className,
  renderResults,
  plotHeight,
}: {
  className?: string;
  renderResults: (resultGroups: [T[], string][], labels: string[] | null) => React.ReactNode;
  plotHeight: number;
}) => {
  const { filteredItems: filteredResultGroups, items: results, labels } = useFilterContext<T>();
  const wrappedRender = React.useCallback((results: [T[], string][]) => renderResults(results, labels), [labels]);

  const items = React.useMemo(() => _.zip(filteredResultGroups, labels ?? []), [filteredResultGroups, labels]) as [
    T[],
    string
  ][];

  if (!filteredResultGroups.length) {
    if (!results.length) {
      return (
        <div className={cn("flex flex-col items-center justify-center h-full", className)}>
          <FrownIcon size={128} className="text-slate-400" />
          <h3 className="text-xl font-semibold mt-8 mb-2">No results available</h3>
          <p className="text-slate-400">
            Make sure the desired analyses are enabled in the settings page. Run analysis to generate results.
          </p>
        </div>
      );
    } else {
      return (
        <div className={cn("flex flex-col items-center justify-center h-full", className)}>
          <SearchXIcon size={128} className="text-slate-400" />
          <h3 className="text-xl font-semibold mt-8 mb-2">No results match the current filters</h3>
          <p className="text-slate-400">
            There are {results.length} results available. Change the filters to see them.
          </p>
        </div>
      );
    }
  }

  return (
    <VirtualizedList
      className={cn("divide-y divide-gray-700 px-3", className)}
      itemHeight={plotHeight}
      items={items}
      renderItems={wrappedRender}
    />
  );
};

const generateResultPlotter =
  <T,>(PlotComponent: PlotComponentType<T>, getResultKey: (result: T) => string) =>
  (resultGroups: [T[], string][], labels: string[] | null) => {
    // Group results by label
    const splitResultGroups = resultGroups.reduce((acc, [results, label]) => {
      (acc[label] = acc[label] ?? []).push(results);
      return acc;
    }, {} as { [label: string]: T[][] });

    // Get number of labels to display ahead of the visible results. We render placeholders to avoid jumps when
    // scrolling
    const uniqueLabels = React.useMemo(() => _.uniq(labels), [labels]);
    const numPreLabels = uniqueLabels.indexOf(Object.keys(splitResultGroups)[0]);

    // Plot each group separately with a sticky label
    return (
      <>
        <div style={{ height: 44 * numPreLabels }} />
        {...Object.entries(splitResultGroups).map(([label, resultGroups]) => (
          <div key={label}>
            {label && (
              <div className="sticky top-0 z-10 flex justify-center">
                <span className="rounded-full bg-slate-700 text-pale text-sm my-2 py-1 px-5 max-w-[300px] truncate">
                  {label}
                </span>
              </div>
            )}
            <div className="divide-y divide-gray-700">
              {...resultGroups.map((results) => (
                <PlotComponent key={results.map(getResultKey).join(" & ")} results={results} className="py-4" />
              ))}
            </div>
          </div>
        ))}
      </>
    );
  };
