import { ResultOf } from "@graphql-typed-document-node/core";
import _ from "lodash";

import { ColumnType, FragmentType, gql } from "@/apis/nannyml";
import { useFilter, useFilterConfig, useFilterContext, useGrouper } from "@/components/Filters";
import { useLabeler } from "@/components/Filters/Filters.hooks";
import { AlertStatusFilter, PerformancePlotMode, SortOrder } from "@/constants/enums";
import { WithAlert } from "@/domains/threshold";

export const resultFilterFragment = gql(/* GraphQL */ `
  fragment ResultFilter on TimeSeriesResult {
    __typename
    id
    analysisType
    metricName
    componentName
    columnName
    columnNames
    segment {
      id
      segmentColumnName
      segment
    }
    tags

    ...TimeSeriesResultThreshold
    data {
      isAnalysis
      startTimestamp
      endTimestamp
      value
    }
  }
`);

export type ResultFilterFragmentType = FragmentType<typeof resultFilterFragment>;
export type ResultFilterType = WithAlert<ResultOf<typeof resultFilterFragment>, "data">;

export type ResultFilterConfig = {
  alertStatus?: AlertStatusFilter;
  columns?: string[];
  columnTypes?: ColumnType[];
  metrics: string[];
  plotMode?: PerformancePlotMode;
  tags: string[];
  sortDirection?: "asc" | "desc";
  sortOrder: SortOrder;
  segments?: (number | null)[];
};

export const useResultFilterContext = useFilterContext<ResultFilterType, ResultFilterConfig>;
export const useResultFilterConfig = useFilterConfig<ResultFilterConfig>;
export const useResultFilter = useFilter<ResultFilterType, ResultFilterConfig>;
export const useResultGrouper = useGrouper<ResultFilterType, ResultFilterConfig>;
export const useResultLabeler = useLabeler<ResultFilterType, ResultFilterConfig>;
