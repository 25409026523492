import { FragmentType, gql, useFragment } from "@/apis/nannyml";
import { BayesianResult, BayesianResultPlot } from "@/components/bayesian";
import { BayesianStatus } from "@/constants/bayesian";
import { getHdiWidthLabel } from "@/formatters/bayesian";

import { BayesianPlotCustomization } from "../bayesian/ResultPlot/ResultPlot";
import { InformationModalChip } from "../dashboard/InformationModal/InformationModalChip";

const experimentResultMetricPlotFragment = gql(/* GraphQL */ `
  fragment ExperimentResultMetricPlotFragment on ExperimentResultMetric {
    experimentId
    metric
    status
    hdiWidthReached
    distribution
    hdiData {
      nrObservations
      hdiLower
      hdiUpper
      cut
    }
    config {
      ropeLowerBound
      ropeUpperBound
      hdiWidth
    }
  }
`);

export const ExperimentMetricResultPlot = (props: {
  className?: string;
  result: FragmentType<typeof experimentResultMetricPlotFragment>;
}) => {
  const { distribution, ...result } = useFragment(experimentResultMetricPlotFragment, props.result);
  const compatibleResult = { ...result, experimentDistribution: distribution };
  return (
    <BayesianResultPlot
      className={props.className}
      result={compatibleResult}
      customization={exterimentPlotCustomization}
    />
  );
};

const ResultTitle = ({ result }: { result: BayesianResult }) => (
  <>
    <h2 className="text-lg font-bold">
      95% HDI of {result.metric}
      <sub>Treatment</sub> - {result.metric}
      <sub>Control</sub>
      <InformationModalChip className="inline px-0" infoName="Treatment - Control" />
    </h2>
    <h3 className="">
      {result.status === BayesianStatus.Accepted
        ? `Experiment accepted, required HDI width reached (${getHdiWidthLabel(result.config)})`
        : result.status === BayesianStatus.Rejected
        ? `Experiment rejected, required HDI width reached (${getHdiWidthLabel(result.config)})`
        : result.hdiWidthReached
        ? `Experiment is ongoing, required HDI width reached (${getHdiWidthLabel(result.config)})`
        : `Experiment is ongoing, required HDI width not reached (${getHdiWidthLabel(result.config)})`}
    </h3>
  </>
);

const exterimentPlotCustomization: BayesianPlotCustomization = {
  TitleComponent: ResultTitle,
  getMetricName: (metric) => `${metric}<sub>Treatment</sub> - ${metric}<sub>Control</sub>`,
  evaluationDatasetName: "Experiment",
};
