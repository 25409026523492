import { Check, X } from "lucide-react";

import { Button } from "@/components/common/Button";
import { cn } from "@/lib/utils";

export const UsageStatisticsPermission = ({
  className,
  permissionGranted,
  onClickPermissions,
}: {
  className?: string;
  permissionGranted: boolean | null;
  onClickPermissions: (isPermissionGranted: boolean) => void;
}) => {
  return (
    <div className={cn("flex flex-col gap-6 items-start w-full", className)}>
      <h2 className="text-2xl text-center">We would like to collect anonymous usage statistics</h2>

      <div className="flex flex-col max-w-[600px]">
        <p className="text-base">
          None of your metrics or raw data will be stored on our usage tracking system, and it will only be used to
          improve NannyML’s software.
        </p>
        <p>
          <a
            className="underline text-cyan-400"
            href="https://www.nannyml.com/blog/usage-statistics-in-nannyml"
            target="_blank"
          >
            You can read about the full details of what we track here
          </a>
        </p>
      </div>

      <div className="flex flex-col justify-center gap-4">
        <Button
          className={"px-3"}
          cva={{ intent: "confirm", size: "small2" }}
          preLabel={
            <div className="rounded-full border border-pale p-[2px] h-4 w-4">
              {permissionGranted && <Check strokeWidth={3} className="h-3 w-3" />}
            </div>
          }
          label={"I want to help NannyML improve"}
          onClick={() => onClickPermissions(true)}
        />

        <Button
          className={"px-3"}
          cva={{ intent: "reject", size: "small2" }}
          preLabel={
            <div className="rounded-full border border-pale p-[2px] h-4 w-4">
              {permissionGranted === false && <X strokeWidth={3} className="h-3 w-3" />}
            </div>
          }
          label={"Don’t anonymously track my usage"}
          onClick={() => onClickPermissions(false)}
        />
      </div>
    </div>
  );
};
