import { FileIcon, LinkIcon } from "lucide-react";
import React from "react";

import { ApplicationSettings, StorageInput } from "@/apis/nannyml";
import AzureBlobIcon from "@/assets/icons/AzureBlob.svg?react";
import S3Icon from "@/assets/icons/S3.svg?react";
import { useApplicationConfiguration } from "@/components/ApplicationConfiguration";
import { ButtonGroup } from "@/components/ButtonGroup";
import { Card } from "@/components/Card";
import { RadioGroup, RadioGroupItem } from "@/components/RadioGroup";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";
import { cn } from "@/lib/utils";

import { AmazonS3, amazonS3Summary } from "./AmazonS3";
import { AzureBlob, azureBlobSummary } from "./AzureBlob";
import { LocalFile, localFileSummary } from "./LocalFile";
import { PublicLink, publicLinkSummary } from "./PublicLink";

type StorageLocation = keyof StorageInput;
type StorageConnector<T extends StorageLocation> = {
  Icon: React.FC<{ size: number }>;
  label: string;
  description: string;
  constraint?: (settings: ApplicationSettings) => string;
  Component: React.FC<{
    className?: string;
    value: Partial<StorageInput[T]>;
    onChange: (value: Partial<NonNullable<StorageInput[T]>>) => void;
    reference?: Partial<StorageInput[T]>;
  }>;
  summary: (value: NonNullable<StorageInput[T]>) => string;
};

const storageConnectors: { [K in StorageLocation]: StorageConnector<K> } = {
  azureBlob: {
    Icon: ({ size }) => <AzureBlobIcon width={size} height={size} />,
    label: "Azure Blob Storage",
    description: "Upload data from Azure Blob Storage",
    Component: AzureBlob,
    summary: azureBlobSummary,
  },
  cache: {
    Icon: ({ size }) => <FileIcon size={size} />,
    label: "Local File",
    description: "Upload data from your device",
    constraint: ({ maxUploadSize }) => `Max ${Math.round(maxUploadSize / 1024 / 1024)} MB`,
    Component: LocalFile,
    summary: localFileSummary,
  },
  raw: {
    Icon: ({ size }) => <LinkIcon size={size} />,
    label: "Public Link",
    description: "Upload data from a public link",
    Component: PublicLink,
    summary: publicLinkSummary,
  },
  s3: {
    Icon: ({ size }) => <S3Icon width={size} height={size} />,
    label: "Amazon S3",
    description: "Upload data from Amazon S3",
    Component: AmazonS3,
    summary: amazonS3Summary,
  },
};

const getLocation = (value: StorageInput | undefined | null) =>
  Object.entries(value ?? {}).find(([_, v]) => v !== null)?.[0] as StorageLocation | undefined;

export type DatasetStorageDetailsProps = {
  className?: string;
  value: StorageInput | null;
  onChange: (value: StorageInput) => void;
  name?: string;
  reference?: StorageInput | null;
};

export const DatasetStorageDetails = ({ className, name, value, onChange, reference }: DatasetStorageDetailsProps) => {
  const appConfig = useApplicationConfiguration();
  const [location, setLocation] = React.useState(getLocation(value) ?? getLocation(reference));

  // If no location is selected, show a list of options as cards
  if (!location) {
    return (
      <div className={cn("flex flex-col items-center gap-8", className)}>
        <h3 className="flex text-lg font-bold col-span-full">
          <span>Where is your {name} data stored?</span>
          <InformationModalChip className="mx-0" infoName="File format" />
        </h3>
        <RadioGroup
          className="flex flex-row flex-wrap gap-6 justify-center"
          value={location}
          onValueChange={(value) => setLocation(value as StorageLocation)}
        >
          {Object.entries(storageConnectors).map(([location, connector]) => (
            <RadioGroupItem key={location} value={location} asChild>
              <Card className="relative basis-[235px] grid grid-cols-[auto_1fr] auto-rows-max gap-4">
                <connector.Icon size={32} />
                <span className="font-bold self-center">{connector.label}</span>
                <span className="text-sm text-gray-400 col-span-full">{connector.description}</span>
                {connector.constraint && (
                  <span className="text-sm absolute top-1 right-1 rounded-full bg-warningDeep px-2">
                    {connector.constraint(appConfig)}
                  </span>
                )}
              </Card>
            </RadioGroupItem>
          ))}
        </RadioGroup>
      </div>
    );
  }

  const Component = storageConnectors[location].Component;

  return (
    <div className={cn("self-stretch flex flex-col items-center", className)}>
      <h3 className="flex text-lg font-bold col-span-full">
        <span>Where is your {name} data stored?</span>
        <InformationModalChip className="mx-0 " infoName="File format" />
      </h3>
      <RadioGroup value={location} onValueChange={(value) => setLocation(value as StorageLocation)} asChild>
        <ButtonGroup className="!flex !gap-0 my-6 [&>*]:p-5">
          {Object.entries(storageConnectors).map(([location, connector]) => (
            <RadioGroupItem key={location} value={location} className="relative flex gap-2 items-center">
              <connector.Icon size={24} />
              <span>{connector.label}</span>
              {connector.constraint && (
                <span className="text-xs absolute top-1 right-1 rounded-full bg-warningDeep px-2">
                  {connector.constraint(appConfig)}
                </span>
              )}
            </RadioGroupItem>
          ))}
        </ButtonGroup>
      </RadioGroup>
      <Component
        className="grow justify-center"
        value={value?.[location] as any}
        onChange={(value) => onChange({ [location]: value })}
        reference={reference?.[location] as any}
      />
    </div>
  );
};

export const DatasetStorageSummary = ({ className, value }: { className?: string; value: StorageInput }) => {
  const location = getLocation(value);
  if (!location) return null;

  const connector = storageConnectors[location];
  const summary = connector.summary(value[location] as any);

  return (
    <div className={cn("flex gap-2 items-center", className)}>
      <div className="shrink-0">
        <connector.Icon size={16} />
      </div>
      <span className="truncate" title={summary}>
        {summary}
      </span>
    </div>
  );
};
