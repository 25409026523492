import { gql } from "@/apis/nannyml";

export const createUserApiToken = gql(/* GraphQL */ `
  mutation createUserApiToken($input: UserApiTokenCreateInput!) {
    create_user_api_token(input: $input) {
      id
      token
    }
  }
`);
