import * as Collapsible from "@radix-ui/react-collapsible";
import _ from "lodash";
import { ChevronDownIcon, ChevronRightIcon, ChevronLeftIcon } from "lucide-react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { RouteObject } from "react-router-dom";

import { SimpleTooltip } from "@/DesignSystem/nanny/SimpleTooltip/SimpleTooltip";
import { cn } from "@/lib/utils";

import { Button } from "./common/Button";

type NavigationContextType = {
  isOpen: (key: string) => boolean | undefined;
  setOpen: (key: string, value: boolean) => void;
};

type KeyContextType = {
  key: string;
  defaultOpen: boolean;
  parent?: KeyContextType;
};

const navigationContext = React.createContext<NavigationContextType | null>(null);
const treeKeyContext = React.createContext<KeyContextType>({ key: "root", defaultOpen: true });

const NavigationProvider = ({ children, id }: { children: React.ReactNode; id?: string }) => {
  const [openState, setOpenState] = React.useState<Record<string, boolean>>(
    id ? JSON.parse(localStorage.getItem(id) ?? "{}") : {}
  );

  const isOpen = (key: string) => openState[key];

  const setOpen = (key: string, value: boolean) => {
    setOpenState((state) => {
      const newState = { ...state, [key]: value };
      if (id) {
        localStorage.setItem(id, JSON.stringify(newState));
      }
      return newState;
    });
  };

  return <navigationContext.Provider value={{ isOpen, setOpen }}>{children}</navigationContext.Provider>;
};

const KeyProvider = ({
  children,
  id,
  defaultOpen = false,
}: {
  children: React.ReactNode;
  id: string;
  defaultOpen?: boolean;
}) => {
  const parent = React.useContext(treeKeyContext);

  const value = React.useMemo(
    () => ({
      key: `${parent.key}.${id}`,
      defaultOpen,
      parent,
    }),
    [parent]
  );
  return <treeKeyContext.Provider value={value}>{children}</treeKeyContext.Provider>;
};

export const useNavigationOpen = (child?: string, defaultOpen?: boolean) => {
  const keyCtx = React.useContext(treeKeyContext);
  const navCtx = React.useContext(navigationContext);
  if (!navCtx) {
    throw new Error("useNavigationOpen must be used within a NavigationProvider");
  }

  // Verify all parent levels are open
  let parent = child ? keyCtx : keyCtx.parent;
  while (parent && (navCtx.isOpen(parent.key) ?? parent.defaultOpen)) {
    parent = parent.parent;
  }
  const areParentsOpen = !parent;

  // Check if the current level or child is open. When accessing child, the `defaultOpen` provided to this hook is used.
  // Otherwise, the default open state of the current level is used.
  const key = child ? `${keyCtx.key}.${child}` : keyCtx.key;
  const isOpen = navCtx.isOpen(key) ?? (child ? defaultOpen : keyCtx.defaultOpen);

  return [areParentsOpen && isOpen, (isOpen: boolean) => navCtx.setOpen(key, isOpen)] as const;
};

export const Navigation = ({
  id,
  className,
  children,
}: React.PropsWithChildren<{ id?: string; className?: string }>) => (
  <NavigationProvider id={id}>
    <NavigationInner className={className} children={children} />
  </NavigationProvider>
);

const NavigationInner = ({ className, children }: { className?: string; children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useNavigationOpen();
  const PanelIcon = isOpen ? ChevronLeftIcon : ChevronRightIcon;

  return (
    <Collapsible.Root className={cn("relative flex flex-col h-full", className)} open={isOpen} onOpenChange={setIsOpen}>
      <Collapsible.Trigger asChild>
        <Button
          className="absolute top-2 right-0 p-0.5 translate-x-1/2 bg-dark border border-gray-600"
          cva={{ intent: "icon", size: "chip" }}
          title={isOpen ? "Collapse" : "Expand"}
        >
          <PanelIcon size={18} strokeWidth={1} />
        </Button>
      </Collapsible.Trigger>
      {children}
    </Collapsible.Root>
  );
};

const NavigationLevel = React.forwardRef<React.ElementRef<typeof Collapsible.Root>, { children: React.ReactNode }>(
  ({ children }, ref) => {
    const [isOpen, setIsOpen] = useNavigationOpen();

    return (
      <Collapsible.Root ref={ref} open={isOpen} onOpenChange={setIsOpen} className="ml-8" asChild>
        {children}
      </Collapsible.Root>
    );
  }
);

export const NavigationContent = Collapsible.Content;

export const NavigationGroup = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <div className="flex flex-col">
    <div className="h-6 px-4">
      <Collapsible.Content className="uppercase text-sm text-gray-400 select-none">{title}</Collapsible.Content>
    </div>
    {children}
  </div>
);

export const NavigationItem = ({
  Icon,
  link,
  label,
  children,
  subItem,
}: {
  Icon?: React.FC<{ size?: string | number }>;
  link: string;
  label: string;
  children?: React.ReactNode;
  subItem?: boolean;
}) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useNavigationOpen(label);
  const ariaCurrent =
    location.pathname === link || (children && !isOpen && location.pathname.startsWith(link)) ? "page" : undefined;
  const MenuIcon = isOpen ? ChevronDownIcon : ChevronRightIcon;

  return (
    <>
      <SimpleTooltip tooltipContent={label} side="right">
        <Link
          to={link}
          className={cn(
            "h-9 flex items-center mx-2 px-2 my-0.5 hover:bg-slate-700 rounded-lg",
            "aria-[current]:text-highlightDeep aria-[current]:bg-highlightPale/25",
            "aria-[current]:hover:bg-highlightPale/50 [&:has([data-state='closed'])]:w-[34px]",
            subItem &&
              "relative before:absolute before:bg-gray-600 before:top-4 before:-left-4 before:w-4 before:h-[1px]"
          )}
          aria-current={ariaCurrent}
          onClick={() => children && setIsOpen(true)}
        >
          {!subItem && Icon && <Icon size={18} />}
          <Collapsible.Content
            className={cn("grow flex justify-between items-center", !subItem && "data-[state=open]:ml-3")}
          >
            {label}
            {children && (
              <Button
                cva={{ intent: "icon", size: "small" }}
                onClick={(e) => (setIsOpen(!isOpen), e.preventDefault(), e.stopPropagation())}
              >
                <MenuIcon size={18} />
              </Button>
            )}
          </Collapsible.Content>
        </Link>
      </SimpleTooltip>
      {children && (
        <KeyProvider id={label}>
          <NavigationContent asChild>
            <NavigationLevel>
              <NavigationContent
                className={cn(
                  "relative before:absolute before:bg-gray-600 before:-top-0.5 before:bottom-[21px] before:-left-2 before:w-[1px]"
                )}
              >
                {children}
              </NavigationContent>
            </NavigationLevel>
          </NavigationContent>
        </KeyProvider>
      )}
    </>
  );
};

export const NavigationRoutes = <T,>({
  routes,
  url,
  className,
  ctx,
}: {
  routes: RouteObject<T>[];
  url: string;
  className?: string;
  ctx?: T;
}) => {
  const groups = _.groupBy(
    routes.filter((route) => route.handle && (route.handle.isApplicable?.(ctx) ?? true)),
    (route) => (route.handle as any).group
  );

  return (
    <div className={cn("flex flex-col py-2 gap-4 overflow-y-auto", className)}>
      {Object.entries(groups).map(([group, routes]) => (
        <NavigationGroup key={group} title={group}>
          <NavRoutes routes={routes} url={url} ctx={ctx} />
        </NavigationGroup>
      ))}
    </div>
  );
};

const NavRoutes = <T,>({
  routes,
  url,
  subItem,
  ctx,
}: {
  routes: RouteObject[];
  url: string;
  subItem?: boolean;
  ctx?: T;
}) => (
  <>
    {routes
      .filter((route) => route.handle && (route.handle.isApplicable?.(ctx) ?? true))
      .map((route) => (
        <NavigationItem
          key={route.path}
          Icon={route.handle!.icon}
          label={route.handle!.breadcrumb}
          link={`${url}/${route.path}`}
          subItem={subItem}
        >
          {route.children && (
            <NavRoutes routes={route.children} url={`${url}/${route.path}`} subItem={true} ctx={ctx} />
          )}
        </NavigationItem>
      ))}
  </>
);
