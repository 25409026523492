import * as Slider from "@radix-ui/react-slider";
import { useEffect, useState } from "react";

import { Text } from "@/DesignSystem/basic/Text/Text";
import { PlotInputIndex } from "@/domains/plotInputs/PlotInputs.types";
import { DateFormatWithDay, formatWith } from "@/lib/dateUtils";
import { cn } from "@/lib/utils";

export const RangeSlider = ({
  maxIndex,
  minIndex,
  markIndex,
  startValue,
  endValue,
  startIndices,
  endIndices,
  onChange,
  onCommit,
  className,
  labelClassName,
}: {
  maxIndex: number;
  minIndex: number;
  markIndex?: number;

  startValue: number;
  endValue: number;

  startIndices?: PlotInputIndex[];
  endIndices?: PlotInputIndex[];
  onChange?: (value: number[]) => void;
  onCommit?: (value: number[]) => void;
  className?: string;
  labelClassName?: string;
}) => {
  const [changingValues, setChangingValues] = useState([minIndex, maxIndex]);

  const [minValuePreview, maxValuePreview] = [startIndices?.[changingValues[0]], endIndices?.[changingValues[1]]];

  const markPositionPercentage = markIndex ? (markIndex * 100) / (maxIndex - minIndex) : undefined;

  const onChangeInternal = (values: number[]) => {
    setChangingValues(values);
    onChange?.(values);
  };

  const onCommitInternal = (values: number[]) => {
    // setShowValues(false);
    onCommit?.(values);
  };

  useEffect(() => {
    setChangingValues([startValue, endValue]);
  }, [startValue, endValue]);

  return (
    <Slider.Root
      defaultValue={[minIndex, maxIndex]}
      min={minIndex}
      max={maxIndex}
      value={changingValues}
      minStepsBetweenThumbs={1}
      onValueChange={onChangeInternal}
      onValueCommit={onCommitInternal}
      className={cn("relative flex items-center pt-4 pb-8", className)}
    >
      <Slider.Track className="relative h-1 bg-gray-500 flex-grow">
        <Slider.Range className="absolute h-1 bg-highlightDeep" />
      </Slider.Track>

      {markPositionPercentage ? (
        <div
          className={"absolute w-[1px] bg-white h-5"}
          style={{
            left: `${markPositionPercentage}%`,
          }}
        />
      ) : null}

      <Slider.Thumb className="block w-2 h-4 bg-highlightDeep hover:cursor-pointer">
        {minValuePreview ? (
          <div className="absolute top-5 pt-1 truncate justify-left">
            <Text size={"xSmall"} className={labelClassName}>
              {formatWith(minValuePreview, DateFormatWithDay)}
            </Text>
          </div>
        ) : null}
      </Slider.Thumb>

      <Slider.Thumb className="block w-2 h-4 bg-highlightDeep hover:cursor-pointer">
        {maxValuePreview ? (
          <div className="absolute top-5 left-[-6ch] pt-1 truncate justify-right">
            <Text size={"xSmall"} className={labelClassName}>
              {formatWith(maxValuePreview, DateFormatWithDay)}
            </Text>
          </div>
        ) : null}
      </Slider.Thumb>
    </Slider.Root>
  );
};
