import { Link, useMatches } from "react-router-dom";

import { cn } from "@/lib/utils";

export const Breadcrumb = ({ className }: { className?: string }) => {
  const matches = useMatches();
  const crumbs = matches
    .filter((match) => match.handle)
    .map((match) => ({
      icon: match.handle.icon,
      breadcrumb: match.handle.breadcrumb,
      url: match.pathname,
    }));

  return (
    <div className={cn("flex items-center gap-2", className)}>
      {crumbs.map((crumb, index) => [
        index > 0 && (
          <span key={`separator-${index}`} className="opacity-60 pointer-events-none">
            /
          </span>
        ),
        index == crumbs.length - 1 ? (
          <span key={index} className="flex items-center gap-2 pointer-events-none">
            {crumb.icon && <crumb.icon size={16} />}
            {crumb.breadcrumb}
          </span>
        ) : (
          <Link key={index} to={crumb.url} className="flex items-center gap-2 opacity-80 hover:opacity-100">
            {crumb.icon && <crumb.icon size={16} />}
            {crumb.breadcrumb}
          </Link>
        ),
      ])}
    </div>
  );
};
