import { ApolloClient, useApolloClient } from "@apollo/client";
import { PublicClientApplication } from "@azure/msal-browser";
import { CheckCircle2Icon, Loader2Icon, RotateCwIcon, Trash2Icon, XCircleIcon } from "lucide-react";
import React, { memo, useLayoutEffect, useState } from "react";

import { AuthMode, AuthenticationSettingsInput, OidcProviderInput } from "@/apis/nannyml";
import { verifyAuth } from "@/apis/nannyml/queries/verifyAuth";
import { Alert } from "@/components/Alert";
import { ButtonGroup } from "@/components/ButtonGroup";
import { Card } from "@/components/Card";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "@/components/Dialog";
import { RadioGroup, RadioGroupItem } from "@/components/RadioGroup";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/Table";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/Tabs";
import { Button } from "@/components/common/Button";
import { SimpleInput } from "@/components/common/FormElements/SimpleInput/SimpleInput";
import { authModeLabels } from "@/formatters/applicationConfiguration";
import { cn } from "@/lib/utils";

type AuthConfigProps = {
  auth: AuthenticationSettingsInput | null;
  className?: string;
  onChange: (auth: AuthenticationSettingsInput | null) => void;
};

export const AuthenticationSetup = ({ auth, className, onChange }: AuthConfigProps) => {
  const [mode, setMode] = useState<AuthMode | undefined>(auth?.mode);

  // If no mode is selected, show a list of options as cards
  if (!mode) {
    return (
      <div className={cn("flex flex-col justify-center items-center", className)}>
        <h3 className="text-xl font-bold mb-10">Select authentication method</h3>
        <RadioGroup className="w-full flex gap-6" value={mode} onValueChange={(value) => setMode(value as AuthMode)}>
          {Object.values(AuthMode).map((mode) => (
            <RadioGroupItem key={mode} value={mode} asChild>
              <Card className="basis-0 grow flex flex-col">
                <span className="font-bold mb-2">{authModeLabels[mode].label}</span>
                <span className="text-sm mb-5 text-gray-400">{authModeLabels[mode].description}</span>
                <span className="text-sm">{authModeLabels[mode].recommendation}</span>
              </Card>
            </RadioGroupItem>
          ))}
        </RadioGroup>
      </div>
    );
  }

  const AuthDetailComponent = authDetailComponents[mode];

  // If mode is selected, show small selector on top & configuration options for that mode underneath
  return (
    <div className={cn("flex flex-col items-center", className)}>
      <RadioGroup
        className="w-full mb-5"
        value={mode}
        onValueChange={(value) => (setMode(value as AuthMode), onChange(null))}
      >
        <ButtonGroup className="w-full">
          {Object.values(AuthMode).map((mode) => (
            <RadioGroupItem className="basis-0 grow flex flex-col text-left" key={mode} value={mode}>
              <span>{authModeLabels[mode].label}</span>
              <span className="text-sm text-gray-400">{authModeLabels[mode].description}</span>
            </RadioGroupItem>
          ))}
        </ButtonGroup>
      </RadioGroup>
      {mode && (
        <div className="w-full">
          <AuthDetailComponent auth={auth} onChange={onChange} />
        </div>
      )}
    </div>
  );
};

const AnonymousAuthenticationDetails = ({ auth, onChange }: AuthConfigProps) => {
  const { longDescription, recommendation } = authModeLabels[AuthMode.Anonymous];

  // No configuration required, so set the mode to anonymous when component is mounted
  useLayoutEffect(() => {
    onChange({ mode: AuthMode.Anonymous });
  }, []);

  return (
    <div className="grow flex flex-col gap-5">
      <p>{longDescription}</p>
      <p>{recommendation}</p>
      <Alert severity="warning" className="mt-5" title="Warning">
        Disabling authentication is not recommended for production use.
      </Alert>
    </div>
  );
};

const LocalAuthenticationDetails = ({ auth, onChange }: AuthConfigProps) => {
  const { longDescription, recommendation } = authModeLabels[AuthMode.Local];
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [verifyPassword, setVerifyPassword] = useState<string>("");
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState<boolean>(false);
  const [addUserError, setAddUserError] = useState<string | null>(null);

  const addUser = () => {
    if (auth?.localUsers?.some((user) => user.email === email)) {
      setAddUserError("User with this email already exists");
    } else if (password !== verifyPassword) {
      setAddUserError("Passwords do not match");
    } else {
      onChange({
        mode: AuthMode.Local,
        localUsers: (auth?.localUsers ?? []).concat({ email, password }),
      });
      setIsAddUserDialogOpen(false);
    }
  };

  const removeUser = (email: string) => {
    onChange({
      mode: AuthMode.Local,
      localUsers: (auth?.localUsers ?? []).filter((user) => user.email !== email),
    });
  };

  return (
    <div className="grow flex flex-col gap-5">
      <p>{longDescription}</p>
      <p>{recommendation}</p>
      <Alert severity="info" className="mt-5" title="Info">
        Currently we do not support dynamic user registration. You must define a list of user credentials below.
      </Alert>
      <div>
        <h3 className="text-lg font-bold mt-5">Users</h3>
        <Table className="mb-5">
          <TableHeader>
            <TableRow className="text-gray-400">
              <TableHead>Email</TableHead>
              <TableHead>Password</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {auth?.localUsers?.map((user) => (
              <TableRow key={user.email}>
                <TableCell>{user.email}</TableCell>
                <TableCell>*****</TableCell>
                <TableCell>
                  <Button
                    cva={{ size: "small2", intent: "icon" }}
                    onClick={() => removeUser(user.email)}
                    title="Remove user"
                  >
                    <Trash2Icon size={16} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Dialog open={isAddUserDialogOpen} onOpenChange={setIsAddUserDialogOpen}>
          <DialogTrigger asChild>
            <Button
              cva={{ intent: "action", border: "thin" }}
              onClick={() => {
                setEmail("");
                setPassword("");
                setVerifyPassword("");
              }}
            >
              Add user
            </Button>
          </DialogTrigger>
          <DialogContent className="text-pale">
            <form
              className="flex flex-col gap-5"
              onSubmit={(e) => {
                addUser();
                e.preventDefault();
              }}
            >
              <DialogTitle>Add user</DialogTitle>
              <SimpleInput
                inputClassName="dark:border-gray-500"
                label="Email"
                placeholder="Enter email"
                value={email}
                onChange={(value) => {
                  setEmail(value);
                  setAddUserError(null);
                }}
                fieldName="email"
              />
              <SimpleInput
                inputClassName="dark:border-gray-500"
                label="Password"
                placeholder="Enter password"
                value={password}
                type="password"
                onChange={(value) => {
                  setPassword(value);
                  setAddUserError(null);
                }}
                fieldName="password"
              />
              <SimpleInput
                inputClassName="dark:border-gray-500"
                label="Verify password"
                placeholder="Verify password"
                value={verifyPassword}
                type="password"
                onChange={(value) => {
                  setVerifyPassword(value);
                  setAddUserError(null);
                }}
                fieldName="verifyPassword"
              />
              <Button cva={{ intent: "action", border: "thin" }} type="submit">
                Add user
              </Button>
              {addUserError && (
                <Alert severity="error" title="Error">
                  {addUserError}
                </Alert>
              )}
            </form>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

const OidcAuthenticationDetails = ({ auth, onChange }: AuthConfigProps) => {
  const provider: Partial<OidcProviderInput> = auth?.oidcProviders?.length ? auth.oidcProviders[0] : {};
  const { longDescription, recommendation } = authModeLabels[AuthMode.Oidc];

  const [tenant, setTenant] = useState<string>("");
  const [clientId, setClientId] = useState<string>("");
  const [audience, setAudience] = useState<string>(provider.audience ?? "");
  const [authority, setAuthority] = useState<string>(provider.authority ?? "");
  const [issuer, setIssuer] = useState<string>(provider.issuer ?? "");
  const [tab, setTab] = useState<string>(provider.audience ? "custom" : "azure");

  return (
    <div className="grow flex flex-col gap-5">
      <p>{longDescription}</p>
      <p>{recommendation}</p>

      <Tabs className="mt-5" value={tab} onValueChange={setTab}>
        <TabsList>
          <TabsTrigger value="azure">Azure AD</TabsTrigger>
          <TabsTrigger value="custom">Custom</TabsTrigger>
        </TabsList>
        <TabsContent value="azure" className="flex flex-col gap-3">
          <p>
            Follow the{" "}
            <a
              href="https://learn.microsoft.com/en-us/entra/identity-platform/quickstart-register-app"
              className="underline"
              target="_blank"
            >
              Microsoft documentation
            </a>{" "}
            to register a single-page application with Azure AD, then enter the required fields below.
          </p>
          <form className="flex flex-col gap-3 w-[300px]" onSubmit={(e) => e.preventDefault()}>
            <SimpleInput
              inputClassName="dark:border-gray-500"
              label="Tenant"
              placeholder="Enter your Tenant ID"
              value={tenant}
              onChange={setTenant}
              fieldName="tenant"
            />
            <SimpleInput
              inputClassName="dark:border-gray-500"
              label="Client ID"
              placeholder="Enter your Client ID"
              value={clientId}
              onChange={setClientId}
              fieldName="clientId"
            />
            <TestOidcConnection
              className="mt-2"
              provider={getAzureOidcProvider(tenant, clientId)}
              onConnectionTest={(provider, success) =>
                onChange(
                  !success
                    ? null
                    : {
                        mode: AuthMode.Oidc,
                        oidcProviders: [provider],
                      }
                )
              }
            />
          </form>
        </TabsContent>
        <TabsContent value="custom" className="flex flex-col gap-3">
          <p>Register your application with your OIDC provider, then enter the required fields below.</p>
          <Alert severity="warning" title="Warning">
            <span>
              Authentication may fail due to small differences in how different providers handle the OIDC protocol.{" "}
              <a className="underline" href="mailto:support@nannyml.com">
                Contact us
              </a>{" "}
              if you are having trouble.
            </span>
          </Alert>
          <form className="flex flex-col gap-3 w-full lg:w-3/4 xl:w-2/3" onSubmit={(e) => e.preventDefault()}>
            <SimpleInput
              inputClassName="dark:border-gray-500"
              label="Audience"
              placeholder="Enter your Audience ID"
              value={audience}
              onChange={setAudience}
              fieldName="audience"
            />
            <SimpleInput
              inputClassName="dark:border-gray-500"
              label="Authority"
              placeholder="Enter your Authority URL"
              value={authority}
              onChange={setAuthority}
              fieldName="authority"
            />
            <SimpleInput
              inputClassName="dark:border-gray-500"
              label="Issuer"
              placeholder="Enter your Issuer URL"
              value={issuer}
              onChange={setIssuer}
              fieldName="issuer"
            />
            <TestOidcConnection
              className="mt-2"
              provider={{ audience, authority, issuer, redirectUri: window.location.origin }}
              onConnectionTest={(provider, success) =>
                onChange(
                  !success
                    ? null
                    : {
                        mode: AuthMode.Oidc,
                        oidcProviders: [provider],
                      }
                )
              }
            />
          </form>
        </TabsContent>
      </Tabs>
    </div>
  );
};

const authDetailComponents: Record<AuthMode, React.FunctionComponent<AuthConfigProps>> = {
  [AuthMode.Anonymous]: AnonymousAuthenticationDetails,
  [AuthMode.Local]: LocalAuthenticationDetails,
  [AuthMode.Oidc]: OidcAuthenticationDetails,
};

const TestOidcConnection = memo(
  ({
    className,
    onConnectionTest,
    provider,
  }: {
    className?: string;
    onConnectionTest: (provider: OidcProviderInput, result: boolean) => void;
    provider: OidcProviderInput;
  }) => {
    const client = useApolloClient();
    const [connectionResult, setConnectionResult] = useState<boolean | null>(null);
    const [isTestActive, setIsTestActive] = useState<boolean>(false);

    useLayoutEffect(() => {
      setConnectionResult(null);
    }, [provider]);

    const verifyConnection = () => {
      setIsTestActive(true);
      verifyOidcAuth(client, provider)
        .then(() => (setConnectionResult(true), onConnectionTest(provider, true)))
        .catch(() => (setConnectionResult(false), onConnectionTest(provider, false)))
        .finally(() => setIsTestActive(false));
    };

    if (isTestActive) {
      return (
        <div className={className}>
          <Loader2Icon className="animate-spin" size={24} />
        </div>
      );
    } else if (connectionResult === null) {
      return (
        <div className={className}>
          <Button cva={{ intent: "action", border: "thin" }} onClick={verifyConnection} type="submit">
            Test authentication
          </Button>
        </div>
      );
    } else if (connectionResult === true) {
      return (
        <div className={cn("flex items-center gap-2 text-green-600", className)}>
          <CheckCircle2Icon />
          <span>Authentication successful</span>
          <Button
            cva={{ size: "small2", intent: "secondary", border: "thin" }}
            onClick={verifyConnection}
            title="Try again"
          >
            <RotateCwIcon size={16} />
          </Button>
        </div>
      );
    } else {
      return (
        <div className={cn("flex items-center gap-2 text-red-600", className)}>
          <XCircleIcon />
          <span>Authentication failed</span>
          <Button
            cva={{ size: "small2", intent: "secondary", border: "thin" }}
            onClick={verifyConnection}
            title="Try again"
          >
            <RotateCwIcon size={16} />
          </Button>
        </div>
      );
    }
  },
  (prevProps, nextProps) => {
    return (
      prevProps.provider.audience === nextProps.provider.audience &&
      prevProps.provider.authority === nextProps.provider.authority &&
      prevProps.provider.issuer === nextProps.provider.issuer &&
      prevProps.provider.redirectUri === nextProps.provider.redirectUri
    );
  }
);

const getAzureOidcProvider = (tenant: string, clientId: string) => ({
  audience: clientId,
  authority: `https://login.microsoftonline.com/${tenant}/`,
  issuer: `https://sts.windows.net/${tenant}/`,
  redirectUri: window.location.origin,
});

const verifyOidcAuth = (client: ApolloClient<object>, provider: OidcProviderInput) => {
  const pca = new PublicClientApplication({
    auth: {
      clientId: provider.audience,
      authority: provider.authority,
      redirectUri: window.location.origin,
    },
  });

  // Get token from Azure AD
  return (
    pca
      .acquireTokenPopup({ scopes: [`${provider.audience}/.default`] })
      // Verify token with NannyML Cloud server
      .then((response) =>
        client.query({
          query: verifyAuth,
          fetchPolicy: "no-cache",
          variables: {
            accessToken: response.accessToken,
            authSettings: {
              mode: AuthMode.Oidc,
              oidcProviders: [provider],
            },
          },
        })
      )
      // Verify response from NannyML Cloud server
      .then((response) => (response.data.verify_auth ? Promise.resolve() : Promise.reject()))
  );
};
