import { useContext } from "react";

import { DateFilterContext } from "./DateFilter.context";

export const useDateFilterContext = () => {
  const context = useContext(DateFilterContext);
  if (!context) {
    throw new Error("useDateFilterContext must be used within a DateFilterProvider");
  }
  return context;
};
