import { useEffect } from "react";

import { Label } from "@/DesignSystem/shadcn/Label/Label";
import { AzureBlobAuthenticationMode, AzureBlobStorageInput } from "@/apis/nannyml";
import { Select, SelectItem } from "@/components/Select";
import { SimpleInput } from "@/components/common/FormElements/SimpleInput/SimpleInput";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";
import { azureBlobAuthenticationModeLabels } from "@/formatters/dataSources";
import { cn } from "@/lib/utils";

export const AzureBlob = ({
  className,
  value,
  onChange,
  reference,
}: {
  className?: string;
  value: Partial<AzureBlobStorageInput> | null | undefined;
  onChange: (value: Partial<AzureBlobStorageInput>) => void;
  reference?: Partial<AzureBlobStorageInput> | null;
}) => {
  useEffect(() => {
    // Copy reference values if value is not set
    if (!value && reference) {
      const { path, ...rest } = reference;
      onChange(rest);
    }
    // Default to ManagedIdentity if authentication mode is not set
    else if (!value?.authenticationMode) {
      onChange({ ...value, authenticationMode: AzureBlobAuthenticationMode.ManagedIdentity });
    }
  }, []);

  return (
    <div className={cn("flex flex-col gap-6 min-w-[55ch]", className)}>
      <SimpleInput
        label="Account name"
        value={value?.accountName ?? ""}
        onChange={(accountName) => onChange({ ...value, accountName })}
        labelClassName="text-lg"
        placeholder="In which storage account is your data stored?"
        required
      />
      <SimpleInput
        label="Blob storage container"
        value={value?.container ?? ""}
        onChange={(container) => onChange({ ...value, container })}
        labelClassName="text-lg"
        placeholder="In which container is your data stored?"
        required
      />
      <SimpleInput
        label="File path"
        value={value?.path ?? ""}
        onChange={(path) => onChange({ ...value, path })}
        labelClassName="text-lg"
        placeholder="Where is your data stored within the container?"
        required
      />
      <Label className="flex flex-col gap-2 font-normal">
        <div className="flex">
          <span className="text-lg font-medium">Authentication mode</span>
          <InformationModalChip infoName="Azure Blob authentication mode" />
        </div>
        <Select
          value={value?.authenticationMode}
          onValueChange={(authMode) =>
            onChange({ ...value, authenticationMode: authMode as AzureBlobAuthenticationMode })
          }
          placeholder="How do you want to authenticate?"
          required
        >
          {Object.values(AzureBlobAuthenticationMode).map((authMode) => (
            <SelectItem key={authMode} value={authMode}>
              {azureBlobAuthenticationModeLabels[authMode]}
            </SelectItem>
          ))}
        </Select>
      </Label>
      {value?.authenticationMode === AzureBlobAuthenticationMode.AccessKey && (
        <SimpleInput
          label="Access key"
          value={value.accessKey ?? ""}
          onChange={(accessKey) => onChange({ ...value, accessKey })}
          labelClassName="text-lg"
          placeholder="Enter the access key for your storage account."
          required
        />
      )}
      {value?.authenticationMode === AzureBlobAuthenticationMode.SasToken && (
        <SimpleInput
          label="SAS token"
          value={value.sasToken ?? ""}
          onChange={(sasToken) => onChange({ ...value, sasToken })}
          labelClassName="text-lg"
          placeholder="Enter the SAS token for your file."
          required
        />
      )}
    </div>
  );
};

export const azureBlobSummary = (value: AzureBlobStorageInput) =>
  `${value.accountName}/${value.container}/${value.path}`;
