import { CheckIcon, ChevronLeftIcon, ChevronRightIcon, LoaderCircleIcon, XIcon } from "lucide-react";
import {
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useId,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { alert } from "@/components/Dialog";
import { Button } from "@/components/common/Button";
import { useIsFormValid } from "@/hooks/form";
import { cn } from "@/lib/utils";

import { WizardContext, WizardStep, WizardStepState, useWizardContext } from "./Wizard.context";

export const Wizard = <T extends object>({
  children,
  className,
  steps,
  value,
  defaultValue,
  onChange,
  onComplete,
  preventForwardJump,
  ...props
}: PropsWithChildren<{
  className?: string;
  steps: WizardStep<T>[];
  value?: Partial<T>;
  defaultValue?: Partial<T>;
  onChange?: (value: SetStateAction<Partial<T>>) => void;
  onComplete: (value: T) => void | Promise<void>;
  preventForwardJump?: boolean;
}>) => {
  const isControlled = typeof value !== "undefined" && typeof onChange !== "undefined";

  const id = useId();
  const [currentStepNr, setCurrentStepNr] = useState<number>(0);
  const [stepState, setStepsState] = useState<WizardStepState[]>(steps.map(() => WizardStepState.Incomplete));
  const [internalValue, setInternalValue] = useState<Partial<T>>(defaultValue ?? {});

  // For memoization we need stable references. Using refs to keep track of the latest values
  const valueRef = useRef(isControlled ? value : internalValue);
  const stepStateRef = useRef(stepState);
  const onCompleteRef = useRef(onComplete);

  // Set up hooks to synchronize refs to state values
  useEffect(() => {
    valueRef.current = isControlled ? value : internalValue;
  }, [isControlled, value, internalValue]);
  useEffect(() => {
    stepStateRef.current = stepState;
  }, [stepState]);
  useEffect(() => {
    onCompleteRef.current = onComplete;
  }, [onComplete]);

  const setStepState = useCallback(
    (stepNr: number, state: WizardStepState) => {
      setStepsState((stepState) => {
        // Skip updating if there's no change in state
        if (stepState[stepNr] === state) {
          return stepState;
        }

        const newState = [...stepState];
        newState[stepNr] = state;
        return newState;
      });
    },
    [setStepsState]
  );

  const setValue = useCallback(
    (update: Partial<T> | ((prev: Partial<T>) => Partial<T>)) => {
      const setValue = isControlled ? onChange : setInternalValue;
      setValue((prev: Partial<T>) => ({ ...prev, ...(typeof update === "function" ? update(prev) : update) }));
      setStepsState((state) => state.map((s, idx) => (idx > currentStepNr ? WizardStepState.Incomplete : s)));
    },
    [isControlled, onChange, setInternalValue, currentStepNr]
  );

  const goForward = useCallback(() => {
    if (currentStepNr === steps.length - 1) {
      if (stepStateRef.current.some((state) => state !== WizardStepState.Completed)) {
        return alert({
          title: "Cannot complete wizard",
          message: "Some steps are not completed",
          variant: "error",
        });
      } else {
        return onCompleteRef.current(valueRef.current as T);
      }
    } else {
      return (steps[currentStepNr].onComplete?.(valueRef.current, setValue) ?? Promise.resolve()).then(() =>
        setCurrentStepNr((stepNr) => stepNr + 1)
      );
    }
  }, [currentStepNr, setCurrentStepNr, steps, setValue]);

  const goBackward = useCallback(() => {
    setCurrentStepNr((stepNr) => (stepNr > 0 ? stepNr - 1 : stepNr));
  }, [setCurrentStepNr]);

  const goToStep = useCallback(
    (stepNr: number) => {
      if (stepNr < 0 || stepNr >= steps.length) {
        throw new Error(`Invalid step number ${stepNr}`);
      }
      setCurrentStepNr(stepNr);
    },
    [setCurrentStepNr, steps.length]
  );

  return (
    <WizardContext.Provider
      value={{
        id,
        value: isControlled ? value : internalValue,
        setValue,
        currentStepNr,
        steps,
        stepState,
        setStepState,
        goForward,
        goBackward,
        goToStep,
        preventForwardJump: preventForwardJump ?? false,
      }}
    >
      <div className={cn("flex flex-col gap-8 h-4/5 w-2/3 p-5", className)} {...props}>
        {children}
      </div>
    </WizardContext.Provider>
  );
};

export const WizardOverview = ({ className }: { className?: string }) => {
  const { currentStepNr, goToStep, steps, stepState, preventForwardJump } = useWizardContext();
  const lastCompletedStepNr = stepState.findLastIndex((state) => state === WizardStepState.Completed);

  const handleKeyboardNavigation = (e: React.KeyboardEvent<HTMLOListElement>) => {
    if (e.key === "ArrowLeft" || e.key === "ArrowDown") {
      goToStep(currentStepNr - 1);
    } else if (e.key === "ArrowRight" || e.key === "ArrowUp") {
      goToStep(currentStepNr + 1);
    }
  };

  return (
    <ol className={cn("w-full flex", className)} onKeyDown={handleKeyboardNavigation}>
      {steps.map((step, idx) => (
        <li
          key={idx}
          aria-current={idx === currentStepNr ? "step" : undefined}
          aria-checked={stepState[idx] === WizardStepState.Completed}
          aria-invalid={idx !== currentStepNr && stepState[idx] === WizardStepState.Invalid}
          data-previous={idx < currentStepNr}
          className={cn(
            "group/step grow basis-0 flex justify-center px-2",
            "relative after:absolute after:top-4 after:left-[calc(50%+16px)] after:w-[calc(100%-32px)] after:h-px",
            "after:bg-white/80 after:last:w-0 after:data-[previous=true]:bg-highlightDeep"
          )}
        >
          <button
            className="group/link flex flex-col items-center hover:enabled:cursor-pointer"
            aria-invalid={stepState[idx] === WizardStepState.Invalid}
            onClick={() => idx !== currentStepNr && goToStep(idx)}
            onKeyDown={(e) => (e.key === "Enter" || e.key === " ") && idx !== currentStepNr && goToStep(idx)}
            disabled={preventForwardJump && lastCompletedStepNr !== -1 && idx > lastCompletedStepNr}
            tabIndex={idx === currentStepNr ? 0 : -1}
          >
            <div
              className={cn(
                "w-8 h-8 flex items-center justify-center rounded-full mb-2 border-2",
                "group-hover/link:group-enabled/link:border-white/70",
                "group-aria-[current]/step:bg-transparent",
                "group-aria-[current]/step:border-highlightDeep",
                "group-aria-[current]/step:group-hover/link:group-enabled/link:bg-transparent",
                "group-aria-[current]/step:group-hover/link:group-enabled/link:border-highlightPale",
                "group-aria-checked/step:bg-highlightDeep ",
                "group-aria-checked/step:border-highlightDeep",
                "group-aria-checked/step:group-hover/link:group-enabled/link:bg-highlightPale",
                "group-aria-checked/step:group-hover/link:group-enabled/link:border-highlightPale",
                "group-aria-invalid/step:bg-red-600",
                "group-aria-invalid/step:border-red-600",
                "group-aria-invalid/step:group-hover/link:group-enabled/link:bg-red-800",
                "group-aria-invalid/step:group-hover/link:group-enabled/link:border-red-800"
              )}
            >
              {idx !== currentStepNr && stepState[idx] === WizardStepState.Completed ? (
                <CheckIcon size={24} className="text-white" />
              ) : idx !== currentStepNr && stepState[idx] === WizardStepState.Invalid ? (
                <XIcon size={24} className="text-white" />
              ) : (
                <div
                  className="
                      w-2 h-2 rounded-full mx-auto my-auto group-hover/link:group-enabled/link:bg-white/70
                      group-aria-[current]/step:bg-highlightDeep
                      group-aria-[current]/step:group-hover/link:group-enabled/link:bg-highlightPale
                      group-aria-invalid/step:bg-red-500
                      group-aria-invalid/step:group-hover/link:group-enabled/link:bg-red-700
                    "
                />
              )}
            </div>
            <span className="text-center">{step.title}</span>
            <span className="text-gray-400 text-sm font-light text-center">{step.subtitle}</span>
          </button>
        </li>
      ))}
    </ol>
  );
};

export const WizardContent = ({ className }: { className?: string }) => {
  const ctx = useWizardContext();
  const [ref, isFormValid] = useIsFormValid();
  const { id, value, setValue, currentStepNr, steps, setStepState } = ctx;

  // Update step resolution when form validity, value or step changes
  useEffect(() => {
    setStepState(
      currentStepNr,
      !(steps[currentStepNr].isCompleted?.(value, ctx) ?? true)
        ? WizardStepState.Incomplete
        : steps[currentStepNr].customControls || isFormValid
        ? WizardStepState.Completed
        : WizardStepState.Invalid
    );
  }, [currentStepNr, value, steps, isFormValid]);

  return steps[currentStepNr].customControls ? (
    <div key={currentStepNr} className={cn("flex-grow flex items-center justify-center", className)}>
      {steps[currentStepNr].render(value, setValue)}
    </div>
  ) : (
    <form
      key={currentStepNr}
      id={id}
      ref={ref}
      className={cn("flex-grow flex items-center justify-center group/form", className)}
      onSubmit={(e) => e.preventDefault()}
    >
      {steps[currentStepNr].render(value, setValue)}
    </form>
  );
};

export const WizardControlButtons = ({
  className,
  overrideCustomControls,
  backLabel = "Back",
  nextLabel = "Next",
  finishLabel = "Finish",
}: {
  className?: string;
  overrideCustomControls?: boolean;
  backLabel?: string;
  nextLabel?: string;
  finishLabel?: string;
}) => {
  const { id, currentStepNr, steps, stepState, goForward, goBackward, value, setStepState } = useWizardContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Clear error when changing steps or value
  useLayoutEffect(() => setError(null), [currentStepNr, value]);

  // Don't render component if step is configured to use custom controls
  if (steps[currentStepNr].customControls && !overrideCustomControls) {
    return null;
  }

  // Wrapper function to handle loading state and error handling
  const onSubmit = () => {
    const promise = goForward();
    if (promise instanceof Promise) {
      setLoading(true);
      promise
        .catch((error) => {
          setError(error?.message ?? error);
          setStepState(currentStepNr, WizardStepState.Invalid);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <div className={cn("w-full grid grid-cols-2 gap-4", className)}>
      <Button
        className="justify-self-end"
        cva={{ intent: "optional", size: "small2" }}
        onClick={goBackward}
        disabled={currentStepNr === 0}
      >
        <ChevronLeftIcon /> {backLabel}
      </Button>
      <Button
        type="submit"
        form={id}
        cva={{ intent: "primary", size: "small2" }}
        onClick={onSubmit}
        disabled={stepState[currentStepNr] !== WizardStepState.Completed || loading}
      >
        {currentStepNr < steps.length - 1 ? nextLabel : finishLabel}
        {loading ? (
          <LoaderCircleIcon className="animate-spin" />
        ) : currentStepNr < steps.length - 1 ? (
          <ChevronRightIcon />
        ) : (
          <CheckIcon />
        )}
      </Button>
      <div className="justify-self-center col-span-2">{error && <span className="text-red-500">{error}</span>}</div>
    </div>
  );
};
