import { PlusIcon, Trash2Icon, ArrowUp, ArrowDown } from "lucide-react";
import { ReactNode, useState } from "react";

import { Text } from "@/DesignSystem/basic/Text/Text";
import { ResultRefInput } from "@/apis/nannyml";
import { Dialog, DialogContent } from "@/components/Dialog";
import { getDefaultPlotConfig } from "@/components/monitoring/PlotConfig";
import { ResultView } from "@/domains/monitoring";
import { resultViewLabels } from "@/formatters/monitoring";
import { formatISODate } from "@/lib/dateUtils";
import { cn } from "@/lib/utils";
import { Metadata } from "@/pages/Reporting/Report";
import { useParamsModelId } from "@/routes/useParamsModelId";

import { Popover, PopoverContent, PopoverTrigger } from "../Popover";
import { Button } from "../common/Button";
import { Content } from "./Content";
import { ContentData, useModelResultList } from "./Report.utils";
import { sections } from "./plotUtils";

type reportOptions = {
  text: {
    options: ContentData[];
    label: string;
  };
  plots: {
    options: ContentData[];
    label: string;
  };
};

type Direction = "UP" | "DOWN";

const reportOptionsObj = {
  text: {
    options: [
      {
        label: "Section Header",
        key: "ContentText",
        args: ["text-xl"],
        textOptions: {
          placeholder: "Sub-title",
          className: "text-xl",
          cols: 100,
          rows: 1,
          iconSize: 17,
        },
      },
      {
        label: "Text",
        key: "ContentText",
        args: ["text-base"],
        textOptions: {
          placeholder: "Placeholder",
          className: "text-base",
          cols: 100,
          rows: 5,
          iconSize: 14,
        },
      },
    ],
    label: "Text options",
  },
  plots: {
    label: "Plot options",
    options: [
      {
        label: resultViewLabels["performance"],
        key: "ResultPlot",
        args: ["performance"],
      },
      {
        label: resultViewLabels["covariate-shift"],
        key: "ResultPlot",
        args: ["covariate-shift"],
      },
      {
        label: resultViewLabels["concept-shift"],
        key: "ResultPlot",
        args: ["concept-shift"],
      },
      {
        label: resultViewLabels["data-quality"],
        key: "ResultPlot",
        args: ["data-quality"],
      },
    ],
  },
} as reportOptions;

const getPlotLabel = (analisysType: string) => {
  switch (analisysType) {
    case "CONCEPT_SHIFT":
      return "concept-shift";
    case "DATA_QUALITY":
      return "data-quality";
    case "FEATURE_DRIFT":
      return "covariate-shift";
    default:
      return "performance";
  }
};

const getPlotsComponent = (plotType: string) => {
  switch (plotType) {
    case "concept-shift":
      return sections["concept-shift"];
    case "data-quality":
      return sections["data-quality"];
    case "covariate-shift":
      return sections["covariate-shift"];
    default:
      return sections.performance;
  }
};

const ResultsHeader = (view: ResultView, modelId: number) => (
  <>
    <Text size="large">{resultViewLabels[view]}</Text>
    <Text>Select {resultViewLabels[view].toLowerCase()} metrics you want to see in the report view.</Text>
    <Text className="text-sm text-gray-400 -mt-4">The metrics selected here apply only to this report.</Text>
  </>
);

const MovableContent = ({
  content,
  dateRange,
  removeContent,
  onContentSwitch,
  onUpdateContentData,
  onAddNewContent,
  onOpenDialog,
  newContentId,
}: {
  content: ContentData;
  dateRange: [number, number] | null;
  removeContent: (contentId: number, plotId?: string) => void;
  onContentSwitch: (id: number, direction: Direction) => void;
  onUpdateContentData: (content: ContentData) => void;
  onAddNewContent: (content: ContentData, id: number) => void;
  onOpenDialog: (flag: string, contentId: number) => void;
  newContentId: number;
}) => {
  return (
    <div key={content.id} className={cn("hover:bg-deepBg", "rounded-md", "flex flex-row", "m-1 group")}>
      <div className="pl-4 py-4 w-[10%]">
        <div className={"flex gap-0.5 text-gray-500 group-hover:text-white"}>
          <NewElement id={content.id} openDialog={onOpenDialog} addNewContent={onAddNewContent}>
            <Button className="text-current" cva={{ intent: "icon", size: "chip" }}>
              <PlusIcon size={16} />
            </Button>
          </NewElement>
          <Button
            cva={{ intent: "icon", size: "chip" }}
            className="text-current"
            onClick={() => onContentSwitch(content.id, "UP")}
          >
            <ArrowUp size={16} />
          </Button>
          <Button
            cva={{ intent: "icon", size: "chip" }}
            className="text-current"
            onClick={() => onContentSwitch(content.id, "DOWN")}
          >
            <ArrowDown size={16} />
          </Button>
        </div>
      </div>
      <div className="p-4 w-[80%]">
        <Content content={content} dateRange={dateRange} onContentUpdate={onUpdateContentData} />
      </div>
      <div className={cn("py-3 w-[10%]", "hidden", "group-hover:flex")}>
        <Trash2Icon
          className="hover:bg-slate-600 m-2"
          onClick={() => removeContent(content.id, content.plotOptions?.id)}
        />
      </div>
    </div>
  );
};

const PlotDialog = ({
  plotSection,
  modelId,
  onResultsSelect,
  open,
  onConfirm,
  onCancel,
  selectedPlots,
}: {
  plotSection: string;
  modelId: number;
  onResultsSelect: (resultRef: ResultRefInput, resultId: string, selected: boolean) => void;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  selectedPlots: string[];
}) => {
  const plot = getPlotsComponent(plotSection);

  const allResults = useModelResultList(plot.resultFilter);

  return (
    <Dialog open={open} onOpenChange={onCancel}>
      <DialogContent className="max-w-fit h-[70%] overflow-auto">
        <div className="flex flex-col h-[100%]  justify-between">
          <div className="overflow-auto h-[80%]">
            <plot.EditSectionComponent
              modelId={modelId}
              results={allResults}
              onResultSelect={onResultsSelect}
              selectedResultIds={selectedPlots}
              Heading={ResultsHeader}
            />
          </div>
          <div className="m-3 gap-2 flex justify-end">
            <Button onClick={onCancel} cva={{ intent: "secondary", size: "medium" }}>
              Cancel
            </Button>
            <Button onClick={onConfirm} cva={{ intent: "primary", size: "medium" }}>
              Confirm
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const NewElement = ({
  id,
  addNewContent,
  openDialog,
  children,
  className,
}: {
  id: number;
  addNewContent: (content: ContentData, id: number) => void;
  openDialog: (flag: string, contentId: number) => void;
  children: ReactNode;
  className?: string;
}) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const addContentAndClose = (data: ContentData) => {
    addNewContent(data, id);
    setPopoverOpen(false);
  };
  return (
    <Popover open={isPopoverOpen} onOpenChange={setPopoverOpen}>
      <PopoverTrigger className={className} asChild>
        {children}
      </PopoverTrigger>
      <PopoverContent align="start" className="text-slate-400 p-2 w-fit fcol">
        <span className="font-semibold">{reportOptionsObj.text.label}</span>
        {reportOptionsObj.text.options.map((option) => {
          const newOption = option;
          return (
            <Button
              key={newOption.label}
              className="p-1 px-2 hover:bg-deepBg"
              onClick={() => addContentAndClose(newOption)}
            >
              {newOption.label}
            </Button>
          );
        })}
        <span className="font-semibold">{reportOptionsObj.plots.label}</span>
        {reportOptionsObj.plots.options.map((option) => {
          return (
            <Button
              key={option.label}
              className="p-1 px-2 hover:bg-deepBg"
              onClick={() => openDialog(option.args[0], id)}
            >
              {option.label}
            </Button>
          );
        })}
      </PopoverContent>
    </Popover>
  );
};

export const Block = ({
  contents,
  metadata,
  onUpdateContent,
}: {
  contents: ContentData[];
  metadata: Metadata;
  onUpdateContent: (contents: ContentData[]) => void;
}) => {
  const modelId = useParamsModelId();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [plotSection, setPlotSection] = useState("");
  const [selectedPlots, setSelectedPlots] = useState<string[]>([]);
  const [newPlots, setNewPlots] = useState<ContentData[]>([]);
  const [id, setId] = useState(contents.length + 1);
  const [newContentIndex, setNewContentIndex] = useState(0);
  const [dateRange, setDateRange] = useState<[number, number] | null>(metadata.dateRange ? metadata.dateRange : null);

  const addNewContent = (option: ContentData) => {
    setId(id + 1);

    onUpdateContent([...contents, { ...option, id: id, data: "" }]);
  };

  const addNewContentInBetween = (content: ContentData, objectId: number) => {
    const objectIndex = contents.findIndex((r) => r.id === objectId);
    setId(id + 1);

    onUpdateContent([
      ...contents.slice(0, objectIndex + 1),
      { ...content, id: id, data: "" },
      ...contents.slice(objectIndex + 1),
    ]);
  };

  const updateContentInContentList = (content: ContentData) => {
    const objectIndex = contents.findIndex((r) => r.id === content.id);

    onUpdateContent([...contents.slice(0, objectIndex), content, ...contents.slice(objectIndex + 1)]);
  };

  const onResultsSelect = (resultRef: ResultRefInput, resultId: string, selected: boolean) => {
    if (selected) {
      setId(id + 1);
      const plotType = getPlotLabel(resultRef.analysisType);

      setSelectedPlots([...selectedPlots, resultId]);

      setNewPlots([
        ...newPlots,
        {
          label: resultViewLabels[plotType],
          key: "ResultPlot",
          args: [plotType],
          id: id,
          plotOptions: {
            modelId: modelId,
            results: [
              {
                modelId: modelId,
                analysisType: resultRef.analysisType,
                columnName: resultRef.columnName ? resultRef.columnName : "",
                metricName: resultRef.metricName ? resultRef.metricName : "",
                componentName: resultRef.componentName ? resultRef.componentName : "",
                id: resultId,
              },
            ],
            layout: {
              xaxis: { range: metadata.dateRange ? metadata.dateRange : null },
            },
            plotConfig: getDefaultPlotConfig(),
          },
        },
      ]);
    } else {
      setNewPlots(newPlots.filter((newPlot) => newPlot.plotOptions?.id != resultId));

      setSelectedPlots(selectedPlots.filter((plotId) => plotId != resultId));
    }
  };

  const removeContent = (id: number, plotId?: string) => {
    const newContentList = contents.filter((content) => content.id != id);

    onUpdateContent(newContentList);
  };

  const openDialog = (selectedPlot: string, contentId: number) => {
    setNewContentIndex(contents.findIndex((r) => r.id === contentId));
    setPlotSection(selectedPlot);
    setDialogOpen(true);
  };

  const openDialogLastElement = (selectedPlot: string, contentId: number) => {
    setNewContentIndex(contents.length);
    setPlotSection(selectedPlot);
    setDialogOpen(true);
  };

  const savePlotSelection = () => {
    setDialogOpen(false);

    onUpdateContent([...contents.slice(0, newContentIndex + 1), ...newPlots, ...contents.slice(newContentIndex + 1)]);

    setNewPlots([]);
    setSelectedPlots([]);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setSelectedPlots([]);
  };

  const switchComponents = (id: number, direction: Direction) => {
    const indexA = contents.findIndex((r) => r.id === id);

    const newIndex = direction === "UP" ? indexA - 1 : indexA + 1;

    if (newIndex >= 0 && newIndex <= contents.length) {
      const newContents = [...contents];
      const moningContent = newContents[indexA];
      newContents.splice(indexA, 1);

      onUpdateContent([...newContents.slice(0, newIndex), moningContent, ...newContents.slice(newIndex)]);
    }
  };

  return (
    <>
      <div className="flex justify-end m-2">
        <div className="fcol w-1/4 justify-end">
          {dateRange ? (
            <label className="text-slate-400">
              Report from: {formatISODate(new Date(dateRange[0]))} to: {formatISODate(new Date(dateRange[1]))}
            </label>
          ) : null}
        </div>
      </div>
      {contents.map((content, idx) => (
        <div key={content.id}>
          <MovableContent
            onContentSwitch={switchComponents}
            content={content}
            removeContent={removeContent}
            onUpdateContentData={updateContentInContentList}
            onAddNewContent={addNewContentInBetween}
            onOpenDialog={openDialog}
            newContentId={id}
            dateRange={dateRange}
          />
        </div>
      ))}
      <NewElement
        className="w-full h-15 flex p-4 hover:bg-slate-600 bg-deepBg m-1 rounded-md"
        id={id}
        openDialog={openDialogLastElement}
        addNewContent={addNewContent}
      >
        <Button className="flex items-center gap-4" cva={{ size: "small" }}>
          <PlusIcon size={16} />
          <span className="font-semibold">Add new element</span>
        </Button>
      </NewElement>
      <PlotDialog
        plotSection={plotSection}
        modelId={modelId}
        onResultsSelect={onResultsSelect}
        open={dialogOpen}
        onCancel={closeDialog}
        onConfirm={savePlotSelection}
        selectedPlots={selectedPlots}
      />
    </>
  );
};
