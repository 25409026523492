import { RawStorageInput } from "@/apis/nannyml";
import { SimpleInput } from "@/components/common/FormElements/SimpleInput/SimpleInput";
import { cn } from "@/lib/utils";

export const PublicLink = ({
  className,
  value,
  onChange,
}: {
  className?: string;
  value: Partial<RawStorageInput> | null | undefined;
  onChange: (value: Partial<RawStorageInput>) => void;
}) => (
  <div className={cn("flex flex-col gap-6 min-w-[55ch]", className)}>
    <SimpleInput
      label="Public link URL"
      value={value?.connectionString ?? ""}
      onChange={(connectionString) => onChange({ connectionString })}
      labelClassName="text-lg"
      placeholder="Enter the public link to your data."
      required
    />
  </div>
);

export const publicLinkSummary = (value: RawStorageInput) => value.connectionString;
