import {
  BayesianPlotDataset,
  BayesianPlotElements,
  BayesianPlotType,
  BayesianSortOrder,
  BayesianStatus,
} from "@/constants/bayesian";
import * as colors from "@/constants/colors";

export const bayesianStatusLabels: Record<BayesianStatus, string> = {
  [BayesianStatus.Accepted]: "ACCEPTED",
  [BayesianStatus.Rejected]: "REJECTED",
  [BayesianStatus.Ongoing]: "ONGOING",
};

export const bayesianSortOrderLabels: Record<BayesianSortOrder, string> = {
  [BayesianSortOrder.Status]: "Status",
  [BayesianSortOrder.Metric]: "Metric",
  [BayesianSortOrder.CurrentHdiWidth]: "Current precision",
};

export const bayesianPlotDatasetLabels: Record<BayesianPlotDataset, string> = {
  [BayesianPlotDataset.Evaluation]: "Evaluation",
  [BayesianPlotDataset.Reference]: "Reference",
};

export const bayesianPlotTypeLabels: Record<BayesianPlotType, string> = {
  [BayesianPlotType.Progression]: "Progression",
  [BayesianPlotType.Distribution]: "Probability distribution",
};

export const bayesianPlotElementLabels: Record<BayesianPlotElements, string> = {
  [BayesianPlotElements.Rope]: "ROPE",
  [BayesianPlotElements.Legend]: "Legend",
  [BayesianPlotElements.Grid]: "Grid",
};

export const bayesianTraceColors: Record<BayesianStatus, string> = {
  [BayesianStatus.Accepted]: colors.acceptedTraceColor,
  [BayesianStatus.Rejected]: colors.rejectedTraceColor,
  [BayesianStatus.Ongoing]: colors.inconclusiveTraceColor,
};

export const bayesianFillColors: Record<BayesianStatus, string> = {
  [BayesianStatus.Accepted]: colors.acceptedFillColor,
  [BayesianStatus.Rejected]: colors.rejectedFillColor,
  [BayesianStatus.Ongoing]: colors.inconclusiveFillColor,
};

/**
 * Number of significant digits to use when formatting numbers
 */
export const nrSignificantDigits = 3;

export const getHdiLabel = (
  hdi: { hdiLower: number; hdiUpper: number },
  { hdiWidth }: { hdiWidth?: number | null }
) => {
  let ref = hdiWidth ?? hdi.hdiUpper - hdi.hdiLower;
  return `${toReferencePrecision(hdi.hdiLower, ref)} \u00a0\u2013\u00a0 ${toReferencePrecision(hdi.hdiUpper, ref)}`;
};

export const getRopeLabel = (
  rope: { ropeLowerBound?: number | null; ropeUpperBound?: number | null },
  { hdiWidth }: { hdiWidth?: number | null }
) => {
  if ((!rope.ropeLowerBound && rope.ropeLowerBound !== 0) || !rope.ropeUpperBound) {
    return "To be calculated";
  }
  let ref = hdiWidth ?? rope.ropeUpperBound - rope.ropeLowerBound;
  return `${toReferencePrecision(rope.ropeLowerBound, ref)} \u00a0\u2013\u00a0 ${toReferencePrecision(
    rope.ropeUpperBound,
    ref
  )}`;
};

export const getHdiWidthLabel = ({ hdiWidth }: { hdiWidth?: number | null }) => {
  return hdiWidth?.toPrecision(nrSignificantDigits) ?? "To be calculated";
};

/**
 * Format a number to a certain number of digits based on precision of another
 * @param value The number to be formatted
 * @param ref The number to use as a reference for precision
 * @param precision Number of signficant digits to use (on the reference number)
 * @returns The value formatted to the same number of digits after the decimal as the reference has when formatted to
 * the specified number of significant digits.
 */
export const toReferencePrecision = (value: number, ref: number, precision: number = nrSignificantDigits) => {
  const refString = ref.toPrecision(precision);
  const nrDigits = refString.length - refString.indexOf(".") - 1;
  return value.toFixed(nrDigits);
};
