import { useSuspenseQuery } from "@apollo/client";
import * as Collapsible from "@radix-ui/react-collapsible";
import { LoaderCircleIcon, SettingsIcon, SheetIcon, TrendingUpIcon } from "lucide-react";
import { Suspense } from "react";
import { RouteObject } from "react-router-dom";

import { gql } from "@/apis/nannyml";
import { Divider } from "@/components/Divider";
import { Navigation, NavigationContent, NavigationRoutes, useNavigationOpen } from "@/components/Navigation";
import { Button } from "@/components/common/Button";
import { experimentTypeLabels } from "@/formatters/experiment";
import { useExperimentId } from "@/hooks/experiment";
import { cn } from "@/lib/utils";

import { ExperimentSettings } from "./Settings";
import { ExperimentResultsRoute } from "./results";
import { ExperimentSummary } from "./summary";

const getExperimentMetadataQuery = gql(/* GraphQL */ `
  query GetExperimentMetadata($id: Int!) {
    experiment(id: $id) {
      id
      name
      experimentType
    }
  }
`);

const routes: RouteObject[] = [
  {
    path: "",
    element: <ExperimentSummary />,
    handle: {
      breadcrumb: "Summary",
      icon: SheetIcon,
      group: "Experiment evaluation",
    },
  },
  {
    path: "metrics",
    element: <ExperimentResultsRoute resultView="metrics " />,
    handle: {
      breadcrumb: "Experiment metrics",
      icon: TrendingUpIcon,
      group: "Experiment evaluation",
    },
  },
  {
    path: "settings",
    element: <ExperimentSettings />,
    handle: {
      breadcrumb: "Settings",
      icon: SettingsIcon,
      group: "Management",
    },
  },
];

export const ExperimentContainer = ({ children }: { children: React.ReactNode }) => {
  const experimentId = useExperimentId();

  return (
    <div className="flex-grow flex h-full">
      <Navigation id="experiment.ExperimentContainer" className="min-w-fit">
        <Suspense fallback={<NavHeaderSkeleton />}>
          <NavHeader experimentId={experimentId} />
        </Suspense>
        <Divider orientation="horizontal" className="mt-1 mb-0" />
        <NavigationRoutes routes={routes} url={`/experiment/${experimentId}`} />
      </Navigation>
      <Divider orientation="vertical" margin="none" />
      <div className="flex-grow h-full overflow-auto">
        <Suspense fallback={<ContentSkeleton />}>{children}</Suspense>
      </div>
    </div>
  );
};

ExperimentContainer.routes = routes;

const NavHeader = ({ experimentId }: { experimentId: number }) => {
  const [isOpen] = useNavigationOpen();
  const {
    data: { experiment },
  } = useSuspenseQuery(getExperimentMetadataQuery, { variables: { id: experimentId } });

  if (!experiment) {
    throw new Error(`Experiment '${experimentId}' not found`);
  }

  return isOpen ? (
    <NavigationContent className="h-[60px] px-4 grid grid-cols-[auto_1fr] items-center gap-x-2 py-2">
      <div className="truncate max-w-[min(20vw,25ch)]" title={experiment.name}>
        {experiment.name}
      </div>
      <span className="text-sm text-gray-400">#{experimentId}</span>
      <div className="col-span-full text-sm text-gray-400">{experimentTypeLabels[experiment.experimentType]}</div>
    </NavigationContent>
  ) : (
    <span className="h-[60px] mx-auto flex items-end text-sm text-gray-400">#{experimentId}</span>
  );
};

const NavHeaderSkeleton = () => (
  <div className="h-[60px] px-4 py-2 space-y-2">
    <div className="w-[25ch] h-5 bg-gray-500 animate-pulse rounded" />
    <div className="w-[14ch] h-4 bg-gray-500 animate-pulse rounded" />
  </div>
);

const ContentSkeleton = () => (
  <div className="h-full w-full flex gap-2 items-center justify-center text-gray-400">
    <LoaderCircleIcon className="animate-spin text-highlightDeep" size={24} />
    <p>Loading the latest data for you...</p>
  </div>
);
