import { useSuspenseQuery } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";
import { PlusIcon, SearchIcon, SearchXIcon } from "lucide-react";
import { Suspense, useState } from "react";
import { Link } from "react-router-dom";

import { Input } from "@/DesignSystem/shadcn/Input";
import { Label } from "@/DesignSystem/shadcn/Label/Label";
import { gql } from "@/apis/nannyml";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/Popover";
import { Button } from "@/components/common/Button";
import { useSearchList } from "@/hooks/useSearchList";
import { cn } from "@/lib/utils";

import { useModelContext } from "../ModelContext";

const getCustomMonitoringMetricsQuery = gql(/* GraphQL */ `
  query GetCustomMonitoringMetrics($filter: MetricsFilter, $input: GetDefaultMetricConfigInput!) {
    monitoring_metrics(filter: $filter) {
      id
      name
      defaultConfig(input: $input) {
        ...CustomMetricConfigDetails
      }
    }
  }
`);

export type CustomMetricDetails = ResultOf<typeof getCustomMonitoringMetricsQuery>["monitoring_metrics"][0];

export const AddCustomMetricButton = ({
  className,
  onSelectMetric,
  excludeMetrics,
}: {
  className?: string;
  onSelectMetric: (metric: CustomMetricDetails) => void;
  excludeMetrics?: number[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button cva={{ intent: "secondary", size: "mediumLong" }} className={className}>
          <PlusIcon size={16} />
          <span>Add custom metric</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-fit">
        <Suspense fallback="Loading metrics...">
          <CustomMetricSelector
            onSelectMetric={(metric) => {
              onSelectMetric(metric);
              setIsOpen(false);
            }}
            excludeMetrics={excludeMetrics}
          />
        </Suspense>
      </PopoverContent>
    </Popover>
  );
};

export const CustomMetricSelector = ({
  className,
  onSelectMetric,
  excludeMetrics,
}: {
  className?: string;
  onSelectMetric: (metric: CustomMetricDetails) => void;
  excludeMetrics?: number[];
}) => {
  const { problemType, schema } = useModelContext();
  const { data } = useSuspenseQuery(getCustomMonitoringMetricsQuery, {
    variables: {
      filter: { problemTypes: [problemType] },
      input: { problemType, schema },
    },
  });
  const availableMetrics = excludeMetrics
    ? data.monitoring_metrics.filter((m) => !excludeMetrics.includes(m.id))
    : data.monitoring_metrics;
  const { search, setSearch, results: metrics } = useSearchList(availableMetrics, "name");

  if (availableMetrics.length === 0) {
    return (
      <div className="flex flex-col items-center gap-4 p-4 text-sm text-slate-400">
        <p className="inline-flex items-center gap-2 text-base">
          <SearchXIcon size={24} /> No custom metrics available
        </p>
        {data.monitoring_metrics.length > 0 ? (
          <p className="max-w-prose">This model is already using all defined custom metrics.</p>
        ) : (
          <p className="max-w-prose">Looks like you haven't created any custom metrics yet.</p>
        )}
        <Link to={`/monitoring/metrics?problemType=${problemType}`}>
          <Button cva={{ intent: "primary", size: "small" }}>Create a new metric</Button>
        </Link>
      </div>
    );
  }

  return (
    <div className={cn("relative flex flex-col max-h-[300px] overflow-y-auto text-slate-400", className)}>
      <Label className="sticky top-0 bg-dark">
        <Input
          className="pl-9 border-0 border-b rounded-none focus:ring-0 font-normal"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search metrics..."
        />
        <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2" size={16} />
      </Label>
      {metrics.map((metric) => (
        <span
          key={metric.name}
          className="px-3 py-2 text-sm hover:bg-slate-700 focus:bg-slate-700 outline-0 hover:cursor-pointer"
          onClick={() => onSelectMetric(metric)}
        >
          {metric.name}
        </span>
      ))}
    </div>
  );
};
