import * as d3 from "d3-time";
import _ from "lodash";
import * as Plotly from "plotly.js";

import { getDateRange, padRange } from "@/adapters/monitoring";
import { PlotConfig, PlotDataset } from "@/components/monitoring/PlotConfig";
import * as colors from "@/constants/colors";
import { PlotElements } from "@/constants/enums";
import { DateLike } from "@/lib/dateUtils";

/**
 * Select specific dataset from a result
 * @param result Result to be filtered
 * @param dataset Dataset to be selected from the given result
 * @returns Data points in the dataset
 */
export const selectDataset = <T extends { data: { isAnalysis: boolean }[] }>(
  result: T,
  dataset: PlotDataset
): T["data"] => {
  switch (dataset) {
    case PlotDataset.Reference:
      return result.data.filter((dp) => !dp.isAnalysis);
    case PlotDataset.Analysis:
      return result.data.filter((dp) => dp.isAnalysis);
    default:
      throw new Error(`Unknown dataset: ${dataset}`);
  }
};

/**
 * Get the name of the x-axis for the given subplot index
 * @param axis Axis to be indexed
 * @param idx 0-based index of the subplot
 * @returns Plotly axis name
 */
export const getAxisName = (axis: "x" | "xaxis" | "y" | "yaxis", idx: number) =>
  (idx === 0 ? axis : axis + (idx + 1)) as Plotly.AxisName;

/**
 * Get plot layout for the given set of results
 * @param results The result to be plotted
 * @param config Plot configuration to be used
 * @returns Plotly layout
 */
export const getPlotLayout = (
  results: { data: { isAnalysis: boolean; startTimestamp: DateLike; endTimestamp: DateLike }[] }[],
  config: PlotConfig,
  dateRange?: [DateLike, DateLike],
  width?: number
): Partial<Plotly.Layout> => {
  const shapes: Partial<Plotly.Shape>[] = [];
  const annotations: Partial<Plotly.Annotations>[] = [];
  const refResult = results[0];

  // Add line to highlight start of analysis data if everything is on one plot
  if (
    !config.subplotPerDataset &&
    config.datasets.includes(PlotDataset.Reference) &&
    config.datasets.includes(PlotDataset.Analysis)
  ) {
    const firstDatapoint = refResult.data.find((dp) => dp.isAnalysis);
    if (firstDatapoint) {
      shapes.push({
        layer: "below",
        type: "line",
        yref: "y domain" as Plotly.YAxisName,
        x0: firstDatapoint.startTimestamp,
        y0: 0,
        x1: firstDatapoint.startTimestamp,
        y1: 1,
        line: {
          color: colors.delimiterColor,
          width: 1,
        },
      });
    }
  }

  // Add annotation per dataset if subplots are enabled
  if (config.subplotPerDataset) {
    config.datasets.forEach((label, idx) =>
      annotations.push({
        xref: `${getAxisName("x", idx)} domain` as Plotly.XAxisName,
        yref: "paper",
        x: 0.5,
        y: 1,
        xanchor: "center",
        yanchor: "bottom",
        text: label,
        showarrow: false,
      })
    );
  }

  // When using subplots we want to split the date range per dataset
  const dateRanges = dateRange
    ? config.subplotPerDataset
      ? config.datasets.map((dataset) => {
          const dataDateRange = padRange(getDateRange(selectDataset(refResult, dataset), config.type));
          return [
            _.maxBy([dateRange[0], dataDateRange[0]], (d) => new Date(d)),
            _.minBy([dateRange[1], dataDateRange[1]], (d) => new Date(d)),
          ] as [DateLike, DateLike];
        })
      : [dateRange]
    : config.subplotPerDataset
    ? new Array(config.datasets.length).fill(undefined)
    : [undefined];

  // Generate properties for all axes
  const nrAxes = config.subplotPerDataset ? config.datasets.length : 1;
  const axes = [...Array(nrAxes).keys()].reduce(
    (acc, _, idx) => ({
      ...acc,
      [getAxisName("xaxis", idx)]: {
        range: dateRanges[idx],
        showgrid: config.elements.includes(PlotElements.Grid),
        linecolor: colors.lineColor,
        gridcolor: colors.gridColor,
        griddash: "dash",
        zeroline: false,
        mirror: true,
        type: "date",
        tickformatstops: getTimestampTickFormatStops(refResult),
      },
      [getAxisName("yaxis", idx)]: {
        showgrid: config.elements.includes(PlotElements.Grid),
        title: {
          standoff: 5,
        },
        linecolor: colors.lineColor,
        gridcolor: colors.gridColor,
        griddash: "dash",
        zeroline: false,
        mirror: true,
      },
    }),
    {}
  );

  // Construct layout by applying overrides to default
  return _.merge({}, defaultLayout, {
    showlegend: config.elements.includes(PlotElements.Legend),
    grid: {
      // Evenly split the plot area between the x-axes on a single row
      subplots: config.subplotPerDataset
        ? [config.datasets.map((_, idx) => getAxisName("x", idx) + getAxisName("y", idx))]
        : undefined,
    },
    annotations,
    shapes,
    ...axes,
    width,
  });
};

/**
 * List of supported time intervals and their corresponding tick format stops
 */
const timestampTickFormatStops: [d3.CountableTimeInterval, Partial<Plotly.TickFormatStop>][] = [
  [d3.timeHour, { dtickrange: [3600000, 86400000], value: "%H:%M h" }],
  [d3.timeDay, { dtickrange: [86400000, 604800000], value: "%b %e" }],
  [d3.timeWeek, { dtickrange: [604800000, "M1"], value: "%b %e" }],
  [d3.timeMonth, { dtickrange: ["M1", "M12"], value: "%b %Y" }],
  [d3.timeYear, { dtickrange: ["M12", null], value: "%Y" }],
];

const getTimestampTickFormatStops = (result: {
  data: { startTimestamp: DateLike; endTimestamp: DateLike }[];
}): Partial<Plotly.TickFormatStop>[] => {
  if (result.data.length == 0) {
    return [];
  }

  const start = new Date(result.data[0].startTimestamp);
  const end = new Date(result.data[0].endTimestamp);

  // Select formatters equal to or larger than the data point interval
  const stops = timestampTickFormatStops.reduce((acc, [interval, stop]) => {
    if (interval.count(start, end) <= 1) {
      acc.push(stop);
    }
    return acc;
  }, [] as Partial<Plotly.TickFormatStop>[]);

  // Set lowest dtickrange to apply to all smaller intervals
  if (stops.length > 1) {
    stops[0].dtickrange![0] = null;
  }

  return stops;
};

/**
 * The default layout used as a basis for all plots
 */
const defaultLayout: Partial<Plotly.Layout> = {
  autosize: true,
  paper_bgcolor: "transparent",
  plot_bgcolor: "transparent",
  legend: {
    orientation: "h",
    traceorder: "grouped",
    itemclick: false,
    itemdoubleclick: false,
    y: -0.12,
  },
  hovermode: "closest",
  hoverlabel: {
    align: "left",
    bgcolor: "black",
    font: {
      color: "white",
    },
  },
  xaxis: {
    linecolor: colors.lineColor,
    gridcolor: colors.gridColor,
    griddash: "dash",
    zeroline: false,
    mirror: true,
  },
  yaxis: {
    linecolor: colors.lineColor,
    gridcolor: colors.gridColor,
    griddash: "dash",
    zeroline: false,
    mirror: true,
    title: {
      standoff: 5,
    },
  },
  margin: {
    t: 24,
    r: 32,
    b: 36,
  },
  font: {
    color: "white",
  },
  grid: {
    xgap: 0.12,
  },
};
