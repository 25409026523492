import { Loader2 } from "lucide-react";
import * as React from "react";

export const RequestStateLayout = (props: {
  isLoading?: boolean;
  hasError?: boolean;
  isEmpty?: boolean;

  loaderContent?: JSX.Element;
  loaderText?: string;

  errorContent?: JSX.Element;
  errorText?: string;

  emptyContent?: JSX.Element;
  emptyText?: string;
}) => {
  if (props.isLoading) {
    return props.loaderContent ? (
      props.loaderContent
    ) : (
      <div aria-label={"loading"} className={"w-full h-full fcol items-center justify-center"}>
        <Loader2 className="animate-spin text-highlightDeep" size={36} />
        {props.loaderText ? <p>{props.loaderText}</p> : null}
      </div>
    );
  }

  if (props.hasError) {
    return props.errorContent ? (
      props.errorContent
    ) : (
      <div className={"w-full h-full fcol items-center justify-center"}>
        <p className={"text-lg font-bold"}>{props.errorText ?? "An error ocurred"}</p>
      </div>
    );
  }

  if (props.isEmpty) {
    return props.emptyContent ? (
      props.emptyContent
    ) : (
      <div className={"w-full h-full fcol items-center justify-center"}>
        <p className={"text-lg font-bold"}>{props.emptyText ?? "No data available"}</p>
      </div>
    );
  }

  return null;
};
