"use client";

import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { Circle } from "lucide-react";
import * as React from "react";

import { cn } from "@/lib/utils";

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return <RadioGroupPrimitive.Root className={cn("grid gap-2", className)} {...props} ref={ref} />;
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> & {
    circleClassName?: string;
  }
>(({ className, circleClassName, children, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item ref={ref} className={cn("group", className)} {...props}>
      {children || (
        <RadioGroupIndicator>
          <Circle className={cn("h-2 w-2 fill-highlightDeep", circleClassName)} />
        </RadioGroupIndicator>
      )}
    </RadioGroupPrimitive.Item>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

const RadioGroupIndicator = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Indicator>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Indicator>
>(({ children, className, ...props }, ref) => {
  return (
    <div
      className={cn(
        "flex items-center justify-center align-top",
        "h-4 w-4 rounded-full border border-slate-300 text-highlightDeep group-hover:border-slate-400",
        "group-focus:outline-none group-focus:ring-2 group-focus:ring-slate-400 group-focus:ring-offset-1",
        "group-disabled:cursor-not-allowed group-disabled:opacity-50",
        className
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator ref={ref}>
        {children || <Circle className={cn("h-2 w-2 fill-highlightDeep")} />}
      </RadioGroupPrimitive.Indicator>
    </div>
  );
});
RadioGroupIndicator.displayName = RadioGroupPrimitive.Indicator.displayName;

const RadioGroupLabeledItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupItem>,
  React.ComponentPropsWithoutRef<typeof RadioGroupItem> & {
    containerClassName?: string;
    label: string;
    labelClassName?: string;
  }
>(({ children, containerClassName, label, labelClassName, ...props }, ref) => {
  const id = React.useId();

  return (
    <div className={cn("inline-flex items-center gap-2", containerClassName)}>
      <RadioGroupItem {...props} ref={ref} id={id} />
      <label htmlFor={id} className={cn("cursor-pointer truncate", labelClassName)}>
        {label}
      </label>
    </div>
  );
});
RadioGroupLabeledItem.displayName = "RadioGroupLabeledItem";

export { RadioGroup, RadioGroupItem, RadioGroupIndicator, RadioGroupLabeledItem };
