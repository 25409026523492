import { ResultOf } from "@graphql-typed-document-node/core";
import _ from "lodash";

import {
  FragmentType,
  ProblemType,
  SummaryStatsMetric,
  SummaryStatsMetricConfigInput,
  gql,
  useFragment,
} from "@/apis/nannyml";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "@/components/Table";
import { summaryStatsMetricLabels } from "@/formatters/monitoring";
import { selectWhere } from "@/lib/typesUtils";
import { cn } from "@/lib/utils";

import { MetricEnableToggle } from "./MetricEnableToggle";
import { ThresholdConfigCells, ThresholdConfigHeaderCells } from "./ThresholdConfig";
import { RuntimeConfigComponentProps, SegmentRuntimeConfigComponentProps } from "./types";

const summaryStatisticsRuntimeConfigFragment = gql(/* GraphQL */ `
  fragment SummaryStatisticsRuntimeConfig on RuntimeConfig {
    summaryStatsMetrics {
      __typename
      metric
      lowerValueLimit
      upperValueLimit
      ... on SummaryStatsColumnMetricConfig {
        continuous {
          ...IsSupportedConfig
        }
        targets {
          ...IsSupportedConfig
        }
        predictions {
          ...IsSupportedConfig
        }
        predictedProbabilities {
          ...IsSupportedConfig
        }
      }
      ... on SummaryStatsSimpleMetricConfig {
        ...IsSupportedConfig
      }
    }
  }
`);

type ColumnMetricConfigType = Extract<
  ResultOf<typeof summaryStatisticsRuntimeConfigFragment>["summaryStatsMetrics"][number],
  { __typename: "SummaryStatsColumnMetricConfig" }
>;
type SimpleMetricConfigType = Extract<
  ResultOf<typeof summaryStatisticsRuntimeConfigFragment>["summaryStatsMetrics"][number],
  { __typename: "SummaryStatsSimpleMetricConfig" }
>;

const useSummaryStatisticsEdit = ({
  config: configFragment,
  value: { summaryStatsMetrics },
  onValueChange,
}: RuntimeConfigComponentProps<FragmentType<typeof summaryStatisticsRuntimeConfigFragment>>) => {
  const config = useFragment(summaryStatisticsRuntimeConfigFragment, configFragment);
  const columnMetrics = config.summaryStatsMetrics.filter(selectWhere("__typename", "SummaryStatsColumnMetricConfig"));
  const simpleMetrics = config.summaryStatsMetrics.filter(selectWhere("__typename", "SummaryStatsSimpleMetricConfig"));
  const metrics = _.keyBy(summaryStatsMetrics, "metric");

  const onMetricChange = (metric: SummaryStatsMetric, change: Partial<SummaryStatsMetricConfigInput>) => {
    onValueChange({
      summaryStatsMetrics: (summaryStatsMetrics ?? []).map((m) => (m.metric === metric ? { ...m, ...change } : m)),
    });
  };

  return {
    columnMetrics,
    simpleMetrics,
    metrics,
    onMetricChange,
  };
};

export const SummaryStatisticsConfig = (
  props: RuntimeConfigComponentProps<FragmentType<typeof summaryStatisticsRuntimeConfigFragment>>
) => {
  const { columnMetrics, metrics, onMetricChange, simpleMetrics } = useSummaryStatisticsEdit(props);

  return (
    <div className={cn("flex flex-col gap-8", props.className)}>
      <Table>
        <ColumnMetricCaption />
        <TableHeader>
          <TableRow>
            <TableHead>Metric</TableHead>
            <ColumnMetricEnableHeaderCells problemType={props.problemType} />
            <ThresholdConfigHeaderCells />
          </TableRow>
        </TableHeader>
        <TableBody>
          {columnMetrics.map((config) => (
            <TableRow key={config.metric}>
              <MetricCell metric={config.metric} />
              <ColumnMetricEnableCells
                problemType={props.problemType}
                config={config}
                value={metrics[config.metric]}
                onValueChange={(value) => onMetricChange(config.metric, value)}
              />
              <ColumnMetricThresholdCells
                config={config}
                value={metrics[config.metric]}
                onValueChange={(value) => onMetricChange(config.metric, value)}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Table>
        <SimpleMetricCaption />
        <TableHeader>
          <TableRow>
            <TableHead>Metric</TableHead>
            <SimpleMetricEnableHeaderCells />
            <ThresholdConfigHeaderCells />
          </TableRow>
        </TableHeader>
        <TableBody>
          {simpleMetrics.map((config) => (
            <TableRow key={config.metric}>
              <MetricCell metric={config.metric} />
              <SimpleMetricEnableCells
                config={config}
                value={metrics[config.metric]}
                onValueChange={(value) => onMetricChange(config.metric, value)}
              />
              <SimpleMetricThresholdCells
                config={config}
                value={metrics[config.metric]}
                onValueChange={(value) => onMetricChange(config.metric, value)}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export const SummaryStatisticsEnableConfig = (
  props: RuntimeConfigComponentProps<FragmentType<typeof summaryStatisticsRuntimeConfigFragment>>
) => {
  const { columnMetrics, simpleMetrics, metrics, onMetricChange } = useSummaryStatisticsEdit(props);
  return (
    <div className={cn("flex flex-col gap-8", props.className)}>
      <Table>
        <ColumnMetricCaption />
        <TableHeader>
          <TableRow>
            <TableHead>Metric</TableHead>
            <ColumnMetricEnableHeaderCells problemType={props.problemType} />
          </TableRow>
        </TableHeader>
        <TableBody>
          {columnMetrics.map((config) => (
            <TableRow key={config.metric}>
              <MetricCell metric={config.metric} />
              <ColumnMetricEnableCells
                problemType={props.problemType}
                config={config}
                value={metrics[config.metric]}
                onValueChange={(value) => onMetricChange(config.metric, value)}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Table>
        <SimpleMetricCaption />
        <TableHeader>
          <TableRow>
            <TableHead>Metric</TableHead>
            <SimpleMetricEnableHeaderCells />
          </TableRow>
        </TableHeader>
        <TableBody>
          {simpleMetrics.map((config) => (
            <TableRow key={config.metric}>
              <MetricCell metric={config.metric} />
              <SimpleMetricEnableCells
                config={config}
                value={metrics[config.metric]}
                onValueChange={(value) => onMetricChange(config.metric, value)}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export const SummaryStatisticsThresholdConfig = (
  props: SegmentRuntimeConfigComponentProps<FragmentType<typeof summaryStatisticsRuntimeConfigFragment>>
) => {
  const { columnMetrics, simpleMetrics, metrics, onMetricChange } = useSummaryStatisticsEdit(props);
  return (
    <div className={cn("flex flex-col gap-8", props.className)}>
      <Table>
        <ColumnMetricCaption />
        <TableHeader>
          <TableRow>
            <TableHead>Metric</TableHead>
            <ThresholdConfigHeaderCells useSegments />
          </TableRow>
        </TableHeader>
        <TableBody>
          {columnMetrics.map((config) => (
            <TableRow key={config.metric}>
              <MetricCell metric={config.metric} />
              <ColumnMetricThresholdCells
                segmentId={props.segmentId}
                config={config}
                value={metrics[config.metric]}
                onValueChange={(value) => onMetricChange(config.metric, value)}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Table>
        <SimpleMetricCaption />
        <TableHeader>
          <TableRow>
            <TableHead>Metric</TableHead>
            <ThresholdConfigHeaderCells useSegments />
          </TableRow>
        </TableHeader>
        <TableBody>
          {simpleMetrics.map((config) => (
            <TableRow key={config.metric}>
              <MetricCell metric={config.metric} />
              <SimpleMetricThresholdCells
                segmentId={props.segmentId}
                config={config}
                value={metrics[config.metric]}
                onValueChange={(value) => onMetricChange(config.metric, value)}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const ColumnMetricCaption = () => (
  <TableCaption className="caption-top mt-0 mb-4 text-left">
    <h3 className="text-lg">Column statistics</h3>
    <span className="text-gray-400">Summary statistics that apply to each column individually.</span>
  </TableCaption>
);

const SimpleMetricCaption = () => (
  <TableCaption className="caption-top mt-0 mb-4 text-left">
    <h3 className="text-lg">Dataset statistics</h3>
    <span className="text-gray-400">Summary statistics that apply to the dataset as a whole.</span>
  </TableCaption>
);

const MetricCell = ({ metric }: { metric: SummaryStatsMetric }) => (
  <TableCell className="whitespace-nowrap">{summaryStatsMetricLabels[metric]}</TableCell>
);

const ColumnMetricEnableHeaderCells = ({ problemType }: { problemType: ProblemType }) => (
  <>
    <TableHead className="text-center">Target</TableHead>
    <TableHead className="text-center">Predictions</TableHead>
    {problemType !== ProblemType.Regression && <TableHead className="text-center">Predicted probabilities</TableHead>}
    <TableHead className="text-center">Continuous features</TableHead>
  </>
);

const ColumnMetricEnableCells = ({
  problemType,
  config,
  value,
  onValueChange,
}: {
  problemType: ProblemType;
  config: ColumnMetricConfigType;
  value: SummaryStatsMetricConfigInput;
  onValueChange: (change: Partial<SummaryStatsMetricConfigInput>) => void;
}) => (
  <>
    <TableCell>
      <MetricEnableToggle
        config={config.targets}
        value={value.enabledTargets ?? false}
        onValueChange={(enabledTargets) => onValueChange({ enabledTargets })}
      />
    </TableCell>
    <TableCell>
      <MetricEnableToggle
        config={config.predictions}
        value={value.enabledPredictions ?? false}
        onValueChange={(enabledPredictions) => onValueChange({ enabledPredictions })}
      />
    </TableCell>
    {problemType !== ProblemType.Regression && (
      <TableCell>
        <MetricEnableToggle
          config={config.predictedProbabilities}
          value={value.enabledPredictedProbabilities ?? false}
          onValueChange={(enabledPredictedProbabilities) => onValueChange({ enabledPredictedProbabilities })}
        />
      </TableCell>
    )}
    <TableCell>
      <MetricEnableToggle
        config={config.continuous}
        value={value.enabledContinuous ?? false}
        onValueChange={(enabledContinuous) => onValueChange({ enabledContinuous })}
      />
    </TableCell>
  </>
);

const ColumnMetricThresholdCells = (props: {
  config: ColumnMetricConfigType;
  segmentId?: number;
  value: SummaryStatsMetricConfigInput;
  onValueChange: (change: Partial<SummaryStatsMetricConfigInput>) => void;
}) => (
  <ThresholdConfigCells
    disabled={
      !props.value.enabledCategorical &&
      !props.value.enabledContinuous &&
      !props.value.enabledPredictedProbabilities &&
      !props.value.enabledPredictions &&
      !props.value.enabledTargets
    }
    {...props}
  />
);

const SimpleMetricEnableHeaderCells = () => <TableHead>Enabled</TableHead>;

const SimpleMetricEnableCells = ({
  config,
  value,
  onValueChange,
}: {
  config: SimpleMetricConfigType;
  value: SummaryStatsMetricConfigInput;
  onValueChange: (change: Partial<SummaryStatsMetricConfigInput>) => void;
}) => (
  <TableCell>
    <MetricEnableToggle
      config={config}
      value={value.enabled ?? false}
      onValueChange={(enabled) => onValueChange({ enabled })}
    />
  </TableCell>
);

const SimpleMetricThresholdCells = (props: {
  config: SimpleMetricConfigType;
  segmentId?: number;
  value: SummaryStatsMetricConfigInput;
  onValueChange: (change: Partial<SummaryStatsMetricConfigInput>) => void;
}) => <ThresholdConfigCells disabled={!props.value.enabled} {...props} />;
