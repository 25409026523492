import { SimpleTooltip } from "@/DesignSystem/nanny/SimpleTooltip/SimpleTooltip";
import { FragmentType, gql, useFragment } from "@/apis/nannyml";
import { Checkbox } from "@/components/common/Checkbox/Checkbox";

const isSupportedConfigFragment = gql(/* GraphQL */ `
  fragment IsSupportedConfig on SupportConfigInterface {
    isSupported
    supportReason
  }
`);

export const MetricEnableToggle = ({
  config,
  value,
  onValueChange,
}: {
  config: FragmentType<typeof isSupportedConfigFragment>;
  value: boolean;
  onValueChange: (enabled: boolean) => void;
}) => {
  const { isSupported, supportReason } = useFragment(isSupportedConfigFragment, config);

  return (
    <div className="flex justify-center items-center">
      {supportReason ? (
        <SimpleTooltip tooltipContent={supportReason}>
          <Checkbox
            disabled={!isSupported}
            checked={value}
            onCheckedChange={(enabled) => onValueChange(Boolean(enabled))}
          />
        </SimpleTooltip>
      ) : (
        <Checkbox
          disabled={!isSupported}
          checked={value}
          onCheckedChange={(enabled) => onValueChange(Boolean(enabled))}
        />
      )}
    </div>
  );
};
