import { useEffect, useState } from "react";

import { formatTimeDelta } from "@/lib/dateUtils";

export const RelativeTime = ({
  value,
  since,
  updateInterval = 1000,
}: {
  value: Date | string | number;
  since?: Date | string | number;
  updateInterval?: number;
}) => {
  const [timeDelta, setTimeDelta] = useState(formatTimeDelta(value, since));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeDelta(formatTimeDelta(value, since));
    }, updateInterval);

    return () => clearInterval(interval);
  }, [value, since]);

  return <>{timeDelta}</>;
};
