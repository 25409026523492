import { ColumnType } from "@/apis/nannyml";

import { ColumnConfig } from "./common";

export const columnConfig: ColumnConfig = {
  types: [
    { type: ColumnType.MetricName, important: true },
    { type: ColumnType.GroupName, important: true },
    { type: ColumnType.SuccessCount, important: true },
    { type: ColumnType.FailCount, important: true },
    { type: ColumnType.Identifier, optional: true, important: true },
    { type: ColumnType.Ignored, optional: true, allowMultiple: true },
  ],
};
