import { AzureBlobAuthenticationMode, S3AuthenticationMode } from "@/apis/nannyml";

export const azureBlobAuthenticationModeLabels: Record<AzureBlobAuthenticationMode, string> = {
  [AzureBlobAuthenticationMode.ManagedIdentity]: "Managed identity",
  [AzureBlobAuthenticationMode.AccessKey]: "Access key",
  [AzureBlobAuthenticationMode.SasToken]: "SAS token",
  [AzureBlobAuthenticationMode.Anonymous]: "Anonymous",
};

export const s3AuthenticationModeLabels: Record<S3AuthenticationMode, string> = {
  [S3AuthenticationMode.AccessKey]: "Access key",
  [S3AuthenticationMode.Integrated]: "IAM role",
  [S3AuthenticationMode.Anonymous]: "Anonymous",
};
