import { useMutation } from "@apollo/client";
import { Command } from "cmdk";
import _ from "lodash";
import { Loader2Icon, SearchIcon, TagIcon } from "lucide-react";
import React from "react";

import { getResultRef } from "@/adapters/monitoring";
import { tagResult } from "@/apis/nannyml/mutations/tagResult/tagResult";
import { untagResult } from "@/apis/nannyml/mutations/untagResult/untagResult";
import { Chip } from "@/components/Chip";
import { toast } from "@/components/common/Toast/useToast";
import { useTags } from "@/hooks/monitoring";
import { cn } from "@/lib/utils";

import { Popover, PopoverContent, PopoverTrigger } from "./Popover";

export type ResultRefWithTags = Parameters<typeof getResultRef>[number] & {
  tags: string[];
};

type TagProps = {
  className?: string;
  endElement?: () => React.ReactNode;
  tag: string;
};

/** Tags that have a special function */
const systemTags = _.sortBy([
  "main",
  "summary",
  "multivariate drift",
  "univariate drift",
  "feature drift",
  "output drift",
  "target drift",
]);

export const Tag = ({ className, endElement, tag }: TagProps) => (
  <div
    className={cn(
      "inline-flex rounded-full py-1 px-3 gap-2 dark:text-white/80 text-sm items-center",
      "outline outline-1 dark:outline-slate-400 hover:cursor-default",
      className
    )}
  >
    <TagIcon size={16} />
    <span>{tag}</span>
    {endElement && endElement()}
  </div>
);

export const RemovableResultTag = ({
  tag,
  result,
}: {
  tag: string;
  result: Parameters<typeof getResultRef>[number];
}) => {
  const [removeTagMutation] = useMutation(untagResult);

  const removeTag = () => {
    removeTagMutation({
      variables: {
        input: {
          resultRef: getResultRef(result),
          tagName: tag,
        },
      },
    }).catch(() =>
      toast({
        title: "Failed to remove tag.",
        variant: "error",
      })
    );
  };

  return <Chip icon={<TagIcon size={16} />} label={tag} onDelete={removeTag} variant="outlined" size="small" />;
};

export const AddTagButton = ({ className, result }: { className?: string; result: ResultRefWithTags }) => {
  const [addTagMutation] = useMutation(tagResult);
  const [isInputActive, setIsInputActive] = React.useState(false);

  const addTag = (tag: string) => {
    addTagMutation({
      variables: {
        input: {
          resultRef: getResultRef(result),
          tagName: tag,
        },
      },
    }).catch(() =>
      toast({
        title: `Failed to add tag '${tag}'.`,
        variant: "error",
      })
    );

    setIsInputActive(false);
  };

  return (
    <Popover open={isInputActive} onOpenChange={setIsInputActive}>
      <PopoverTrigger asChild>
        <Chip icon={<TagIcon size={16} />} label={"Add tag"} variant="outlined" size="small" />
      </PopoverTrigger>
      <PopoverContent align="start" sideOffset={3} className="min-w-fit whitespace-nowrap">
        <React.Suspense
          fallback={
            <div className="h-full min-h-[100px] flex items-center justify-center gap-2">
              <Loader2Icon className="animate-spin text-highlightDeep" size={20} />
              <p>Loading tags</p>
            </div>
          }
        >
          <AddTagInput className="max-h-[300px]" existingTags={result.tags} onAddTag={addTag} />
        </React.Suspense>
      </PopoverContent>
    </Popover>
  );
};

export const AddTagInput = ({
  className,
  existingTags,
  onAddTag,
}: {
  className?: string;
  existingTags: string[];
  onAddTag: (tag: string) => void;
}) => {
  const tags = useTags();
  const [value, setValue] = React.useState("");

  // Filter out existing tags and split into system and user tags
  const newTags = tags.reduce(
    (acc, tag) => {
      if (existingTags.includes(tag)) {
        return acc;
      } else if (systemTags.includes(tag)) {
        acc.system.push(tag);
      } else {
        acc.user.push(tag);
      }
      return acc;
    },
    { system: [], user: [] } as { system: string[]; user: string[] }
  );

  // Command natively handles enter key presses when selecting an existing item. This handles enter key presses when
  // creating a new tag.
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "Enter") {
      onAddTag(value);
    }
  };

  const Item = ({ tag }: { tag: string }) => (
    <Command.Item
      className="flex items-center aria-selected:bg-slate-700 hover:cursor-pointer rounded-md px-3 py-1"
      onSelect={() => onAddTag(tag)}
      value={tag}
    >
      <TagIcon size={16} className="mr-2" />
      {tag}
    </Command.Item>
  );

  return (
    <Command loop className={cn("flex flex-col", className)}>
      <div className="flex items-center border-b border-slate-500">
        <SearchIcon size={20} className="ml-3" />
        <Command.Input
          autoFocus
          className="bg-transparent w-full p-2 outline-none"
          value={value}
          onValueChange={setValue}
          onKeyDown={onKeyDown}
        />
      </div>
      <Command.List className="flex-grow overflow-y-scroll overflow-x-hidden text-ellipsis pb-3">
        <Command.Empty className="px-3 py-5 text-sm text-center">
          <p className="italic mb-3">No existing tags found.</p>
          <p>Press enter to create a new tag.</p>
        </Command.Empty>
        <Command.Group heading={<span className="text-sm uppercase font-thin px-2">System</span>}>
          {...newTags.system.map((tag) => <Item key={tag} tag={tag} />)}
        </Command.Group>
        <Command.Group heading={<span className="text-sm uppercase font-thin px-2">User</span>}>
          {...newTags.user.map((tag) => <Item key={tag} tag={tag} />)}
        </Command.Group>
      </Command.List>
    </Command>
  );
};
