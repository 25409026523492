import {
  ArrowDown01Icon,
  ArrowDownAZIcon,
  ArrowUp01Icon,
  ArrowUpAZIcon,
  CheckIcon,
  ClockIcon,
  LucideProps,
  RefreshCwIcon,
  RefreshCwOffIcon,
  XIcon,
} from "lucide-react";
import React from "react";

import { cn } from "@/lib/utils";

export const ScheduledIcon = React.forwardRef<SVGSVGElement, LucideProps>(({ className, ...props }, ref) => (
  <ClockIcon ref={ref} size={20} strokeWidth={1.5} className={cn("text-scheduled", className)} {...props} />
));

export const RunningIcon = React.forwardRef<SVGSVGElement, LucideProps>(({ className, ...props }, ref) => (
  <RefreshCwIcon ref={ref} size={20} strokeWidth={1.5} className={cn("text-running", className)} {...props} />
));

export const CancellingIcon = React.forwardRef<SVGSVGElement, LucideProps>(({ className, ...props }, ref) => (
  <RefreshCwOffIcon ref={ref} size={20} strokeWidth={1.5} className={cn("text-cancelling", className)} {...props} />
));

export const SuccessIcon = React.forwardRef<SVGSVGElement, LucideProps>(({ className, ...props }, ref) => (
  <div className={cn("rounded-full p-0.5 border border-current text-success w-fit h-fit", className)}>
    <CheckIcon ref={ref} size={14} className={className} {...props} />
  </div>
));

export const FailedIcon = React.forwardRef<SVGSVGElement, LucideProps>(({ className, ...props }, ref) => (
  <div className={cn("rounded-full p-0.5 border border-current text-failed w-fit h-fit", className)}>
    <XIcon ref={ref} size={14} className={className} {...props} />
  </div>
));

type SortIconProps = LucideProps & {
  direction: "asc" | "desc";
  type: "numeric" | "alphabetic";
};

export const SortIcon = React.forwardRef<SVGSVGElement, SortIconProps>(({ direction, type, ...props }, ref) => {
  const Icon =
    type === "numeric"
      ? direction === "asc"
        ? ArrowDown01Icon
        : ArrowUp01Icon
      : direction === "asc"
      ? ArrowDownAZIcon
      : ArrowUpAZIcon;

  return <Icon ref={ref} size={16} {...props} />;
});
