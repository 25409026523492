export const ChunkingInfo = `
NannyML Cloud aggregates metrics on samples of data. These samples are called chunks.

Chunks can be created based on time periods (hour, day, week, month, quarter, year), or they can also be size-based so that each chunk contains N observations.`;

export const SourcesInfo = `
By creating a source here, you are defining how we accept data from a location, and the format all future data from that source will be in.

For example, if you have local file for your reference data, cloud based file storage for your model inputs, and a  database for your outputs, then you can create three different sources.

It is important to only upload one initial file per file storage source. This will act as a template, you will be able to add as many files as you want once the model has been set up.`;

export const ReferenceAndAnalysisInfo = `
NannyML separates data into two parts.

Reference data is used to establish baseline performance; it should be data that your model performs acceptably on, and ideally cover the expected spread of data for each feature. It is also used to calculate baselines for alerting thresholds. Often people use their test data as reference.

Analysis data is the data you want to monitor. This is what you will add to as your model progresses, and is compared with reference to assess various metrics.`;

export const SourceName = `
A name to identify this source inside the settings. It is only used for display purposes.`;

//

export const ModelPredictions = `Prediction made by the model. For binary classification this is a binary value, multiclass classification predicts a class, while a regression model predicts a continuous value.`;

export const ModelPredictedProbability = `The probabilities assigned by a machine learning model regarding the chance that a positive event materializes for the binary outcome it was called to predict.`;

export const Timestamp = `This provides NannyML Cloud with the date and time that the prediction was made.

NannyML Cloud supports any format supported by Pandas, such as:

ISO 8601, e.g. 2021-10-13T08:47:23Z
Unix-epoch in units of seconds, e.g. 1513393355`;

export const IdentifierColumn = `Unique identifier for each row in the dataset.

NannyML will use this column to join analysis and target data sources.`;

export const Target = `The actual outcome of what your model predicted`;

export const ColumnType = `
Columns can be defined as one of the following types:

Target
The actual outcome of what your model predicted.

Model predicted probability
The scores or probabilities that your model predicts for its target outcome.

Model prediction
The predicted outcome according to your model.

Timestamp
The time your model ran the prediction.

Identifier Column
NannyML will use this to join different datasets.

Categorical feature
NannyML will treat this column as a categorical feature.

Continuous feature
NannyML will treat this column as a continuous feature.

Ignore
NannyML will not use this column for anything.
`;

export const ApiToken = `
An API token is a secret string that you can use to authenticate programmatically, e.g. when using the NannyML Cloud SDK. The token provides the same level of access as your personal account.

If you no longer need a token, we recommend you delete it to avoid any security issues.
`;

export const KeyPerformanceMetric =
  "The Key Performance Metric (KPM) is the primary metric used to evaluate model performance. NannyML Cloud displays the KPM in a central location while other metrics are considered secondary and are displayed in a separate section.";
