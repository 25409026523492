import { useFragment } from "@apollo/client";

import { gql } from "@/apis/nannyml";
import { useEvaluationModelId } from "@/hooks/evaluation";

const evaluationModelNameFragment = gql(/* GraphQL */ `
  fragment EvaluationModelName on EvaluationModel {
    name
  }
`);

export const EvaluationModelNameFromRoute = () => {
  const modelId = useEvaluationModelId();
  const {
    data: { name },
  } = useFragment({
    fragment: evaluationModelNameFragment,
    from: {
      __typename: "EvaluationModel",
      id: modelId,
    },
  });

  return <EvaluationModelName modelId={modelId} modelName={name} />;
};

export const EvaluationModelName = ({ modelId, modelName }: { modelId: number; modelName?: string }) =>
  modelName ? (
    <span>
      #{modelId} - {modelName}
    </span>
  ) : (
    <span>#{modelId}</span>
  );
