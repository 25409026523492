import { CircleCheckIcon, CircleXIcon } from "lucide-react";
import { useState } from "react";

import { StorageInput } from "@/apis/nannyml";
import { ButtonGroup } from "@/components/ButtonGroup";
import { Card } from "@/components/Card";
import { DatasetStorageDetails, DatasetStorageDetailsProps } from "@/components/DatasetStorage";
import { RadioGroup, RadioGroupItem } from "@/components/RadioGroup";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";

export const EvaluationData = ({
  value,
  onChange,
  ...rest
}: Omit<DatasetStorageDetailsProps, "onChange" | "value"> & {
  onChange: (value: StorageInput | null | undefined) => void;
  value: StorageInput | null | undefined;
}) => {
  const [useEvaluationData, setUseEvaluationData] = useState<boolean | undefined>(
    value === undefined ? undefined : value !== null
  );
  const onEvaluationDataSelect = (value: string) => {
    const useEvaluationData = value === "true";
    onChange(useEvaluationData ? undefined : null);
    setUseEvaluationData(useEvaluationData);
  };

  if (!useEvaluationData) {
    return (
      <RadioGroup
        className="grid grid-cols-2 gap-8 w-full xl:w-4/6"
        value={useEvaluationData?.toString()}
        onValueChange={onEvaluationDataSelect}
      >
        <h3 className="col-span-full flex items-center justify-center text-lg font-bold">
          Do you have an initial set of evaluation data available?
          <InformationModalChip infoName="Missing targets" className="ml-1" />
        </h3>
        <RadioGroupItem value="true" asChild>
          <Card className="flex flex-col">
            <div className="flex items-center gap-3 mb-2">
              <CircleCheckIcon className="text-green-500 shrink-0" size={32} />
              <p className="font-bold">Yes, I already have evaluation data</p>
            </div>
            <p className="text-sm text-gray-400 mb-5">
              Provide an initial set of evaluation data as part of the wizard. You can add more evaluation data to the
              model later as the data becomes available.
            </p>
            <p className="text-sm mt-auto">The evaluation data will be processed to provide the first datapoint.</p>
          </Card>
        </RadioGroupItem>
        <RadioGroupItem value="false" asChild>
          <Card className="flex flex-col">
            <div className="flex items-center gap-3 mb-2">
              <CircleXIcon className="text-red-500 shrink-0" size={32} />
              <p className="font-bold">No, I don't have evaluation data right now</p>
            </div>
            <p className="text-sm text-gray-400 mb-5">You can add evaluation data later as it becomes available.</p>
            <p className="text-sm mt-auto">Only results for reference will be available.</p>
          </Card>
        </RadioGroupItem>
      </RadioGroup>
    );
  }

  return (
    <div className="flex flex-col items-center gap-8">
      <RadioGroup value={useEvaluationData.toString()} onValueChange={onEvaluationDataSelect}>
        <ButtonGroup>
          <RadioGroupItem className="basis-1/3 grow flex flex-col text-left gap-1" value="true">
            <div className="flex items-center gap-2">
              <CircleCheckIcon className="text-green-500 shrink-0" size={20} />
              <p className="font-bold">Yes, I already have evaluation data</p>
            </div>
            <span className="text-sm text-gray-400">
              The evaluation data will be processed to provide the first datapoint.
            </span>
          </RadioGroupItem>
          <RadioGroupItem className="basis-1/3 grow flex flex-col text-left gap-1" value="false">
            <div className="flex items-center gap-2">
              <CircleXIcon className="text-red-500 shrink-0" size={20} />
              <p className="font-bold">No, I don't have evaluation data right now</p>
            </div>
            <span className="text-sm text-gray-400">Only results for reference will be available.</span>
          </RadioGroupItem>
        </ButtonGroup>
      </RadioGroup>
      <DatasetStorageDetails value={value ?? null} onChange={onChange} {...rest} />
    </div>
  );
};
