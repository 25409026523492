import { gql } from "@/apis/nannyml";

export const editUserNotificationSettings = gql(/* GraphQL */ `
  mutation EditUserNotificationSettings($input: EditUserNotificationSettingsInput!) {
    edit_user_notification_settings(settings: $input) {
      __typename
      ... on UserNotificationSettings {
        emailEnabled
      }
    }
  }
`);
