import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { worker } from "./apis/msw/browser";
import { routes } from "./routes/routes";

if (process.env.NODE_ENV === "development" && parseInt(window.localStorage.getItem("msw") ?? "0")) {
  // noinspection JSIgnoredPromiseFromCall
  worker.start();
}

const router = createBrowserRouter(routes);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
