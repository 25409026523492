import _ from "lodash";
import { useMemo } from "react";

import { SelectCheckboxList, SelectCheckboxListProps } from "@/components/dashboard/SelectCheckbox/SelectCheckboxList";

import { useFilter, useFilterConfig, useFilterContext } from "./Filters.hooks";

export type FilterMetricsItemType = {
  metricName: string;
};

export type FilterMetricsConfigType = {
  metrics?: string[];
};

export const FilterMetrics = ({ metricLabels }: { metricLabels: SelectCheckboxListProps["names"] }) => {
  const [{ metrics }, setFilterConfig] = useFilterConfig<FilterMetricsConfigType>();
  const { items: results } = useFilterContext<FilterMetricsItemType, FilterMetricsConfigType>();
  const availableMetrics = useMemo(() => _.uniq(results.map((r) => r.metricName)), [results]);

  useFilter<FilterMetricsItemType, FilterMetricsConfigType>(
    (resultGroups, { metrics }) => {
      // Filter out metrics that are not in the results. This may happen when metrics are deleted.
      metrics = metrics?.filter((metric) => availableMetrics.includes(metric));
      if (!metrics?.length) {
        return resultGroups;
      }

      return resultGroups.filter((results) => results.some((result) => metrics.includes(result.metricName)));
    },
    [availableMetrics]
  );

  const setSelectedMetrics = (updateMetrics: (currentMetrics: string[]) => string[]) => {
    setFilterConfig({ metrics: updateMetrics(metrics ?? []) });
  };

  return (
    <SelectCheckboxList
      values={availableMetrics}
      names={metricLabels}
      selectedValues={metrics}
      setSelectedValues={setSelectedMetrics}
      showSearch={false}
      showSelectOnlyCurrent={true}
      emptyPlaceholder="No metrics found."
    />
  );
};
