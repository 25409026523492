import { Dispatch, ReactNode, SetStateAction, createContext, useContext } from "react";

export type WizardStep<T extends object> = {
  title: string;
  subtitle?: string;
  isCompleted?: (value: Partial<T>, ctx: WizardContextType<T>) => boolean;
  onComplete?: (value: Partial<T>, setValue: Dispatch<SetStateAction<Partial<T>>>) => void | Promise<void>;
  render: (value: Partial<T>, setValue: Dispatch<SetStateAction<Partial<T>>>) => ReactNode;
  customControls?: boolean;
};

export enum WizardStepState {
  Incomplete = "incomplete",
  Invalid = "invalid",
  Completed = "completed",
}

export type WizardContextType<T extends object> = {
  id: string;
  value: any;
  setValue: Dispatch<SetStateAction<Partial<T>>>;
  currentStepNr: number;
  steps: WizardStep<T>[];
  stepState: WizardStepState[];
  setStepState: (stepNr: number, state: WizardStepState) => void;
  goForward: () => Promise<void> | void;
  goBackward: () => void;
  goToStep: (stepNr: number) => void;
  preventForwardJump: boolean;
};

export const WizardContext = createContext<WizardContextType<any> | null>(null);

export const useWizardContext = () => {
  const wizard = useContext(WizardContext);

  if (!wizard) {
    throw new Error("Must be used inside a Wizard");
  }

  return wizard;
};

export const getWizardControls = <T extends object>({ currentStepNr, stepState, goToStep }: WizardContextType<T>) => ({
  arePreviousStepsCompleted: () => stepState.slice(0, currentStepNr).every((s) => s === WizardStepState.Completed),
  goToFirstIncompleteStep: () => {
    const idx = stepState.findIndex((s) => s !== WizardStepState.Completed);
    if (idx !== -1) {
      goToStep(idx);
    }
  },
});

export const useWizardControls = () => {
  const ctx = useWizardContext();
  return getWizardControls(ctx);
};
