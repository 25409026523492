export const keys = <T extends {}>(obj: T) => {
  return Object.keys(obj) as Array<keyof T>;
};

export const reverseRecord = <T extends PropertyKey, U extends PropertyKey>(input: Record<T, U>) => {
  return Object.fromEntries(Object.entries(input).map(([key, value]) => [value, key])) as Record<U, T>;
};

export const fromArray = <T extends {}, Key extends keyof T, Value extends T[Key] & string>(array: T[], key: Key) => {
  return array.reduce((ac, item) => {
    return {
      ...ac,
      [item[key] as string]: item,
    };
  }, {} as Record<Value, T>);
};

export const countNestedLengths = <T extends Record<any, any[]>>(obj: T) => {
  const lengths = Object.values(obj).map((g) => g.length);

  return lengths.reduce((sum, len) => sum + len, 0);
};

export const groupsBy = <T>(array: T[], grouper: (item: T) => string[]) => {
  let groups: Record<string, T[]> = {};

  for (const item of array) {
    const itemGroups = grouper(item);

    for (const group of itemGroups) {
      if (groups[group]) {
        groups[group].push(item);
      } else {
        groups[group] = [item];
      }
    }
  }

  return groups;
};

export const groupsStringsBy = <T>(array: T[], grouper: (item: T) => string[], getter: (item: T) => string) => {
  let groups: Record<string, string[]> = {};

  for (const item of array) {
    const itemGroups = grouper(item);

    const value = getter(item);

    for (const group of itemGroups) {
      if (groups[group]) {
        groups[group].push(value);
      } else {
        groups[group] = [value];
      }
    }
  }

  return groups;
};

export function sliceInGroups<T extends any[] | []>(groups: Record<string, T>, offset: number, len: number) {
  let slicedGroups: Record<string, T> = {};
  let count = 0;

  let missingOffset = offset;

  for (const group in groups) {
    const groupLen = groups[group].length;

    // jump to next group if offset already covers it
    if (missingOffset >= groupLen) {
      missingOffset -= groupLen;
      continue;
    }

    // Get the items from the current group, without exceeding the length
    const start = missingOffset;

    const items = groups[group].slice(start, start + (len - count));
    count += items.length;

    // @ts-ignore
    slicedGroups[group] = items;

    // Stop if we have enough items
    if (count >= len) {
      break;
    }

    // If we made it this far, the offset it paid, so all new groups should start at 0
    missingOffset = 0;
  }

  return slicedGroups;
}

export function safeParseJSON<T>(json: string, defaultValue: T): T {
  try {
    return JSON.parse(json);
  } catch (e) {
    return defaultValue;
  }
}

export function combineObjects(objs: Record<string, any>[]) {
  return objs.reduce((acc, obj) => {
    return {
      ...acc,
      ...obj,
    };
  }, {});
}

export function doubleMapping<FirstMapping extends object, SecondMapping extends object>(
  key: string | null | undefined,
  firstMapping: FirstMapping,
  secondMapping: SecondMapping
) {
  const firstResult = firstMapping[key as keyof FirstMapping];

  if (firstResult) {
    const secondResult = secondMapping[firstResult as keyof SecondMapping];

    return [firstResult, secondResult];
  } else {
    return [firstResult, undefined];
  }
}

type OmitRecursively<T, K extends PropertyKey> = Omit<
  { [P in keyof T]: T[P] extends any ? (T[P] extends object ? OmitRecursively<T[P], K> : T[P]) : never },
  K
>;

export const omitRecursively = <T, K extends PropertyKey>(obj: T, field: K): OmitRecursively<T, K> => {
  const newObj = Array.isArray(obj) ? [...obj] : ({ ...obj } as any);

  for (const key in obj) {
    if ((field as any) === key) {
      delete newObj[key];
    } else if (newObj[key] !== null && typeof newObj[key] === "object") {
      newObj[key] = omitRecursively(newObj[key], field);
    }
  }

  return newObj;
};
