import React, { PropsWithChildren } from "react";

import { AuthMode, AuthenticationSettings } from "../codegen/__generated/graphql";
import { AzureAuthProvider } from "./AzureAuthProvider";
import { LocalAuthProvider } from "./LocalAuthProvider";

export type AuthProviderProps = PropsWithChildren<{
  authSettings?: AuthenticationSettings | null;
}>;

export const AuthProvider = ({ authSettings, children }: AuthProviderProps): React.ReactElement => {
  if (authSettings?.mode === AuthMode.Oidc) {
    if (authSettings.oidcProviders.length !== 1) {
      throw new Error("Only one OIDC provider is supported");
    }

    return <AzureAuthProvider {...authSettings.oidcProviders[0]}>{children}</AzureAuthProvider>;
  } else if (authSettings?.mode === AuthMode.Local) {
    return <LocalAuthProvider>{children}</LocalAuthProvider>;
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
};
