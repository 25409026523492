import React from "react";

import { cn } from "@/lib/utils";

export const Card = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, onClick, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        "p-5 rounded-lg border border-gray-400 shadow-sm",
        onClick && "hover:bg-slate-700/50 hover:cursor-pointer aria-checked:border-highlightDeep",
        "focus:outline-none focus:ring-2 focus:ring-highlightDeep focus:ring-offset-1",
        className
      )}
      role={onClick ? "button" : undefined}
      onClick={onClick}
      {...props}
    />
  )
);
