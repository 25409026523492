import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ResultOf } from "@graphql-typed-document-node/core";
import _ from "lodash";
import { GripVerticalIcon, PlusIcon, SettingsIcon, Trash2Icon } from "lucide-react";
import { ComponentType, Suspense, useState, createContext, PropsWithChildren, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import { SimpleTooltip } from "@/DesignSystem/nanny/SimpleTooltip/SimpleTooltip";
import { Input } from "@/DesignSystem/shadcn/Input";
import {
  BusinessValueMetricRuleInput,
  ClassificationRuleType,
  CustomMetricConfigInput,
  FragmentType,
  PerformanceMetric,
  PerformanceMetricConfigInput,
  PerformanceType,
  ProblemType,
  gql,
  useFragment,
} from "@/apis/nannyml";
import { ComboBox, ComboBoxGroup, ComboBoxItem, ComboBoxSearch } from "@/components/ComboBox";
import { Dialog, DialogContent, DialogTrigger } from "@/components/Dialog";
import { LabeledField } from "@/components/LabeledField";
import { RadioGroup, RadioGroupItem } from "@/components/RadioGroup";
import { Select, SelectItem } from "@/components/Select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, TableSeparatorCell } from "@/components/Table";
import { Button } from "@/components/common/Button";
import { toast } from "@/components/common/Toast/useToast";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";
import { RequestStateLayout } from "@/components/dashboard/RequestStateLayout/RequestStateLayout";
import { performanceMetricLabels, performanceTypeLabels } from "@/formatters/monitoring";
import { parseNullableFloat } from "@/lib/numbersUtils";
import { cn } from "@/lib/utils";

import { AddCustomMetricButton, CustomMetricDetails } from "./CustomMetrics";
import { MetricEnableToggle } from "./MetricEnableToggle";
import { convertMetricThresholdsToInput, ThresholdConfigCells, ThresholdConfigHeaderCells } from "./ThresholdConfig";
import { PerformanceConfigComponentProps } from "./types";

const performanceRuntimeConfigFragment = gql(/* GraphQL */ `
  fragment PerformanceRuntimeConfig on RuntimeConfig {
    performanceTypes {
      type
      isSupported
    }
    performanceMetrics {
      metric
      realized {
        isSupported
        ...IsSupportedConfig
      }
      estimated {
        isSupported
        ...IsSupportedConfig
      }
      lowerValueLimit
      upperValueLimit
    }
    customMetrics {
      ...CustomMetricConfigDetails
    }
  }
`);

const customMetricConfigDetailsFragment = gql(/* GraphQL */ `
  fragment CustomMetricConfigDetails on CustomMetricConfig {
    metric {
      id
      name
    }
    realized {
      isSupported
      ...IsSupportedConfig
    }
    estimated {
      isSupported
      ...IsSupportedConfig
    }
    lowerValueLimit
    upperValueLimit
    ...MetricThresholdConfig
  }
`);

const performanceContext = createContext<ReturnType<typeof usePerformanceEdit> | null>(null);

// This component provides the performance configuration context to its children. It's required because the custom
// metrics state that is created as part of `usePerformanceEdit` needs to be shared between the different components
export const PerformanceConfigProvider = ({
  children,
  ...props
}: PropsWithChildren<
  Omit<PerformanceConfigComponentProps<FragmentType<typeof performanceRuntimeConfigFragment>>, "className">
>) => {
  const ctx = usePerformanceEdit(props);
  return <performanceContext.Provider value={ctx}>{children}</performanceContext.Provider>;
};

const usePerformanceConfig = () => {
  const ctx = useContext(performanceContext);
  if (!ctx) {
    throw new Error("usePerformanceConfig must be used within PerformanceConfigProvider");
  }
  return ctx;
};

const usePerformanceEdit = ({
  config: configFragment,
  value,
  kpm,
  onValueChange,
  onKpmChange,
  problemType,
  classes,
}: PerformanceConfigComponentProps<FragmentType<typeof performanceRuntimeConfigFragment>>) => {
  const { performanceMetrics, customMetrics } = value;
  const config = useFragment(performanceRuntimeConfigFragment, configFragment);
  const configMetrics = _.keyBy(config.performanceMetrics, "metric");

  // Custom metrics can be added or removed dynamically, so we need to keep track of them in a state variable
  const [configCustomMetrics, setConfigCustomMetrics] = useState<
    Record<number, ResultOf<typeof customMetricConfigDetailsFragment>>
  >(_.keyBy(useFragment(customMetricConfigDetailsFragment, config.customMetrics), "metric.id"));

  // This effect is used to update the custom metrics state when they change in the config, e.g. after a mutation
  useEffect(() => {
    const newCustomMetrics = _.keyBy(useFragment(customMetricConfigDetailsFragment, config.customMetrics), "metric.id");
    setConfigCustomMetrics((configCustomMetrics) => ({ ...configCustomMetrics, ...newCustomMetrics }));
  }, [config.customMetrics]);

  const onMetricChange = (metric: PerformanceMetric, change: Partial<PerformanceMetricConfigInput>) => {
    const metricConfig = { ...performanceMetrics.find((m) => m.metric === metric), ...change };
    // When completely disabling metric
    if (!metricConfig.enabledEstimated && !metricConfig.enabledRealized) {
      // Make sure at least one metric is enabled
      const enabledMetric = performanceMetrics.find(
        (m) => m.metric !== metric && (m.enabledEstimated || m.enabledRealized)
      );
      if (!enabledMetric) {
        toast({
          title: "Cannot disable metric",
          description: "At least one metric must be enabled",
          variant: "error",
        });
        return;
      }

      // Switch KPM to another metric
      if (metric === kpm.metric) {
        onKpmChange({ metric: enabledMetric.metric });
      }
    }
    onValueChange({
      performanceMetrics: (performanceMetrics ?? []).map((m) => (m.metric === metric ? { ...m, ...change } : m)),
    });
  };

  const onCustomMetricChange = (metricId: number, change: Partial<CustomMetricConfigInput>) => {
    onValueChange({
      customMetrics: (customMetrics ?? []).map((m) => (m.metricId === metricId ? { ...m, ...change } : m)),
    });
  };

  const onAddCustomMetric = (metric: CustomMetricDetails) => {
    const config = useFragment(customMetricConfigDetailsFragment, metric.defaultConfig);
    setConfigCustomMetrics((configCustomMetrics) => ({ ...configCustomMetrics, [config.metric.id]: config }));

    onValueChange({
      customMetrics: (customMetrics ?? []).concat({
        metricId: config.metric.id,
        enabledEstimated: config.estimated.isSupported,
        enabledRealized: config.realized.isSupported,
        ...convertMetricThresholdsToInput(config),
      }),
    });
  };

  const onRemoveCustomMetric = (metricId: number) => {
    onValueChange({
      customMetrics: customMetrics.filter((m) => m.metricId !== metricId),
    });
  };

  return {
    problemType,
    classes,
    config,
    kpm,
    value,
    configMetrics,
    configCustomMetrics,
    onValueChange,
    onMetricChange,
    onCustomMetricChange,
    onKpmChange: (metric: string) => {
      const metricValue = performanceMetrics.find((m) => m.metric === metric);
      if (!metricValue?.enabledEstimated && !metricValue?.enabledRealized) {
        onMetricChange(metric as PerformanceMetric, {
          enabledEstimated: configMetrics[metric as PerformanceMetric].estimated.isSupported,
          enabledRealized: configMetrics[metric as PerformanceMetric].realized.isSupported,
        });
      }
      onKpmChange({ metric: metric as PerformanceMetric });
    },
    onAddCustomMetric,
    onRemoveCustomMetric,
  };
};

export const PerformanceConfig = (
  props: PerformanceConfigComponentProps<FragmentType<typeof performanceRuntimeConfigFragment>>
) => {
  const ctx = usePerformanceConfig();
  const {
    configMetrics,
    onMetricChange,
    configCustomMetrics,
    onCustomMetricChange,
    onKpmChange,
    onAddCustomMetric,
    onRemoveCustomMetric,
  } = ctx;

  return (
    <performanceContext.Provider value={ctx}>
      <RadioGroup
        className={cn("flex flex-col gap-4", props.className)}
        value={props.kpm?.metric}
        onValueChange={onKpmChange}
      >
        <PerformanceEstimatorConfig {...props} />
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Metric</TableHead>
              <TableHead className="whitespace-nowrap">
                KPM
                <InformationModalChip infoName="Key performance metric" className="p-0 mr-0 inline" />
              </TableHead>
              <TableHead>Realized performance</TableHead>
              <TableHead>Estimated performance</TableHead>
              <ThresholdConfigHeaderCells />
              <TableHead />
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableSeparatorCell colSpan={8}>Standard metrics</TableSeparatorCell>
            </TableRow>
            {props.value.performanceMetrics.map((metric) => (
              <TableRow key={metric.metric}>
                <TableCell>{performanceMetricLabels[metric.metric]}</TableCell>
                <TableCell>
                  <KpmSelect metric={metric.metric} />
                </TableCell>
                <TableCell>
                  <MetricEnableToggle
                    config={configMetrics[metric.metric].realized}
                    value={metric.enabledRealized ?? false}
                    onValueChange={(enabledRealized) => onMetricChange(metric.metric, { enabledRealized })}
                  />
                </TableCell>
                <TableCell>
                  <MetricEnableToggle
                    config={configMetrics[metric.metric].estimated}
                    value={metric.enabledEstimated ?? false}
                    onValueChange={(enabledEstimated) => onMetricChange(metric.metric, { enabledEstimated })}
                  />
                </TableCell>
                <ThresholdConfigCells
                  config={configMetrics[metric.metric]}
                  value={metric}
                  disabled={!metric.enabledEstimated && !metric.enabledRealized}
                  onValueChange={(change) => onMetricChange(metric.metric, change)}
                />
                <TableCell>
                  <MetricConfigButton
                    value={metric}
                    onValueChange={(config) => onMetricChange(metric.metric, config)}
                    config={configMetrics[metric.metric]}
                  />
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableSeparatorCell colSpan={8}>Custom metrics</TableSeparatorCell>
            </TableRow>
            {props.value.customMetrics.map((metric) => (
              <TableRow key={metric.metricId}>
                <TableCell>{configCustomMetrics[metric.metricId].metric.name}</TableCell>
                <TableCell />
                <TableCell>
                  <MetricEnableToggle
                    config={configCustomMetrics[metric.metricId].realized}
                    value={metric.enabledRealized ?? false}
                    onValueChange={(enabledRealized) => onCustomMetricChange(metric.metricId, { enabledRealized })}
                  />
                </TableCell>
                <TableCell>
                  <MetricEnableToggle
                    config={configCustomMetrics[metric.metricId].estimated}
                    value={metric.enabledEstimated ?? false}
                    onValueChange={(enabledEstimated) => onCustomMetricChange(metric.metricId, { enabledEstimated })}
                  />
                </TableCell>
                <ThresholdConfigCells
                  config={configCustomMetrics[metric.metricId]}
                  value={metric}
                  disabled={!metric.enabledEstimated && !metric.enabledRealized}
                  onValueChange={(change) => onCustomMetricChange(metric.metricId, change)}
                />
                <TableCell>
                  <Button
                    cva={{ size: "small", intent: "reject" }}
                    onClick={() => onRemoveCustomMetric(metric.metricId)}
                  >
                    <Trash2Icon size={16} />
                    Remove from model
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={8}>
                <AddCustomMetricButton
                  onSelectMetric={onAddCustomMetric}
                  excludeMetrics={props.value.customMetrics.map((m) => m.metricId)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </RadioGroup>
    </performanceContext.Provider>
  );
};

export const PerformanceEstimatorConfig = ({ className }: { className?: string }) => {
  const { config, value, onValueChange } = usePerformanceConfig();
  const estimatorOptions =
    config.performanceTypes.filter((t) => t.type !== PerformanceType.Realized && t.isSupported).map((t) => t.type) ??
    [];
  const estimator = value.performanceTypes.find((t) => t.enabled && estimatorOptions.includes(t.type))?.type;
  const onEstimatorChange = (estimator: PerformanceType) => {
    onValueChange({
      performanceTypes: (value.performanceTypes ?? []).map((t) => ({
        ...t,
        enabled: t.type === estimator ? true : t.type === PerformanceType.Realized ? t.enabled : false,
      })),
    });
  };

  if (estimatorOptions.length <= 1) {
    return null;
  }

  return (
    <LabeledField
      label={
        <div className="flex items-end">
          Performance estimation method{" "}
          <InformationModalChip infoName="Performance analysis" className="text-current ml-1" />
        </div>
      }
      className={cn("w-fit", className)}
    >
      <Select value={estimator} onValueChange={onEstimatorChange} className="min-w-[100px]">
        {estimatorOptions.map((type) => (
          <SelectItem key={type} value={type}>
            {performanceTypeLabels[type]}
          </SelectItem>
        ))}
      </Select>
    </LabeledField>
  );
};

export const PerformanceEnableConfig = ({ className }: { className?: string }) => {
  const {
    kpm,
    value,
    configMetrics,
    onMetricChange,
    configCustomMetrics,
    onCustomMetricChange,
    onKpmChange,
    onAddCustomMetric,
    onRemoveCustomMetric,
  } = usePerformanceConfig();

  return (
    <RadioGroup value={kpm?.metric} onValueChange={onKpmChange} asChild>
      <Table className={cn("table", className)}>
        <TableHeader>
          <TableRow>
            <TableHead>Metric</TableHead>
            <TableHead className="whitespace-nowrap">
              KPM
              <InformationModalChip infoName="Key performance metric" className="p-0 mr-0 inline" />
            </TableHead>
            <TableHead className="whitespace-nowrap">Realized performance</TableHead>
            <TableHead className="whitespace-nowrap">Estimated performance</TableHead>
            <TableHead className="w-full"></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableSeparatorCell colSpan={8}>Standard metrics</TableSeparatorCell>
          </TableRow>
          {value.performanceMetrics.map((metric) => (
            <TableRow key={metric.metric}>
              <TableCell className="whitespace-nowrap">{performanceMetricLabels[metric.metric]}</TableCell>
              <TableCell>
                <KpmSelect metric={metric.metric} />
              </TableCell>
              <TableCell>
                <MetricEnableToggle
                  config={configMetrics[metric.metric].realized}
                  value={metric.enabledRealized ?? false}
                  onValueChange={(enabledRealized) => onMetricChange(metric.metric, { enabledRealized })}
                />
              </TableCell>
              <TableCell>
                <MetricEnableToggle
                  config={configMetrics[metric.metric].estimated}
                  value={metric.enabledEstimated ?? false}
                  onValueChange={(enabledEstimated) => onMetricChange(metric.metric, { enabledEstimated })}
                />
              </TableCell>
              <TableCell>
                <MetricConfigButton
                  value={metric}
                  onValueChange={(config) => onMetricChange(metric.metric, config)}
                  config={configMetrics[metric.metric]}
                />
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableSeparatorCell colSpan={8}>Custom metrics</TableSeparatorCell>
          </TableRow>
          {value.customMetrics.map((metric) => (
            <TableRow key={metric.metricId}>
              <TableCell className="whitespace-nowrap">
                <Link to={`/monitoring/metrics?id=${metric.metricId}`} className="underline hover:cursor-pointer">
                  {configCustomMetrics[metric.metricId].metric.name}
                </Link>
              </TableCell>
              <TableCell />
              <TableCell>
                <MetricEnableToggle
                  config={configCustomMetrics[metric.metricId].realized}
                  value={metric.enabledRealized ?? false}
                  onValueChange={(enabledRealized) => onCustomMetricChange(metric.metricId, { enabledRealized })}
                />
              </TableCell>
              <TableCell>
                <MetricEnableToggle
                  config={configCustomMetrics[metric.metricId].estimated}
                  value={metric.enabledEstimated ?? false}
                  onValueChange={(enabledEstimated) => onCustomMetricChange(metric.metricId, { enabledEstimated })}
                />
              </TableCell>
              <TableCell>
                <Button cva={{ size: "small", intent: "reject" }} onClick={() => onRemoveCustomMetric(metric.metricId)}>
                  <Trash2Icon size={16} />
                  Remove from model
                </Button>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={5}>
              <AddCustomMetricButton
                onSelectMetric={onAddCustomMetric}
                excludeMetrics={value.customMetrics.map((m) => m.metricId)}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </RadioGroup>
  );
};

export const PerformanceThresholdConfig = ({ segmentId, className }: { segmentId?: number; className?: string }) => {
  const { value, configMetrics, onMetricChange, configCustomMetrics, onCustomMetricChange } = usePerformanceConfig();
  return (
    <Table className={className}>
      <TableHeader>
        <TableRow>
          <TableHead>Metric</TableHead>
          <TableHead className="whitespace-nowrap">Threshold type</TableHead>
          <TableHead className="whitespace-nowrap">Threshold value</TableHead>
          <TableHead className="w-full" />
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableSeparatorCell colSpan={8}>Standard metrics</TableSeparatorCell>
        </TableRow>
        {value.performanceMetrics.map((value) => (
          <TableRow key={value.metric}>
            <TableCell className="whitespace-nowrap">{performanceMetricLabels[value.metric]}</TableCell>
            <ThresholdConfigCells
              config={configMetrics[value.metric]}
              value={value}
              segmentId={segmentId}
              onValueChange={(change) => onMetricChange(value.metric, change)}
              disabled={!value.enabledEstimated && !value.enabledRealized}
            />
          </TableRow>
        ))}
        <TableRow>
          <TableSeparatorCell colSpan={8}>Custom metrics</TableSeparatorCell>
        </TableRow>
        {value.customMetrics.map((value) => (
          <TableRow key={value.metricId}>
            <TableCell className="whitespace-nowrap">{configCustomMetrics[value.metricId].metric.name}</TableCell>
            <ThresholdConfigCells
              config={configCustomMetrics[value.metricId]}
              value={value}
              segmentId={segmentId}
              onValueChange={(change) => onCustomMetricChange(value.metricId, change)}
              disabled={!value.enabledEstimated && !value.enabledRealized}
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const KpmSelect = ({ metric }: { metric: PerformanceMetric }) => (
  <div className="flex justify-center items-center">
    <RadioGroupItem
      value={metric}
      disabled={metric === PerformanceMetric.ConfusionMatrix}
      title={
        metric === PerformanceMetric.ConfusionMatrix
          ? "Confusion matrix consists of multiple elements and cannot be selected as KPM"
          : undefined
      }
    />
  </div>
);

type PerformanceMetricConfigProps = {
  value: PerformanceMetricConfigInput;
  onValueChange: (config: Partial<PerformanceMetricConfigInput>) => void;
  config: ResultOf<typeof performanceRuntimeConfigFragment>["performanceMetrics"][number];
};

const BusinessValueConfig = (props: PerformanceMetricConfigProps) => {
  const { problemType } = usePerformanceConfig();
  return problemType === ProblemType.BinaryClassification ? (
    <BinaryClassificationBusinessValueConfig {...props} />
  ) : problemType === ProblemType.MulticlassClassification ? (
    <MulticlassClassificationBusinessValueConfig {...props} />
  ) : null;
};

const BinaryClassificationBusinessValueConfig = ({
  value,
  onValueChange,
}: Omit<PerformanceMetricConfigProps, "problemType">) => {
  const businessValue = value.businessValue!;
  const [weights, setWeights] = useState<Record<string, string>>({
    trueNegativeWeight: (businessValue.trueNegativeWeight ?? 0).toString(),
    falsePositiveWeight: (businessValue.falsePositiveWeight ?? 0).toString(),
    falseNegativeWeight: (businessValue.falseNegativeWeight ?? 0).toString(),
    truePositiveWeight: (businessValue.truePositiveWeight ?? 0).toString(),
  });

  const onWeightChange = (key: string, input: string) => {
    const weight = parseNullableFloat(input);
    setWeights((weights) => ({ ...weights, [key]: input }));
    if (weight !== null) {
      onValueChange({ businessValue: { ...businessValue, [key]: weight } });
    }
  };
  return (
    <div className="flex flex-col">
      <h3 className="font-bold text-lg">Business value configuration</h3>
      <span className="text-sm text-gray-400">
        The business value metric is calculated as a weighted sum of the confusion matrix elements.
      </span>
      <div className="flex items-center gap-2 [&>span]:mt-4 mt-6">
        <LabeledField label="True negative weight">
          <Input
            required
            placeholder="weight"
            value={weights.trueNegativeWeight ?? ""}
            onChange={(e) => onWeightChange("trueNegativeWeight", e.target.value)}
          />
        </LabeledField>
        <span>*</span>
        <span>TN</span>
        <span>+</span>
        <LabeledField label="False positive weight">
          <Input
            required
            placeholder="weight"
            value={weights.falsePositiveWeight ?? ""}
            onChange={(e) => onWeightChange("falsePositiveWeight", e.target.value)}
          />
        </LabeledField>
        <span>*</span>
        <span>FP</span>
        <span>+</span>
        <LabeledField label="False negative weight">
          <Input
            required
            placeholder="weight"
            value={weights.falseNegativeWeight ?? ""}
            onChange={(e) => onWeightChange("falseNegativeWeight", e.target.value)}
          />
        </LabeledField>
        <span>*</span>
        <span>FN</span>
        <span>+</span>
        <LabeledField label="True positive weight">
          <Input
            required
            placeholder="weight"
            value={weights.truePositiveWeight ?? ""}
            onChange={(e) => onWeightChange("truePositiveWeight", e.target.value)}
          />
        </LabeledField>
        <span>*</span>
        <span>TP</span>
      </div>
    </div>
  );
};

type BusinessValueMetricRuleInputWithId = BusinessValueMetricRuleInput & { id: number };

const MulticlassClassificationBusinessValueConfig = ({
  value,
  onValueChange,
  config,
}: Omit<PerformanceMetricConfigProps, "problemType">) => {
  // We need to add ID's to the rules in local state to allow for drag-and-drop reordering
  const [rules, setRules] = useState<BusinessValueMetricRuleInputWithId[]>(
    (value.businessValue!.rules ?? []).map((rule, i) => ({ ...rule, id: i }))
  );
  const { classes } = usePerformanceConfig();

  if (config.__typename !== "BusinessValueMetricConfig") {
    throw new Error(
      `Received unexpected metric '${config.__typename}' for multiclass classification business value config`
    );
  }
  if (!classes) {
    throw new Error("Classes must be provided for multiclass business value configuration");
  }

  const onRulesChange = (rules: BusinessValueMetricRuleInputWithId[]) => {
    // Update local state with new rules
    setRules(rules);

    // Update parent state with new rules, but don't expose the id field
    onValueChange({ businessValue: { ...value.businessValue, rules: rules.map(({ id, ...rule }) => rule) } });
  };

  const onRuleChange = (rule: BusinessValueMetricRuleInputWithId) => {
    onRulesChange(rules.map((r) => (r.id === rule.id ? rule : r)));
  };

  const onRuleRemove = (rule: BusinessValueMetricRuleInputWithId) => () => {
    onRulesChange(rules.filter((r) => r.id !== rule.id));
  };

  const onRuleMove = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = rules.findIndex((r) => r.id === active.id);
      const newIndex = rules.findIndex((r) => r.id === over.id);

      onRulesChange(arrayMove(rules, oldIndex, newIndex));
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <h3 className="font-bold text-lg">Business value configuration</h3>
      <span className="text-sm text-gray-400 max-w-[720px]">
        The business value metric is calculated as a weighted sum of the confusion matrix elements. On this page you can
        define rules to assign weights to different outcomes. Rules are applied from top to bottom, allowing later rules
        to override earlier ones.
      </span>
      <DndContext onDragEnd={onRuleMove} modifiers={[restrictToVerticalAxis, restrictToParentElement]}>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead />
              <TableHead>True class</TableHead>
              <TableHead>Predicted class</TableHead>
              <TableHead>Weight</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody className="border-b border-disabledGray">
            {rules
              .filter((r) => r.isDefaultRule)
              .map((rule) => (
                <MulticlassClassificationBusinessValueRule
                  key={rule.id}
                  rule={rule}
                  classes={classes}
                  onRuleChange={onRuleChange}
                  onRuleRemove={onRuleRemove(rule)}
                />
              ))}
          </TableBody>
          <TableBody>
            <SortableContext items={rules}>
              {rules
                .filter((r) => !r.isDefaultRule)
                .map((rule) => (
                  <MulticlassClassificationBusinessValueRule
                    key={rule.id}
                    rule={rule}
                    classes={classes}
                    onRuleChange={onRuleChange}
                    onRuleRemove={onRuleRemove(rule)}
                  />
                ))}
            </SortableContext>
          </TableBody>
        </Table>
      </DndContext>
      <Button
        cva={{ size: "small2", intent: "primary" }}
        className="text-sm"
        onClick={() =>
          onRulesChange(
            rules.concat({
              trueClass: ClassificationRuleType.Any,
              trueClassName: null,
              predictedClass: ClassificationRuleType.Equals,
              predictedClassName: null,
              weight: 0,
              id: rules.length,
            })
          )
        }
      >
        <PlusIcon size={16} /> Add rule
      </Button>
    </div>
  );
};

const MulticlassClassificationBusinessValueRule = ({
  rule,
  classes,
  onRuleChange,
  onRuleRemove,
}: {
  rule: BusinessValueMetricRuleInputWithId;
  classes: string[];
  onRuleChange: (rule: BusinessValueMetricRuleInputWithId) => void;
  onRuleRemove: () => void;
}) => {
  const [weight, setWeight] = useState(rule.weight.toString());
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: rule.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <TableRow ref={setNodeRef} style={style}>
      {rule.isDefaultRule ? (
        <TableCell />
      ) : (
        <TableCell {...attributes} {...listeners}>
          <GripVerticalIcon size={16} />
        </TableCell>
      )}
      <TableCell>
        <BusinessValueRuleClassSelect
          classes={classes}
          equalsSuffix="predicted class"
          onClassChange={(trueClass, trueClassName) => onRuleChange({ ...rule, trueClass, trueClassName })}
          ruleType={rule.trueClass}
          ruleClassName={rule.trueClassName}
          disabled={rule.isDefaultRule}
          disableDependent={
            rule.predictedClass === ClassificationRuleType.Equals ||
            rule.predictedClass === ClassificationRuleType.NotEquals
          }
        />
      </TableCell>
      <TableCell>
        <BusinessValueRuleClassSelect
          classes={classes}
          equalsSuffix="true class"
          onClassChange={(predictedClass, predictedClassName) =>
            onRuleChange({ ...rule, predictedClass, predictedClassName })
          }
          ruleType={rule.predictedClass}
          ruleClassName={rule.predictedClassName}
          disabled={rule.isDefaultRule}
          disableDependent={
            rule.trueClass === ClassificationRuleType.Equals || rule.trueClass === ClassificationRuleType.NotEquals
          }
        />
      </TableCell>
      <TableCell>
        <Input
          required
          placeholder="weight"
          value={weight}
          onChange={(e) => {
            setWeight(e.target.value);
            onRuleChange({ ...rule, weight: parseNullableFloat(e.target.value) ?? 0 });
          }}
        />
      </TableCell>
      <TableCell>
        <SimpleTooltip
          tooltipContent={
            rule.isDefaultRule
              ? "This is a built-in rule. It cannot be removed, but you can change the associated weight."
              : "Remove rule"
          }
          side="right"
        >
          <Button cva={{ intent: "icon", size: "chip" }} onClick={onRuleRemove} disabled={rule.isDefaultRule}>
            <Trash2Icon size={20} strokeWidth={1} />
          </Button>
        </SimpleTooltip>
      </TableCell>
    </TableRow>
  );
};

const formatRuleClassValue = (ruleType: ClassificationRuleType, className: string | null | undefined) =>
  ruleType === ClassificationRuleType.Class ? `class:${className}` : ruleType;

const parseRuleClassValue = (value: string): [ClassificationRuleType, string | null] =>
  value.startsWith("class:") ? [ClassificationRuleType.Class, value.slice(6)] : [value as ClassificationRuleType, null];

const getRuleClassLabel =
  (equalsSuffix: string) =>
  ({ value }: { value: string }) => {
    const [ruleType, className] = parseRuleClassValue(value);
    if (ruleType === ClassificationRuleType.Class) {
      return <>{className}</>;
    } else if (ruleType === ClassificationRuleType.Equals) {
      return <>= {equalsSuffix}</>;
    } else if (value === ClassificationRuleType.NotEquals) {
      return <>≠ {equalsSuffix}</>;
    } else {
      return <>Any</>;
    }
  };

const BusinessValueRuleClassSelect = ({
  ruleType,
  ruleClassName,
  onClassChange,
  equalsSuffix,
  classes,
  disabled,
  disableDependent,
}: {
  ruleType: ClassificationRuleType;
  ruleClassName: string | null | undefined;
  onClassChange(ruleType: ClassificationRuleType, className: string | null): void;
  equalsSuffix: string;
  classes: string[];
  disabled?: boolean;
  disableDependent?: boolean;
}) => (
  <ComboBox
    required
    className="w-60"
    placeholder="Select class"
    values={[formatRuleClassValue(ruleType, ruleClassName)]}
    onChange={([value]) => onClassChange(...parseRuleClassValue(value))}
    allowMultiple={false}
    ValueComponent={getRuleClassLabel(equalsSuffix)}
    disabled={disabled}
  >
    <ComboBoxSearch />
    <ComboBoxGroup>
      <ComboBoxItem value={ClassificationRuleType.Any}>Any</ComboBoxItem>
      <ComboBoxItem value={ClassificationRuleType.Equals} disabled={disableDependent}>
        = {equalsSuffix}
      </ComboBoxItem>
      <ComboBoxItem value={ClassificationRuleType.NotEquals} disabled={disableDependent}>
        ≠ {equalsSuffix}
      </ComboBoxItem>
    </ComboBoxGroup>
    <ComboBoxGroup>
      {classes.map((className) => (
        <ComboBoxItem key={className} value={`class:${className}`}>
          {className}
        </ComboBoxItem>
      ))}
    </ComboBoxGroup>
  </ComboBox>
);

const metricConfigurators: Partial<Record<PerformanceMetric, ComponentType<PerformanceMetricConfigProps>>> = {
  [PerformanceMetric.BusinessValue]: BusinessValueConfig,
};

const MetricConfigButton = ({ value, onValueChange, config }: PerformanceMetricConfigProps) => {
  const MetricConfig = metricConfigurators[value.metric];
  if (!MetricConfig) {
    return null;
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          cva={{ size: "small", intent: "secondary" }}
          className="flex items-center gap-2"
          disabled={!value.enabledEstimated && !value.enabledRealized}
        >
          <SettingsIcon size={16} />
          Configure
        </Button>
      </DialogTrigger>
      <DialogContent className="text-pale max-h-full max-w-full w-fit overflow-auto">
        <Suspense fallback={<RequestStateLayout isLoading={true} loaderText="Loading settings..." />}>
          <MetricConfig value={value} onValueChange={onValueChange} config={config} />
        </Suspense>
      </DialogContent>
    </Dialog>
  );
};
