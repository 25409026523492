import { CalculatorType } from "@/apis/nannyml";
import { ObjectValues } from "@/constants/enums";
import { ProblemTypeOptions } from "@/domains/model/modelOptions";
import { keys } from "@/lib/objUtils";

export const FilterPerformanceAnalysisOptions = {
  estimatedVsRealizedPerformance: "Estimated VS Realized performance",
  estimatedAndRealizedPerformance: "Estimated and Realized performance",
  realizedPerformance: "Realized performance",
  estimatedPerformance: "Estimated performance",
} as const;

export type TypeFilterPerformanceAnalysisOptions = ObjectValues<typeof FilterPerformanceAnalysisOptions>;

export const FilterPerformanceAnalysisOptionsValues = Object.values(FilterPerformanceAnalysisOptions);

export const PerformanceCalculatorOptions = {
  RealizedPerformance: "RealizedPerformance",
} as const;

export type TypePerformanceCalculatorOptions = ObjectValues<typeof PerformanceCalculatorOptions>;

export const PerformanceEstimatorOptions = {
  Cbpe: CalculatorType.Cbpe,
  Pape: CalculatorType.Pape,
  Dle: CalculatorType.Dle,
};

export type TypePerformanceEstimatorOptions = ObjectValues<typeof PerformanceEstimatorOptions>;

export const PerformanceMetricOptions = {
  ROC_AUC: "ROC_AUC",
  F1: "F1",
  Precision: "Precision",
  Recall: "Recall",
  Specificity: "Specificity",
  Accuracy: "Accuracy",
  BusinessValue: "BusinessValue",
  ConfusionMatrix: "ConfusionMatrix",
  AveragePrecision: "AveragePrecision",

  MAE: "MAE",
  MAPE: "MAPE",
  MSE: "MSE",
  RMSE: "RMSE",
  MSLE: "MSLE",
  RMSLE: "RMSLE",
} as const;

export type TypePerformanceMetricOptions = ObjectValues<typeof PerformanceMetricOptions>;

export const PerformanceMetricOptionsValues = Object.values(PerformanceMetricOptions);

export const BinaryClassificationMetrics = [
  PerformanceMetricOptions.ROC_AUC,
  PerformanceMetricOptions.F1,
  PerformanceMetricOptions.Precision,
  PerformanceMetricOptions.Recall,
  PerformanceMetricOptions.Specificity,
  PerformanceMetricOptions.Accuracy,
  PerformanceMetricOptions.BusinessValue,
  PerformanceMetricOptions.ConfusionMatrix,
  PerformanceMetricOptions.AveragePrecision,
] satisfies TypePerformanceMetricOptions[];

export const ClassificationMetrics = [
  PerformanceMetricOptions.ROC_AUC,
  PerformanceMetricOptions.F1,
  PerformanceMetricOptions.Precision,
  PerformanceMetricOptions.Recall,
  PerformanceMetricOptions.Specificity,
  PerformanceMetricOptions.Accuracy,
  PerformanceMetricOptions.BusinessValue,
] satisfies TypePerformanceMetricOptions[];

export const RegressionMetrics = [
  PerformanceMetricOptions.MAE,
  PerformanceMetricOptions.MAPE,
  PerformanceMetricOptions.MSE,
  PerformanceMetricOptions.RMSE,
  PerformanceMetricOptions.RMSLE,
  PerformanceMetricOptions.MSLE,
] satisfies TypePerformanceMetricOptions[];

export const ProblemTypePerformanceMetricsOptions = {
  [ProblemTypeOptions.BinaryClassification]: BinaryClassificationMetrics,
  [ProblemTypeOptions.MulticlassClassification]: ClassificationMetrics,
  [ProblemTypeOptions.Regression]: RegressionMetrics,
} as const;

export const ProblemTypePerformanceEstimatorOptions = {
  [ProblemTypeOptions.BinaryClassification]: [PerformanceEstimatorOptions.Cbpe, PerformanceEstimatorOptions.Pape],
  [ProblemTypeOptions.MulticlassClassification]: [PerformanceEstimatorOptions.Cbpe],
  [ProblemTypeOptions.Regression]: [PerformanceEstimatorOptions.Dle],
} as const;

export const PreferredProblemTypePerformanceMetricsOptions = {
  [ProblemTypeOptions.BinaryClassification]: PerformanceMetricOptions.ROC_AUC,
  [ProblemTypeOptions.MulticlassClassification]: PerformanceMetricOptions.ROC_AUC,
  [ProblemTypeOptions.Regression]: PerformanceMetricOptions.MAE,
};

export const MatrixValueOptions = {
  truePositiveWeight: "truePositiveWeight",
  falseNegativeWeight: "falseNegativeWeight",
  falsePositiveWeight: "falsePositiveWeight",
  trueNegativeWeight: "trueNegativeWeight",
};

export const matrixValuesKeys = keys(MatrixValueOptions);

export type TypeMatrixValuesKeys = (typeof matrixValuesKeys)[number];
