import { InputWithClearButton } from "@/DesignSystem/shadcn/Input/Input";
import {
  CreateEvaluationModelInput,
  EvaluationHypothesis,
  EvaluationPerformanceMetricConfigInput,
  PerformanceMetric,
} from "@/apis/nannyml";
import { Alert } from "@/components/Alert";
import { RadioGroup, RadioGroupItem } from "@/components/RadioGroup";
import { RopeInput } from "@/components/RopeInput";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/Table";
import { Checkbox } from "@/components/common/Checkbox/Checkbox";
import { toast } from "@/components/common/Toast/useToast";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";
import { evaluationPerformanceMetrics } from "@/domains/evaluation";
import { performanceMetricLabels } from "@/formatters/monitoring";
import { parseNullableFloat } from "@/lib/numbersUtils";

type InputArgs = {
  hypothesis?: EvaluationHypothesis;
  kpm?: PerformanceMetric;
  metrics?: Array<EvaluationPerformanceMetricConfigInput>;
};

type ChangeArgs = {
  kpm: PerformanceMetric;
  metrics: Array<EvaluationPerformanceMetricConfigInput>;
};

export const EvaluationMetricConfig = ({
  value: { hypothesis, kpm, metrics },
  onChange,
}: {
  value: InputArgs;
  onChange: (change: Partial<ChangeArgs>) => void;
}) => {
  const metricMap = Object.fromEntries(metrics?.map((metric) => [metric.metric, metric]) ?? []);

  // Helper function for updating metric config
  const setMetricConfig = (metric: PerformanceMetric, config: Partial<CreateEvaluationModelInput["metrics"][0]>) => {
    if (config.enabled === false) {
      // Check at least one metric is enabled
      const enabledMetric = metrics?.find((m) => m.metric !== metric && m.enabled);
      if (!enabledMetric) {
        toast({
          title: "Cannot disable metric",
          description: "At least one metric must be enabled",
          variant: "error",
        });
        return;
      }

      // Switch KPM to another metric when it gets disabled
      if (metric === kpm) {
        onChange({ kpm: enabledMetric?.metric ?? PerformanceMetric.RocAuc });
      }
    }

    const idx = metrics!.findIndex((m) => m.metric === metric);
    const metricConfig = { ...metrics![idx], ...config };
    onChange({
      metrics: [...metrics!.slice(0, idx), metricConfig, ...metrics!.slice(idx + 1)],
    });
  };

  return (
    <RadioGroup
      value={kpm ?? ""}
      onValueChange={(value) => onChange({ kpm: value as PerformanceMetric })}
      className="w-[800px]"
    >
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="whitespace-nowrap">
              Enabled
              <InformationModalChip infoName="Enable metric" className="p-0 mr-0 inline align-text-bottom" />
            </TableHead>
            <TableHead className="whitespace-nowrap">
              KPM
              <InformationModalChip infoName="Key performance metric" className="p-0 mr-0 inline align-text-bottom" />
            </TableHead>
            <TableHead className="whitespace-nowrap">Meric name</TableHead>
            <TableHead className="whitespace-nowrap">
              Region Of Practical Equivalence (ROPE)
              <InformationModalChip
                infoName="Region of practical equivalence (ROPE)"
                className="p-0 mr-0 inline align-text-bottom"
              />
            </TableHead>
            <TableHead className="whitespace-nowrap">
              Required 95% HDI width
              <InformationModalChip infoName="Required 95% HDI width" className="p-0 mr-0 inline align-text-bottom" />
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {evaluationPerformanceMetrics.map((metric) => (
            <TableRow key={metric}>
              <TableCell className="text-center !pr-3">
                <Checkbox
                  checked={metricMap[metric]?.enabled ?? true}
                  onCheckedChange={(enabled) => setMetricConfig(metric, { enabled: Boolean(enabled) })}
                />
              </TableCell>
              <TableCell className="text-center">
                <RadioGroupItem value={metric} disabled={!metricMap[metric]?.enabled} />
              </TableCell>
              <TableCell>{performanceMetricLabels[metric]}</TableCell>
              <TableCell>
                <RopeInput
                  min={0}
                  max={1}
                  supportsAutomatic={hypothesis !== EvaluationHypothesis.ModelPerformanceWithinRange}
                  lowerBound={metricMap[metric]?.ropeLowerBound ?? null}
                  upperBound={metricMap[metric]?.ropeUpperBound ?? null}
                  disabled={!metricMap[metric]?.enabled}
                  onChange={(ropeLowerBound, ropeUpperBound) =>
                    setMetricConfig(metric, { ropeLowerBound, ropeUpperBound })
                  }
                />
              </TableCell>
              <TableCell>
                <InputWithClearButton
                  type="number"
                  min="0"
                  max="1"
                  step="any"
                  placeholder="Automatic"
                  value={metricMap[metric]?.hdiWidth ?? ""}
                  onChange={(e) => setMetricConfig(metric, { hdiWidth: parseNullableFloat(e.target.value) })}
                  onBlur={(e) => e.target.reportValidity()}
                  disabled={!metricMap[metric]?.enabled}
                  className="hide-arrows"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Alert severity="info" title="Automatic defaults" className="text-sm mt-3">
        <p>
          If ROPE or required HDI width are not specified we'll calculate values for you based on reference data. For
          most scenarios these defaults are good enough, but if you have specific requirements you can provide custom
          values.
        </p>
        <p className="mt-2">Note when specifying ROPE, you must specify both the lower and upper bound.</p>
      </Alert>
    </RadioGroup>
  );
};
