import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { getUserSettings } from "@/apis/nannyml/queries/getUserSettings/getUserSettings";
import { useApplicationConfiguration } from "@/components/ApplicationConfiguration";
import { Button } from "@/components/common/Button";
import { SimpleInput } from "@/components/common/FormElements/SimpleInput/SimpleInput";
import { RequestStateLayout } from "@/components/dashboard/RequestStateLayout/RequestStateLayout";
import { ApplicationSetup } from "@/pages/ApplicationSetup";

import { ApiTokens } from "./ApiTokens";
import { NotificationSettings } from "./NotificationSettings";
import { UsageStatistics } from "./UsageStatistics";

export const Settings = () => {
  const navigate = useNavigate();
  const appSettings = useApplicationConfiguration();
  const userSettingsQuery = useQuery(getUserSettings);

  if (userSettingsQuery.loading || userSettingsQuery.error || !userSettingsQuery.data) {
    return (
      <RequestStateLayout
        isLoading={userSettingsQuery.loading}
        hasError={Boolean(userSettingsQuery.error) || !userSettingsQuery.data}
      />
    );
  }

  const userSettings = userSettingsQuery.data.current_user;

  return (
    <div className="flex-grow px-20 py-10">
      <div className="flex flex-col gap-8">
        <h2 className="text-4xl font-bold">Profile</h2>

        {userSettings?.email ? (
          <div>
            <SimpleInput
              fieldName={"email"}
              label={"Email"}
              value={userSettings.email ?? ""}
              inputClassName={"w-[350px] bg-dark border-0"}
              disabled={true}
            />
          </div>
        ) : (
          <p>Using app on authentication-less mode</p>
        )}
        <UsageStatistics enabled={appSettings?.usageStatistics ?? false} />
        {userSettings?.notificationSettings ? (
          <NotificationSettings settings={userSettings.notificationSettings} email={userSettings.email} />
        ) : null}
        <ApiTokens />
        <div className="w-[800px]">
          <h3 className="font-bold text-3xl text-alert">Danger zone</h3>
          <p className="text-gray-400 text-sm mt-2">
            This section allows you to make changes to the application that could break things. Make sure you know what
            you're doing before applying changes.
          </p>
          <div className="flex justify-between mt-4 items-center gap-4">
            <div className="flex flex-col">
              <p className="font-bold">Configure application</p>
              <p className="text-sm text-gray-400">
                Use the application setup wizard to change authentication, notification delivery methods, etc. This will
                affect all users.
              </p>
            </div>
            <Button
              label={"Configure application"}
              cva={{ intent: "reject" }}
              className="p-2 justify-center whitespace-nowrap h-fit"
              onClick={() => navigate(ApplicationSetup.url)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
