import { cva, VariantProps } from "class-variance-authority";
import { XIcon } from "lucide-react";
import * as React from "react";

import { Button } from "@/components/common/Button";
import { cn } from "@/lib/utils";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const inputStyle = cva(
  [
    "flex h-10 w-full rounded-md border border-gray-500 bg-transparent py-2 px-3 text-sm text-slate-50",
    "focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-900",
    "disabled:cursor-not-allowed disabled:opacity-50",
    "file:bg-transparent file:text-sm file:font-medium file:border-0 file:text-slate-50",
    "placeholder:text-slate-400 placeholder:italic",
    "invalid:border-red-500 invalid:focus:ring-red-400",
  ],
  {
    variants: {
      style: {
        default: "",
        underline: [
          "hide-arrows rounded-none h-fit px-0 py-[2px] text-center",
          "border-x-0 border-t-0 border-b-1 border-white dark:border-white border-solid",
          "focus:outline-dotted focus:outline-[rgba(255,255,255,0.3)] focus:outline-1 focus:ring-0 focus:ring-offset-0",
        ],
      },
    },
    defaultVariants: {
      style: "default",
    },
  }
);

export const Input = React.forwardRef<
  HTMLInputElement,
  InputProps & {
    cva?: VariantProps<typeof inputStyle>;
  }
>(({ className, cva, ...props }, ref) => {
  return <input className={cn(inputStyle(cva), className)} ref={ref} {...props} />;
});
Input.displayName = "Input";

// React overrides the input setter. See https://stackoverflow.com/a/46012210 for context
const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")!.set!;

export const InputWithClearButton = React.forwardRef<HTMLInputElement, React.ComponentPropsWithoutRef<typeof Input>>(
  ({ className, disabled, ...props }, forwardRef) => {
    const ref = React.useRef<HTMLInputElement>(null);

    React.useImperativeHandle(forwardRef, () => ref.current!);

    return (
      <div className="relative">
        <Input ref={ref} className={cn("pr-8", className)} disabled={disabled} {...props} />
        <Button
          className="absolute right-1 top-2.5 text-slate-400"
          cva={{ intent: "icon", size: "chip" }}
          onClick={() => {
            nativeInputValueSetter.call(ref.current, "");
            ref.current!.dispatchEvent(new Event("input", { bubbles: true }));
          }}
          disabled={disabled}
          disabledStyle="icon"
          title="Clear value"
          tabIndex={-1}
        >
          <XIcon size={14} />
        </Button>
      </div>
    );
  }
);
