import { BookTextIcon, HistoryIcon, MessageCircleQuestion } from "lucide-react";
import { Suspense } from "react";

import { Card } from "@/components/Card";
import { ApplicationVersion } from "@/components/Version";

export const Help = () => {
  return (
    <div className="grid grid-cols-[repeat(auto-fill,minmax(400px,1fr))] gap-8 px-20 py-10">
      <h2 className="text-4xl font-bold col-span-full">About NannyML Cloud</h2>
      <Card>
        <h3 className="font-semibold text-xl mb-4 flex gap-2 items-center">
          <BookTextIcon size={24} /> Documentation
        </h3>
        <p className="text-sm">
          Want to learn more about NannyML Cloud? Check out{" "}
          <a className="underline" href="https://docs.nannyml.com/cloud" target="_blank">
            the documentation
          </a>
          .
        </p>
      </Card>
      <Card>
        <h3 className="font-semibold text-xl mb-4 flex gap-2 items-center">
          <MessageCircleQuestion size={24} /> Support
        </h3>
        <p className="text-sm">
          Need help? Contact us at{" "}
          <a className="underline" href="mailto:support@nannyml.com" target="_blank">
            support@nannyml.com
          </a>
          .
        </p>
      </Card>
      <Card className="flex flex-col gap-4">
        <h3 className="font-semibold text-xl flex gap-2 items-center">
          <HistoryIcon size={24} />
          Version
        </h3>
        <Suspense fallback={<div>Loading...</div>}>
          <p className="text-sm">
            NannyML Cloud <ApplicationVersion />
          </p>
          <p className="text-sm">
            Curious what has changed? Find out in our{" "}
            <a className="underline" href="https://docs.nannyml.com/cloud/miscellaneous/versions">
              release notes
            </a>
            .
          </p>
        </Suspense>
      </Card>
    </div>
  );
};
