import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { createFragmentRegistry } from "@apollo/client/cache";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { PropsWithChildren } from "react";

import { useAuth } from "@/apis/nannyml/auth";
import * as fragments from "@/apis/nannyml/fragments";
import { NANNYML_API_ADDRESS } from "@/constants/env";

import api from "./codegen/__generated/graphql";

export { type FragmentType, gql, useFragment } from "./codegen/__generated";
export * from "./codegen/__generated/graphql";
export * from "./fragments";

export type ApiProviderType = PropsWithChildren<{}>;

const cache = new InMemoryCache({
  fragments: createFragmentRegistry(...Object.values(fragments)),
  possibleTypes: api.possibleTypes,
  typePolicies: {
    ApplicationSettings: {
      keyFields: [],
    },
    Model: {
      merge: true,
      fields: {
        kpm: {
          merge: true,
        },
      },
    },
    TimeSeriesResult: {
      fields: {
        lastDataPoint: {
          merge: true,
        },
        config: {
          merge: true,
        },
      },
    },
    EvaluationModel: {
      merge: true,
    },
    EvaluationPerformanceResult: {
      keyFields: ["modelId", "metric"],
      merge: true,
    },
    Experiment: {
      merge: true,
    },
    ExperimentResultMetric: {
      keyFields: ["experimentId", "metric"],
      merge: true,
    },
  },
});

export const PublicApiProvider = ({ children }: ApiProviderType) => {
  const client = new ApolloClient({
    uri: `${NANNYML_API_ADDRESS}public`,
    cache,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export const SecureApiProvider = ({ children }: ApiProviderType) => {
  const httpLink = createUploadLink({
    uri: `${NANNYML_API_ADDRESS}graphql`,
  });
  const { acquireToken } = useAuth();

  const withToken = setContext((_, { headers }) =>
    acquireToken().then((token) => ({
      headers: !token
        ? headers
        : {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
    }))
  );

  const client = new ApolloClient({
    link: withToken.concat(httpLink),
    cache,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
