export type ObjectValues<T> = T[keyof T];

export enum AlertStatusFilter {
  NoAlerts = "NO_ALERTS",
  AlertInAnyChunk = "ALERT_IN_ANY_CHUNK",
  AlertInLastChunk = "ALERT_IN_LAST_CHUNK",
  AlertForKeyPerformanceMetric = "ALERT_FOR_MAIN_METRIC",
  Everything = "EVERTYHING",
}

export enum PerformancePlotMode {
  EstimatedPerformance = "ESTIMATED_PERFORMANCE",
  RealizedPerformance = "REALIZED_PERFORMANCE",
  RealizedAndEstimatedPerformance = "REALIZED_AND_ESTIMATED_PERFORMANCE",
  CompareRealizedToEstimatedPerformance = "COMPARE_REALIZED_TO_ESTIMATED_PERFORMANCE",
}

export enum SortOrder {
  NrOfAlers = "NR_OF_ALERTS",
  RecencyOfAlerts = "RECENCY_OF_ALERTS",
  Metric = "METRIC",
  Method = "METHOD",
  Column = "COLUMN",
}

export enum PlotType {
  Step = "STEP",
  Line = "LINE",
  Distribution = "DISTRIBUTION",
}

export enum PlotElements {
  Alerts = "ALERTS",
  ConfidenceBands = "CONFIDENCE_BANDS",
  Legend = "LEGEND",
  Thresholds = "THREHOLDS",
  Grid = "GRID",
}

export enum EmailMode {
  Disabled = "DISABLED",
  Saas = "SAAS",
  Smtp = "SMTP",
}

export enum WebhookMode {
  Disabled = "DISABLED",
  Custom = "CUSTOM",
}
