import { useApolloClient } from "@apollo/client";
import { CheckCircle2Icon, Loader2Icon, XCircleIcon } from "lucide-react";
import { useLayoutEffect, useState } from "react";

import { EmailNotificationSettingsInput, EmailSmtpSettingsInput } from "@/apis/nannyml";
import { sendTestEmail } from "@/apis/nannyml/queries/sendTestEmail";
import { Alert } from "@/components/Alert";
import { ButtonGroup } from "@/components/ButtonGroup";
import { Card } from "@/components/Card";
import { RadioGroup, RadioGroupItem } from "@/components/RadioGroup";
import { Button } from "@/components/common/Button";
import { SimpleCheckbox } from "@/components/common/FormElements/SimpleCheckbox/SimpleCheckbox";
import { SimpleInput } from "@/components/common/FormElements/SimpleInput/SimpleInput";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";
import { EmailMode } from "@/constants/enums";
import { emailModeLabels } from "@/formatters/applicationConfiguration";
import { cn } from "@/lib/utils";

const getEmailMode = (settings?: EmailNotificationSettingsInput | null): EmailMode | undefined => {
  if (!settings) {
    return undefined;
  } else if (!settings.enabled) {
    return EmailMode.Disabled;
  } else if (settings.smtp) {
    return EmailMode.Smtp;
  } else {
    return EmailMode.Saas;
  }
};

type NotificationSetupProps = {
  className?: string;
  notifications: EmailNotificationSettingsInput | null;
  onChange: (notifications: EmailNotificationSettingsInput | null) => void;
};

export const NotificationSetup = ({ notifications, className, onChange }: NotificationSetupProps) => {
  const [emailMode, setEmailMode] = useState(getEmailMode(notifications));

  // If no email mode is selected, show a list of options as cards
  if (!emailMode) {
    return (
      <div className={cn("flex flex-col justify-center items-center", className)}>
        <h3 className="text-xl font-bold mb-10">Select method for delivering email notifications</h3>
        <RadioGroup
          className="w-full flex gap-6"
          value={emailMode}
          onValueChange={(value) => setEmailMode(value as EmailMode)}
        >
          {Object.values(EmailMode).map((mode) => (
            <RadioGroupItem key={mode} value={mode} asChild>
              <Card className="basis-0 grow flex flex-col">
                <span className="font-bold mb-2">{emailModeLabels[mode].label}</span>
                <span className="text-sm mb-5 text-gray-400">{emailModeLabels[mode].description}</span>
                <span className="text-sm">{emailModeLabels[mode].recommendation}</span>
              </Card>
            </RadioGroupItem>
          ))}
        </RadioGroup>
      </div>
    );
  }

  const EmailDetailComponent = emailDetailComponents[emailMode];

  // If mode is selected, show small selector on top & configuration options for that mode underneath
  return (
    <div className={cn("flex flex-col items-center", className)}>
      <RadioGroup
        className="w-full mb-5"
        value={emailMode}
        onValueChange={(value) => (setEmailMode(value as EmailMode), onChange(null))}
      >
        <ButtonGroup className="w-full">
          {Object.values(EmailMode).map((mode) => (
            <RadioGroupItem className="basis-0 grow flex flex-col text-left" key={mode} value={mode}>
              <span>{emailModeLabels[mode].label}</span>
              <span className="text-sm text-gray-400">{emailModeLabels[mode].description}</span>
            </RadioGroupItem>
          ))}
        </ButtonGroup>
      </RadioGroup>
      {emailMode && (
        <div className="w-full">
          <EmailDetailComponent notifications={notifications} onChange={onChange} />
        </div>
      )}
    </div>
  );
};

const SmtpEmailDetails = ({ className, notifications, onChange }: NotificationSetupProps) => {
  const { longDescription, recommendation } = emailModeLabels[EmailMode.Smtp];

  const [host, setHost] = useState(notifications?.smtp?.host ?? "");
  const [port, setPort] = useState(notifications?.smtp?.port ?? 587);
  const [username, setUsername] = useState(notifications?.smtp?.username ?? "");
  const [password, setPassword] = useState(notifications?.smtp?.password ?? "");
  const [sender, setSender] = useState(notifications?.smtp?.sender ?? "");
  const [useTls, setUseTls] = useState(notifications?.smtp?.useTls ?? true);

  return (
    <div className={cn("grow flex flex-col gap-5", className)}>
      <p>{longDescription}</p>
      <p>{recommendation}</p>
      <form className="flex flex-col w-full lg:w-3/4 xl:w-2/3" onSubmit={(e) => e.preventDefault()}>
        <h3 className="text-lg font-bold mt-5 mb-1">SMTP Configuration</h3>
        <p className="text-sm text-gray-400 mb-3">These settings will be used to connect to the SMTP server.</p>
        <div className="flex gap-3 mb-3">
          <SimpleInput
            containerClassName="grow"
            inputClassName="dark:border-gray-500"
            label="Host"
            placeholder="SMTP server address"
            value={host}
            onChange={setHost}
            fieldName="host"
          />
          <SimpleInput
            inputClassName="dark:border-gray-500"
            label="Port"
            placeholder="SMTP server port"
            type="number"
            value={port.toString()}
            onChange={(value) => setPort(parseInt(value))}
            fieldName="port"
          />
        </div>
        <div className="flex gap-3 mb-3">
          <SimpleInput
            containerClassName="grow"
            inputClassName="dark:border-gray-500"
            label="Username"
            placeholder="SMTP username"
            value={username}
            onChange={setUsername}
            fieldName="username"
          />
          <SimpleInput
            containerClassName="grow"
            inputClassName="dark:border-gray-500"
            label="Password"
            placeholder="SMTP password"
            type="password"
            value={password}
            onChange={setPassword}
            fieldName="password"
          />
        </div>
        <SimpleInput
          containerClassName="mb-3"
          inputClassName="dark:border-gray-500"
          label="Sender"
          placeholder="Sender email address"
          type="email"
          value={sender}
          onChange={setSender}
          fieldName="sender"
        />
        <div className="flex items-center">
          <SimpleCheckbox
            checkboxClassName="dark:border-gray-500"
            label="Use TLS"
            value={useTls}
            onChange={setUseTls}
            name="useTls"
            containerClassName="flex"
          />
          <InformationModalChip infoName="SMTP TLS" />
        </div>
        <hr className="border-gray-400 my-5" />
        <span className="text-sm text-gray-400 mb-3">
          Enter an email address and send a test email before continuing. This will not save the email address.
        </span>
        <TestSmtpEmail
          onConnectionTest={(smtpSettings, success) =>
            onChange(
              !success
                ? null
                : {
                    enabled: true,
                    smtp: smtpSettings,
                  }
            )
          }
          smtpSettings={{ host, port, username, password, sender, useTls }}
        />
      </form>
    </div>
  );
};

const SaasEmailDetails = ({ className, onChange }: NotificationSetupProps) => {
  const { longDescription, recommendation } = emailModeLabels[EmailMode.Saas];

  useLayoutEffect(() => {
    // Enable notifications without email configuration. This will cause the backend to use Resend.
    onChange({
      enabled: true,
    });
  }, []);

  return (
    <div className={cn("grow flex flex-col gap-5", className)}>
      <p>{longDescription}</p>
      <p>{recommendation}</p>
      <Alert className="mt-5" title="Info" severity="info">
        <p>
          We use{" "}
          <a className="underline" target="_blank" href="https://resend.com/">
            Resend
          </a>{" "}
          to send emails. Their system may store emails for some time. If you don't want this, we recommend using your
          own SMTP server.
        </p>
      </Alert>
    </div>
  );
};

const DisabledEmailDetails = ({ className, onChange }: NotificationSetupProps) => {
  const { longDescription, recommendation } = emailModeLabels[EmailMode.Disabled];

  useLayoutEffect(() => {
    // Disable notifications
    onChange({
      enabled: false,
    });
  }, []);

  return (
    <div className={cn("grow flex flex-col gap-5", className)}>
      <p>{longDescription}</p>
      <p>{recommendation}</p>
      <Alert className="mt-5" title="Info" severity="info">
        We recommend enabling notifications for production use. If desired, users can opt out of notifications in their
        individual profile settings.
      </Alert>
    </div>
  );
};

const emailDetailComponents: Record<EmailMode, React.FunctionComponent<NotificationSetupProps>> = {
  [EmailMode.Smtp]: SmtpEmailDetails,
  [EmailMode.Saas]: SaasEmailDetails,
  [EmailMode.Disabled]: DisabledEmailDetails,
};

const TestSmtpEmail = ({
  className,
  onConnectionTest,
  smtpSettings,
}: {
  className?: string;
  onConnectionTest: (smtpSettings: EmailSmtpSettingsInput, result: boolean) => void;
  smtpSettings: EmailSmtpSettingsInput;
}) => {
  const client = useApolloClient();
  const [testResult, setTestResult] = useState<boolean | string | null>(null);
  const [isTestActive, setIsTestActive] = useState<boolean>(false);
  const [recipient, setRecipient] = useState<string>("");

  const sendEmail = () => {
    setIsTestActive(true);
    setTestResult(null);

    client
      .query({
        query: sendTestEmail,
        fetchPolicy: "no-cache",
        variables: {
          recipient,
          smtpSettings,
        },
      })
      .then(() => {
        setTestResult(true);
        onConnectionTest(smtpSettings, true);
      })
      .catch((error) => {
        setTestResult(error.message);
        onConnectionTest(smtpSettings, false);
      })
      .finally(() => setIsTestActive(false));
  };

  return (
    <div>
      <div className={cn("flex gap-3 items-end", className)}>
        <SimpleInput
          containerClassName="grow"
          inputClassName="dark:border-gray-500"
          label="Recipient"
          placeholder="Recipient email address"
          type="email"
          value={recipient}
          onChange={setRecipient}
          fieldName="recipient"
        />
        <Button
          cva={{ size: "small2", intent: "action", border: "thin" }}
          onClick={sendEmail}
          type="submit"
          disabled={isTestActive}
        >
          {isTestActive ? (
            <>
              <span>Sending email...</span>
              <Loader2Icon className="animate-spin" />
            </>
          ) : (
            "Send test email"
          )}
        </Button>
      </div>
      {testResult === true ? (
        <div className="flex items-center gap-2 text-green-600 mt-2">
          <CheckCircle2Icon className="shrink-0" size={20} />
          <span>Email sent successfully</span>
        </div>
      ) : (
        testResult !== null && (
          <div className="flex items-center gap-2 text-red-600 mt-2">
            <XCircleIcon className="shrink-0" size={20} />
            <span>
              Failed to send email: <i>{testResult}</i>
            </span>
          </div>
        )
      )}
    </div>
  );
};
