import { ObjectValues } from "@/constants/enums";

export const ProblemTypeOptions = {
  BinaryClassification: "BinaryClassification",
  MulticlassClassification: "MulticlassClassification",
  Regression: "Regression",
} as const;

export type TypeProblemTypeOptions = ObjectValues<typeof ProblemTypeOptions>;

export const ModelResultsViewsOptions = {
  Performance: "performance",
  ConceptShift: "concept-shift",
  CovariateShift: "covariate-shift",
  DataQuality: "data-quality",
} as const;

export type TypeModelResultsViewsOptions = ObjectValues<typeof ModelResultsViewsOptions>;
