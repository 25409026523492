import { Dispatch, SetStateAction } from "react";

import { Label } from "@/DesignSystem/shadcn/Label/Label";
import { CreateExperimentInput, ExperimentType } from "@/apis/nannyml";
import { Select, SelectItem } from "@/components/Select";
import { SimpleInput } from "@/components/common/FormElements/SimpleInput/SimpleInput";
import { experimentTypeLabels } from "@/formatters/experiment";

export const ExperimentDetails = ({
  value,
  onChange,
}: {
  value: Partial<CreateExperimentInput>;
  onChange: Dispatch<SetStateAction<Partial<CreateExperimentInput>>>;
}) => {
  return (
    <div className="flex flex-col gap-8 min-w-[55ch]">
      <Label className="flex flex-col gap-2 font-normal">
        <span className="text-lg font-medium">What is your problem type?</span>
        <Select
          value={value.experimentType}
          onValueChange={(experimentType) => onChange({ experimentType: experimentType as ExperimentType })}
          placeholder="Select experiment type"
        >
          {Object.values(ExperimentType).map((experimentType) => (
            <SelectItem key={experimentType} value={experimentType}>
              {experimentTypeLabels[experimentType]}
            </SelectItem>
          ))}
        </Select>
      </Label>
      <SimpleInput
        label="What shall we call your experiment?"
        value={value.name ?? ""}
        onChange={(name) => onChange({ name })}
        labelClassName="text-lg"
        placeholder="Provide a memorable name for your experiment"
      />
    </div>
  );
};
