import { ExperimentStatus, ExperimentType } from "@/apis/nannyml";

export const experimentStatusLabels: Record<ExperimentStatus, string> = {
  [ExperimentStatus.Accepted]: "ACCEPTED",
  [ExperimentStatus.Rejected]: "REJECTED",
  [ExperimentStatus.Ongoing]: "ONGOING",
};

export const experimentTypeLabels: Record<ExperimentType, string> = {
  [ExperimentType.ABTesting]: "A/B Test",
};
