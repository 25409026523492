import { useSuspenseQuery } from "@apollo/client";
import * as Plotly from "plotly.js";
import { useMemo } from "react";

import { AnalysisType, ResultPlotFragment, gql } from "@/apis/nannyml";
import { LabeledField } from "@/components/LabeledField";
import { PlotType } from "@/constants/enums";
import { exceedsThreshold } from "@/domains/threshold";
import { calculateResultThreshold } from "@/hooks/monitoring";
import { DateLike } from "@/lib/dateUtils";
import { selectWhere } from "@/lib/typesUtils";

import { PlotTypeConfig } from "../PlotConfig";
import { KdeDistributionPlot } from "./KdeDistribution";
import { PlotPlaceholder } from "./PlotPlaceholder";
import { ValueCountDistributionPlot } from "./ValueCountDistribution";

const getDistributionResultsQuery = gql(/* GraphQL */ `
  query GetDistributionResults($modelId: Int!, $filter: [ModelResultsFilter!]) {
    monitoring_model(id: $modelId) {
      results(filter: $filter) {
        __typename
        ... on TimeSeriesResult {
          data {
            value
          }
          ...TimeSeriesResultThreshold
        }
        ... on KdeDistributionResult {
          ...KdeDistributionResultDetails
        }
        ... on ValueCountDistributionResult {
          ...ValueCountDistributionResultDetails
        }
      }
    }
  }
`);

export const DistributionResultPlot = ({
  dateRange,
  className,
  results,
  width,
  onUpdate,
  layout,
}: {
  dateRange?: [DateLike, DateLike];
  className?: string;
  results: readonly ResultPlotFragment[];
  width?: number;
  onUpdate?: (figure: Plotly.Layout, graphDiv: any) => void;
  layout?: Partial<Plotly.Layout>;
}) => {
  const result = results[0];
  if (results.length !== 1 || !result?.columnName) {
    return (
      <PlotPlaceholder className="flex flex-col gap-4">
        <span>Unable to plot distribution for this result because it is not associated with a single column.</span>
        <span>To view the data associated with this result, change to a different plot format.</span>
        <LabeledField label="Plot format">
          <PlotTypeConfig plotTypes={[PlotType.Line, PlotType.Step, PlotType.Distribution]} />
        </LabeledField>
      </PlotPlaceholder>
    );
  }

  const {
    data: { monitoring_model: model },
  } = useSuspenseQuery(getDistributionResultsQuery, {
    variables: {
      modelId: result.modelId,
      filter: [
        {
          analysisTypes: [AnalysisType.Distribution],
          columnNames: [result.columnName],
          segments: result.segment?.id ? [result.segment.id] : [null],
        },
        {
          calculatorTypes: [result.calculatorType],
          metricNames: [result.metricName],
          componentNames: result.componentName === null ? null : [result.componentName],
          columnNames: result.columnName === null ? null : [result.columnName],
          segments: result.segment?.id ? [result.segment.id] : [null],
        },
      ],
    },
  });

  const [resultDetails, distribution, alerts] = useMemo(() => {
    const resultDetails = model?.results.find(selectWhere("__typename", "TimeSeriesResult"))!;
    const distribution = model?.results.find((r) => r.__typename !== "TimeSeriesResult")!;
    const threshold = calculateResultThreshold(resultDetails);
    return [resultDetails, distribution, resultDetails.data.map((dp) => exceedsThreshold(dp.value, threshold))];
  }, [model]);

  if (!distribution) {
    let error = `No distribution found for column '${result.columnName}'`;
    if (result.segment) {
      error += ` in segment ${result.segment.segmentColumnName}: ${result.segment.segment}.`;
    }
    throw new Error(error);
  }

  if (distribution.__typename === "KdeDistributionResult") {
    return (
      <KdeDistributionPlot
        dateRange={dateRange}
        className={className}
        result={distribution}
        width={width}
        alerts={alerts}
        layout={layout}
        onUpdate={onUpdate}
      />
    );
  } else if (distribution.__typename === "ValueCountDistributionResult") {
    return (
      <ValueCountDistributionPlot
        dateRange={dateRange}
        className={className}
        result={distribution}
        width={width}
        alerts={alerts}
        layout={layout}
        onUpdate={onUpdate}
      />
    );
  } else {
    throw new Error("Unknown distribution result type");
  }
};
