import Plotly from "plotly.js-dist-min";
import { useEffect, useState } from "react";

import { TimeSeriesResult } from "@/apis/nannyml";
import { getResultTitles } from "@/formatters/monitoring";

import { ResultPlot } from "../monitoring";
import { PlotConfigContextProvider, usePlotConfig } from "../monitoring/PlotConfig";
import { ContentData, getPlotData, plotGreyGrid, plotGreyLabel } from "./Report.utils";
import { TextContent } from "./TextContent";

const PlotContent = ({ content, dateRange }: { content: ContentData; dateRange: [number, number] | null }) => {
  const [plotDateRange, setPlotDateRange] = useState(dateRange ?? content.plotOptions!.layout?.xaxis.range);
  const results = content
    .plotOptions!.results!.map((el) => {
      return getPlotData(el!);
    })
    .flat();

  if (results.length) {
    const titles = getResultTitles({ ...results[0] } as TimeSeriesResult);
    content.plotOptions!.plotTitle = `${titles.title} ${titles.subtitle}`;
  }

  const onUpdate = (figure: any, graphDiv: any) => {
    Plotly.toImage(graphDiv).then(function (url) {
      content.plotOptions!.plotImage = url;
    });
    // This is intended for individual plot zooming
    content.plotOptions!.layout = figure.layout;

    // Avoid infinity re-renders by allowing the state change only when the dare range fits into the interval
    if (dateRange) {
      if (
        new Date(content.plotOptions!.layout.xaxis.range[0]).getTime() > new Date(dateRange[0]).getTime() &&
        new Date(content.plotOptions!.layout.xaxis.range[1]).getTime() < new Date(dateRange[1]).getTime()
      ) {
        setPlotDateRange(content.plotOptions!.layout.xaxis.range);
      }
    }
  };

  return (
    <PlotConfigContextProvider plotConfig={content.plotOptions?.plotConfig}>
      {results ? (
        <ResultPlot
          results={results}
          onPlotUpdate={onUpdate}
          dateRange={plotDateRange ? plotDateRange : undefined}
          layout={{
            ...content.plotOptions!.layout,
            font: { color: plotGreyLabel },
            xaxis: {
              ...content.plotOptions!.layout?.xaxis,
              range: plotDateRange,
              gridcolor: plotGreyGrid,
              griddash: "dash",
              linecolor: plotGreyGrid,
            },
            yaxis: {
              ...content.plotOptions!.layout?.yaxis,
              gridcolor: plotGreyGrid,
              griddash: "dash",
              linecolor: plotGreyGrid,
            },
          }}
        />
      ) : null}
    </PlotConfigContextProvider>
  );
};

export const Content = ({
  content,
  dateRange,
  onContentUpdate,
}: {
  content: ContentData;
  dateRange: [number, number] | null;
  onContentUpdate: (content: ContentData) => void;
}) => {
  return content.plotOptions ? (
    <>
      <PlotContent dateRange={dateRange} content={content} />
    </>
  ) : (
    <>
      <TextContent content={content} onContentUpdate={onContentUpdate} />
    </>
  );
};
