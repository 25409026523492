import { useMutation } from "@apollo/client";

import { editApplicationSettings } from "@/apis/nannyml/mutations/editApplicationSettings/editApplicationSettings";
import { getApplicationSettings } from "@/apis/nannyml/queries/getApplicationSettings/getApplicationSettings";
import { SimpleCheckbox } from "@/components/common/FormElements/SimpleCheckbox/SimpleCheckbox";
import { useToast } from "@/components/common/Toast/useToast";

export const UsageStatistics = ({ enabled }: { enabled: boolean }) => {
  const [editUsageStatistics] = useMutation(editApplicationSettings);
  const { resolveWithToast } = useToast();

  const onChangeUsageStatisticsPermissions = (value: boolean) => {
    const promise = editUsageStatistics({
      variables: {
        settings: {
          usageStatistics: value,
        },
      },
      refetchQueries: [getApplicationSettings],
    });

    // Display feedback for user via toast
    resolveWithToast(promise, {
      title: value ? "Opting in to usage statistics" : "Opting out of usage statistics",
      successDescription: "✅ Your preferences have been updated",
      errorDescription: "❌ Failed to update your preferences",
      retry: () => onChangeUsageStatisticsPermissions(value),
    });
  };

  return (
    <div className={"flex flex-col gap-4"}>
      <h2 className={"text-2xl font-bold"}>Usage statistics</h2>

      <div>
        <SimpleCheckbox
          label={"Send anonymous usage statistics"}
          name={"usageStatistics"}
          containerClassName="flex gap-2"
          value={enabled ?? false}
          onChange={onChangeUsageStatisticsPermissions}
        />
        <p className="pl-8 pt-2 text-sm text-gray-400">
          We collect anonymized information about usage data, so we can improve NannyML for everyone.&nbsp;
          <a className="underline" href="https://www.nannyml.com/blog/usage-statistics-in-nannyml">
            Find out more.
          </a>
        </p>
      </div>
    </div>
  );
};
