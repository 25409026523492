import { useFragment } from "@apollo/client";

import { gql } from "@/apis/nannyml";
import { useExperimentId } from "@/hooks/experiment";

const experimentNameFragment = gql(/* GraphQL */ `
  fragment ExperimentName on Experiment {
    name
  }
`);

export const ExperimentNameFromRoute = () => {
  const experimentId = useExperimentId();
  const {
    data: { name },
  } = useFragment({
    fragment: experimentNameFragment,
    from: {
      __typename: "Experiment",
      id: experimentId,
    },
  });

  return <ExperimentName experimentId={experimentId} experimentName={name} />;
};

export const ExperimentName = ({ experimentId, experimentName }: { experimentId: number; experimentName?: string }) =>
  experimentName ? (
    <span>
      #{experimentId} - {experimentName}
    </span>
  ) : (
    <span>#{experimentId}</span>
  );
