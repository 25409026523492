import { alertDeep, confirmationDeep, redL1, redL2 } from "@/constants/colors";

export const colors = {
  alertDepp: alertDeep,
  confirmationPale: "#35FE85",
  confirmationDeep: confirmationDeep,
  warningDeep: "#FF9900",
  errorDeep: "#DD0000",
  highlightDeep: "#00C8E5",
  contrastDeep: "#341578",
  placeholder: "#686F82",
  dark: "#1B1C24",
  pale: "#F2F2F2",
  primaryBg: "#2E2F37",
  oddBg: "#27282F",
  secondaryCyan: "#00C8E5",
  disabledGray: "#4C5160",

  redL1,
  redL2,
};
