import { Chunking, ColumnFlag, ColumnType, ProblemType } from "@/apis/nannyml";

export const problemTypeLabels: Record<ProblemType, string> = {
  [ProblemType.BinaryClassification]: "Binary classification",
  [ProblemType.MulticlassClassification]: "Multiclass classification",
  [ProblemType.Regression]: "Regression",
};

export const columnTypeLabels: Record<ColumnType, string> = {
  [ColumnType.CategoricalFeature]: "Categorical feature",
  [ColumnType.ContinuousFeature]: "Continuous feature",
  [ColumnType.Identifier]: "Identifier",
  [ColumnType.Ignored]: "Ignored",
  [ColumnType.Prediction]: "Prediction",
  [ColumnType.PredictionScore]: "Predicted probability",
  [ColumnType.Target]: "Target",
  [ColumnType.Timestamp]: "Timestamp",
  [ColumnType.MetricName]: "Metric name",
  [ColumnType.GroupName]: "Group name",
  [ColumnType.SuccessCount]: "Success count",
  [ColumnType.FailCount]: "Fail count",
};

export const columnFlagLabels: Record<ColumnFlag, string> = {
  [ColumnFlag.Segment]: "Segment by",
};

export const chunkingLabels: Record<Chunking, string> = {
  [Chunking.Hourly]: "Hour",
  [Chunking.Daily]: "Day",
  [Chunking.Weekly]: "Week",
  [Chunking.Monthly]: "Month",
  [Chunking.Quarterly]: "Quarter",
  [Chunking.Yearly]: "Year",
  [Chunking.NumberOfRows]: "Number of rows",
};
