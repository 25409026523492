import { CopyIcon } from "lucide-react";

import { Input } from "@/DesignSystem/shadcn/Input";
import {
  ClassificationMetricInput,
  CreateMetricInput,
  FragmentType,
  gql,
  ProblemType,
  useFragment,
} from "@/apis/nannyml";
import { LabeledField } from "@/components/LabeledField";
import { TextArea } from "@/components/TextArea";
import { Button } from "@/components/common/Button";
import { useCopyToClipboard } from "@/hooks/clipboard";
import { parseNullableFloat } from "@/lib/numbersUtils";

import { MetricConfiguratorProps } from "./types";

const classificationMetricDetails = gql(/* GraphQL */ `
  fragment ClassificationMetricDetails on ClassificationMetric {
    name
    description
    problemType
    lowerValueLimit
    upperValueLimit
    calculateFn
    estimateFn
  }
`);

const getCalculateFnTemplate = (problemType: ProblemType, metricName: string) => `import pandas as pd

def calculate(
    y_true: pd.Series,
    y_pred: pd.Series,
    y_pred_proba: pd.DataFrame,
    chunk_data: pd.DataFrame,
    labels: list[str],
    class_probability_columns: list[str],
    **kwargs
) -> float:
    """Compute realized performance for '${metricName}' metric"""
    pass
`;

const getEstimateFnTemplate = (problemType: ProblemType, metricName: string) => `import pandas as pd

def estimate(
    estimated_target_probabilities: pd.DataFrame,
    y_pred: pd.Series,
    y_pred_proba: pd.DataFrame,
    chunk_data: pd.DataFrame,
    labels: list[str],
    class_probability_columns: list[str],
    **kwargs
) -> float:
    """Compute estimated performance for '${metricName}' metric"""
    pass
`;

export const convertClassificationMetricToInput = (
  fragment: FragmentType<typeof classificationMetricDetails>
): CreateMetricInput => {
  const metric = useFragment(classificationMetricDetails, fragment);
  return {
    name: metric.name,
    description: metric.description,
    problemType: metric.problemType,
    lowerValueLimit: metric.lowerValueLimit,
    upperValueLimit: metric.upperValueLimit,
    classification: {
      calculateFn: metric.calculateFn,
      estimateFn: metric.estimateFn,
    },
  };
};

export const ClassificationMetric = ({ metric, onMetricChange }: MetricConfiguratorProps) => {
  const copyToClipboard = useCopyToClipboard();
  const onMetricUpdate = (update: Partial<CreateMetricInput>) => onMetricChange({ ...metric, ...update });
  const onClassificationMetricUpdate = (update: Partial<ClassificationMetricInput>) =>
    onMetricChange({ ...metric, classification: { ...metric.classification!, ...update } });

  const calculateFnTemplate = getCalculateFnTemplate(metric.problemType, metric.name);
  const estimateFnTemplate = getEstimateFnTemplate(metric.problemType, metric.name);

  return (
    <div className="flex flex-col gap-4">
      <LabeledField label="Metric name" required>
        <Input
          type="text"
          placeholder="What shall we call your metric?"
          value={metric.name}
          onChange={(e) => onMetricUpdate({ name: e.target.value })}
          required
        />
      </LabeledField>
      <LabeledField label="Description">
        <Input
          type="text"
          value={metric.description ?? ""}
          placeholder="Describe the metric"
          onChange={(e) => onMetricUpdate({ description: e.target.value })}
        />
      </LabeledField>
      <LabeledField
        className="grid grid-cols-2 items-end"
        label="Metric calculation function"
        infoName="Metric calculation function"
        required
      >
        <Button
          cva={{ size: "small", intent: "action" }}
          className="place-self-end"
          onClick={() => copyToClipboard(calculateFnTemplate)}
        >
          <CopyIcon size={16} />
          Copy template
        </Button>
        <TextArea
          className="col-span-2"
          cols={100}
          rows={5}
          value={metric.classification?.calculateFn ?? ""}
          onChange={(e) => onClassificationMetricUpdate({ calculateFn: e.target.value })}
          placeholder={calculateFnTemplate}
          required
        />
      </LabeledField>
      <LabeledField
        className="grid grid-cols-2 items-end"
        label="Metric estimation function"
        infoName="Metric estimation function"
      >
        <Button
          cva={{ size: "small", intent: "action" }}
          className="place-self-end"
          onClick={() => copyToClipboard(estimateFnTemplate)}
        >
          <CopyIcon size={16} />
          Copy template
        </Button>
        <TextArea
          className="col-span-2"
          cols={100}
          rows={5}
          value={metric.classification?.estimateFn ?? ""}
          onChange={(e) => onClassificationMetricUpdate({ estimateFn: e.target.value })}
          placeholder={estimateFnTemplate}
        />
      </LabeledField>
      <LabeledField label="Metric limits" infoName="Metric limits">
        <div className="flex items-center gap-2">
          <Input
            type="number"
            className="hide-arrows w-32"
            placeholder="Lower bound"
            value={metric.lowerValueLimit ?? ""}
            onChange={(e) => onMetricUpdate({ lowerValueLimit: parseNullableFloat(e.target.value) })}
          />
          <span>-</span>
          <Input
            type="number"
            className="hide-arrows w-32"
            placeholder="Upper bound"
            value={metric.upperValueLimit ?? ""}
            onChange={(e) => onMetricUpdate({ upperValueLimit: parseNullableFloat(e.target.value) })}
          />
        </div>
      </LabeledField>
    </div>
  );
};
