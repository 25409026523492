import { Dispatch, SetStateAction } from "react";

export type Dispatcher<S> = Dispatch<SetStateAction<S>>;

export type PatchStateAction<S> = Partial<S> | ((prevState: S) => Partial<S>);

export type MaybeNull<T> = T | undefined | null;

export type ErrorMessage = string | undefined;

// Used to select an item from a union using a discriminator field in a type safe way
// Taken from https://dev.to/katallaxie/the-power-of-typescript-for-graphql-union-type-filters-4cl2
export const selectWhere = <T, K extends keyof T, V extends string & T[K]>(
  k: K,
  v: V
): ((o: T) => o is Extract<T, Record<K, V>>) => {
  return (o: T): o is Extract<T, Record<K, V>> => {
    return o[k] === v;
  };
};

export const isEnumMember = <E extends { [key: string]: any }, V>(e: E, v: V): v is E[keyof E] => {
  return Object.values(e).includes(v as E[keyof E]);
};

export const isNullish = <T>(value: T | null | undefined): value is null | undefined =>
  value === null || value === undefined;
