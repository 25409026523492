import { useSuspenseQuery } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";
import _ from "lodash";

import { ModelResultsFilter, ResultPlotFragment, gql } from "@/apis/nannyml";
import { selectWhere } from "@/lib/typesUtils";
import { useParamsModelId } from "@/routes/useParamsModelId";

import { PlotConfig } from "../monitoring/PlotConfig";

export type Theme = {
  backgroundColor: string;
  color: string;
  font: string;
};

export const plotGreyLabel = "rgb(156 163 175)";

export const plotGreyGrid = "rgba(156 163 175, 0.1)";

export const darkTheme = {
  backgroundColor: "#1B1C24",
  color: "#FFFFFF",
  font: "Helvetica",
};

export const lightTheme = {
  backgroundColor: "#FFFFFF",
  color: "#1B1C24",
  font: "Helvetica",
};

const getModelResultsQuery = gql(/* GraphQL */ `
  query GetModelReportResults($modelId: Int!, $filter: [ModelResultsFilter!]) {
    monitoring_model(id: $modelId) {
      results(filter: $filter) {
        __typename
        id
        ... on TimeSeriesResult {
          ...ResultPlot
        }
      }
      results(filter: $filter) {
        ...SummaryResult
      }
    }
  }
`);

export type TimeSeriesResultSummary = Extract<
  ResultOf<typeof getModelResultsQuery>,
  { __typename: "TimeSeriesResult" }
>;

export type Results = {
  modelId: number;
  analysisType: string;
  columnName: string;
  metricName: string;
  componentName: string;
  id: string;
};

export type PlotOptions = {
  results?: Results[];
  modelId: number;
  analysisType?: string;
  columnName?: string;
  metricName?: string;
  componentName?: string;
  id?: string;
  layout?: any;
  dateRange?: [number, number];
  plotImage?: string;
  plotTitle?: string;
  plotConfig?: PlotConfig;
  plotsToCompare?: PlotOptions[];
};

export type TextOptions = {
  placeholder: string;
  className: string;
  cols: number;
  rows: number;
  iconSize: number;
};

export type ContentData = {
  id: number;
  label: string;
  key: string;
  args: string[];
  data?: string;
  plotOptions?: PlotOptions;
  textOptions?: TextOptions;
};

export const useModelResultList = (filter: any) => {
  const modelId = useParamsModelId();

  const { data, error } = useSuspenseQuery(getModelResultsQuery, {
    variables: { modelId: modelId },
  });

  const model = data?.monitoring_model;
  if (!model) {
    throw new Error(`Model ${1} not found`);
  }
  if (model.results) {
    const allResults = model.results.filter(selectWhere("__typename", "TimeSeriesResult")) ?? [];

    return allResults.filter(filter);
  }

  return [];
};

export const getPlotData = (plotOptions: PlotOptions) => {
  const resultFilter = {
    analysisTypes: [plotOptions.analysisType],
    columnNames: plotOptions.columnName ? [plotOptions.columnName] : null,
    metricNames: plotOptions.metricName ? [plotOptions.metricName] : null,
    componentNames: plotOptions.componentName ? [plotOptions.componentName] : null,
  } as ModelResultsFilter;

  const {
    data: { monitoring_model: model },
  } = useSuspenseQuery(getModelResultsQuery, {
    variables: { modelId: plotOptions.modelId, filter: { ...resultFilter } },
  });
  const results = model?.results.filter(selectWhere("__typename", "TimeSeriesResult")) ?? [];

  return results;
  
};
