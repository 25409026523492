import { createContext, useContext } from "react";

import { ApplicationSettings } from "@/apis/nannyml";

export const ApplicationConfigurationContext = createContext<ApplicationSettings | null>(null);

export const useApplicationConfiguration = () => {
  const value = useContext(ApplicationConfigurationContext);

  if (!value) {
    throw new Error("useApplicationConfiguration must be used within a ApplicationConfigurationProvider");
  }

  return value;
};
