import { ApolloClient } from "@apollo/client";

import { ColumnInput, CreateDataSourceInput, ProblemType, ProductType, StorageInput, gql } from "@/apis/nannyml";
import { omitRecursively } from "@/lib/objUtils";

const inspectDataset = gql(/* GraphQL */ `
  query InspectDataset($input: InspectDataSourceInput!) {
    inspect_dataset(input: $input) {
      columns {
        name
        columnType
        columnFlags
        dataType
        className
      }
      head
    }
  }
`);

const inspectDatasetCategoricalColumn = gql(/* GraphQL */ `
  query InspectDatasetCategoricalColumn($input: InspectCategoricalColumnInput!) {
    inspect_dataset_categorical_column(input: $input) {
      uniqueValues
    }
  }
`);

/**
 * Inspects the data source to obtain column information and head data. If the data source has no storage info or
 * already has column information, this function will do nothing.
 *
 * @param client Apollo client to use for querying
 * @param dataSource Data source to inspect
 * @param productType Product type of the data source
 * @param problemType Problem type of the data source
 * @param setColumns Function to set the columns of the data source
 * @param setDataSourceHead Function to set the head of the data source
 * @param schema Optional schema to use for updating inspected column types
 * @returns Promise that resolves when the data source has been inspected
 */
export const inspectDataSource = (
  client: ApolloClient<any>,
  dataSource: CreateDataSourceInput,
  productType: ProductType,
  problemType: ProblemType | undefined,
  setColumns: (columns: ColumnInput[]) => void,
  setDataSourceHead?: (head: any) => void,
  schema?: ColumnInput[]
) => {
  if (!dataSource.storageInfo || dataSource.columns.length > 0) {
    return;
  }

  return client
    .query({
      query: inspectDataset,
      variables: {
        input: {
          problemType,
          productType,
          storageInfo: dataSource.storageInfo,
        },
      },
    })
    .then(({ data }) => {
      const columns: ColumnInput[] = omitRecursively(data.inspect_dataset.columns, "__typename");

      // If a schema is provided, update the column types, flags & class names of the inspected data source
      if (schema) {
        const columnLookup = Object.fromEntries(schema.map((col) => [col.name, col]));
        const extraColumns = [];
        for (let i = 0; i < columns.length; i++) {
          const column = columnLookup[columns[i].name];
          if (!column) {
            extraColumns.push(columns[i].name);
          }
          columns[i].columnType = column.columnType;
          columns[i].columnFlags = column.columnFlags;
          columns[i].className = column.className;
        }

        if (extraColumns.length) {
          const columnList = extraColumns.map((name) => `'${name}'`).join(", ");
          return Promise.reject(
            `Found column${extraColumns.length > 1 ? "s" : ""} ${columnList} in '${dataSource.name}' data. ` +
              (extraColumns.length > 1 ? "These columns were " : "This column was ") +
              "not found in the reference data. All columns must be present in the reference dataset. Please upload " +
              "a different dataset or add the missing columns to the reference dataset."
          );
        }
      }

      setColumns(columns);
      setDataSourceHead?.(JSON.parse(data.inspect_dataset.head));
    });
};

/**
 * Inspects a column within a data source to obtain unique values.
 *
 * @param client Apollo client to use for querying
 * @param storageInput Location of the data to inspect
 * @param columnName Name of the column to inspect
 * @param expectedNrValues Expected number of unique values
 */
export const inspectCategoricalColumn = (
  client: ApolloClient<any>,
  storageInput: StorageInput,
  columnName: string,
  expectedNrValues?: number
) => {
  return client
    .query({
      query: inspectDatasetCategoricalColumn,
      variables: {
        input: {
          storageInfo: storageInput,
          columnName,
          expectedNrValues,
        },
      },
    })
    .then(({ data }) => data.inspect_dataset_categorical_column.uniqueValues);
};
