import { Label } from "@/DesignSystem/shadcn/Label/Label";
import { Chunking, CreateModelInput, ProblemType } from "@/apis/nannyml";
import { Select, SelectItem } from "@/components/Select";
import { SimpleInput } from "@/components/common/FormElements/SimpleInput/SimpleInput";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";
import { chunkingMethods } from "@/components/monitoring/RuntimeConfig";
import { chunkingLabels, problemTypeLabels } from "@/formatters/models";

export const ModelDetails = ({
  modelInput,
  onModelInputChange,
  chunking,
  onChunkingChange,
  nrOfRows,
  onNrOfRowsChange,
}: {
  modelInput: Pick<Partial<CreateModelInput>, "problemType" | "name">;
  onModelInputChange: (value: Partial<CreateModelInput>) => void;
  chunking?: Chunking;
  onChunkingChange: (chunking: Chunking) => void;
  nrOfRows?: number | null;
  onNrOfRowsChange: (nrOfRows: number) => void;
}) => (
  <div className="flex flex-col gap-8 min-w-[55ch]">
    <Label className="flex flex-col gap-2 font-normal">
      <span className="text-lg font-medium">What is your problem type?</span>
      <Select
        value={modelInput.problemType}
        onValueChange={(problemType) => onModelInputChange({ problemType: problemType as ProblemType })}
        placeholder="Select problem type"
        required
      >
        {Object.values(ProblemType).map((problemType) => (
          <SelectItem key={problemType} value={problemType}>
            {problemTypeLabels[problemType]}
          </SelectItem>
        ))}
      </Select>
    </Label>
    <Label className="flex flex-col gap-2 font-normal">
      <span className="text-lg font-medium">
        How should we chunk your data?
        <InformationModalChip infoName="Chunking" className="pl-0 inline-block" />
      </span>
      <Select
        value={chunking}
        onValueChange={(chunking) => onChunkingChange(chunking as Chunking)}
        placeholder="Select chunking method"
        required
      >
        {chunkingMethods.map((chunking) => (
          <SelectItem key={chunking} value={chunking}>
            {chunkingLabels[chunking]}
          </SelectItem>
        ))}
      </Select>
    </Label>
    {chunking === Chunking.NumberOfRows && (
      <SimpleInput
        label="How many rows should each chunk contain?"
        value={nrOfRows?.toString() ?? ""}
        type="number"
        onChange={(numberOfRows) => onNrOfRowsChange(parseInt(numberOfRows))}
        labelClassName="text-lg"
        inputClassName="hide-arrows"
        placeholder="# rows"
        required
      />
    )}
    <SimpleInput
      label="What shall we call your model?"
      value={modelInput.name ?? ""}
      onChange={(name) => onModelInputChange({ name })}
      labelClassName="text-lg"
      placeholder="Provide a memorable name for your model"
      required
    />
  </div>
);
