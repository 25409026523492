import React, { PropsWithChildren, useCallback, useEffect, useState } from "react";

import { acquireToken as acquireTokenApi, login, logout as logoutApi } from "@/apis/nannyml/rest/auth";
import { LoginForm } from "@/pages/Auth/LoginForm";

import { AuthContext } from "./AuthContext";

export type LocalAuthProviderProps = PropsWithChildren<{}>;

export const LocalAuthProvider = ({ children }: LocalAuthProviderProps): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(true);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [expiresAt, setExpiresAt] = useState<Date | null>(null);

  const acquireTokenFromApi = () => {
    return acquireTokenApi()
      .then(({ access_token, expires_in }) => {
        setAccessToken(access_token);
        setExpiresAt(new Date(Date.now() + expires_in * 1000));
      })
      .catch(() => {
        setAccessToken(null);
      });
  };

  /* Quietly acquire token on render if possible */
  useEffect(() => {
    acquireTokenFromApi().finally(() => {
      setLoading(false);
    });
  }, []);

  /* Callback for fetching a token from cache */
  const acquireToken = useCallback(() => {
    if (accessToken && expiresAt! > new Date()) {
      return Promise.resolve(accessToken);
    } else {
      setAccessToken(null);
      return Promise.reject(new Error("Not logged in"));
    }
  }, [accessToken, expiresAt]);

  /* Callback for logging out */
  const logout = useCallback(() => {
    setAccessToken(null);
    return logoutApi();
  }, []);

  const handleLogin = (email: string, password: string) => {
    return login({ username: email, password }).then(acquireTokenFromApi);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ acquireToken, logout }}>
      {accessToken && expiresAt! > new Date() ? children : <LoginForm onLogin={handleLogin} />}
    </AuthContext.Provider>
  );
};
