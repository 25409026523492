import { PublicClientApplication } from "@azure/msal-browser";
import { InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";
import { RedirectRequest } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from "@azure/msal-react";
import React, { PropsWithChildren, useCallback, useEffect, useMemo } from "react";

import { OidcProvider } from "../codegen/__generated/graphql";
import { AuthContext } from "./AuthContext";
import { useAuth } from "./useAuth";

export type AzureAuthProviderProps = PropsWithChildren<OidcProvider>;

/**
 * Component providing authentication context to children
 * Ensures that the user is authenticated before rendering children.
 */
const Protected = ({ children, clientId }: PropsWithChildren<{ clientId: string }>) => {
  const { instance, accounts } = useMsal();
  const request: RedirectRequest = React.useMemo(
    () => ({
      account: accounts?.[0],
      scopes: [`${clientId}/.default`],
      state: window.btoa(JSON.stringify({ url: window.location.href })),
    }),
    [accounts]
  );
  const msal = useMsalAuthentication(InteractionType.Silent, request);
  const auth = useAuth();

  // Login using redirect if silent authentication fails
  useEffect(() => {
    if (msal.error instanceof InteractionRequiredAuthError) {
      // noinspection JSIgnoredPromiseFromCall
      msal.login(InteractionType.Redirect, request);
    }
  }, [msal.error]);

  // Acquire token wrapper to comply with AuthContext
  const acquireToken = useCallback(
    () => msal.acquireToken().then((resp) => resp?.accessToken ?? null),
    [msal.acquireToken]
  );

  // Prepare memoized auth context
  const ctx = useMemo(
    () => ({ ...auth, acquireToken, logout: instance.logoutRedirect }),
    [auth, acquireToken, instance]
  );

  return (
    <AuthContext.Provider value={ctx}>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="h-screen flex justify-center items-center">Authenticating using Microsoft account...</div>
      </UnauthenticatedTemplate>
    </AuthContext.Provider>
  );
};

export const AzureAuthProvider = ({
  children,
  audience,
  authority,
  redirectUri,
}: AzureAuthProviderProps): React.ReactElement => {
  const pca: PublicClientApplication = useMemo(
    () =>
      new PublicClientApplication({
        auth: {
          clientId: audience,
          authority: authority,
          redirectUri: redirectUri || window.location.href,
        },
      }),
    []
  );

  return (
    <MsalProvider instance={pca}>
      <Protected clientId={audience}>{children}</Protected>
    </MsalProvider>
  );
};
