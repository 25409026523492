import { Popover, PopoverContent, PopoverTrigger } from "@radix-ui/react-popover";
import { RadioGroup } from "@radix-ui/react-radio-group";
import { ChevronDown } from "lucide-react";
import { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";

import { Input } from "@/DesignSystem/shadcn/Input";
import { TemplateType, gql } from "@/apis/nannyml";
import { Dialog, DialogContent } from "@/components/Dialog";
import { LabeledField } from "@/components/LabeledField";
import { cn } from "@/lib/utils";

import { RadioGroupLabeledItem } from "../RadioGroup";
import { Button } from "../common/Button";

export const createReportMutation = gql(/* GraphQL */ `
  mutation CreateReport($report: ReportInput!) {
    create_report(report: $report) {
      ...ReportSummary
    }
  }
`);

type TemplateInfo = {
  type: TemplateType | null;
  label: string;
};

const templateLabels = {
  performance: { type: TemplateType.Performance, label: "Performance template" },
  none: { type: null, label: "No template" },
};

export const ReportDateRangePicker = ({
  dateRange,
  setDateRange,
}: {
  dateRange: [number, number] | null;
  setDateRange: (dateRange: [number, number] | null) => void;
}) => {
  const ranges = {
    week: [new Date().setUTCDate(new Date().getUTCDate() - 7), new Date().getTime()],
    month: [new Date().setUTCDate(new Date().getUTCDate() - 30), new Date().getTime()],
    year: [new Date().setUTCDate(new Date().getUTCDate() - 365), new Date().getTime()],
  };
  const [datePick, setDatePick] = useState("all");

  return (
    <>
      <LabeledField label={"Select the report date range:"}>
        <RadioGroup
          value={datePick}
          onValueChange={(type) => {
            setDatePick(type);
            if (type === "all") {
              setDateRange(null);
            } else {
              setDateRange(ranges[type as keyof typeof ranges] as [number, number]);
            }
          }}
        >
          <RadioGroupLabeledItem className="m-2" key={"week"} value={"week"} label={"Last Week"} />
          <RadioGroupLabeledItem className="m-2" key={"month"} value={"month"} label={"Last Month"} />
          <RadioGroupLabeledItem className="m-2" key={"year"} value={"year"} label={"Last Year"} />
          <RadioGroupLabeledItem className="m-2" key={"all"} value={"all"} label={"All data"} />
        </RadioGroup>
      </LabeledField>

      <div className="py-2">
        <LabeledField label={"Or select a custom date range:"}>
          <Datepicker
            value={
              dateRange
                ? { startDate: new Date(dateRange[0]), endDate: new Date(dateRange[1]) }
                : { startDate: new Date(), endDate: new Date() }
            }
            useRange={false}
            onChange={(value) => {
              if (value && value.startDate && value.endDate) {
                setDateRange([new Date(value.startDate).getTime(), new Date(value.endDate).getTime()]);
                setDatePick("");
              }
            }}
            containerClassName={(defaults) => cn(defaults, "border rounded-md")}
            inputClassName={(defaults) =>
              cn(
                defaults,
                "min-w-[195px] dark:bg-dark rounded-md p-2 outline-0 ring-inset focus:ring-1 dark:ring-white",
                "[&:not(:focus)]:hover:dark:bg-slate-900 [&:not(:focus)]:hover:cursor-pointer"
              )
            }
            toggleClassName="hidden"
          />
        </LabeledField>

        <label className="text-sm text-gray-400">
          The date range setup on this field will make the plots start and end date standard across all the reports'
          plots
        </label>
      </div>
    </>
  );
};

const PickTemplate = ({
  template,
  setTemplate,
}: {
  template: TemplateInfo;
  setTemplate: (template: TemplateInfo) => void;
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  return (
    <div className="flex items-center">
      Select a template for the report:
      <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
        <PopoverTrigger className="flex p-4 hover:bg-slate-600 bg-deepBg m-1 rounded-md">
          {template.label} <ChevronDown size="20" />
        </PopoverTrigger>
        <PopoverContent align="start" className="text-slate-400 p-2 w-fit fcol bg-dark rounded-md">
          {Object.values(templateLabels).map((el) => (
            <Button
              cva={{ size: "small" }}
              className="w-full"
              onClick={() => {
                setTemplate(el);
                setPopoverOpen(false);
              }}
              key={el.label}
            >
              {el.label}
            </Button>
          ))}
        </PopoverContent>
      </Popover>
    </div>
  );
};

export const CreateReportPopUp = ({
  onDialogOpen,
  setDialogOpen,
  createReport,
}: {
  onDialogOpen: boolean;
  setDialogOpen: (isOpen: boolean) => void;
  createReport: (reportName: string, dateRange: [number, number] | null, template: TemplateType | null) => void;
}) => {
  const [reportName, setReportName] = useState("New Report");
  const [dateRange, setDateRange] = useState<[number, number] | null>(null);
  const [template, setTemplate] = useState<TemplateInfo>(templateLabels.none);
  const [popoverOpen, setPopoverOpen] = useState(false);
  return (
    <Dialog open={onDialogOpen} onOpenChange={() => setDialogOpen(false)}>
      <DialogContent className="h-fit">
        <form
          className="flex flex-col h-[100%] justify-between gap-3"
          onSubmit={() => createReport(reportName, dateRange, template.type)}
        >
          <div>
            <h1>Create new Report</h1>
            <div className="m-2 p-2">
              <LabeledField label={"New report name"}>
                <Input
                  className=" pl-3 border-gray-600"
                  placeholder="New Report"
                  value={reportName}
                  onChange={(e) => setReportName(e.target.value)}
                />
              </LabeledField>
            </div>
            <div className="m-2 p-2">
              <ReportDateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
            </div>
            <PickTemplate template={template} setTemplate={setTemplate} />
          </div>
          <div className="m-3 gap-2 flex justify-end">
            <Button onClick={() => setDialogOpen(false)} cva={{ intent: "secondary", size: "medium" }}>
              Cancel
            </Button>
            <Button
              onClick={() => createReport(reportName, dateRange, template.type)}
              cva={{ intent: "primary", size: "medium" }}
            >
              Confirm
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export const CopyRequestPopUp = ({
  open,
  reportName,
  reportId,
  onOpenChange,
  copyReport,
}: {
  open: boolean;
  reportName: string;
  reportId: number;
  onOpenChange: (isOpen: boolean) => void;
  copyReport: (reportId: number, dateRange: [number, number] | null, reportName: string) => void;
}) => {
  const [newReportName, setReportName] = useState("");
  const [dateRange, setDateRange] = useState<[number, number] | null>(null);

  return (
    <Dialog open={open} onOpenChange={() => onOpenChange(false)}>
      <DialogContent className="h-fit">
        <form
          className="flex flex-col justify-between h-[100%] gap-3"
          onSubmit={() => copyReport(reportId, dateRange, newReportName ? newReportName : `${reportName} - Copy`)}
        >
          <div>
            <h1>Copy the report: {reportName}</h1>
            <div className="m-2 p-2">
              <LabeledField label={"New report name"}>
                <Input
                  className=" pl-3 border-gray-600"
                  placeholder={reportName}
                  value={newReportName}
                  onChange={(e) => setReportName(e.target.value)}
                  autoFocus={false}
                />
              </LabeledField>

              <label className="text-sm text-gray-400">Leave this field empty to keep the original name.</label>
            </div>
            <div className="m-2 p-2">
              <ReportDateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
            </div>
          </div>
          <div className="m-3 gap-2 flex justify-end">
            <Button onClick={() => onOpenChange(false)} cva={{ intent: "secondary", size: "medium" }}>
              Cancel
            </Button>
            <Button
              onClick={() => copyReport(reportId, dateRange, newReportName ? newReportName : `${reportName} - Copy`)}
              cva={{ intent: "primary", size: "medium" }}
            >
              Confirm
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
