import { useToast } from "@/components/common/Toast/useToast";

export const useCopyToClipboard = () => {
  const { toast } = useToast();

  const copyToClipboard = (
    text: string,
    {
      successTitle = "✅ Copied to clipboard",
      errorTitle = "❌ Failed to copy to clipboard",
    }: {
      successTitle?: string;
      errorTitle?: string;
    } = {}
  ) =>
    navigator.clipboard
      .writeText(text)
      .then(() =>
        toast({
          title: successTitle,
          variant: "info",
        })
      )
      .catch((exc) =>
        toast({
          title: errorTitle,
          description: exc.message,
          variant: "error",
        })
      );

  return copyToClipboard;
};
