import { ExternalLink, Info } from "lucide-react";
import { createContext, ReactNode, useContext, useState } from "react";
import * as React from "react";

import { Text, TextStyles } from "@/DesignSystem/basic/Text/Text";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/Dialog";
import {
  informationModalCatalog,
  InformationModalNames,
  InformationModalData,
} from "@/formatters/informationModal/informationModalCatalog";
import { cn } from "@/lib/utils";

type InformationModalType = {
  isOpen: boolean;
  infoName: InformationModalNames | null;
  openInfo: (infoName: InformationModalNames) => void;
  closeInfo: () => void;
};

export const InformationModalContext = createContext<InformationModalType | undefined>(undefined);

export const InformationModalProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [infoName, setInfoName] = useState<InformationModalNames | null>(null);

  const openInfo = (infoName: InformationModalNames) => {
    setIsOpen(true);
    setInfoName(infoName);
  };

  const closeInfo = () => {
    setIsOpen(false);
    setInfoName(null);
  };

  const selectedInfo: InformationModalData | undefined = informationModalCatalog.find((info) => info.name === infoName);

  return (
    <InformationModalContext.Provider
      value={{
        isOpen,
        infoName,
        openInfo,
        closeInfo,
      }}
    >
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className={"dark:bg-actionBg"}>
          <DialogHeader>
            <DialogTitle>
              <Text size={"large"} stroke={"bold"} className={"flex gap-1 items-center"}>
                {/*<Info />*/}
                {selectedInfo?.name}
              </Text>
            </DialogTitle>

            <DialogDescription className={cn(TextStyles(), "whitespace-pre-line py-4 mt-0")}>
              {selectedInfo?.content?.trim()}
            </DialogDescription>

            {selectedInfo?.readMoreLink ? (
              <DialogFooter className={"sm:flex-row sm:justify-end pt-0 mt-0"}>
                <Text stroke={"thin"} className={"border-b-[1px] border-solid border-white"}>
                  <a target={"_blank"} className={"flex gap-1 items-center"} href={selectedInfo.readMoreLink}>
                    <span>Read more</span>
                    <ExternalLink size={12} />
                  </a>
                </Text>
              </DialogFooter>
            ) : null}
          </DialogHeader>
        </DialogContent>
      </Dialog>
      {children}
    </InformationModalContext.Provider>
  );
};

export const useInformationModal = () => {
  const context = useContext(InformationModalContext);
  if (context === undefined) {
    throw new Error("useInformationModal must be used within a InformationModalProvider");
  }
  return context;
};
