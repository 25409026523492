import { useFragment } from "@apollo/client";
import { useParams } from "react-router-dom";

import { gql } from "@/apis/nannyml";

const monitoringModelNameFragment = gql(/* GraphQL */ `
  fragment MonitoringModelName on Model {
    name
  }
`);

export const MonitoringModelName = ({ modelId }: { modelId?: number }) => {
  const params = useParams();
  if (modelId === undefined) {
    if (!params.modelId) {
      throw new Error("ModelId is required");
    }
    modelId = parseInt(params.modelId, 10);
  }

  const {
    data: { name },
  } = useFragment({
    fragment: monitoringModelNameFragment,
    from: {
      __typename: "Model",
      id: modelId,
    },
  });

  return name ? (
    <span>
      #{modelId} - {name}
    </span>
  ) : (
    <span>#{modelId}</span>
  );
};
