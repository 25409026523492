import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";
import { useCallback, useEffect } from "react";

import { FragmentType, RunState, gql, useFragment } from "@/apis/nannyml";
import * as RunStatus from "@/components/RunStatus";
import { useEvaluationModelId, useStartEvaluationRun } from "@/hooks/evaluation";

const evaluationRunStatusFragment = gql(/* GraphQL */ `
  fragment EvaluationRunStatus on EvaluationRun {
    id
    state
    scheduledFor
    startedAt
    completedAt
    ranSuccessfully
  }
`);

const pollEvaluationRunStatusQuery = gql(/* GraphQL */ `
  query PollEvaluationRunStatus($modelId: Int!) {
    evaluation_model(id: $modelId) {
      latestRun {
        id
        state
        startedAt
        ranSuccessfully
        events {
          eventType
          timestamp
          currentStep
          nrSteps
          description
        }
      }
    }
  }
`);

export const cancelEvaluationRunMutation = gql(/* GraphQL */ `
  mutation CancelEvaluationRun($runId: Int!) {
    cancel_evaluation_model_run(evaluationRunId: $runId) {
      id
    }
  }
`);

export const LatestRunStatus = (props: {
  className?: string;
  run: FragmentType<typeof evaluationRunStatusFragment> | null;
}) => {
  const run = useFragment(evaluationRunStatusFragment, props.run) ?? null;

  if (run?.state === RunState.Running || run?.state === RunState.Cancelling) {
    return <ActiveRunStatus className={props.className} />;
  } else {
    return <InactiveRunStatus className={props.className} run={run} />;
  }
};

const InactiveRunStatus = ({
  className,
  run,
}: {
  className?: string;
  run: ResultOf<typeof evaluationRunStatusFragment> | null;
}) => {
  const modelId = useEvaluationModelId();
  const [startRun, { loading }] = useStartEvaluationRun(modelId);

  return <RunStatus.InactiveRunStatus className={className} run={run} startRun={startRun} isStarting={loading} />;
};

const ActiveRunStatus = ({ className }: { className?: string }) => {
  const modelId = useEvaluationModelId();
  const client = useApolloClient();
  const { data } = useQuery(pollEvaluationRunStatusQuery, {
    variables: { modelId: modelId },
    pollInterval: 1000,
  });
  const run = data?.evaluation_model?.latestRun;
  const [cancelEvaluationRun, { loading }] = useMutation(cancelEvaluationRunMutation);

  const cancelRun = useCallback(() => {
    if (!run?.id) {
      return;
    }
    cancelEvaluationRun({ variables: { runId: run.id } });
  }, [run?.id]);

  useEffect(() => {
    if (run?.state === RunState.Completed) {
      // Invalidate the cache for the evaluation model when the run completes
      client.cache.evict({ id: `EvaluationModel:${modelId}` });
    }
  }, [run?.state]);

  return <RunStatus.ActiveRunStatus className={className} run={run} cancelRun={cancelRun} isCancelling={loading} />;
};
