import React from "react";
import { Link } from "react-router-dom";

import { FragmentType, gql, useFragment } from "@/apis/nannyml";
import { Divider } from "@/components/Divider";
import { EvaluationStatusLabel } from "@/components/evaluation";
import { getHdiLabel, getRopeLabel } from "@/formatters/bayesian";
import { performanceMetricLabels } from "@/formatters/monitoring";
import { cn } from "@/lib/utils";

const evaluationPerformanceResultSummaryFragment = gql(/* GraphQL */ `
  fragment EvaluationPerformanceResultSummary on EvaluationPerformanceResult {
    modelId
    metric
    status
    latestHdi {
      hdiLower
      hdiUpper
    }
    hdiWidthReached
    config {
      ropeLowerBound
      ropeUpperBound
      hdiWidth
    }
  }
`);

type MetricCardProps = {
  className?: string;
  result: FragmentType<typeof evaluationPerformanceResultSummaryFragment>;
  to: string;
};

export const MetricCard = React.forwardRef<HTMLAnchorElement, MetricCardProps>((props, ref) => {
  const result = useFragment(evaluationPerformanceResultSummaryFragment, props.result);

  return (
    <Link
      ref={ref}
      className={cn(
        "w-[380px] bg-primaryBg p-4",
        "rounded-lg border border-transparent hover:border-highlightDeep hover:cursor-pointer",
        props.className
      )}
      to={props.to}
    >
      <h3 className="text-lg font-bold">{performanceMetricLabels[result.metric]}</h3>
      <Divider />
      <div className="grid grid-cols-[1fr_auto] gap-2 gap-x-8">
        <span>Status</span>
        <EvaluationStatusLabel status={result.status} />
        <span>ROPE</span>
        <span>{getRopeLabel(result.config, result.config)}</span>
        <span>95% HDI</span>
        <span>{result.latestHdi ? getHdiLabel(result.latestHdi, result.config) : "-"}</span>
        <span>Required 95% HDI width</span>
        {result.hdiWidthReached ? (
          <span className="text-success">REACHED</span>
        ) : (
          <span className="text-failed">NOT REACHED</span>
        )}
      </div>
    </Link>
  );
});
