import { useMutation, useSuspenseQuery } from "@apollo/client";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { SaveIcon, PencilIcon, FileText, FileDown, Copy, Presentation } from "lucide-react";
import React, { PropsWithChildren, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { EditReport, gql, useFragment } from "@/apis/nannyml";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/Popover";
import { PdfReport } from "@/components/Reporting/PdfReport";
import { PptxReport } from "@/components/Reporting/PptxReport";
import { Report } from "@/components/Reporting/Report";
import { ContentData, darkTheme, lightTheme } from "@/components/Reporting/Report.utils";
import { Button } from "@/components/common/Button";
import { toast } from "@/components/common/Toast/useToast";
import { RequestStateLayout } from "@/components/dashboard/RequestStateLayout/RequestStateLayout";

const ReportDetails = gql(/* GraphQL */ `
  fragment ReportDetails on Report {
    id
    creationDate
    title
    content
  }
`);

const getReportInfo = gql(/* GraphQL */ `
  query GetReport($reportId: Int!) {
    get_report(reportId: $reportId) {
      ...ReportDetails
    }
  }
`);

const updateReportMutation = gql(/* GraphQL */ `
  mutation UpdateReport($report: EditReport!) {
    edit_report(report: $report) {
      ...ReportDetails
    }
  }
`);

export const Reporting = () => (
  <React.Suspense fallback={<RequestStateLayout isLoading={true} />}>
    <ReportingContainer />
  </React.Suspense>
);

export type ReportData = {
  title: ContentData;
  contents: ContentData[];
  metadata: Metadata;
};

type ReportContent = {
  data: ContentData[];
  metadata: Metadata;
};

export type Metadata = {
  dateRange?: [number, number];
};

const useReportId = () => {
  const reportId = useParams();
  return reportId["reportId"] && parseInt(reportId["reportId"]) ? parseInt(reportId["reportId"]) : 0;
};

const useContents = (reportId: number) => {
  const { data, error } = useSuspenseQuery(getReportInfo, {
    variables: { reportId: reportId },
  });

  const reportDetails = useFragment(ReportDetails, data.get_report);

  const reportContent: ReportContent = JSON.parse(reportDetails.content);

  const contentData: ContentData[] = reportContent.data.map((content: any) => {
    return { ...(content as ContentData) };
  });

  const title = contentData.filter((c) => c.label === "Title");
  const contents = contentData.filter((c) => c.label !== "Title");

  return { title: title[0], contents: contents, metadata: reportContent.metadata } as ReportData;
};

const ExportReport = ({ contents }: { contents: ContentData[] }) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  return (
    <Popover open={isPopoverOpen} onOpenChange={setPopoverOpen}>
      <PopoverTrigger className="items-center gap-3 flex">
        <FileDown />
        <span className="font-semibold">Export to</span>
      </PopoverTrigger>
      <PopoverContent align="start" className="text-slate-400 p-2 w-fit fcol">
        <PDFDownloadLink document={<PdfReport reportContent={contents} theme={darkTheme} />} fileName="report.pdf">
          <Button>
            <FileText />
            PDF Dark Version
          </Button>
        </PDFDownloadLink>
        <PDFDownloadLink document={<PdfReport reportContent={contents} theme={lightTheme} />} fileName="report.pdf">
          <Button>
            <FileText />
            PDF Light Version
          </Button>
        </PDFDownloadLink>
        <Button onClick={() => PptxReport(contents, darkTheme)}>
          <Presentation />
          PPTx Dark Version
        </Button>
        <Button onClick={() => PptxReport(contents, lightTheme)}>
          <Presentation />
          PPTx Light Version
        </Button>
      </PopoverContent>
    </Popover>
  );
};

const ReportingContainer = ({ children }: PropsWithChildren) => {
  const [updateReport, {}] = useMutation(updateReportMutation, {
    update(cache, { data }) {
      if (data) {
        const updatedReport = useFragment(ReportDetails, data.edit_report);
        cache.modify({
          fields: {
            reports() {
              cache.updateFragment(
                {
                  id: `Report:${updatedReport.id}`,
                  fragment: ReportDetails,
                },
                (element) => {
                  return { ...element, ...updatedReport };
                }
              );
            },
            get_report() {
              return updatedReport;
            },
          },
        });
      }
    },
  });

  const onUpdateSaveContent = (contents: ContentData[]) => {
    setContents(contents);
    setLoading(true);

    updateReport({
      variables: {
        report: {
          id: reportId,
          title: title.data,
          content: JSON.stringify({ data: [title, ...contents], metadata: metadata }),
        } as EditReport,
      },
    })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const reportId = useReportId();

  const reportContent = useContents(reportId);

  const [contents, setContents] = useState<ContentData[]>(reportContent.contents);
  const [title, setTitle] = useState<ContentData>(reportContent.title);
  const [metadata, setMetadata] = useState<Metadata>(reportContent.metadata);

  const saveContent = () => {
    setLoading(true);

    updateReport({
      variables: {
        report: {
          id: reportId,
          title: title.data,
          content: JSON.stringify({ data: [title, ...contents], metadata: metadata }),
        } as EditReport,
      },
    })
      .then(({ data }) => {
        toast({
          title: `Report ${title.data} was saved successfully`,
          variant: "success",
        });
      })
      .catch((error) => {
        toast({
          title: `Failed to save the report ${title.data}`,
          variant: "error",
        });
      })
      .finally(() => setLoading(false));
  };

  const saveAndExport = () => {
    saveContent();
  };

  return (
    <div className="flex flex-col h-full gap-1 p-4">
      <div id="graph" style={{ display: "none" }}></div>
      {loading || error ? (
        <RequestStateLayout isLoading={loading} hasError={error} errorText={error} />
      ) : (
        <div>
          <div className="flex flex-row justify-end">
            <div className="flex flex-row justify-end gap-2">
              <Button
                onClick={() => {
                  saveAndExport();
                }}
              >
                <SaveIcon />
                Save
              </Button>
            </div>
            <div className="hover:bg-slate-900 py-3 px-5 text-base font-bold rounded">
              <ExportReport contents={[title, ...contents]} />
            </div>
            <div className="flex flex-row justify-end"></div>
          </div>
          <Report
            title={title}
            content={contents}
            metadata={metadata}
            onUpdateTitle={setTitle}
            onUpdateContentList={onUpdateSaveContent}
          />
        </div>
      )}
    </div>
  );
};
