import { DayOfWeek, ScheduleFrequency } from "@/apis/nannyml";

export const scheduleFrequencyLabels: Record<ScheduleFrequency, string> = {
  [ScheduleFrequency.Hourly]: "Hour",
  [ScheduleFrequency.Daily]: "Day",
  [ScheduleFrequency.Weekly]: "Week",
  [ScheduleFrequency.Monthly]: "Month",
  [ScheduleFrequency.Quarterly]: "Quarter",
  [ScheduleFrequency.Yearly]: "Year",
};

export const dayOfWeekLabels: Record<DayOfWeek, string> = {
  [DayOfWeek.Monday]: "Monday",
  [DayOfWeek.Tuesday]: "Tuesday",
  [DayOfWeek.Wednesday]: "Wednesday",
  [DayOfWeek.Thursday]: "Thursday",
  [DayOfWeek.Friday]: "Friday",
  [DayOfWeek.Saturday]: "Saturday",
  [DayOfWeek.Sunday]: "Sunday",
};
