import { Input } from "@/DesignSystem/shadcn/Input";
import { Chunking, RuntimeConfigInput } from "@/apis/nannyml";
import { LabeledField } from "@/components/LabeledField";
import { Select, SelectItem } from "@/components/Select";
import { chunkingLabels } from "@/formatters/models";

export const chunkingMethods = [
  Chunking.Hourly,
  Chunking.Daily,
  Chunking.Weekly,
  Chunking.Monthly,
  Chunking.Quarterly,
  Chunking.Yearly,
  Chunking.NumberOfRows,
];

export const ChunkingConfig = ({
  value: { dataChunking },
  onValueChange,
}: {
  value: RuntimeConfigInput;
  onValueChange: (config: Partial<RuntimeConfigInput>) => void;
}) => {
  const chunker = dataChunking.find((c) => c.enabled);
  return (
    <div className="flex gap-4">
      <LabeledField label="Chunking method">
        <Select
          className="w-40"
          value={chunker?.chunking}
          onValueChange={(value) =>
            onValueChange({
              dataChunking: [{ chunking: value as Chunking, enabled: true }],
            })
          }
          placeholder="Select chunking method"
          required
        >
          {chunkingMethods.map((chunking) => (
            <SelectItem key={chunking} value={chunking}>
              {chunkingLabels[chunking]}
            </SelectItem>
          ))}
        </Select>
      </LabeledField>
      <LabeledField label="Number of rows">
        <Input
          type="number"
          className="hide-arrows"
          value={chunker?.nrOfRows ?? ""}
          onChange={(e) =>
            onValueChange({
              dataChunking: [{ ...chunker!, nrOfRows: e.target.value ? parseInt(e.target.value) : null }],
            })
          }
          disabled={chunker?.chunking !== Chunking.NumberOfRows}
          required
        />
      </LabeledField>
    </div>
  );
};
