import React from "react";

export const useIsFormValid = () => {
  const ref = React.useRef<HTMLFormElement>(null);
  const [isValidState, setIsValidState] = React.useState(false);
  // Using a ref to track the validity of the form. This allows us to only schedule state updates when the validity
  // changes. Normally wouldn't be required, but because we're deferring state updates using `setTimeout` it makes sense
  // to avoid redundant updates.
  const isValidRef = React.useRef<boolean>();

  React.useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    // Store reference to the form element, as the ref might be null when the component is unmounted.
    const form = ref.current;

    // Check the form's validity when the component is mounted.
    isValidRef.current = form.checkValidity();
    setIsValidState(isValidRef.current);

    // Function to check the form's validity and update the state if it has changed.
    const onFormChange = () => {
      const isValid = form.checkValidity();
      if (isValidRef.current !== isValid) {
        isValidRef.current = isValid;
        // Updating state is deferred to the next tick because it interferes with handling input events.
        setTimeout(() => setIsValidState(isValid));
      }
    };

    // Set up a MutationObserver to listen for changes to the form's children. This is necessary because the form's
    // validity can change when children are added or removed.
    const observer = new MutationObserver(onFormChange);
    observer.observe(form, { childList: true, subtree: true });

    // Set up event listeners to listen for changes to the form's inputs.
    form.addEventListener("input", onFormChange, { passive: true });
    form.addEventListener("change", onFormChange, { passive: true });

    // Clean up the observer and event listeners when the component is unmounted.
    return () => {
      form.removeEventListener("input", onFormChange);
      form.removeEventListener("change", onFormChange);
      observer.disconnect();
    };
  }, [ref.current]);

  return [ref, isValidState] as const;
};
