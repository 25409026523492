import { VariantProps, cva } from "class-variance-authority";
import React from "react";

import { cn } from "@/lib/utils";

const progressStyle = cva(["flex justify-center items-center h-full text-white"], {
  variants: {
    intent: {
      progress: "bg-highlightPale",
      error: "bg-red-400",
    },
  },
  defaultVariants: {
    intent: "progress",
  },
});

type ProgressBarProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof progressStyle> & {
    progress: number;
  };

export const ProgressBar = React.forwardRef<HTMLDivElement, ProgressBarProps>(
  ({ className, intent, progress }, ref) => (
    <div ref={ref} className={cn("h-5 w-full bg-gray-300 rounded-full text-sm overflow-hidden", className)}>
      <div className={progressStyle({ intent })} style={{ width: `${progress * 100}%` }}>
        {Math.round(progress * 100)}%
      </div>
    </div>
  )
);
