import { gql } from "@/apis/nannyml";

export const getUserSettings = gql(/* GraphQL */ `
  query GetUserSettings {
    current_user {
      id
      email
      notificationSettings {
        emailEnabled
        emailRecipient
      }
    }
  }
`);
