import { EvaluationStatus, FragmentType, PerformanceMetric, gql, useFragment } from "@/apis/nannyml";
import { getHdiWidthLabel } from "@/formatters/bayesian";
import { performanceMetricLabels } from "@/formatters/monitoring";

import { BayesianResult, BayesianResultPlot } from "../bayesian";
import { BayesianPlotCustomization } from "../bayesian/ResultPlot/ResultPlot";

const evaluationPerformanceResultPlotFragment = gql(/* GraphQL */ `
  fragment EvaluationPerformanceResultPlotFragment on EvaluationPerformanceResult {
    modelId
    metric
    status
    hdiWidthReached
    experimentDistribution
    referenceDistribution
    hdiData {
      nrObservations
      hdiLower
      hdiUpper
      cut
    }
    config {
      ropeLowerBound
      ropeUpperBound
      hdiWidth
    }
  }
`);

const getMetricName = (metric: string) => performanceMetricLabels[metric as PerformanceMetric];

export const EvaluationPerformanceResultPlot = (props: {
  className?: string;
  result: FragmentType<typeof evaluationPerformanceResultPlotFragment>;
}) => {
  const result = useFragment(evaluationPerformanceResultPlotFragment, props.result);
  return <BayesianResultPlot className={props.className} result={result} customization={evaluationPlotCustomization} />;
};

const ResultTitle = ({ result }: { result: BayesianResult }) => (
  <>
    <h2 className="text-lg font-bold">{getMetricName(result.metric)} - 95% HDI</h2>
    <h3 className="">
      {result.status === EvaluationStatus.Accepted
        ? `Hypothesis accepted, required HDI width reached (${getHdiWidthLabel(result.config)})`
        : result.status === EvaluationStatus.Rejected
        ? `Hypothesis rejected, required HDI width reached (${getHdiWidthLabel(result.config)})`
        : result.hdiWidthReached
        ? `Evaluation is ongoing, required HDI width reached (${getHdiWidthLabel(result.config)})`
        : `Evaluation is ongoing, required HDI width not reached (${getHdiWidthLabel(result.config)})`}
    </h3>
  </>
);

const evaluationPlotCustomization: BayesianPlotCustomization = {
  TitleComponent: ResultTitle,
  getMetricName: (metric) => performanceMetricLabels[metric as PerformanceMetric],
  evaluationDatasetName: "Evaluation",
};
