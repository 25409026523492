import { Chunking, CreateModelInput } from "@/apis/nannyml";
import { DatasetStorageSummary } from "@/components/DatasetStorage";
import { Divider } from "@/components/Divider";
import { LabeledField } from "@/components/LabeledField";
import { SchemaOverview } from "@/components/Schema";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";
import { RuntimeConfigSummary } from "@/components/monitoring/RuntimeConfig/RuntimeConfig";
import { problemTypeColumns } from "@/domains/monitoring";
import { chunkingLabels, problemTypeLabels } from "@/formatters/models";
import { performanceMetricLabels } from "@/formatters/monitoring";

export const Review = ({ settings }: { settings: CreateModelInput }) => {
  const chunker = settings.runtimeConfig.dataChunking.find((c) => c.enabled)!;
  return (
    <div className="grid grid-cols-1 md:grid-cols-[1fr_minmax(30%,auto)] gap-8 w-full xl:w-5/6">
      <Panel title="Model details">
        <div className="flex flex-wrap gap-8">
          <LabeledField label="Model name" className="whitespace-nowrap">
            {settings.name}
          </LabeledField>
          <LabeledField label="Problem type" className="whitespace-nowrap">
            {problemTypeLabels[settings.problemType]}
          </LabeledField>
          <LabeledField
            className="whitespace-nowrap"
            label={
              <>
                KPM
                <InformationModalChip infoName="Key performance metric" className="p-0 inline text-current" />
              </>
            }
          >
            {performanceMetricLabels[settings.kpm.metric]}
          </LabeledField>
          <LabeledField
            label={
              <>
                Chunk by
                <InformationModalChip infoName="Chunking" className="p-0 inline text-current" />
              </>
            }
          >
            {chunkingLabels[chunker.chunking]}
          </LabeledField>
          {chunker.chunking === Chunking.NumberOfRows && (
            <LabeledField label="Number of rows">{chunker.nrOfRows}</LabeledField>
          )}
        </div>
      </Panel>
      <Panel title="Metrics" className="row-span-2">
        <RuntimeConfigSummary runtimeConfig={settings.runtimeConfig} />
      </Panel>
      <Panel title="Data sources" className="min-w-0">
        <div className="flex flex-wrap gap-10 gap-y-4">
          {settings
            .dataSources!.filter((ds) => ds.storageInfo)
            .map((ds) => (
              <LabeledField
                className="overflow-hidden"
                key={ds.name}
                label={
                  ds.hasReferenceData ? "Reference data" : ds.name === "analysis" ? "Monitored data" : "Target data"
                }
              >
                <DatasetStorageSummary value={ds.storageInfo!} />
              </LabeledField>
            ))}
        </div>
      </Panel>
      <Panel title="Schema" className="col-span-full">
        <SchemaOverview
          className="!grid-cols-[repeat(auto-fit,minmax(150px,1fr))]"
          schema={settings.dataSources!.find((ds) => ds.hasReferenceData)!.columns}
          columnConfig={problemTypeColumns[settings.problemType]}
        />
      </Panel>
    </div>
  );
};

const Panel = ({ title, children, className }: { title: string; children?: React.ReactNode; className?: string }) => (
  <div className={className}>
    <h3 className="text-lg font-semibold">{title}</h3>
    <Divider className="bg-gray-600" />
    {children}
  </div>
);
