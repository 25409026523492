import { gql } from "../codegen/__generated";

export const DataSourceSchema = gql(/* GraphQL */ `
  fragment DataSourceSchema on DataSource {
    columns {
      name
      columnType
      dataType
      className
    }
  }
`);
