import { useState } from "react";

import { Divider } from "@/components/Divider";
import { DataQualityEnableConfig, DataQualityThresholdConfig } from "@/components/monitoring/RuntimeConfig";
import { thresholdDesc } from "@/formatters/thresholds";

import { EditModelControls, Subtitle, Title, useModelEditor } from "./EditModel";
import { SegmentSelect } from "./SegmentSelect";

export const DataQualitySettings = () => {
  const { model, config, onRuntimeConfigChange } = useModelEditor();
  const [segmentId, setSegmentId] = useState<number>();

  return (
    <div className="p-4 flex flex-col w-fit max-w-[920px] gap-4">
      <Title>Data quality configuration</Title>
      <Subtitle>
        Configure which data quality metrics to analyse. Specific column types can be selected to optimize compute.
      </Subtitle>
      <DataQualityEnableConfig
        problemType={model.problemType}
        config={config}
        value={model.runtimeConfig}
        onValueChange={onRuntimeConfigChange}
      />
      <Title>Data quality thresholds</Title>
      <Subtitle>{thresholdDesc}</Subtitle>
      <SegmentSelect segments={model.segments} onSegmentChange={(segment) => setSegmentId(segment?.id)} />
      <DataQualityThresholdConfig
        problemType={model.problemType}
        config={config}
        value={model.runtimeConfig}
        onValueChange={onRuntimeConfigChange}
        segmentId={segmentId}
      />
      <EditModelControls className="sticky bottom-0 py-4 -my-4 bg-dark" />
    </div>
  );
};
