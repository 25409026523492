import _ from "lodash";
import React from "react";

import { cn } from "@/lib/utils";

export const VirtualizedList = <T,>({
  className,
  items,
  itemHeight,
  overscan,
  renderItems,
}: {
  className?: string;
  itemHeight: number;
  items: readonly T[];
  overscan?: number;
  renderItems: (items: T[], index: number) => React.ReactNode;
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [nrPastItems, setNrPastItems] = React.useState(0);
  const [nrVisibleItems, setNrVisibleItems] = React.useState(0);

  overscan = overscan ?? 1;
  const nrRemainingItems = items.length - nrPastItems - nrVisibleItems;
  const displayStartIdx = Math.max(0, nrPastItems - overscan);
  const displayEndIdx = Math.min(items.length, nrPastItems + nrVisibleItems + overscan);

  // Scroll to top when the list is first rendered
  React.useLayoutEffect(() => {
    ref.current?.scrollTo({ top: 0 });
  }, []);

  React.useLayoutEffect(() => {
    // If the container DOM element is in this component, the ref should be available immediately
    const container = ref.current!;

    // Event handler that updates the state based on the current scroll position & container height
    const handleDisplayChange = _.debounce(() => {
      const nrPastItems = Math.floor(container.scrollTop / itemHeight);
      setNrPastItems(nrPastItems);
      setNrVisibleItems(Math.ceil((container.scrollTop + container.clientHeight) / itemHeight) - nrPastItems);
    }, 100);

    // Seed initial state
    handleDisplayChange();

    // Register event handlers
    container.addEventListener("scroll", handleDisplayChange);
    window.addEventListener("resize", handleDisplayChange);
    return () => {
      container.removeEventListener("scroll", handleDisplayChange);
      window.removeEventListener("resize", handleDisplayChange);
    };
  }, [itemHeight]);

  return (
    <div className={cn("overflow-y-scroll h-full", className)} ref={ref}>
      {nrPastItems > overscan && <div style={{ height: (nrPastItems - overscan) * itemHeight }} />}
      {renderItems(items.slice(displayStartIdx, displayEndIdx), displayStartIdx)}
      {nrRemainingItems > overscan && <div style={{ height: (nrRemainingItems - overscan) * itemHeight }} />}
    </div>
  );
};
