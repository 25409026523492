import { EvaluationHypothesis, EvaluationStatus } from "@/apis/nannyml";

export const evaluationStatusLabels: Record<EvaluationStatus, string> = {
  [EvaluationStatus.Accepted]: "ACCEPTED",
  [EvaluationStatus.Rejected]: "REJECTED",
  [EvaluationStatus.Ongoing]: "ONGOING",
};

export const evaluationHypothesisLabels: Record<EvaluationHypothesis, string> = {
  [EvaluationHypothesis.ModelPerformanceNoWorseThanReference]:
    "Model performance is no worse than the reference performance",
  [EvaluationHypothesis.ModelPerformanceWithinRange]: "Model performance is within a specified range",
};
