import { useQuery } from "@apollo/client";

import { MappingProblemTypeRead } from "@/adapters/model/modelMapping";
import { useMonitorResults } from "@/adapters/results/ResultsTransform";
import { getModelsOverview } from "@/apis/nannyml/queries/getModelsOverview/getModelsOverview";
import { RequestStateLayout } from "@/components/dashboard/RequestStateLayout/RequestStateLayout";
import { ModelsOverviewTable } from "@/components/monitoring/modelsOverview/ModelsOverviewTable";
import { calcModelOverview } from "@/domains/model/modelOverview";
import { ErrorPage } from "@/routes/ErrorPage";

export const ModelMonitoringHub = () => {
  const { data, loading, error } = useQuery(getModelsOverview, {
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <RequestStateLayout isLoading={loading} emptyText={"Start by adding a model"} />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  // ----- //

  const models = data?.monitoring_models ?? [];

  const modelsOverviewItems = models.map((model) => {
    // Read
    const modelInfo = {
      id: model.id,
      name: model.name,
      problemType: MappingProblemTypeRead[model.problemType],
    };
    const latestRun = {
      state: model.latestRun?.state,
      ranSuccessfully: model.latestRun?.ranSuccessfully,
    };
    const summaryResults = useMonitorResults(model.summaryResults);
    const mainResults = useMonitorResults(model.kpm.results);

    // Transform
    return calcModelOverview({
      model: modelInfo,
      latestRun,
      summaryResults,
      mainResults,
    });
  });

  return <ModelsOverviewTable modelsOverview={modelsOverviewItems} />;
};
