import { XCircleIcon } from "lucide-react";
import React from "react";

import { Button } from "@/components/common/Button";

import { useWizardControls } from "./Wizard.context";

export const WizardCompletionGuard = ({ children }: { children: React.ReactNode }) => {
  const { arePreviousStepsCompleted, goToFirstIncompleteStep } = useWizardControls();

  if (!arePreviousStepsCompleted()) {
    return (
      <div className="flex flex-col gap-8 items-center">
        <XCircleIcon size={96} className="text-gray-400" />
        <p className="text-lg">Some steps have not yet been completed.</p>
        <Button cva={{ intent: "primary" }} onClick={goToFirstIncompleteStep}>
          Go back to complete the wizard
        </Button>
      </div>
    );
  } else {
    return <>{children}</>;
  }
};
