import { ConceptShiftMetricsOptions } from "@/domains/conceptShift/conceptShiftOptions";

export const ConceptShiftTexts = {
  [ConceptShiftMetricsOptions.ROC_AUC]: "ROC AUC delta",
  [ConceptShiftMetricsOptions.Precision]: "Precision delta",
  [ConceptShiftMetricsOptions.Recall]: "Recall delta",
  [ConceptShiftMetricsOptions.F1]: "F1 delta",
  [ConceptShiftMetricsOptions.Accuracy]: "Accuracy delta",
  [ConceptShiftMetricsOptions.Specificity]: "Specificity delta",
  [ConceptShiftMetricsOptions.AveragePrecision]: "Average precision delta",
  [ConceptShiftMetricsOptions.Magnitude]: "Magnitude",
};
