import _ from "lodash";

import { useFilter, useFilterConfig, useFilterContext } from "@/components/Filters";
import { SelectCheckboxList } from "@/components/dashboard/SelectCheckbox/SelectCheckboxList";
import { BayesianStatus } from "@/constants/bayesian";
import { bayesianStatusLabels } from "@/formatters/bayesian";

export type FilterBayesianStatusItemType = {
  status: BayesianStatus;
};

export type FilterBayesianStatusConfigType = {
  bayesianStates?: BayesianStatus[];
};

export const FilterBayesianStatus = () => {
  const [{ bayesianStates }, setFilterConfig] = useFilterConfig<FilterBayesianStatusConfigType>();
  const { items: results } = useFilterContext<FilterBayesianStatusItemType, FilterBayesianStatusConfigType>();

  useFilter<FilterBayesianStatusItemType, FilterBayesianStatusConfigType>((resultGroups, { bayesianStates }) => {
    if (!bayesianStates?.length) {
      return resultGroups;
    }

    return resultGroups.filter((results) => results.some((result) => bayesianStates.includes(result.status)));
  });

  const setSelectedStates = (updateTags: (currentStates: string[]) => string[]) => {
    setFilterConfig({ bayesianStates: updateTags(bayesianStates ?? []) as BayesianStatus[] });
  };
  const availableStates = _.uniq(results.map((result) => result.status));

  return (
    <SelectCheckboxList
      values={availableStates}
      names={bayesianStatusLabels}
      selectedValues={bayesianStates}
      setSelectedValues={setSelectedStates}
      showSearch={false}
      showSelectOnlyCurrent={true}
      searchClassName="border-0"
      emptyPlaceholder="No evaluations found."
    />
  );
};
