import { Navigate, Outlet, RouteObject } from "react-router-dom";

import { MonitoringModelName } from "@/components/monitoring";
import { ApplicationSetup } from "@/pages/ApplicationSetup";
import { Header } from "@/pages/Header";
import { Help } from "@/pages/Help";
import { Settings } from "@/pages/Settings/Settings";
import {
  AddEvaluationModelWizard,
  EvaluationModelContainer,
  EvaluationModelHub,
  EvaluationModelNameFromRoute,
} from "@/pages/evaluation";
import { AddExperimentWizard, ExperimentHub, ExperimentContainer, ExperimentNameFromRoute } from "@/pages/experiment";
import { AddMonitoringModelWizard, ModelMonitoringHub } from "@/pages/monitoring";
import { CustomMetrics } from "@/pages/monitoring";
import { ModelContainer } from "@/pages/monitoring/ModelContainer/ModelContainer";

import App from "../App";
import { ErrorPage } from "./ErrorPage";

const NavigatePrefix = ({ prefix }: { prefix: string }) => {
  return <Navigate to={prefix + location.pathname} replace />;
};

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/setup",
        element: <ApplicationSetup />,
      },
      {
        path: "/",
        element: (
          <Header>
            <Outlet />
          </Header>
        ),
        children: [
          {
            path: "/help",
            element: <Help />,
            handle: {
              breadcrumb: "Help",
            },
          },
          {
            path: "/",
            element: <Navigate to="/monitoring" replace />,
          },
          {
            path: "/overview",
            element: <Navigate to="/monitoring" replace />,
          },
          {
            path: "/model/*",
            element: <NavigatePrefix prefix="/monitoring" />,
          },
          {
            path: "/monitoring",
            element: <Outlet />,
            errorElement: <ErrorPage />,
            handle: {
              breadcrumb: "Monitoring",
              title: "Monitoring",
              root: true,
            },
            children: [
              {
                path: "",
                element: <ModelMonitoringHub />,
              },
              {
                path: "add",
                element: <AddMonitoringModelWizard />,
                handle: {
                  breadcrumb: "Add model",
                },
              },
              {
                path: "metrics",
                element: <CustomMetrics />,
                handle: {
                  breadcrumb: "Custom metrics",
                },
              },
              {
                path: "model/:modelId",
                element: <ModelContainer />,
                handle: {
                  breadcrumb: <MonitoringModelName />,
                },
                children: ModelContainer.routes,
              },
            ],
          },
          {
            path: "/evaluation",
            element: <Outlet />,
            errorElement: <ErrorPage />,
            handle: {
              breadcrumb: "Hub",
              title: "Model evaluation",
              root: true,
            },
            children: [
              {
                path: "",
                element: <EvaluationModelHub />,
              },
              {
                path: "add",
                element: <AddEvaluationModelWizard />,
                handle: {
                  breadcrumb: "Add model",
                },
              },
              {
                path: ":modelId",
                element: <EvaluationModelContainer />,
                handle: {
                  breadcrumb: <EvaluationModelNameFromRoute />,
                },
                children: EvaluationModelContainer.routes,
              },
            ],
          },
          {
            path: "/experiment",
            element: <Outlet />,
            errorElement: <ErrorPage />,
            handle: {
              breadcrumb: "Hub",
              title: "Experiment",
              root: true,
            },
            children: [
              {
                path: "",
                element: <ExperimentHub />,
              },
              {
                path: "add",
                element: <AddExperimentWizard />,
                handle: {
                  breadcrumb: "Add experiment",
                },
              },
              {
                path: ":experimentId",
                element: (
                  <ExperimentContainer>
                    <Outlet />
                  </ExperimentContainer>
                ),
                handle: {
                  breadcrumb: <ExperimentNameFromRoute />,
                },
                children: ExperimentContainer.routes,
              },
            ],
          },
          {
            path: "/settings",
            element: <Settings />,
            handle: {
              breadcrumb: "Settings",
            },
          },
        ],
      },
    ],
  },
];
