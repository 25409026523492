import { gql } from "../../index";

export const getUserApiTokens = gql(/* GraphQL */ `
  query GetUserApiTokens {
    current_user {
      apiTokens {
        id
        createdAt
        description
      }
    }
  }
`);
