import { gql } from "@/apis/nannyml";

export const editApplicationSettings = gql(/* GraphQL */ `
  mutation EditApplicationSettings($settings: EditApplicationSettingsInput!) {
    edit_application_settings(settings: $settings) {
      __typename
      ...ApplicationSettingsDetails
    }
  }
`);
