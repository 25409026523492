import { CircleCheckIcon, CircleXIcon } from "lucide-react";
import { useState } from "react";

import { StorageInput } from "@/apis/nannyml";
import { ButtonGroup } from "@/components/ButtonGroup";
import { Card } from "@/components/Card";
import { DatasetStorageDetails, DatasetStorageDetailsProps } from "@/components/DatasetStorage";
import { RadioGroup, RadioGroupItem } from "@/components/RadioGroup";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";

export const TargetData = ({
  value,
  ...props
}: Omit<DatasetStorageDetailsProps, "onChange" | "value"> & {
  onChange: (value: StorageInput | null | undefined) => void;
  value: StorageInput | null | undefined;
}) => {
  const [useSeparateTargets, setUseSeparateTargets] = useState<boolean | undefined>(
    value === undefined ? undefined : value !== null
  );
  const onSelectSeparateTargets = (value: string) => {
    const useSeparateTargets = value === "true";
    props.onChange(useSeparateTargets ? undefined : null);
    setUseSeparateTargets(useSeparateTargets);
  };

  if (!useSeparateTargets) {
    return (
      <RadioGroup
        className="grid grid-cols-2 gap-8 w-full xl:w-4/6"
        value={useSeparateTargets?.toString()}
        onValueChange={onSelectSeparateTargets}
      >
        <h3 className="col-span-full flex items-center justify-center text-lg font-bold">
          Do you have a separate dataset with targets?
          <InformationModalChip infoName="Missing targets" className="ml-1" />
        </h3>
        <RadioGroupItem value="true" asChild>
          <Card className="flex flex-col">
            <div className="flex items-center gap-3 mb-2">
              <CircleCheckIcon className="text-green-500 shrink-0" size={32} />
              <p className="font-bold">Yes, I have a separate target dataset</p>
            </div>
            <p className="text-sm text-gray-400 mb-5">
              Provide an initial set of targets as part of the wizard. You can add more target data to the model later
              as the data become available.
            </p>
            <p className="text-sm mt-auto">All algorithms will be available.</p>
          </Card>
        </RadioGroupItem>
        <RadioGroupItem value="false" asChild>
          <Card className="flex flex-col">
            <div className="flex items-center gap-3 mb-2">
              <CircleXIcon className="text-red-500 shrink-0" size={32} />
              <p className="font-bold">No, I don't have target data right now</p>
            </div>
            <p className="text-sm text-gray-400 mb-5">
              You can add target data and enable algorithms later should targets become available.
            </p>
            <p className="text-sm mt-auto">
              Algorithms that require targets will not be available, i.e. performance calculation and concept drift
              detection.
            </p>
          </Card>
        </RadioGroupItem>
      </RadioGroup>
    );
  }

  return (
    <div className="flex flex-col items-center gap-8">
      <RadioGroup value={useSeparateTargets.toString()} onValueChange={onSelectSeparateTargets}>
        <ButtonGroup>
          <RadioGroupItem className="basis-1/3 grow flex flex-col text-left gap-1" value="true">
            <div className="flex items-center gap-2">
              <CircleCheckIcon className="text-green-500 shrink-0" size={20} />
              <p className="font-bold">Yes, I have a separate target dataset</p>
            </div>
            <span className="text-sm text-gray-400">All algorithms will be available.</span>
          </RadioGroupItem>
          <RadioGroupItem className="basis-1/3 grow flex flex-col text-left gap-1" value="false">
            <div className="flex items-center gap-2">
              <CircleXIcon className="text-red-500 shrink-0" size={20} />
              <p className="font-bold">No, I don't have target data right now</p>
            </div>
            <span className="text-sm text-gray-400">
              Algorithms that require targets will not be available, i.e. performance calculation and concept drift
              detection.
            </span>
          </RadioGroupItem>
        </ButtonGroup>
      </RadioGroup>
      <DatasetStorageDetails value={value ?? null} {...props} />
    </div>
  );
};
