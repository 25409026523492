import { createContext, useContext } from "react";
import { StoreApi, useStore } from "zustand";

import { BayesianPlotDataset, BayesianPlotElements, BayesianPlotType } from "@/constants/bayesian";

export type BayesianPlotConfig = {
  datasets: BayesianPlotDataset[];
  types: BayesianPlotType[];
  elements: BayesianPlotElements[];
};

export type BayesianPlotConfigStore = BayesianPlotConfig & {
  setPlotConfig: (config: Partial<BayesianPlotConfig>) => void;
};

export const BayesianPlotConfigContext = createContext<null | StoreApi<BayesianPlotConfigStore>>(null);

type UseBayesianPlotConfigType = {
  (): BayesianPlotConfigStore;
  <T>(selector: (state: BayesianPlotConfigStore) => T): T;
};

export const useBayesianPlotConfig: UseBayesianPlotConfigType = <T>(
  selector?: (state: BayesianPlotConfigStore) => T
) => {
  const ctx = useContext(BayesianPlotConfigContext);

  if (!ctx) {
    throw new Error("useBayesianPlotConfig must be used within a BayesianPlotConfigContextProvider");
  }

  if (!selector) {
    selector = (state) => state as T;
  }

  return useStore(ctx, selector);
};
