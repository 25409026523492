import _ from "lodash";
import { LucideIcon, SearchIcon } from "lucide-react";
import { CSSProperties, useState } from "react";

import { Input } from "@/DesignSystem/shadcn/Input";
import { Label } from "@/DesignSystem/shadcn/Label/Label";
import { ColumnFlag, ColumnInput, ColumnType } from "@/apis/nannyml";
import { Chip } from "@/components/Chip";
import { ComboBox, ComboBoxGroup, ComboBoxItem, ComboBoxSearch } from "@/components/ComboBox";
import { LabeledField } from "@/components/LabeledField";
import { Select, SelectItem } from "@/components/Select";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@/components/Table";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";
import { ColumnConfig, ColumnFlagConfig, ColumnTypeConfig } from "@/domains/common";
import { columnFlagIcons, columnTypeIcons } from "@/domains/monitoring";
import { InformationModalNames } from "@/formatters/informationModal/informationModalCatalog";
import { columnFlagLabels, columnTypeLabels } from "@/formatters/models";
import { useSearchList } from "@/hooks/useSearchList";
import { formatISODateTime } from "@/lib/dateUtils";
import { cn } from "@/lib/utils";

const columnTypeTooltips: Partial<Record<ColumnType, InformationModalNames>> = {
  [ColumnType.Timestamp]: "Timestamp",
  [ColumnType.Identifier]: "Identifier column",
  [ColumnType.Target]: "Target",
  [ColumnType.Prediction]: "Model prediction",
  [ColumnType.PredictionScore]: "Model predicted probability",
};

const columnFlagTooltips: Partial<Record<ColumnFlag, InformationModalNames>> = {
  [ColumnFlag.Segment]: "Segmentation",
};

const ColumnIcon = ({ className, label, Icon }: { className?: string; label: string; Icon: LucideIcon }) => (
  <div className={cn("flex items-center gap-2", className)}>
    <Icon size={16} />
    <span>{label}</span>
  </div>
);

const ColumnTypeIcon = ({
  className,
  columnType,
  label,
}: {
  className?: string;
  columnType: ColumnType;
  label?: string;
}) => (
  <ColumnIcon className={className} label={label ?? columnTypeLabels[columnType]} Icon={columnTypeIcons[columnType]} />
);

const ColumnFlagIcon = ({
  className,
  columnFlag,
  label,
}: {
  className?: string;
  columnFlag: ColumnFlag;
  label?: string;
}) => (
  <ColumnIcon className={className} label={label ?? columnFlagLabels[columnFlag]} Icon={columnFlagIcons[columnFlag]} />
);

export const ColumnChip = ({ column }: { column: ColumnInput }) => {
  const Icon = columnTypeIcons[column.columnType];

  return <Chip className="max-w-full" label={column.name} icon={<Icon size={20} />} />;
};

export const ColumnList = ({ columns, displayCount }: { columns: ColumnInput[]; displayCount?: number }) => {
  // Display placeholder if no columns
  if (!columns.length) {
    return <span>-</span>;
  }

  const displayColumns = displayCount ? columns.slice(0, displayCount) : columns;

  return (
    <div className="flex flex-col gap-2">
      {displayColumns.map((column) => (
        <ColumnChip key={column.name} column={column} />
      ))}
      {displayCount && columns.length > displayCount && (
        <span className="text-sm">
          ... and {columns.length - displayCount} other column{columns.length - displayCount > 1 ? "s" : ""}
        </span>
      )}
    </div>
  );
};

export const SchemaOverview = ({
  displayCount = 3,
  className,
  columnConfig,
  schema,
}: {
  className?: string;
  columnConfig: ColumnConfig;
  displayCount?: number;
  schema: ColumnInput[];
}) => {
  return (
    <div className={cn("grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-4", className)}>
      {columnConfig.types
        .filter((c) => c.important)
        .map(({ type, optional }) => (
          <LabeledField key={type} label={<ColumnTypeLabel type={type} optional={optional} />}>
            <ColumnList columns={schema.filter((column) => column.columnType === type)} displayCount={displayCount} />
          </LabeledField>
        ))}
      {columnConfig.flags
        ?.filter((c) => c.important)
        .map(({ flag }) => (
          <LabeledField key={flag} label={<ColumnFlagLabel flag={flag} />}>
            <ColumnList
              columns={schema.filter((column) => column.columnFlags?.includes(flag))}
              displayCount={displayCount}
            />
          </LabeledField>
        ))}
    </div>
  );
};

export const SchemaTable = ({
  className,
  columnConfig,
  schema,
  head,
  onSchemaChange,
}: {
  className?: string;
  columnConfig: ColumnConfig;
  head: { [column: string]: any }[];
  schema: ColumnInput[];
  onSchemaChange?: (schema: ColumnInput[]) => void;
}) => {
  const { originalColumnTypes, resolveColumnConflict } = useResolveColumnConflict(columnConfig.types, schema);
  const { search, setSearch, results: matchingColumns } = useSearchList(schema, "name");

  const onColumnTypeChange =
    onSchemaChange &&
    ((name: string, columnType: ColumnType) => {
      const columnDef = getColumnTypeDef(columnConfig.types, columnType);

      onSchemaChange(
        schema.map((column) => ({
          ...column,
          columnType:
            column.name === name
              ? columnType
              : columnDef.allowMultiple || column.columnType !== columnType
              ? column.columnType
              : resolveColumnConflict(column, columnType, [name]),
        }))
      );
    });

  const onColumnFlagsChange =
    onSchemaChange &&
    ((name: string, columnFlags: ColumnFlag[]) => {
      onSchemaChange(
        schema.map((column) => ({
          ...column,
          columnFlags: column.name === name ? columnFlags : column.columnFlags,
        }))
      );
    });

  return (
    <div className={cn("flex flex-col gap-3 max-w-full", className)}>
      <Label className="relative w-[200px] text-slate-400 cursor-text">
        <SearchIcon className="absolute left-3 top-3" size={16} />
        <Input
          className="pl-9"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search columns..."
        />
      </Label>
      <div className="w-fit max-w-full overflow-x-scroll pb-1">
        <Table>
          <TableBody>
            <TableRow>
              <TableHead className="whitespace-nowrap" scope="row">
                Column name
              </TableHead>
              {matchingColumns.map((column) => (
                <TableCell key={column.name} className="[&:nth-child(2)]:pl-4">
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableHead className="whitespace-nowrap" scope="row">
                <span className="inline-flex items-center">
                  <span>Column type</span>
                  <InformationModalChip infoName="Column type" className="ml-1.5 mr-0 p-0" />
                </span>
              </TableHead>
              {matchingColumns.map(({ name, columnType }) => (
                <TableCell key={name} className="pr-2 pl-0 [&:nth-child(2)]:pl-2">
                  {onColumnTypeChange ? (
                    <Select
                      size="small"
                      className={cn(
                        "whitespace-nowrap min-w-[195px] justify-between",
                        columnType !== originalColumnTypes[name] && "dark:border-warningPale"
                      )}
                      value={columnType}
                      onValueChange={(value) => onColumnTypeChange(name, value as ColumnType)}
                    >
                      {columnConfig.types.map(({ type }) => (
                        <SelectItem key={type} value={type}>
                          <ColumnTypeIcon columnType={type} />
                        </SelectItem>
                      ))}
                    </Select>
                  ) : (
                    <ColumnTypeIcon columnType={columnType} className="whitespace-nowrap px-2" />
                  )}
                </TableCell>
              ))}
            </TableRow>
            {columnConfig.flags && (
              <TableRow>
                <TableHead className="whitespace-nowrap" scope="row">
                  <span className="inline-flex items-center">
                    <span>Column flags</span>
                    <InformationModalChip infoName="Column flags" className="ml-1.5 mr-0 p-0" />
                  </span>
                </TableHead>
                {matchingColumns.map(({ name, columnFlags }) => (
                  <TableCell key={name} className="pr-2 pl-0 [&:nth-child(2)]:pl-2">
                    {onColumnFlagsChange ? (
                      <ComboBox
                        // size="small"
                        className="whitespace-nowrap min-w-[195px] justify-between"
                        values={columnFlags}
                        onChange={(value) => onColumnFlagsChange(name, value as ColumnFlag[])}
                        placeholder="Select flags"
                        ValueComponent={({ value }) => <ColumnFlagIcon columnFlag={value as ColumnFlag} />}
                        allowMultiple
                      >
                        {(columnConfig.flags ?? []).map(({ flag }) => (
                          <ComboBoxItem key={flag} value={flag}>
                            <ColumnFlagIcon columnFlag={flag} />
                          </ComboBoxItem>
                        ))}
                      </ComboBox>
                    ) : (
                      columnFlags.map((flag) => {
                        const Icon = columnFlagIcons[flag];
                        return <Chip key={flag} label={columnFlagLabels[flag]} icon={<Icon size={20} />} />;
                      })
                    )}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {head.map((row: { [column: string]: any }, idx: number) => (
              <TableRow key={idx}>
                <TableHead scope="row">{idx}</TableHead>
                {matchingColumns.map((column) => (
                  <TableCell key={column.name} className="truncate [&:nth-child(2)]:pl-4">
                    {column.columnType === ColumnType.Timestamp
                      ? formatISODateTime(new Date(row[column.name]))
                      : row[column.name]?.toString()}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export const SchemaConfiguration = ({
  className,
  schema,
  onSchemaChange,
  columnConfig,
}: {
  className?: string;
  schema: ColumnInput[];
  onSchemaChange: (schema: ColumnInput[]) => void;
  columnConfig: ColumnConfig;
}) => {
  const sortedColumns = _.sortBy(schema, "name");
  const maxColumnNameLength = Math.max(...schema.map(({ name }) => name.length));
  const { resolveColumnConflict } = useResolveColumnConflict(columnConfig.types, schema);
  const onColumnTypeChange = (type: ColumnType, columns: string[]) => {
    onSchemaChange(
      schema.map((column) => ({
        ...column,
        columnType: columns.includes(column.name)
          ? type
          : column.columnType !== type
          ? column.columnType
          : resolveColumnConflict(column, type, columns),
      }))
    );
  };
  const onColumnFlagChange = (flag: ColumnFlag, columns: string[]) => {
    onSchemaChange(
      schema.map((column) => ({
        ...column,
        columnFlags: columns.includes(column.name)
          ? [...new Set(column.columnFlags.concat(flag))]
          : column.columnFlags?.filter((f) => f !== flag),
      }))
    );
  };

  return (
    <div
      className={cn("flex flex-col gap-8", className)}
      style={{ "--max-column-name-length": `${maxColumnNameLength}ch` } as CSSProperties}
    >
      <div className="flex flex-wrap justify-center gap-6">
        {columnConfig.types
          .filter(({ important }) => important)
          .map(({ type, allowMultiple, optional }) => (
            <LabeledField key={type} label={<ColumnTypeLabel type={type} optional={optional} />}>
              <ComboBox
                className={
                  allowMultiple
                    ? "w-[calc(2*var(--max-column-name-length)+60px)]"
                    : "w-[calc(var(--max-column-name-length)+60px)]"
                }
                values={sortedColumns.filter(({ columnType }) => columnType === type).map(({ name }) => name)}
                onChange={(values) => onColumnTypeChange(type, values)}
                required={!optional}
                placeholder={`Select column${allowMultiple ? "s" : ""}`}
                ValueComponent={({ value }) => <ColumnTypeIcon columnType={type} label={value} />}
                allowMultiple={allowMultiple ?? false}
              >
                <ComboBoxSearch />
                <ComboBoxGroup>
                  {sortedColumns.map((column) => (
                    <ComboBoxItem key={column.name} value={column.name}>
                      <ColumnTypeIcon columnType={column.columnType} label={column.name} />
                    </ComboBoxItem>
                  ))}
                </ComboBoxGroup>
              </ComboBox>
            </LabeledField>
          ))}
      </div>
      <div className="flex flex-wrap justify-center gap-6">
        {(columnConfig.flags ?? [])
          .filter(({ important }) => important)
          .map(({ flag }) => (
            <LabeledField key={flag} label={<ColumnFlagLabel flag={flag} />}>
              <ComboBox
                className="w-[calc(2*var(--max-column-name-length)+60px)]"
                values={sortedColumns.filter(({ columnFlags }) => columnFlags.includes(flag)).map(({ name }) => name)}
                onChange={(values) => onColumnFlagChange(flag, values)}
                placeholder="Select columns"
                ValueComponent={({ value }) => (
                  <ColumnTypeIcon columnType={schema.find((c) => c.name === value)!.columnType} label={value} />
                )}
                allowMultiple
              >
                <ComboBoxSearch />
                <ComboBoxGroup>
                  {sortedColumns.map((column) => (
                    <ComboBoxItem key={column.name} value={column.name}>
                      <ColumnTypeIcon columnType={column.columnType} label={column.name} />
                    </ComboBoxItem>
                  ))}
                </ComboBoxGroup>
              </ComboBox>
            </LabeledField>
          ))}
      </div>
    </div>
  );
};

/**
 * Hook for resolving column conflicts. It keeps track of the schema history and resolves conflicts by reverting the
 * conflicting column to its latest column type that does not result in a conflict.
 */
const useResolveColumnConflict = (columnTypes: ColumnTypeConfig[], schema: ColumnInput[]) => {
  const [schemaHistory, setSchemaHistory] = useState([schema]);

  if (schema !== schemaHistory.at(-1)) {
    setSchemaHistory((history) => history.concat([schema]));
  }

  return {
    originalColumnTypes: Object.fromEntries(schemaHistory[0].map((column) => [column.name, column.columnType])),
    resolveColumnConflict: (column: ColumnInput, columnType: ColumnType, names: string[]) => {
      for (let i = schemaHistory.length - 1; i >= 0; i--) {
        let originalColumnType = schemaHistory[i].find((c) => c.name === column.name)?.columnType!;
        if (
          originalColumnType !== columnType &&
          (getColumnTypeDef(columnTypes, originalColumnType).allowMultiple ||
            !schema.some((c) => c.columnType === originalColumnType && !names.includes(c.name)))
        ) {
          return originalColumnType;
        }
      }
      return ColumnType.Ignored;
    },
  };
};

/**
 * Get column definition for a given column type.
 * @param columnTypes List of column types applicable to the schema.
 * @param columnType Column type to get definition for.
 */
const getColumnTypeDef = (columnTypes: ColumnTypeConfig[], columnType: ColumnType) => {
  const columnDef = columnTypes.find(({ type }) => type === columnType);
  if (!columnDef) {
    throw new Error(`Column type '${columnType}' is not supported.`);
  }
  return columnDef;
};

const ColumnTypeLabel = ({ type, optional }: { type: ColumnType; optional?: boolean }) => (
  <>
    <span>{columnTypeLabels[type]}</span>
    {columnTypeTooltips[type] && (
      <InformationModalChip infoName={columnTypeTooltips[type]!} className="inline-flex ml-0 -mr-1 py-0 text-current" />
    )}
    {!optional && <span className="text-red-400"> *</span>}
  </>
);

const ColumnFlagLabel = ({ flag }: { flag: ColumnFlag }) => (
  <>
    <span>{columnFlagLabels[flag]}</span>
    {columnFlagTooltips[flag] && (
      <InformationModalChip infoName={columnFlagTooltips[flag]!} className="inline-flex ml-0 -mr-1 py-0 text-current" />
    )}
  </>
);
