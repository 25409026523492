import { LoaderCircleIcon, SettingsIcon, SheetIcon, TrendingUpIcon } from "lucide-react";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { RouteObject } from "react-router-dom";

import { Divider } from "@/components/Divider";
import { Navigation, NavigationContent, NavigationRoutes, useNavigationOpen } from "@/components/Navigation";
import { problemTypeLabels } from "@/formatters/models";
import { useEvaluationModelId, useEvaluationModelMetadata } from "@/hooks/evaluation";

import { EvaluationModelSettings } from "./Settings";
import { EvaluationModelResultsRoute } from "./results";
import { EvaluationModelSummary } from "./summary";

const routes: RouteObject[] = [
  {
    path: "",
    element: <EvaluationModelSummary />,
    handle: {
      breadcrumb: "Summary",
      icon: SheetIcon,
      group: "Probabilistic model evaluation",
    },
  },
  {
    path: "performance",
    element: <EvaluationModelResultsRoute resultView="performance" />,
    handle: {
      breadcrumb: "Performance",
      icon: TrendingUpIcon,
      group: "Probabilistic model evaluation",
    },
  },
  {
    path: "settings",
    element: <EvaluationModelSettings />,
    handle: {
      breadcrumb: "Settings",
      icon: SettingsIcon,
      group: "Management",
    },
  },
];

export const EvaluationModelContainer = () => {
  const modelId = useEvaluationModelId();

  return (
    <div className="flex-grow flex h-full">
      <Navigation id="evaluation.ModelContainer" className="min-w-fit">
        <Suspense fallback={<NavHeaderSkeleton />}>
          <NavHeader modelId={modelId} />
        </Suspense>
        <Divider orientation="horizontal" className="mt-1 mb-0" />
        <NavigationRoutes routes={routes} url={`/evaluation/${modelId}`} />
      </Navigation>
      <Divider orientation="vertical" margin="none" />
      <div className="flex-grow overflow-auto">
        <Suspense fallback={<ContentSkeleton />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
};

EvaluationModelContainer.routes = routes;

const NavHeader = ({ modelId }: { modelId: number }) => {
  const { name, problemType } = useEvaluationModelMetadata(modelId);
  const [isOpen] = useNavigationOpen();

  return isOpen ? (
    <NavigationContent className="h-[60px] px-4 grid grid-cols-[auto_1fr] items-center gap-x-2 py-2">
      <div className="truncate max-w-[min(20vw,25ch)]" title={name}>
        {name}
      </div>
      <span className="text-sm text-gray-400">#{modelId}</span>
      <div className="col-span-full text-sm text-gray-400">{problemTypeLabels[problemType]}</div>
    </NavigationContent>
  ) : (
    <span className="h-[60px] mx-auto flex items-end text-sm text-gray-400">#{modelId}</span>
  );
};

const NavHeaderSkeleton = () => (
  <div className="h-[60px] px-4 py-2 space-y-2">
    <div className="w-[25ch] h-5 bg-gray-500 animate-pulse rounded" />
    <div className="w-[14ch] h-4 bg-gray-500 animate-pulse rounded" />
  </div>
);

const ContentSkeleton = () => (
  <div className="h-full w-full flex gap-2 items-center justify-center text-gray-400">
    <LoaderCircleIcon className="animate-spin text-highlightDeep" size={24} />
    <p>Loading the latest data for you...</p>
  </div>
);
