import { cva, VariantProps } from "class-variance-authority";
import { forwardRef, TextareaHTMLAttributes } from "react";

import { cn } from "@/lib/utils";

const textAreaStyle = cva(
  [
    "rounded-md border border-gray-500 bg-transparent p-2 text-sm text-slate-50 overflow-auto",
    "focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-900",
    "disabled:cursor-not-allowed disabled:opacity-50",
    "placeholder:text-slate-400 placeholder:italic",
    "invalid:border-red-500 invalid:focus:ring-red-400",
  ],
  {
    variants: {},
  }
);

export const TextArea = forwardRef<
  HTMLTextAreaElement,
  TextareaHTMLAttributes<HTMLTextAreaElement> & VariantProps<typeof textAreaStyle>
>(({ className, ...props }, ref) => <textarea ref={ref} className={cn(textAreaStyle(), className)} {...props} />);
