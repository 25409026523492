import { InfoIcon, Trash2Icon } from "lucide-react";
import { ReactNode, createContext, useContext, useRef } from "react";

import { SimpleTooltip } from "@/DesignSystem/nanny/SimpleTooltip/SimpleTooltip";
import { InputWithClearButton } from "@/DesignSystem/shadcn/Input/Input";
import { ConstantThresholdInput, ThresholdInput, StandardDeviationThresholdInput } from "@/apis/nannyml";
import { Select, SelectItem } from "@/components/Select";
import { parseNullableFloat } from "@/lib/numbersUtils";
import { cn } from "@/lib/utils";

import { Button } from "./common/Button";

export type ThresholdInputProps = {
  value: ThresholdInput | null;
  onValueChange: (value: ThresholdInput | null) => void;
  disabled?: boolean;
};

type SegmentThresholdType = {
  threshold?: ThresholdInput | null;
  segmentThresholds: {
    segmentId: number;
    threshold?: ThresholdInput | null;
  }[];
};

export type SegmentThresholdInputProps = {
  segmentId: number | undefined;
  value: SegmentThresholdType;
  onValueChange: (value: Partial<SegmentThresholdType>) => void;
  disabled?: boolean;
};

const ThresholdInputContext = createContext<ThresholdInputProps | null>(null);
const SegmentThresholdInputContext = createContext<{
  isSegment: boolean;
  hasOverride: boolean;
  onOverrideRemove: () => void;
} | null>(null);

export const Root = ({ children, ...props }: ThresholdInputProps & { children: ReactNode }) => (
  <ThresholdInputContext.Provider value={props} children={children} />
);
export const ThresholdInputRoot = Root;

const useThresholdInputContext = () => {
  const ctx = useContext(ThresholdInputContext);
  if (ctx === null) {
    throw new Error("ThresholdInputContext must be used within a ThresholdInputRoot");
  }
  return ctx;
};

const useSegmentThresholdInputContext = () => {
  const ctx = useContext(SegmentThresholdInputContext);
  if (ctx === null) {
    throw new Error("SegmentThresholdInputContext must be used within a SegmentThresholdInputRoot");
  }
  return ctx;
};

export const Type = ({ className }: { className?: string }) => {
  const { value, onValueChange, disabled } = useThresholdInputContext();
  const type = value?.constant ? "constant" : value?.standardDeviation ? "standardDeviation" : "none";

  const onSelectType = (type: string) => {
    onValueChange(
      type === "none"
        ? null
        : {
            constant: type === "constant" ? {} : null,
            standardDeviation: type === "standardDeviation" ? {} : null,
          }
    );
  };

  return (
    <Select className={cn("w-44", className)} value={type} onValueChange={onSelectType} disabled={disabled}>
      <SelectItem value="none">No threshold</SelectItem>
      <SelectItem value="constant">Constant</SelectItem>
      <SelectItem value="standardDeviation">Standard deviation</SelectItem>
    </Select>
  );
};
export const ThresholdInputType = Type;

export const Value = ({ className, min, max }: { className?: string; min?: number; max?: number }) => {
  const { value, onValueChange, disabled } = useThresholdInputContext();

  return value?.constant ? (
    <ConstantThreshold
      className={className}
      value={value.constant}
      onValueChange={(constant) => onValueChange({ ...value, constant })}
      min={min}
      max={max}
      disabled={disabled}
    />
  ) : value?.standardDeviation ? (
    <StandardDeviationThreshold
      className={className}
      value={value.standardDeviation}
      onValueChange={(standardDeviation) => onValueChange({ ...value, standardDeviation })}
      disabled={disabled}
    />
  ) : (
    <ConstantThreshold className={className} value={{ lower: null, upper: null }} min={min} max={max} disabled />
  );
};
export const ThresholdInputValue = Value;

const ConstantThreshold = ({
  className,
  value,
  onValueChange,
  min,
  max,
  disabled,
}: {
  className?: string;
  value: ConstantThresholdInput;
  onValueChange?: (value: ConstantThresholdInput) => void;
  min?: number;
  max?: number;
  disabled?: boolean;
}) => {
  const lowerBoundRef = useRef<HTMLInputElement>(null);
  const upperBoundRef = useRef<HTMLInputElement>(null);

  const validateInput = (element: HTMLInputElement) => {
    const lowerBound = parseNullableFloat(lowerBoundRef.current!.value);
    const upperBound = parseNullableFloat(upperBoundRef.current!.value);

    // Ensure upper bound is larger than lower bound
    if (lowerBound !== null && upperBound !== null && upperBound <= lowerBound) {
      element.setCustomValidity("Upper bound must be larger than lower bound.");
    } else {
      lowerBoundRef.current!.setCustomValidity("");
      upperBoundRef.current!.setCustomValidity("");
    }

    return [lowerBound, upperBound];
  };

  const handleChange = (element: HTMLInputElement) => {
    const [lower, upper] = validateInput(element);

    // Propagate changes to parent component
    onValueChange?.({ lower, upper });
  };

  const handleBlur = (element: HTMLInputElement) => {
    validateInput(element);

    // Report any validity issues to user
    element.reportValidity();
  };

  return (
    <div className={cn("flex items-center gap-2 w-96", className)}>
      <InputWithClearButton
        ref={lowerBoundRef}
        type="number"
        min={min}
        max={max}
        step="any"
        placeholder={min?.toString() ?? "-∞"}
        value={value.lower ?? ""}
        onChange={(e) => handleChange(e.target)}
        onBlur={(e) => handleBlur(e.target)}
        disabled={disabled}
        className="hide-arrows"
      />
      <span>-</span>
      <InputWithClearButton
        ref={upperBoundRef}
        type="number"
        min={min}
        max={max}
        step="any"
        placeholder={max?.toString() ?? "+∞"}
        value={value.upper ?? ""}
        onChange={(e) => handleChange(e.target)}
        onBlur={(e) => handleBlur(e.target)}
        disabled={disabled}
        className="hide-arrows"
      />
    </div>
  );
};

const StandardDeviationThreshold = ({
  className,
  value,
  onValueChange,
  disabled,
}: {
  className?: string;
  value: StandardDeviationThresholdInput;
  onValueChange: (value: StandardDeviationThresholdInput) => void;
  disabled?: boolean;
}) => {
  const lowerBoundRef = useRef<HTMLInputElement>(null);
  const upperBoundRef = useRef<HTMLInputElement>(null);

  const onChange = () => {
    const stdLowerMultiplier = parseNullableFloat(lowerBoundRef.current!.value);
    const stdUpperMultiplier = parseNullableFloat(upperBoundRef.current!.value);

    // Propagate changes to parent component
    onValueChange({ stdLowerMultiplier, stdUpperMultiplier });
  };

  return (
    <div className={cn("flex items-center gap-2 w-96", className)}>
      <div className="relative">
        <InputWithClearButton
          ref={lowerBoundRef}
          type="number"
          min={0}
          step="any"
          placeholder="∞"
          value={value.stdLowerMultiplier ?? ""}
          onChange={onChange}
          onBlur={(e) => e.target.reportValidity()}
          disabled={disabled}
          className="hide-arrows pl-5 pr-14"
        />
        <span className="absolute left-3 top-2.5 text-gray-400">-</span>
        <span className="absolute right-8 top-2.5 italic text-gray-400">std</span>
      </div>
      <span>-</span>
      <div className="relative">
        <InputWithClearButton
          ref={upperBoundRef}
          type="number"
          min={0}
          step="any"
          placeholder="∞"
          value={value.stdUpperMultiplier ?? ""}
          onChange={onChange}
          onBlur={(e) => e.target.reportValidity()}
          disabled={disabled}
          className="hide-arrows pl-5 pr-14"
        />
        <span className="absolute left-2 top-2.5 text-gray-400">+</span>
        <span className="absolute right-8 top-2.5 italic text-gray-400">std</span>
      </div>
    </div>
  );
};

export const SegmentRoot = ({
  segmentId,
  value,
  onValueChange,
  disabled,
  children,
}: SegmentThresholdInputProps & { children: ReactNode }) => {
  const segmentThreshold = value.segmentThresholds.find((t) => t.segmentId === segmentId);
  const hasOverride = segmentThreshold?.threshold !== undefined;
  const threshold = hasOverride ? segmentThreshold.threshold : value.threshold;
  const isSegment = segmentId !== undefined;

  const onThresholdChange = (newThreshold: ThresholdInput | null) => {
    onValueChange(
      segmentId
        ? {
            segmentThresholds: value.segmentThresholds
              .filter((t) => t.segmentId !== segmentId)
              .concat({ segmentId, threshold: newThreshold }),
          }
        : { threshold: newThreshold }
    );
  };

  const onOverrideRemove = () => {
    onValueChange({ segmentThresholds: value.segmentThresholds.filter((t) => t.segmentId !== segmentId) });
  };

  return (
    <SegmentThresholdInputContext.Provider value={{ isSegment, hasOverride, onOverrideRemove }}>
      <Root value={threshold ?? null} onValueChange={onThresholdChange} disabled={disabled} children={children} />
    </SegmentThresholdInputContext.Provider>
  );
};
export const SegmentThresholdInputRoot = SegmentRoot;

export const SegmentInfo = () => {
  const { hasOverride, isSegment } = useSegmentThresholdInputContext();
  return (
    <SimpleTooltip
      tooltipContent={
        !isSegment
          ? "This is the default threshold for all segments"
          : hasOverride
          ? "This segment is using a custom threshold"
          : "This segment is using the default threshold"
      }
    >
      <InfoIcon size={20} className={hasOverride ? "text-warningPale" : "text-gray-400"} />
    </SimpleTooltip>
  );
};

export const ClearSegmentOverrideButton = () => {
  const { hasOverride, isSegment, onOverrideRemove } = useSegmentThresholdInputContext();
  return (
    <SimpleTooltip tooltipContent="Remove custom threshold from segment">
      <Button
        cva={{ intent: "icon", size: "chip" }}
        onClick={onOverrideRemove}
        className="text-gray-400 p-1.5"
        disabled={!isSegment || !hasOverride}
        disabledStyle="icon"
      >
        <Trash2Icon size={20} />
      </Button>
    </SimpleTooltip>
  );
};
