import {
  ConfusionMatrixComponentsOptions,
  TypeConfusionMatrixComponentsOptions,
} from "@/domains/performance/performanceComponentsOptions";
import {
  FilterPerformanceAnalysisOptions,
  MatrixValueOptions,
  PerformanceCalculatorOptions,
  PerformanceMetricOptions,
  TypePerformanceMetricOptions,
} from "@/domains/performance/performanceOptions";
import { getMetricAndComponentName, NameElements } from "@/formatters/results/resultsStrings";

export const MainPerformanceMetricNoOptionsLabel = "Select a problem type first";

export const PerformanceCalculatorTexts = {
  [PerformanceCalculatorOptions.RealizedPerformance]: "Performance calculator",
};

export const PerformanceMetricTexts = {
  [PerformanceMetricOptions.ROC_AUC]: "ROC AUC",
  [PerformanceMetricOptions.F1]: "F1",
  [PerformanceMetricOptions.Precision]: "Precision",
  [PerformanceMetricOptions.Recall]: "Recall",
  [PerformanceMetricOptions.Specificity]: "Specificity",
  [PerformanceMetricOptions.Accuracy]: "Accuracy",
  [PerformanceMetricOptions.BusinessValue]: "Business value",
  [PerformanceMetricOptions.ConfusionMatrix]: "Confusion matrix",
  [PerformanceMetricOptions.AveragePrecision]: "Average precision",
  [PerformanceMetricOptions.MAE]: "MAE",
  [PerformanceMetricOptions.MAPE]: "MAPE",
  [PerformanceMetricOptions.MSE]: "MSE",
  [PerformanceMetricOptions.RMSE]: "RMSE",
  [PerformanceMetricOptions.MSLE]: "MSLE",
  [PerformanceMetricOptions.RMSLE]: "RMSLE",
};

export const BusinessValueTexts = {
  [MatrixValueOptions.truePositiveWeight]: "True positive weight",
  [MatrixValueOptions.trueNegativeWeight]: "True negative weight",
  [MatrixValueOptions.falsePositiveWeight]: "False positive weight",
  [MatrixValueOptions.falseNegativeWeight]: "False negative weight",
};

export const ConfusionMatrixTexts = {
  [ConfusionMatrixComponentsOptions.TruePositive]: "True Positive",
  [ConfusionMatrixComponentsOptions.TrueNegative]: "True Negative",
  [ConfusionMatrixComponentsOptions.FalsePositive]: "False Positive",
  [ConfusionMatrixComponentsOptions.FalseNegative]: "False Negative",
};

export const ConfusionMatrixAbbreviations = {
  [ConfusionMatrixComponentsOptions.TruePositive]: "TP",
  [ConfusionMatrixComponentsOptions.TrueNegative]: "TN",
  [ConfusionMatrixComponentsOptions.FalsePositive]: "FP",
  [ConfusionMatrixComponentsOptions.FalseNegative]: "FN",
};

export const getNameOfPerformanceMetric = (resultInfo: NameElements) => {
  const metricAndComponentName = getMetricAndComponentName(
    resultInfo.metric ?? "",
    resultInfo.componentName,
    resultInfo.analysisType
  );

  return [metricAndComponentName];
};
