import { useApolloClient, useMutation } from "@apollo/client";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ColumnInput,
  CreateDataSourceInput,
  CreateEvaluationModelInput,
  PerformanceMetric,
  ProblemType,
  ProductType,
  gql,
} from "@/apis/nannyml";
import { DatasetStorageDetails, inspectDataSource } from "@/components/DatasetStorage";
import { SchemaConfiguration, SchemaTable } from "@/components/Schema";
import {
  Wizard,
  WizardControlButtons,
  WizardContent,
  WizardOverview,
  WizardStep,
  WizardCompletionGuard,
} from "@/components/Wizard";
import { getWizardControls } from "@/components/Wizard/Wizard.context";
import { evaluationPerformanceMetrics, columnConfig } from "@/domains/evaluation";
import { cn } from "@/lib/utils";

import { EvaluationMetricConfig } from "../../../components/evaluation/EvaluationMetricConfig";
import { DataRequirements } from "./DataRequirements";
import { EvaluationData } from "./EvaluationData";
import { ModelDetails } from "./ModelDetails";
import { Review } from "./Review";

const createEvaluationModelMutation = gql(/* GraphQL */ `
  mutation CreateEvaluationModel($input: CreateEvaluationModelInput!) {
    create_evaluation_model(input: $input) {
      id
    }
  }
`);

export const AddEvaluationModelWizard = () => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const [value, setValue] = useState<Partial<CreateEvaluationModelInput>>({
    problemType: ProblemType.BinaryClassification,
    kpm: PerformanceMetric.RocAuc,
    metrics: evaluationPerformanceMetrics.map((metric) => ({
      metric,
      enabled: true,
      ropeLowerBound: null,
      ropeUpperBound: null,
      hdiWidth: null,
    })),
  });
  const [referenceHead, setReferenceHead] = useState<any>();

  const [createEvaluationModel] = useMutation(createEvaluationModelMutation);

  const steps: WizardStep<CreateEvaluationModelInput>[] = useMemo(
    () => [
      {
        title: "Data requirements",
        subtitle: "Find out what data you need to provide",
        isCompleted: () => true,
        render: () => <DataRequirements />,
      },
      {
        title: "Model details",
        subtitle: "Define details of the model to be evaluated",
        isCompleted: (settings) =>
          Boolean(settings.name && settings.problemType && settings.hypothesis && settings.classificationThreshold),
        render: (settings, setSettings) => <ModelDetails value={settings} onChange={setSettings} />,
      },
      {
        title: "Metrics",
        subtitle: "Define metrics to evaluate",
        isCompleted: (settings) => Boolean(settings.metrics?.length && settings.kpm),
        render: (settings, setSettings) => <EvaluationMetricConfig value={settings} onChange={setSettings} />,
      },
      {
        title: "Reference data",
        subtitle: "Provide reference data for evaluation",
        isCompleted: (settings) => Boolean(settings.referenceDataSource?.storageInfo),
        onComplete: (modelInput, setModelInput) =>
          inspectDataSource(
            client,
            modelInput.referenceDataSource!,
            ProductType.Evaluation,
            ProblemType.BinaryClassification,
            (columns) =>
              setModelInput(({ referenceDataSource }) => ({
                referenceDataSource: { ...referenceDataSource!, columns },
              })),
            setReferenceHead
          ),
        render: (modelInput, setModelInput) => (
          <DatasetStorageDetails
            name="reference"
            value={modelInput.referenceDataSource?.storageInfo ?? null}
            onChange={(storageInfo) =>
              setModelInput({
                referenceDataSource: {
                  name: "reference",
                  hasReferenceData: true,
                  hasAnalysisData: false,
                  columns: [],
                  storageInfo,
                },
              })
            }
          />
        ),
      },
      {
        title: "Configure schema",
        subtitle: "Define columns for the model",
        render: (modelInput, setModelInput) => {
          const schema = modelInput.referenceDataSource!.columns!;
          const onSchemaChange = (columns: ColumnInput[]) =>
            setModelInput(({ referenceDataSource }) => ({
              referenceDataSource: { ...referenceDataSource!, columns },
            }));

          return (
            <div className="self-stretch flex flex-col justify-around max-w-full">
              <SchemaConfiguration columnConfig={columnConfig} schema={schema} onSchemaChange={onSchemaChange} />
              <SchemaTable
                columnConfig={columnConfig}
                schema={schema}
                head={referenceHead ?? []}
                onSchemaChange={onSchemaChange}
              />
            </div>
          );
        },
      },
      {
        title: "Evaluation data",
        subtitle: "Optionally provide evaluation data",
        isCompleted: (modelInput) => modelInput.evaluationDataSource !== undefined,
        onComplete: (modelInput, setModelInput) =>
          inspectDataSource(
            client,
            modelInput.evaluationDataSource!,
            ProductType.Evaluation,
            ProblemType.BinaryClassification,
            (columns) =>
              setModelInput({
                evaluationDataSource: { ...(modelInput.evaluationDataSource as CreateDataSourceInput), columns },
              }),
            undefined,
            modelInput.referenceDataSource?.columns
          ),
        render: (modelInput, setModelInput) => (
          <EvaluationData
            name="evaluation"
            value={modelInput.evaluationDataSource?.storageInfo ?? null}
            onChange={(storageInfo) =>
              setModelInput({
                evaluationDataSource: {
                  name: "evaluation",
                  hasReferenceData: false,
                  hasAnalysisData: true,
                  columns: [],
                  storageInfo,
                },
              })
            }
          />
        ),
      },
      {
        title: "Review",
        subtitle: "Review the model and data sources",
        isCompleted: (_, ctx) => getWizardControls(ctx).arePreviousStepsCompleted(),
        render: (settings) => (
          <WizardCompletionGuard>
            <Review settings={settings as CreateEvaluationModelInput} />
          </WizardCompletionGuard>
        ),
      },
    ],
    [client, referenceHead]
  );

  const onComplete = (input: CreateEvaluationModelInput) => {
    return createEvaluationModel({ variables: { input } })
      .then((data) => {
        if (data.errors || !data.data) {
          throw new Error("Failed to add evaluation model: " + data.errors?.[0].message);
        }

        navigate(`/evaluation/${data.data.create_evaluation_model.id}`);
      })
      .catch((error) => Promise.reject(`Failed to add evaluation model: ${error.message}`));
  };

  return (
    <div className="flex h-full bg-dark overflow-y-auto py-4">
      <Wizard
        className={cn(
          "rounded-xl border border-gray-600 bg-primaryBg px-8",
          "m-auto min-h-[max(70%,750px)] h-fit w-4/5 2xl:w-9/12"
        )}
        steps={steps}
        value={value}
        onChange={setValue}
        onComplete={onComplete}
        preventForwardJump
      >
        <h3 className="text-2xl text-center">Add evaluation model</h3>
        <WizardOverview />
        <WizardContent />
        <WizardControlButtons finishLabel="Start evaluation" />
      </Wizard>
    </div>
  );
};
