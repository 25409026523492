import { Dispatch, SetStateAction, createContext } from "react";

import { PatchStateAction } from "@/lib/typesUtils";

export type GrouperFunction<T, C> = (results: readonly T[], filterConfig: C) => readonly T[][];
export type FilterFunction<T, C> = (results: readonly T[][], filterConfig: C) => readonly T[][];
export type LabelFunction<T, C> = (results: readonly T[], filterConfig: C) => string;

export type FilterContextType<T = unknown, C = unknown> = {
  items: readonly T[];
  filteredItems: readonly T[][];
  filters: FilterFunction<T, C>[];
  setFilters: Dispatch<SetStateAction<FilterFunction<T, C>[]>>;
  groupers: GrouperFunction<T, C>[];
  setGroupers: Dispatch<SetStateAction<GrouperFunction<T, C>[]>>;
  labels: string[] | null;
  labeler: LabelFunction<T, C> | null;
  setLabeler: Dispatch<SetStateAction<LabelFunction<T, C> | null>>;
  filterConfig: C;
  setFilterConfig: Dispatch<PatchStateAction<C>>;
};

export const FilterContext = createContext<FilterContextType<any, any> | null>(null);
