import { ReactNode } from "react";

import { Tooltip, TooltipContent, TooltipTrigger } from "@/DesignSystem/shadcn/Tooltip/Tooltip";

export const SimpleTooltip = ({
  children,
  tooltipContent,
  side,
  className,
}: {
  children: ReactNode;
  tooltipContent: ReactNode;
  side?: "top" | "bottom" | "left" | "right";
  className?: string;
}) => {
  return (
    <Tooltip>
      <TooltipTrigger className={className} asChild>
        {children}
      </TooltipTrigger>
      <TooltipContent className={"data-[side=right]:slide-in-from-left-1"} side={side ?? "bottom"}>
        {tooltipContent}
      </TooltipContent>
    </Tooltip>
  );
};
