import { HelpCircleIcon } from "lucide-react";
import * as React from "react";

import { SimpleTooltip } from "@/DesignSystem/nanny/SimpleTooltip/SimpleTooltip";
import { EvaluationStatus } from "@/apis/nannyml";
import { FailedIcon, SuccessIcon } from "@/components/Icons";
import { evaluationStatusLabels } from "@/formatters/evaluation";
import { cn } from "@/lib/utils";

const icons: Record<EvaluationStatus, JSX.Element> = {
  [EvaluationStatus.Accepted]: <SuccessIcon />,
  [EvaluationStatus.Rejected]: <FailedIcon />,
  [EvaluationStatus.Ongoing]: <HelpCircleIcon className="text-running" size={20} strokeWidth={1.5} />,
};

const colors: Record<EvaluationStatus, string> = {
  [EvaluationStatus.Accepted]: "text-success",
  [EvaluationStatus.Rejected]: "text-failed",
  [EvaluationStatus.Ongoing]: "text-running",
};

export const EvaluationStatusIcon = ({ status, tooltip = true }: { status: EvaluationStatus; tooltip?: boolean }) =>
  tooltip ? (
    <SimpleTooltip tooltipContent={<EvaluationStatusLabel status={status} />} side="right">
      {icons[status]}
    </SimpleTooltip>
  ) : (
    icons[status]
  );

export const EvaluationStatusLabel = React.forwardRef<
  HTMLSpanElement,
  React.HTMLAttributes<HTMLSpanElement> & {
    status: EvaluationStatus;
  }
>(({ status, className }, ref) => (
  <span ref={ref} className={cn(colors[status], className)}>
    {evaluationStatusLabels[status]}
  </span>
));

export const EvaluationStatusSummary = React.forwardRef<
  HTMLSpanElement,
  React.HTMLAttributes<HTMLSpanElement> & {
    statuses: EvaluationStatus[];
  }
>(({ className, statuses, ...props }, ref) => (
  <span ref={ref} className={cn("inline-flex gap-3", className)} {...props}>
    {statuses.some((status) => status === EvaluationStatus.Accepted) && (
      <EvaluationStatusIcon status={EvaluationStatus.Accepted} tooltip={false} />
    )}
    {statuses.some((status) => status === EvaluationStatus.Rejected) && (
      <EvaluationStatusIcon status={EvaluationStatus.Rejected} tooltip={false} />
    )}
    {statuses.some((status) => status === EvaluationStatus.Ongoing) && (
      <EvaluationStatusIcon status={EvaluationStatus.Ongoing} tooltip={false} />
    )}
  </span>
));
