import { PropsWithChildren, useMemo } from "react";
import { createStore } from "zustand";
import { persist } from "zustand/middleware";

import { SelectCheckboxList } from "@/components/dashboard/SelectCheckbox/SelectCheckboxList";
import { BayesianPlotDataset, BayesianPlotElements, BayesianPlotType } from "@/constants/bayesian";
import { bayesianPlotDatasetLabels, bayesianPlotElementLabels, bayesianPlotTypeLabels } from "@/formatters/bayesian";

import {
  BayesianPlotConfig,
  BayesianPlotConfigContext,
  BayesianPlotConfigStore,
  useBayesianPlotConfig,
} from "./BayesianPlotConfig.context";

const defaultConfig: BayesianPlotConfig = {
  datasets: Object.values(BayesianPlotDataset),
  types: Object.values(BayesianPlotType),
  elements: Object.values(BayesianPlotElements),
};

export const BayesianPlotConfigContextProvider = ({
  children,
  storeName,
}: PropsWithChildren<{
  storeName: string;
}>) => {
  const store = useMemo(
    () =>
      createStore(
        persist<BayesianPlotConfigStore>(
          (set) => ({
            ...defaultConfig,
            setPlotConfig: set,
          }),
          {
            name: `EvaluationPlotConfigStore.${storeName}`,
            merge: (persisted, initial) => {
              const { types, ...rest } = persisted as BayesianPlotConfig;
              return {
                ...initial,
                ...rest,
                // Filter out any invalid plot types (as a result of renames)
                types: types.filter((type) => Object.values(BayesianPlotType).includes(type)),
              };
            },
          }
        )
      ),
    [storeName]
  );

  return <BayesianPlotConfigContext.Provider value={store}>{children}</BayesianPlotConfigContext.Provider>;
};

export const BayesianPlotDatasetsConfig = () => {
  const { datasets, setPlotConfig } = useBayesianPlotConfig();

  const setSelectedDatasets = (updateDatasets: (currentDatasets: string[]) => string[]) => {
    setPlotConfig({ datasets: updateDatasets(datasets) as BayesianPlotDataset[] });
  };

  return (
    <SelectCheckboxList
      values={Object.values(BayesianPlotDataset)}
      selectedValues={datasets}
      setSelectedValues={setSelectedDatasets}
      showSearch={false}
      names={bayesianPlotDatasetLabels}
    />
  );
};

export const BayesianPlotTypesConfig = () => {
  const { types, setPlotConfig } = useBayesianPlotConfig();

  const setSelectedPlotTypes = (updatePlotTypes: (currentPlotTypes: string[]) => string[]) => {
    setPlotConfig({ types: updatePlotTypes(types) as BayesianPlotType[] });
  };

  return (
    <SelectCheckboxList
      values={Object.values(BayesianPlotType)}
      selectedValues={types}
      setSelectedValues={setSelectedPlotTypes}
      showSearch={false}
      names={bayesianPlotTypeLabels}
    />
  );
};

export const BayesianPlotElementsConfig = () => {
  const { elements, setPlotConfig } = useBayesianPlotConfig();

  const setSelectedElements = (updateElements: (currentElements: string[]) => string[]) => {
    setPlotConfig({ elements: updateElements(elements) as BayesianPlotElements[] });
  };

  return (
    <SelectCheckboxList
      values={Object.values(BayesianPlotElements)}
      selectedValues={elements}
      setSelectedValues={setSelectedElements}
      showSearch={false}
      names={bayesianPlotElementLabels}
    />
  );
};
