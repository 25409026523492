export const splitLines = (text: string) => text.split("\n");

export const splitPascalCase = (word: string) => {
  let wordRe = /($[a-z])|[A-Z][^A-Z]+/g;
  return word.match(wordRe)?.join(" ") ?? "";
};

export const capitalizeWords = (text: string) => {
  return text
    .replaceAll("_", " ")
    .split(" ")
    .map((word) => word[0]?.toUpperCase() + word.slice(1))
    .join(" ");
};
