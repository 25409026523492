import { cva, VariantProps } from "class-variance-authority";
import React from "react";

import { cn } from "@/lib/utils";

const buttonGroupStyle = cva(["flex flex-row gap-0 [&>*]:bg-dark", "[&>[aria-checked='true']]:bg-dark/80"], {
  variants: {
    size: {
      small: "[&>*]:py-1.5 [&>*]:px-3",
      medium: "[&>*]:py-3 [&>*]:px-5",
    },
    border: {
      true: [
        "[&>*]:border [&>:not(:last-of-type)]:border-r-0 [&>*]:rounded-none [&>*]:border-gray-500",
        "[&>:first-of-type]:rounded-l-lg [&>:last-of-type]:rounded-r-lg",
      ],
      false: "",
    },
  },
  defaultVariants: {
    size: "medium",
    border: true,
  },
});

type ButtonGroupProps = React.HTMLAttributes<HTMLDivElement> & {
  border?: boolean;
  size?: VariantProps<typeof buttonGroupStyle>["size"];
};

export const ButtonGroup = React.forwardRef<HTMLDivElement, ButtonGroupProps>(
  ({ className, border, size, ...props }, ref) => (
    <div ref={ref} className={cn(buttonGroupStyle({ border, size }), className)} {...props} />
  )
);
