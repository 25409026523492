import * as PopoverPrimitive from "@radix-ui/react-popover";
import React from "react";

import { cn } from "@/lib/utils";

export const Popover = PopoverPrimitive.Root;

export const PopoverTrigger = PopoverPrimitive.Trigger;
export const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      className={cn(
        "bg-dark border border-slate-500 rounded-md text-pale shadow-xl shadow-slate-800",
        "w-[var(--radix-popover-trigger-width)] max-h-[var(--radix-popover-content-available-height)] overflow-auto",
        className
      )}
      {...props}
      ref={ref}
    />
  </PopoverPrimitive.Portal>
));
