import { useQuery } from "@apollo/client";
import _ from "lodash";
import { useParams } from "react-router-dom";

import { gql } from "@/apis/nannyml";
import { RadioGroup, RadioGroupLabeledItem } from "@/components/RadioGroup";
import { AlertStatusFilter } from "@/constants/enums";
import { exceedsThreshold } from "@/domains/threshold";
import { alertStatusFilterLabels } from "@/formatters/filters";
import { calculateResultThreshold } from "@/hooks/monitoring";

import { ResultFilterType, useResultFilter, useResultFilterConfig } from "./ResultFilters.hooks";

const getKpmAlertsQuery = gql(/* GraphQL */ `
  query GetKpmAlerts($modelId: Int!) {
    monitoring_model(id: $modelId) {
      id
      kpm {
        results(segments: [null]) {
          ...TimeSeriesResultThreshold
          data {
            value
          }
        }
      }
    }
  }
`);

const alertFilters: Record<AlertStatusFilter, (result: ResultFilterType, refAlerts: Boolean[]) => Boolean> = {
  [AlertStatusFilter.Everything]: () => true,
  [AlertStatusFilter.AlertInAnyChunk]: (result) => result.data.some(({ hasAlert }) => hasAlert),
  [AlertStatusFilter.AlertInLastChunk]: (result) => result.data.at(-1)?.hasAlert ?? false,
  [AlertStatusFilter.AlertForKeyPerformanceMetric]: (result, perfAlerts) =>
    result.data.some(({ hasAlert }, i) => hasAlert && perfAlerts[i]),
  [AlertStatusFilter.NoAlerts]: (result) => result.data.every(({ hasAlert }) => hasAlert),
};

export const FilterAlertStatus = () => {
  // TODO: This is a hack to get the model ID from the URL. We should probably get this from the context instead
  const { modelId } = useParams();
  const { error, data, called } = useQuery(getKpmAlertsQuery, {
    variables: {
      modelId: parseInt(modelId!),
    },
  });

  const [{ alertStatus }, setFilterConfig] = useResultFilterConfig();

  useResultFilter(
    (resultGroups, { alertStatus = AlertStatusFilter.Everything }) => {
      let alerts: boolean[];
      if (alertStatus === AlertStatusFilter.AlertForKeyPerformanceMetric) {
        if (error) {
          throw new Error("Unable to load KPM results:", error);
        }
        if (!data || !data.monitoring_model) {
          return resultGroups;
        }
        alerts = _.zipWith(
          ...data.monitoring_model.kpm.results.map((result) => {
            const threshold = calculateResultThreshold(result);
            return result.data.map((dp) => exceedsThreshold(dp.value, threshold));
          }),
          (...alerts) => alerts.some((alert) => alert)
        );
      }
      const filter = alertFilters[alertStatus];
      return resultGroups.filter((results) => results.some((result) => filter(result, alerts)));
    },
    [data]
  );

  return (
    <RadioGroup
      className="flex flex-col"
      value={alertStatus}
      onValueChange={(alertStatus) => setFilterConfig({ alertStatus: alertStatus as AlertStatusFilter })}
    >
      {Object.entries(AlertStatusFilter).map(([key, value]) => (
        <RadioGroupLabeledItem key={key} value={value} label={alertStatusFilterLabels[value]} />
      ))}
    </RadioGroup>
  );
};
