import { Alert } from "@/components/Alert";

export const DataRequirements = () => (
  <div className="space-y-4 max-w-[900px] w-full">
    <p>
      This wizard will guide you through the process for creating a new experiment. If you're not familiar with
      NannyML's concept of an experiment dataset, please read{" "}
      <a className="underline" href="https://docs.nannyml.com/cloud/experiments-module/tutorials/data-preparation">
        the data requirements documentation
      </a>{" "}
      first.
    </p>
    <p className="pt-4">Brief overview of data requirements:</p>
    <ul className="list-disc pl-4">
      <li>A metric name column is required to identify the different metrics in the experiment.</li>
      <li>A group name column is required to identify which group an observation belongs to.</li>
      <li>Two 'count' columns are required to define number of successes and fails in each observation.</li>
    </ul>
    <Alert severity="info" title="Note">
      This wizard sets up an experiment and imports the data. It does not set up automatic synchronization with the
      selected data source. Once the experiment is created, you can add more data using the SDK or experiment settings
      page for evaluating the experiment over time.
    </Alert>
  </div>
);
