import { useMutation } from "@apollo/client";
import { useState } from "react";

import { UserNotificationSettings } from "@/apis/nannyml";
import { editUserNotificationSettings as editUserNotificationSettingsMutation } from "@/apis/nannyml/mutations/editUserNotificationSettings/editUserNotificationSettings";
import { getUserSettings } from "@/apis/nannyml/queries/getUserSettings/getUserSettings";
import { Button } from "@/components/common/Button";
import { SimpleCheckbox } from "@/components/common/FormElements/SimpleCheckbox/SimpleCheckbox";
import { SimpleInput } from "@/components/common/FormElements/SimpleInput/SimpleInput";
import { useToast } from "@/components/common/Toast/useToast";
import { cn } from "@/lib/utils";

export const NotificationSettings = ({
  settings,
  email,
}: {
  settings: UserNotificationSettings;
  email?: string | null;
}) => {
  const [editUserNotificationSettings] = useMutation(editUserNotificationSettingsMutation);
  const { resolveWithToast } = useToast();

  const initialRecipient = settings.emailRecipient ?? email ?? "";

  const [emailRecipient, setEmailRecipient] = useState(initialRecipient);

  // data conditions
  const isEmailEnabled = Boolean(settings.emailEnabled);

  const isEmailRecipientNew = emailRecipient !== initialRecipient;

  // todo: implement a email verification function
  const isEmailRecipientValid = emailRecipient && emailRecipient.length > 0;

  // ui conditions

  const isRecipientInputEnabled = isEmailEnabled;

  const isSaveRecipientEmailVisible = isEmailEnabled && isEmailRecipientNew;

  const isSaveRecipientEmailEnabled = isEmailRecipientValid;

  const onChangeEmailMonitoringReports = (enabled: boolean, recipient: string | null) => {
    const promise = editUserNotificationSettings({
      variables: {
        input: {
          emailEnabled: enabled,
          emailRecipient: recipient,
        },
      },
      refetchQueries: [getUserSettings],
    });

    // Display feedback for user via toast
    resolveWithToast(promise, {
      title: enabled ? "Enabling email notifications" : "Disabling email notifications",
      successDescription: "✅ Your preferences have been updated",
      errorDescription: "❌ Failed to update your preferences",
      retry: () => onChangeEmailMonitoringReports(enabled, recipient),
    });
  };

  const onChangeReceiveEmail = (value: boolean) => {
    onChangeEmailMonitoringReports(value, settings.emailRecipient ?? null);
  };

  const onSaveEmailRecipient = () => {
    onChangeEmailMonitoringReports(settings.emailEnabled, emailRecipient);
  };

  return (
    <div className={"flex flex-col gap-6"}>
      <h2 className={"text-2xl font-bold"}>Notifications</h2>

      <div>
        <SimpleCheckbox
          label={"I want to receive monitoring reports via email"}
          name={"emailRunCompleted"}
          containerClassName="flex gap-2"
          value={settings.emailEnabled ?? false}
          onChange={onChangeReceiveEmail}
        />

        <div className="pl-8 pt-2 fcol gap-4">
          <p className="text-sm text-gray-400">
            {isEmailEnabled
              ? "We'll email an overview every time a model analysis completes."
              : "We won't send you any emails."}
          </p>

          <div className={"fcol gap-4"}>
            <SimpleInput
              containerClassName={" fcol gap-1"}
              inputClassName={"w-[300px] bg-dark"}
              fieldName={"recipient_email"}
              value={emailRecipient}
              onChange={setEmailRecipient}
              label={"Recipient address"}
              disabled={!isRecipientInputEnabled}
            />

            {isSaveRecipientEmailVisible ? (
              <Button
                disabled={!isSaveRecipientEmailEnabled}
                cva={{ intent: "action", border: "thin", size: "small" }}
                label={"Save new custom address"}
                onClick={onSaveEmailRecipient}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
