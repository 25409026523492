import { useState } from "react";

import { Button } from "@/components/common/Button";
import { SimpleInput } from "@/components/common/FormElements/SimpleInput/SimpleInput";

export type LoginFormProps = {
  onLogin: (email: string, password: string) => Promise<void>;
};

export const LoginForm = ({ onLogin }: LoginFormProps) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loginActive, setLoginActive] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string | null>(null);

  const onClickLogin = (event: any) => {
    // Prevent form submission
    event.preventDefault();

    if (!email || !password) {
      setLoginError("Please enter email and password");
      return;
    }

    setLoginActive(true);
    setLoginError(null);
    onLogin(email, password)
      .catch((error) => setLoginError(error.message))
      .finally(() => setLoginActive(false));
  };

  return (
    <div className="h-full w-full flex justify-center items-center">
      <div className="bg-background4 min-w-[min(60%,400px)] py-5 px-12 rounded-lg">
        <form className={"flex flex-col gap-5"}>
          <h1 className="font-bold text-xl">Sign in</h1>
          <SimpleInput fieldName="email" label="Email" value={email} onChange={setEmail} />
          <SimpleInput fieldName="password" label="Password" value={password} type="password" onChange={setPassword} />
          <Button
            type="submit"
            label="Login"
            cva={{ intent: "primary" }}
            disabled={loginActive}
            onClick={onClickLogin}
          />
          {loginError ? <p className="text-red-500">{loginError}</p> : null}
        </form>
      </div>
    </div>
  );
};
