import { PlayIcon, TriangleAlertIcon, XCircleIcon } from "lucide-react";
import { Link } from "react-router-dom";

import { Text } from "@/DesignSystem/basic/Text/Text";
import { SimpleTooltip } from "@/DesignSystem/nanny/SimpleTooltip/SimpleTooltip";
import { RunEventType, RunState } from "@/apis/nannyml";
import { ProgressBar } from "@/components/ProgressBar";
import { RelativeTime } from "@/components/RelativeTime";
import { Button } from "@/components/common/Button";
import { formatISODateTime } from "@/lib/dateUtils";
import { cn } from "@/lib/utils";

import { CancellingIcon, FailedIcon, RunningIcon, ScheduledIcon, SuccessIcon } from "./Icons";

export const RunStatusIcon = ({
  run: { state, ranSuccessfully },
  url,
}: {
  run: {
    state: RunState;
    ranSuccessfully: boolean | null;
  };
  url?: string;
}) => {
  let Icon, message;

  if (state === RunState.Scheduled) {
    Icon = ScheduledIcon;
    message = "Run scheduled";
  } else if (state === RunState.Running) {
    Icon = RunningIcon;
    message = "Run in progress";
  } else if (state === RunState.Cancelling) {
    Icon = CancellingIcon;
    message = "Run is being cancelled";
  } else if (ranSuccessfully) {
    Icon = SuccessIcon;
    message = "Run completed without errors";
  } else {
    Icon = FailedIcon;
    message = "Run completed with errors";
  }

  return (
    <SimpleTooltip tooltipContent={<Text>{message}</Text>} side={"right"}>
      {url ? (
        <Link to={url}>
          <Icon />
        </Link>
      ) : (
        <Icon />
      )}
    </SimpleTooltip>
  );
};

export type RunSummary = {
  id: string | number;
  state: RunState;
  ranSuccessfully: boolean | null;
  startedAt: string | null;
  completedAt: string | null;
  scheduledFor: string | null;
};

export type ActiveRunDetails = Omit<RunSummary, "scheduledFor" | "completedAt"> & {
  events: {
    eventType: RunEventType;
    timestamp: string;
    currentStep: number | null;
    nrSteps: number | null;
    description: string | null;
  }[];
};

export const InactiveRunStatus = ({
  className,
  run,
  startRun,
  isStarting,
}: {
  className?: string;
  run: RunSummary | null;
  startRun: () => void;
  isStarting: boolean;
}) => {
  switch (run?.state) {
    case null:
    case undefined:
      return (
        <div className={cn("flex items-center gap-3 text-scheduled", className)}>
          <span>NannyML has not been run yet.</span>
          <RunNowButton onClick={startRun} loading={isStarting} />
        </div>
      );
    case RunState.Scheduled:
      return (
        <div className={cn("flex items-center gap-3 text-scheduled", className)}>
          <span>
            First NannyML run scheduled <RelativeTime value={run.scheduledFor!} />.
          </span>
          <RunNowButton onClick={startRun} loading={isStarting} />
        </div>
      );
    case RunState.Completed:
      return (
        <div
          className={cn("flex items-center gap-3", run.ranSuccessfully ? "text-success" : "text-errorText", className)}
        >
          {run.ranSuccessfully ? (
            <span>
              Last NannyML run completed <RelativeTime value={run.completedAt!} />.
            </span>
          ) : (
            <span>
              Last NannyML run completed with errors <RelativeTime value={run.completedAt!} />.
            </span>
          )}
          <RunNowButton onClick={startRun} loading={isStarting} />
        </div>
      );
    default:
      throw new Error(`Unexpected run state: ${run?.state}`);
  }
};

export const ActiveRunStatus = ({
  className,
  run,
  cancelRun,
  isCancelling,
}: {
  className?: string;
  run?: ActiveRunDetails | null;
  cancelRun: () => void;
  isCancelling: boolean;
}) => {
  const progressEvent = run?.events.findLast((event) => event.eventType === RunEventType.Progress);
  const cancelEvent = run?.events.findLast((event) => event.eventType === RunEventType.Cancelled);
  const latestEvent = run?.events.findLast(
    (event) =>
      event.eventType === RunEventType.Progress ||
      event.eventType === RunEventType.Warning ||
      event.eventType == RunEventType.Error ||
      event.eventType == RunEventType.InfrastructureWarning ||
      event.eventType == RunEventType.InfrastructureError
  );

  return (
    <div className={cn("relative grid grid-cols-[1fr_auto] items-start gap-1 gap-x-4 text-gray-400", className)}>
      <span>Started at {run ? formatISODateTime(new Date(run.startedAt!)) : "..."}</span>
      {run && !cancelEvent && (
        <CancelRunButton className="absolute right-0 -top-4" onClick={cancelRun} loading={isCancelling} />
      )}
      <ProgressBar
        className="col-span-full [& progress-bar]"
        progress={progressEvent ? progressEvent.currentStep! / progressEvent.nrSteps! : 0}
        intent={cancelEvent ? "error" : "progress"}
      />
      {cancelEvent ? (
        <span className="text-warningPale">Cancelling run...</span>
      ) : latestEvent?.eventType === RunEventType.Warning ||
        latestEvent?.eventType === RunEventType.InfrastructureWarning ? (
        <span className="text-warningPale flex gap-2 items-center">
          <TriangleAlertIcon size={20} className="shrink-0" />
          {latestEvent.description}
        </span>
      ) : latestEvent?.eventType === RunEventType.Error ||
        latestEvent?.eventType === RunEventType.InfrastructureError ? (
        <span className="text-errorText flex gap-2 items-center">
          <XCircleIcon size={20} className="shrink-0" />
          {latestEvent.description}
        </span>
      ) : (
        <span>{progressEvent?.description ?? "Starting run..."}</span>
      )}
      <span className="justify-self-end">
        {run && <RelativeTime value={cancelEvent?.timestamp ?? progressEvent?.timestamp ?? run.startedAt!} />}
      </span>
    </div>
  );
};

type ActionButtonProps = {
  className?: string;
  onClick: () => void;
  loading: boolean;
};

const RunNowButton = ({ className, onClick, loading }: ActionButtonProps) => {
  return (
    <Button
      className={cn("rounded-full", className)}
      onClick={onClick}
      cva={{ intent: "chip", size: "small", border: "thin" }}
      disabled={loading}
    >
      Run now
      <PlayIcon size={16} />
    </Button>
  );
};

const CancelRunButton = ({ className, onClick, loading }: ActionButtonProps) => {
  return (
    <Button cva={{ size: "small2", border: "thin" }} className={className} onClick={onClick} disabled={loading}>
      Cancel run
    </Button>
  );
};
