import { useState } from "react";

import { gql, useFragment } from "@/apis/nannyml";
import {
  PerformanceEnableConfig,
  PerformanceEstimatorConfig,
  PerformanceThresholdConfig,
} from "@/components/monitoring/RuntimeConfig";
import { PerformanceConfigProvider } from "@/components/monitoring/RuntimeConfig/PerformanceConfig";
import { thresholdDesc } from "@/formatters/thresholds";

import { EditModelControls, Subtitle, Title, useModelEditor } from "./EditModel";
import { SegmentSelect } from "./SegmentSelect";

const performanceConfigFragment = gql(/* GraphQL */ `
  fragment MonitoringPerformanceConfig on Model {
    kpm {
      metric
      component
    }
  }
`);

export const PerformanceSettings = () => {
  const { model, config, onModelChange, onRuntimeConfigChange } = useModelEditor();
  const [segmentId, setSegmentId] = useState<number>();
  const kpm = useFragment(performanceConfigFragment, model).kpm;

  return (
    <div className="p-4 flex flex-col w-fit max-w-[870px] gap-4">
      <div className="flex flex-col gap-4">
        <Title>Performance metric configuration</Title>
        <Subtitle>
          Configure which performance metrics to analyse. Realized performance uses targets to calculate actual model
          peformance while performance estimation provides insight into model performance when targets are not
          available.
        </Subtitle>
        <PerformanceConfigProvider
          problemType={model.problemType}
          config={config}
          value={model.runtimeConfig}
          kpm={kpm}
          onKpmChange={(kpm) => onModelChange({ kpm })}
          onValueChange={onRuntimeConfigChange}
          classes={model.classes}
        >
          <PerformanceEstimatorConfig />
          <PerformanceEnableConfig />
          <Title>Performance metric thresholds</Title>
          <Subtitle>{thresholdDesc}</Subtitle>
          <SegmentSelect segments={model.segments} onSegmentChange={(segment) => setSegmentId(segment?.id)} />
          <PerformanceThresholdConfig segmentId={segmentId} />
        </PerformanceConfigProvider>
      </div>
      <EditModelControls className="sticky bottom-0 py-4 -mb-4 bg-dark" />
    </div>
  );
};
