import { useMutation } from "@apollo/client";
import { CircleCheckIcon, Loader2Icon, UploadIcon } from "lucide-react";
import React from "react";

import { CacheStorageInput, gql } from "@/apis/nannyml";
import { useApplicationConfiguration } from "@/components/ApplicationConfiguration";
import { alert } from "@/components/Dialog";
import { cn } from "@/lib/utils";

const uploadDataset = gql(/* GraphQL */ `
  mutation UploadDataset($input: Upload!) {
    upload_dataset(file: $input) {
      __typename
      id
    }
  }
`);

export const LocalFile = ({
  className,
  value,
  onChange,
}: {
  className?: string;
  value: Partial<CacheStorageInput> | null | undefined;
  onChange: (value: Partial<CacheStorageInput>) => void;
}) => {
  const appConfig = useApplicationConfiguration();
  const [upload, { loading: isUploading }] = useMutation(uploadDataset);
  const [fileName, setFileName] = React.useState(value?.filename ?? "");
  const [isDragging, setIsDragging] = React.useState(false);

  const handleFile = (files: FileList) => {
    if (files.length > 1) {
      return alert({
        title: "Multiple files selected",
        message: (
          <div className="flex flex-col gap-3">
            <span>Datasets should be provided as a single file.</span>
            <span>Please upload only one file.</span>
          </div>
        ),
        variant: "error",
      });
    } else if (files.length === 0) {
      return;
    }

    const file = files[0];
    if (file.size > appConfig.maxUploadSize) {
      return alert({
        title: "File size exceeded",
        message:
          `File size (${Math.round(file.size / 1024 / 1024)} MB) is bigger than ` +
          `max allowed size (${Math.round(appConfig.maxUploadSize / 1024 / 1024)} MB)`,
        variant: "error",
      });
    }

    // Clear existing file reference before starting upload
    onChange({});
    setFileName(file.name);
    upload({ variables: { input: file } })
      .then(({ data }) => onChange({ id: data?.upload_dataset.id, filename: file.name }))
      .catch(() => onChange({}));
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    handleFile(e.dataTransfer.files);
  };

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      handleFile(files);
    } else {
      setFileName("");
    }
  };

  return (
    <div className={cn("flex flex-col gap-6 min-w-[55ch]", className)}>
      <div
        className={cn(
          "relative border-2 border-dashed border-gray-400 hover:bg-slate-700/50",
          "rounded-xl w-full h-72 flex flex-col justify-center items-center gap-2",
          "focus-within:outline focus-within:outline-offset-2",
          isDragging && "bg-slate-700/50"
        )}
        onDragEnter={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
        onDragOver={(e) => e.preventDefault()}
        onDrop={onDrop}
      >
        <input
          className="absolute inset-0 opacity-0 hover:cursor-pointer"
          accept={".csv,.parquet,.pq"}
          type="file"
          onChange={onSelectFile}
        />
        {isUploading ? <Loader2Icon size={32} className="animate-spin" /> : <UploadIcon size={32} />}
        <span>{"Drag your file here or click to browse"}</span>
        <div className="flex gap-2 items-center">
          {fileName && <span className="text-gray-400">{fileName}</span>}
          {value?.id && <CircleCheckIcon size={16} className="text-confirmationPale" />}
        </div>
      </div>
    </div>
  );
};

export const localFileSummary = (value: CacheStorageInput) => value.filename ?? "";
