import { VariantProps, cva } from "class-variance-authority";
import { AlertTriangleIcon, InfoIcon, XCircleIcon } from "lucide-react";
import * as React from "react";

import { cn } from "@/lib/utils";

const alertStyle = cva(["flex flex-col rounded-md border"], {
  variants: {
    severity: {
      info: ["border-infoPale"],
      warning: ["border-warningPale"],
      error: ["border-errorText"],
    },
    size: {
      small: ["p-2"],
      medium: ["p-5"],
    },
  },
  defaultVariants: {
    severity: "info",
    size: "medium",
  },
});

const titleStyle = cva(["flex items-center gap-2 mb-2 font-bold"], {
  variants: {
    severity: {
      info: ["text-infoPale"],
      warning: ["text-warningPale"],
      error: ["text-errorText"],
    },
  },
});

const icons = {
  info: InfoIcon,
  warning: AlertTriangleIcon,
  error: XCircleIcon,
};

export type AlertProps = React.PropsWithChildren<VariantProps<typeof alertStyle>> & {
  className?: string;
  title: string;
};

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  ({ children, className, severity, size, title, ...props }, ref) => {
    const Icon = icons[severity ?? "info"];
    return (
      <div ref={ref} className={cn(alertStyle({ severity, size }), className)} {...props}>
        <p className={cn(titleStyle({ severity }))}>
          <Icon size={24} />
          {title}
        </p>
        {children}
      </div>
    );
  }
);
