import { ObjectValues } from "@/constants/enums";

export const ConceptShiftMetricsOptions = {
  ROC_AUC: "ROC_AUC",
  F1: "F1",
  Precision: "PRECISION",
  Recall: "RECALL",
  Specificity: "SPECIFICITY",
  Accuracy: "ACCURACY",
  AveragePrecision: "AVERAGE_PRECISION",
  Magnitude: "MAGNITUDE",
} as const;

export type TypeConceptShiftMetricsOptions = ObjectValues<typeof ConceptShiftMetricsOptions>;

export const ConceptShiftMetricsOptionsValues = Object.values(ConceptShiftMetricsOptions);
