import { SearchXIcon } from "lucide-react";

import { InputWithClearButton } from "@/DesignSystem/shadcn/Input/Input";
import { CreateExperimentInput, ExperimentMetricConfigInput, PerformanceMetric } from "@/apis/nannyml";
import { RadioGroup, RadioGroupItem } from "@/components/RadioGroup";
import { RopeInput } from "@/components/RopeInput";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/Table";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";

type InputArgs = {
  kem?: string;
  config?: {
    metrics?: Array<ExperimentMetricConfigInput> | null;
  };
};

type ChangeArgs = {
  kem: PerformanceMetric;
  config: {
    metrics: Array<ExperimentMetricConfigInput>;
  };
};

export const ExperimentMetricConfig = ({
  value: { kem, config: { metrics, ...otherConfig } = {} },
  onChange,
}: {
  value: InputArgs;
  onChange: (change: Partial<ChangeArgs>) => void;
}) => {
  // Helper function for updating metric config
  const setMetricConfig = (metric: string, config: Partial<CreateExperimentInput["config"]["metrics"][0]>) => {
    const idx = metrics!.findIndex((m) => m.metric === metric);
    const metricConfig = { ...metrics![idx], ...config };
    onChange({
      config: {
        ...otherConfig,
        metrics: [...metrics!.slice(0, idx), metricConfig, ...metrics!.slice(idx + 1)],
      },
    });
  };

  return (
    <RadioGroup
      value={kem ?? ""}
      onValueChange={(value) => onChange({ kem: value as PerformanceMetric })}
      className="w-[800px]"
    >
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="whitespace-nowrap">
              KEM
              <InformationModalChip infoName="Key experiment metric" className="p-0 mr-0 inline align-text-bottom" />
            </TableHead>
            <TableHead className="whitespace-nowrap">Meric name</TableHead>
            <TableHead className="whitespace-nowrap">
              Region Of Practical Equivalence (ROPE)
              <InformationModalChip
                infoName="Region of practical equivalence (ROPE)"
                className="p-0 mr-0 inline align-text-bottom"
              />
            </TableHead>
            <TableHead className="whitespace-nowrap">
              Required 95% HDI width
              <InformationModalChip infoName="Required 95% HDI width" className="p-0 mr-0 inline align-text-bottom" />
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {metrics ? (
            metrics.map((metric) => (
              <TableRow key={metric.metric}>
                <TableCell className="text-center">
                  <RadioGroupItem value={metric.metric} />
                </TableCell>
                <TableCell>{metric.metric}</TableCell>
                <TableCell>
                  <RopeInput
                    lowerBound={metric.ropeLowerBound ?? null}
                    upperBound={metric.ropeUpperBound ?? null}
                    onChange={(ropeLowerBound, ropeUpperBound) =>
                      setMetricConfig(metric.metric, {
                        ropeLowerBound: ropeLowerBound!,
                        ropeUpperBound: ropeUpperBound!,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <InputWithClearButton
                    type="number"
                    step="any"
                    required
                    placeholder="Required HDI width"
                    value={metric.hdiWidth ?? ""}
                    onChange={(e) => setMetricConfig(metric.metric, { hdiWidth: parseFloat(e.target.value) })}
                    onBlur={(e) => e.target.reportValidity()}
                    className="hide-arrows"
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} className="border border-gray-600 rounded-b">
                <div className="flex flex-col items-center justify-center gap-4 my-10 text-gray-400">
                  <SearchXIcon size={64} />
                  <span>No metrics found</span>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </RadioGroup>
  );
};
