import { CreateExperimentInput } from "@/apis/nannyml";
import { Divider } from "@/components/Divider";
import { LabeledField } from "@/components/LabeledField";
import { SchemaOverview } from "@/components/Schema";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/Table";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";
import { columnConfig } from "@/domains/experiment";
import { getHdiWidthLabel, getRopeLabel } from "@/formatters/bayesian";
import { experimentTypeLabels } from "@/formatters/experiment";

export const Review = ({ settings }: { settings: CreateExperimentInput }) => (
  <div className="grid grid-cols-1 lg:grid-cols-[1fr_auto] grid-rows-[auto_1fr] gap-8 w-full xl:w-5/6">
    <Panel title="Model details">
      <div className="grid grid-cols-[1fr_auto] gap-4 gap-x-8">
        <LabeledField label="Model name" className="col-span-full">
          {settings.name}
        </LabeledField>
        <LabeledField label="Experiment type">{experimentTypeLabels[settings.experimentType]}</LabeledField>
        <LabeledField
          label={
            <>
              KEM
              <InformationModalChip infoName="Key experiment metric" className="p-0 inline text-current" />
            </>
          }
        >
          {settings.kem}
        </LabeledField>
      </div>
    </Panel>
    <Panel title="Metrics" className="row-span-2">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="whitespace-nowrap">Meric name</TableHead>
            <TableHead className="whitespace-nowrap">
              ROPE
              <InformationModalChip
                infoName="Region of practical equivalence (ROPE)"
                className="p-0 mr-0 inline align-text-bottom"
              />
            </TableHead>
            <TableHead className="whitespace-nowrap">
              Required 95% HDI width
              <InformationModalChip infoName="Required 95% HDI width" className="p-0 mr-0 inline align-text-bottom" />
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {settings.config.metrics.map((metric) => (
            <TableRow key={metric.metric}>
              <TableCell className="whitespace-nowrap">
                {metric.metric}
                {metric.metric === settings.kem && <sup className="ml-1">KEM</sup>}
              </TableCell>
              <TableCell>{getRopeLabel(metric, metric)}</TableCell>
              <TableCell>{getHdiWidthLabel(metric)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Panel>
    <Panel title="Schema">
      <SchemaOverview
        className="!grid-cols-[repeat(auto-fit,minmax(150px,1fr))]"
        schema={settings.dataSource.columns}
        columnConfig={columnConfig}
      />
    </Panel>
  </div>
);

const Panel = ({ title, children, className }: { title: string; children?: React.ReactNode; className?: string }) => (
  <div className={className}>
    <h3 className="text-lg font-semibold col-span-full">{title}</h3>
    <Divider className="col-span-full bg-gray-600" />
    {children}
  </div>
);
