import { gql } from "@/apis/nannyml";

export const getModelsOverview = gql(/* GraphQL */ `
  query GetModelsOverview {
    monitoring_models {
      id
      name
      problemType

      kpm {
        results(segments: [null]) {
          ...MonitoringResultValue
        }
      }

      summaryResults: results(filter: { tags: ["summary"] }) {
        ...MonitoringResultValue
      }

      latestRun {
        state
        ranSuccessfully
      }
    }
  }
`);
