export const Welcome = ({ newInstance }: { newInstance: boolean }) => (
  <div className="text-center mb-20">
    <img src="/nannyml-logo.svg" className="mx-auto h-30 mb-20" />
    {newInstance ? (
      <>
        <h1 className="text-2xl my-10">Welcome to your NannyML Cloud instance!</h1>
        <p>
          This wizard will walk you through the setup process. Click <i>Next</i> to continue.
        </p>
      </>
    ) : (
      <>
        <h1 className="text-2xl my-10">Change settings for your NannyML Cloud instance</h1>
        <p>This wizard allows you to change application-level settings that will affect all users.</p>
        <p>Please note an invalid configuration may lock you out of the application.</p>
        <p className="mt-3">
          Click <i>Next</i> to continue.
        </p>
      </>
    )}
  </div>
);
