import { useMemo, useState } from "react";

import { commandScore } from "@/lib/commandScore";

export const useSearchList = <ListItem>(
  items: ListItem[],
  filterBy: keyof ListItem | ((item: ListItem) => string),
  enableRawResults?: boolean
) => {
  const [search, setSearch] = useState("");

  const filterResults = (searchValue: string) => {
    return items.filter((item) => {
      const itemValue = typeof filterBy === "function" ? filterBy(item) : item[filterBy];

      if (typeof itemValue === "string") {
        const score = commandScore(itemValue, searchValue);

        return score > 0;
      } else {
        return false;
      }
    });
  };

  const results = useMemo(() => {
    return filterResults(search);
  }, [items, search]);

  const rawResults = enableRawResults ? filterResults(search) : [];

  return {
    search,
    setSearch,
    results,
    rawResults,
  };
};
