import { ResultOf } from "@graphql-typed-document-node/core";
import { MinusIcon, PlusIcon } from "lucide-react";
import { ReactNode } from "react";

import { Text } from "@/DesignSystem/basic/Text/Text";
import { FragmentType, ResultRefInput, gql, useFragment } from "@/apis/nannyml";
import { Dialog, DialogContent, DialogTrigger } from "@/components/Dialog";
import { ResultPlot } from "@/components/monitoring/ResultPlot";

import { MetricCard } from "./MetricCard";

const summarySectionResultFragment = gql(/* GraphQL */ `
  fragment SummarySectionResult on TimeSeriesResult {
    id
    ...MetricCard
    ...ResultPlot
  }
`);

type SummarySectionFragment = FragmentType<typeof summarySectionResultFragment>;

export const SummarySection = ({
  title,
  detailsLink,
  summaryResults,
  plotResults,
  editSectionComponent,
  onPlotOpenChange,
  onResultRemove,
  flags,
}: {
  title: string;
  detailsLink?: string;
  summaryResults: SummarySectionFragment[];
  plotResults: SummarySectionFragment[];
  editSectionComponent?: ReactNode;
  onPlotOpenChange: (resultId: string, open: boolean) => void;
  onResultRemove: (resultRef: ResultRefInput, resultId: string) => void;
  flags?: Record<string, (result: ResultOf<typeof summarySectionResultFragment>) => boolean>;
}) => {
  return (
    <div className="flex flex-col gap-1 py-2">
      <Text size="medium2" className="border-b border-disabledGray">
        {title}
      </Text>

      {/* Subsections */}
      <div className="grid grid-cols-[repeat(auto-fill,250px)] gap-4 pt-2 items-center">
        {summaryResults.map((resultFragment) => {
          const result = useFragment(summarySectionResultFragment, resultFragment);
          return (
            <MetricCard
              className="basis-[250px]"
              key={result.id}
              detailsLink={detailsLink}
              result={result}
              isChartOpen={plotResults.includes(resultFragment)}
              onCardRemove={onResultRemove}
              onChartOpenChange={(open) => onPlotOpenChange(result.id, open)}
              flags={Object.entries(flags || {})
                .filter(([_, predicate]) => predicate(result))
                .map(([key, _]) => key)}
            />
          );
        })}

        <Dialog>
          <div>
            <DialogTrigger asChild>
              <button className="flex gap-4 bg-primaryBg p-4 rounded-xl hover:bg-slate-700/50">
                <PlusIcon />
                <MinusIcon />
              </button>
            </DialogTrigger>
          </div>
          <DialogContent className="max-w-fit max-h-[90%] overflow-auto">{editSectionComponent}</DialogContent>
        </Dialog>
      </div>

      {/* Plots */}
      <div className="divide-y divide-gray-700">
        {plotResults?.map((resultFragment) => {
          const result = useFragment(summarySectionResultFragment, resultFragment);
          return <ResultPlot key={result.id} results={[result]} className="py-4" />;
        })}
      </div>
    </div>
  );
};
