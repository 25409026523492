import { ResultOf } from "@graphql-typed-document-node/core";
import { valueFromAST } from "graphql";
import _ from "lodash";

import {
  DataQualityMetric,
  DataQualityMetricConfigInput,
  FragmentType,
  ProblemType,
  gql,
  useFragment,
} from "@/apis/nannyml";
import { Select, SelectItem } from "@/components/Select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/Table";
import { Checkbox } from "@/components/common/Checkbox/Checkbox";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";
import { dataQualityMetricLabels } from "@/formatters/monitoring";

import { MetricEnableToggle } from "./MetricEnableToggle";
import { ThresholdConfigCells, ThresholdConfigHeaderCells } from "./ThresholdConfig";
import { RuntimeConfigComponentProps, SegmentRuntimeConfigComponentProps } from "./types";

const dataQualityRuntimeConfigFragment = gql(/* GraphQL */ `
  fragment DataQualityRuntimeConfig on RuntimeConfig {
    dataQualityMetrics {
      metric
      lowerValueLimit
      upperValueLimit
      categorical {
        ...IsSupportedConfig
      }
      continuous {
        ...IsSupportedConfig
      }
      targets {
        ...IsSupportedConfig
      }
      predictions {
        ...IsSupportedConfig
      }
      predictedProbabilities {
        ...IsSupportedConfig
      }
    }
  }
`);

const useDataQualityEdit = ({
  config: configFragment,
  value: { dataQualityMetrics },
  onValueChange,
}: RuntimeConfigComponentProps<FragmentType<typeof dataQualityRuntimeConfigFragment>>) => {
  const config = useFragment(dataQualityRuntimeConfigFragment, configFragment);
  const configMetrics = _.keyBy(config.dataQualityMetrics, "metric");

  const onMetricChange = (metric: DataQualityMetric, change: Partial<DataQualityMetricConfigInput>) => {
    onValueChange({
      dataQualityMetrics: (dataQualityMetrics ?? []).map((m) => (m.metric === metric ? { ...m, ...change } : m)),
    });
  };

  return {
    config,
    configMetrics,
    onMetricChange,
  };
};

export const DataQualityConfig = (
  props: RuntimeConfigComponentProps<FragmentType<typeof dataQualityRuntimeConfigFragment>>
) => {
  const { configMetrics, onMetricChange } = useDataQualityEdit(props);

  return (
    <Table className={props.className}>
      <TableHeader>
        <TableRow>
          <TableHead>Method</TableHead>
          <DataQualityConfigHeadercells problemType={props.problemType} />
          <ThresholdConfigHeaderCells />
        </TableRow>
      </TableHeader>
      <TableBody>
        {props.value.dataQualityMetrics.map((value) => (
          <TableRow key={value.metric}>
            <MetricCell metric={value.metric} />
            <DataQualityConfigCells
              problemType={props.problemType}
              config={configMetrics[value.metric]}
              value={value}
              onValueChange={(change) => onMetricChange(value.metric, change)}
            />
            <DataQualityThresholdCells
              config={configMetrics[value.metric]}
              value={value}
              onValueChange={(change) => onMetricChange(value.metric, change)}
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const DataQualityEnableConfig = (
  props: RuntimeConfigComponentProps<FragmentType<typeof dataQualityRuntimeConfigFragment>>
) => {
  const { configMetrics, onMetricChange } = useDataQualityEdit(props);

  return (
    <Table className={props.className}>
      <TableHeader>
        <TableRow>
          <TableHead>Method</TableHead>
          <DataQualityConfigHeadercells problemType={props.problemType} />
        </TableRow>
      </TableHeader>
      <TableBody>
        {props.value.dataQualityMetrics.map((value) => (
          <TableRow key={value.metric}>
            <MetricCell metric={value.metric} />
            <DataQualityConfigCells
              problemType={props.problemType}
              config={configMetrics[value.metric]}
              value={value}
              onValueChange={(change) => onMetricChange(value.metric, change)}
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const DataQualityThresholdConfig = (
  props: SegmentRuntimeConfigComponentProps<FragmentType<typeof dataQualityRuntimeConfigFragment>>
) => {
  const { configMetrics, onMetricChange } = useDataQualityEdit(props);

  return (
    <Table className={props.className}>
      <TableHeader>
        <TableRow>
          <TableHead>Method</TableHead>
          <ThresholdConfigHeaderCells />
        </TableRow>
      </TableHeader>
      <TableBody>
        {props.value.dataQualityMetrics.map((value) => (
          <TableRow key={value.metric}>
            <MetricCell metric={value.metric} />
            <DataQualityThresholdCells
              segmentId={props.segmentId}
              config={configMetrics[value.metric]}
              value={value}
              onValueChange={(change) => onMetricChange(value.metric, change)}
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const MetricCell = ({ metric }: { metric: DataQualityMetric }) => (
  <TableCell className="whitespace-nowrap">{dataQualityMetricLabels[metric]}</TableCell>
);

const DataQualityConfigHeadercells = ({ problemType }: { problemType: ProblemType }) => (
  <>
    <TableHead className="text-center">Target</TableHead>
    <TableHead className="text-center">Predictions</TableHead>
    {problemType !== ProblemType.Regression && <TableHead className="text-center">Predicted probabilities</TableHead>}
    <TableHead className="text-center">Continuous features</TableHead>
    <TableHead className="text-center">Categorical features</TableHead>
    <TableHead className="text-center">
      <div className="flex items-center">
        Normalize <InformationModalChip className="ml-1" infoName="Normalization" />
      </div>
    </TableHead>
  </>
);

const DataQualityConfigCells = ({
  problemType,
  config,
  value,
  onValueChange,
}: {
  problemType: ProblemType;
  config: ResultOf<typeof dataQualityRuntimeConfigFragment>["dataQualityMetrics"][0];
  value: DataQualityMetricConfigInput;
  onValueChange: (change: Partial<DataQualityMetricConfigInput>) => void;
}) => (
  <>
    <TableCell>
      <MetricEnableToggle
        config={config.targets}
        value={value.enabledTargets ?? false}
        onValueChange={(enabledTargets) => onValueChange({ enabledTargets })}
      />
    </TableCell>
    <TableCell>
      <MetricEnableToggle
        config={config.predictions}
        value={value.enabledPredictions ?? false}
        onValueChange={(enabledPredictions) => onValueChange({ enabledPredictions })}
      />
    </TableCell>
    {problemType !== ProblemType.Regression && (
      <TableCell>
        <MetricEnableToggle
          config={config.predictedProbabilities}
          value={value.enabledPredictedProbabilities ?? false}
          onValueChange={(enabledPredictedProbabilities) => onValueChange({ enabledPredictedProbabilities })}
        />
      </TableCell>
    )}
    <TableCell>
      <MetricEnableToggle
        config={config.continuous}
        value={value.enabledContinuous ?? false}
        onValueChange={(enabledContinuous) => onValueChange({ enabledContinuous })}
      />
    </TableCell>
    <TableCell>
      <MetricEnableToggle
        config={config.categorical}
        value={value.enabledCategorical ?? false}
        onValueChange={(enabledCategorical) => onValueChange({ enabledCategorical })}
      />
    </TableCell>
    <TableCell>
      <div className="flex justify-center items-center">
        <Select
          className="w-28"
          disabled={!isMetricEnabled}
          value={value.normalize ? "true" : "false"}
          onValueChange={(value) => onValueChange({ normalize: value === "true" ? true : false })}
        >
          <SelectItem value="true">Enabled</SelectItem>
          <SelectItem value="false">Disabled</SelectItem>
        </Select>
      </div>
    </TableCell>
  </>
);

const DataQualityThresholdCells = (props: {
  config: ResultOf<typeof dataQualityRuntimeConfigFragment>["dataQualityMetrics"][0];
  segmentId?: number;
  value: DataQualityMetricConfigInput;
  onValueChange: (change: Partial<DataQualityMetricConfigInput>) => void;
}) => <ThresholdConfigCells disabled={!isMetricEnabled(props.value)} {...props} />;

const isMetricEnabled = (value: DataQualityMetricConfigInput): boolean =>
  value.enabledCategorical ||
  value.enabledContinuous ||
  value.enabledPredictedProbabilities ||
  value.enabledPredictions ||
  value.enabledTargets;
