import { useSuspenseQuery } from "@apollo/client";
import { ColumnDef } from "@tanstack/react-table";
import { Loader2Icon, SearchIcon, SearchXIcon } from "lucide-react";
import { Suspense, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

import { SimpleTooltip } from "@/DesignSystem/nanny/SimpleTooltip/SimpleTooltip";
import { Input } from "@/DesignSystem/shadcn/Input";
import { Label } from "@/DesignSystem/shadcn/Label/Label";
import { GetEvaluationModelsQuery, PerformanceMetric, gql } from "@/apis/nannyml";
import { DataTable } from "@/components/DataTable";
import { RunStatusIcon } from "@/components/RunStatus";
import { Button } from "@/components/common/Button";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";
import { EvaluationStatusIcon, EvaluationStatusLabel, EvaluationStatusSummary } from "@/components/evaluation";
import { getHdiLabel } from "@/formatters/bayesian";
import { performanceMetricLabels } from "@/formatters/monitoring";
import { useSearchList } from "@/hooks/useSearchList";
import { cn } from "@/lib/utils";

const getEvaluationModelsQuery = gql(/* GraphQL */ `
  query GetEvaluationModels {
    evaluation_models {
      id
      name
      kpm
      kpmResult {
        modelId
        metric
        status
        latestHdi {
          hdiLower
          hdiUpper
        }
        config {
          hdiWidth
        }
      }
      summaryResults: results {
        modelId
        metric
        status
      }
      latestRun {
        state
        ranSuccessfully
      }
    }
  }
`);

export const EvaluationModelHub = () => (
  <div className="h-full overflow-auto scrollbar-thin">
    <Suspense
      fallback={
        <div className="flex items-center justify-center h-full gap-2">
          <Loader2Icon className="animate-spin text-highlightDeep" size={24} />
          Loading...
        </div>
      }
    >
      <EvaluationModelOverviewTable className="container mx-auto my-4" />
    </Suspense>
  </div>
);

const EvaluationModelOverviewTable = ({ className }: { className?: string }) => {
  const { data } = useSuspenseQuery(getEvaluationModelsQuery, { fetchPolicy: "network-only" });
  const { search, setSearch, results: models } = useSearchList(data.evaluation_models, "name");

  const columns: ColumnDef<GetEvaluationModelsQuery["evaluation_models"][0]>[] = useMemo(
    () => [
      {
        header: "Model name",
        meta: {
          className: "w-full xl:w-2/5 text-center font-bold border-r border-gray-600",
        },
        columns: [
          {
            accessorKey: "name",
            header: "",
            meta: {
              className: "border-r border-gray-600 px-4 py-4",
            },
            cell: ({ row }) => <Link to={`/evaluation/${row.original.id}`}>{row.original.name}</Link>,
          },
        ],
      },
      {
        header: "Key performance metric",
        meta: {
          className: "border-r border-gray-600 text-center font-bold",
        },
        columns: [
          {
            accessorKey: "kpm",
            header: "Name",
            meta: {
              className: "font-normal text-right pr-0 whitespace-nowrap",
            },
            cell: ({ getValue }) => performanceMetricLabels[getValue<PerformanceMetric>()],
          },
          {
            id: "kpmStatus",
            accessorKey: "kpmResult",
            header: "Status",
            meta: {
              className: "font-normal text-center",
            },
            cell: ({ getValue }) => (
              <div className="flex justify-center items-center">
                {getValue()?.status ? <EvaluationStatusIcon status={getValue()?.status} /> : "-"}
              </div>
            ),
          },
          {
            id: "kpmHdi",
            accessorKey: "kpmResult",
            header: "95% HDI",
            meta: {
              className: "border-r border-gray-600 font-normal text-center whitespace-nowrap",
            },
            cell: ({ getValue }) =>
              getValue()?.latestHdi ? getHdiLabel(getValue()?.latestHdi, getValue()?.config) : "-",
          },
        ],
      },
      {
        header: "Summary performance metrics",
        meta: {
          className: "border-r border-gray-600 text-center font-bold whitespace-nowrap",
        },
        columns: [
          {
            accessorKey: "summaryResults",
            header: "",
            meta: {
              className: "border-r border-gray-600 text-center",
            },
            cell: ({ getValue }) => {
              const results = getValue<GetEvaluationModelsQuery["evaluation_models"][0]["summaryResults"]>();
              return results.length ? (
                <SimpleTooltip
                  side="right"
                  tooltipContent={
                    <div>
                      <h3 className="font-bold mb-2">Summary performance metrics</h3>
                      <ul className="list-disc pl-4 text-left">
                        {results.map((result) => (
                          <li key={result.metric}>
                            <span className="pr-2">{performanceMetricLabels[result.metric]}:</span>
                            <EvaluationStatusLabel status={result.status} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                >
                  <EvaluationStatusSummary statuses={results.map((result) => result.status)} />
                </SimpleTooltip>
              ) : (
                "-"
              );
            },
          },
        ],
      },
      {
        id: "runStatus",
        header: () => (
          <div className="flex justify-center">
            Run status
            <InformationModalChip infoName="Run status" />
          </div>
        ),
        meta: {
          className: "text-center font-bold whitespace-nowrap",
        },
        columns: [
          {
            accessorKey: "latestRun",
            header: "",
            cell: ({ getValue }) => (
              <div className="flex justify-center items-center">
                {getValue() ? <RunStatusIcon run={getValue()} /> : "-"}
              </div>
            ),
          },
        ],
      },
    ],
    []
  );

  return (
    <div className={cn("flex flex-col gap-3", className)}>
      <Label className="relative w-[300px] text-slate-400 cursor-text">
        <SearchIcon className="absolute left-3 top-3" size={16} />
        <Input
          className="pl-9 dark:border-gray-600"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search models..."
        />
      </Label>
      <DataTable
        className={cn(
          "border rounded-md border-gray-600 overflow-visible odd:[&_tr]:bg-oddBg",
          "[&_tr:last-child_td:first-child]:rounded-bl-md [&_tr:last-child_td:last-child]:rounded-br-md"
        )}
        columns={columns}
        data={models}
        emptyContent={<NoModelsPlaceholder />}
      />
    </div>
  );
};

const NoModelsPlaceholder = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center my-24 gap-4">
      <SearchXIcon size={128} className="text-gray-400" />
      <h1 className="text-2xl">No models found</h1>
      <p className="max-w-prose">Looks like you haven't added any evaluation models yet. Add one now!</p>
      <Button className="mt-4" cva={{ intent: "primary" }} onClick={() => navigate("/evaluation/add")}>
        Add evaluation model
      </Button>
    </div>
  );
};
