import { ProblemType } from "@/apis/nannyml";
import { ProblemTypeOptions } from "@/domains/model/modelOptions";
import { reverseRecord } from "@/lib/objUtils";

export const MappingProblemTypeWrite = {
  [ProblemTypeOptions.BinaryClassification]: ProblemType.BinaryClassification,
  [ProblemTypeOptions.MulticlassClassification]: ProblemType.MulticlassClassification,
  [ProblemTypeOptions.Regression]: ProblemType.Regression,
};
export const MappingProblemTypeRead = reverseRecord(MappingProblemTypeWrite);
