import { gql } from "@/apis/nannyml";

export const getModelRuns = gql(/* GraphQL */ `
  query GetModelRuns($modelId: Int!) {
    monitoring_model(id: $modelId) {
      runs {
        id
        state
        scheduledFor
        startedAt
        completedAt
        ranSuccessfully
        log
        events {
          id
          calculator
          eventType
          timestamp
          ranSuccessfully
          description
        }
      }
    }
  }
`);
