import { FragmentType, gql, ThresholdInput, useFragment } from "@/apis/nannyml";
import { TableCell, TableHead } from "@/components/Table";
import * as ThresholdInputComponent from "@/components/ThresholdInput";

const metricThresholdConfigFragment = gql(/* GraphQL */ `
  fragment MetricThresholdConfig on MetricConfig {
    __typename
    threshold {
      ...ThresholdDetails
    }
    segmentThresholds {
      segment {
        id
      }
      threshold {
        ...ThresholdDetails
      }
    }
  }
`);

const thresholdDetailsFragment = gql(/* GraphQL */ `
  fragment ThresholdDetails on Threshold {
    __typename
    ... on ConstantThreshold {
      lower
      upper
    }
    ... on StandardDeviationThreshold {
      stdLowerMultiplier
      stdUpperMultiplier
    }
  }
`);

export const convertMetricThresholdsToInput = (metricConfig: FragmentType<typeof metricThresholdConfigFragment>) => {
  const metric = useFragment(metricThresholdConfigFragment, metricConfig);
  return {
    threshold: convertThresholdToInput(metric.threshold),
    segmentThresholds: metric.segmentThresholds.map((s) => ({
      segmentId: s.segment.id,
      threshold: convertThresholdToInput(s.threshold),
    })),
  };
};

const convertThresholdToInput = (
  thresholdConfig: FragmentType<typeof thresholdDetailsFragment> | null
): ThresholdInput | null => {
  const threshold = useFragment(thresholdDetailsFragment, thresholdConfig);
  if (!threshold) {
    return null;
  } else if (threshold.__typename === "ConstantThreshold") {
    return { constant: { lower: threshold.lower, upper: threshold.upper } };
  } else if (threshold.__typename === "StandardDeviationThreshold") {
    return {
      standardDeviation: {
        stdLowerMultiplier: threshold.stdLowerMultiplier,
        stdUpperMultiplier: threshold.stdUpperMultiplier,
      },
    };
  } else {
    return null;
  }
};

export const ThresholdConfigCells = ({
  config,
  ...rest
}: Omit<ThresholdInputComponent.SegmentThresholdInputProps, "segmentId"> & {
  segmentId?: number;
  config: { lowerValueLimit: number | null; upperValueLimit: number | null };
}) => (
  <ThresholdInputComponent.SegmentRoot segmentId={rest.segmentId} {...rest}>
    <TableCell>
      <ThresholdInputComponent.Type />
    </TableCell>
    <TableCell>
      <ThresholdInputComponent.Value
        min={config.lowerValueLimit ?? undefined}
        max={config.upperValueLimit ?? undefined}
      />
    </TableCell>
    {Object.hasOwn(rest, "segmentId") && (
      <TableCell className="w-full">
        <div className="flex items-center gap-2">
          <ThresholdInputComponent.SegmentInfo />
          <ThresholdInputComponent.ClearSegmentOverrideButton />
        </div>
      </TableCell>
    )}
  </ThresholdInputComponent.SegmentRoot>
);

export const ThresholdConfigHeaderCells = ({ useSegments }: { useSegments?: boolean }) => (
  <>
    <TableHead className="whitespace-nowrap">Threshold type</TableHead>
    <TableHead className="whitespace-nowrap">Threshold value</TableHead>
    {useSegments && <TableHead />}
  </>
);
