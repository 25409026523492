import { ColumnType, PerformanceMetric } from "@/apis/nannyml";

import { ColumnConfig } from "./common";

export const evaluationPerformanceMetrics: PerformanceMetric[] = [
  PerformanceMetric.Accuracy,
  PerformanceMetric.F1,
  PerformanceMetric.Precision,
  PerformanceMetric.Recall,
  PerformanceMetric.RocAuc,
];

export const columnConfig: ColumnConfig = {
  types: [
    { type: ColumnType.Target, important: true },
    { type: ColumnType.PredictionScore, important: true },
    { type: ColumnType.Identifier, optional: true, important: true },
    { type: ColumnType.Ignored, optional: true, allowMultiple: true },
  ],
};
