import { useSuspenseQuery } from "@apollo/client";

import { gql } from "@/apis/nannyml";

const getVersionQuery = gql(/* GraphQL */ `
  query GetVersion {
    version {
      applicationVersion
      serverVersion
      products {
        product
        version
      }
    }
  }
`);

export const ApplicationVersion = ({ className }: { className?: string }) => {
  const query = useSuspenseQuery(getVersionQuery);
  const version = query.data.version;

  return <span className={className}>{version.applicationVersion}</span>;
};
