import { gql } from "@/apis/nannyml";

export const getModelSchema = gql(/* GraphQL */ `
  query GetModelSchema($modelId: Int!, $dataSourceFilter: DataSourcesFilter) {
    monitoring_model(id: $modelId) {
      name
      problemType
      dataSources(filter: $dataSourceFilter) {
        name
        hasReferenceData
        hasAnalysisData
        ...DataSourceSchema
      }
    }
  }
`);
