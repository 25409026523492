import { Outlet } from "react-router-dom";

import { TooltipProvider } from "@/DesignSystem/shadcn/Tooltip/Tooltip";
import { Toaster } from "@/components/common/Toast/Toaster";
import { InformationModalProvider } from "@/components/dashboard/InformationModal/InformationModalProvider";

import { PublicApiProvider, SecureApiProvider } from "./apis/nannyml";
import { AuthProvider } from "./apis/nannyml/auth";
import "./assets/index.css";
import { ApplicationConfigurationProvider, useApplicationConfiguration } from "./components/ApplicationConfiguration";

function App() {
  return (
    <div className="App dark bg-primaryBg text-pale">
      <PublicApiProvider>
        <ApplicationConfigurationProvider>
          <InnerApp />
        </ApplicationConfigurationProvider>
      </PublicApiProvider>

      {/* div to put tailwind classes that are not detected by the compiler */}
      {/* this may be due that are placed programmatically and tailwind can not detect them */}
      <div className="hidden basis-2/5 max-h-[120px] placeholder:text-placeholder "></div>
    </div>
  );
}

function InnerApp() {
  const { auth } = useApplicationConfiguration();
  return (
    <AuthProvider authSettings={auth}>
      <SecureApiProvider>
        <InformationModalProvider>
          <TooltipProvider>
            <div className="h-full">
              <Outlet />
              <Toaster />
            </div>
          </TooltipProvider>
        </InformationModalProvider>
      </SecureApiProvider>
    </AuthProvider>
  );
}

export default App;
