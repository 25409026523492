import { Image, Text, Page, Document, StyleSheet, View } from "@react-pdf/renderer";

import { ContentData, Theme } from "./Report.utils";

export const PdfReport = ({ reportContent, theme }: { reportContent: ContentData[]; theme: Theme }) => {
  const styles = StyleSheet.create({
    page: {
      backgroundColor: theme.backgroundColor,
      color: theme.color,
    },
    content: {
      paddingBottom: 80,
      paddingHorizontal: 20,
      fontFamily: theme.font,
    },
    Title: { fontSize: 20, marginBottom: 15, marginHorizontal: 20 },
    "Section Header": { fontSize: 16, marginBottom: 10, marginHorizontal: 20 },
    Paragraph: { fontSize: 12, marginBottom: 7, marginHorizontal: 20 },
    PlotTitle: { fontSize: 10, marginBottom: 7, marginHorizontal: 30 },
    Plot: { marginBottom: 30, marginHorizontal: 30 },
    Logo: { marginLeft: 470, marginTop: 20, marginBottom: 20, width: 50, height: 50 },
  });

  console.log(reportContent);
  return (
    <Document>
      <Page style={styles.page} wrap={true}>
        <Image style={styles.Logo} src={"/nannyml-logo-icon.png"} fixed />
        <View style={styles.content}>
          {reportContent.map((content) => {
            return content.plotOptions ? (
              <View key={content.id} wrap={false} style={styles.Plot}>
                <Text style={styles.PlotTitle}>{content.plotOptions?.plotTitle}</Text>
                <Image src={content.plotOptions?.plotImage}></Image>
              </View>
            ) : (
              <View key={content.id} style={styles[content.label as keyof typeof styles] ?? styles.Paragraph}>
                <Text>{content.data}</Text>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};
