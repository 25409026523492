import { gql } from "@/apis/nannyml";

export const untagResult = gql(/* GraphQL */ `
  mutation UntagResult($input: TagResultInput!) {
    untag_monitoring_result(input: $input) {
      id
      tags
    }
  }
`);
