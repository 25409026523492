export enum BayesianSortOrder {
  Metric = "METRIC",
  Status = "STATUS",
  CurrentHdiWidth = "CURRENT_HDI_WIDTH",
}

export enum BayesianPlotDataset {
  Evaluation = "EVALUATION",
  Reference = "REFERENCE",
}

export enum BayesianPlotElements {
  Rope = "ROPE",
  Legend = "LEGEND",
  Grid = "GRID",
}

export enum BayesianPlotType {
  Progression = "PROGRESSION",
  Distribution = "DISTRIBUTION",
}

// Using an object instead of enum for interchangability - see https://stackoverflow.com/a/63137814
export const BayesianStatus = {
  Accepted: "ACCEPTED",
  Ongoing: "ONGOING",
  Rejected: "REJECTED",
};
export type BayesianStatus = (typeof BayesianStatus)[keyof typeof BayesianStatus];
