import _ from "lodash";
import { EraserIcon } from "lucide-react";
import { CSSProperties, useState } from "react";

import { Segment } from "@/apis/nannyml";
import { LabeledField } from "@/components/LabeledField";
import { Select, SelectItem } from "@/components/Select";
import { Button } from "@/components/common/Button";
import { cn } from "@/lib/utils";

export const SegmentSelect = ({
  className,
  segments,
  onSegmentChange,
}: {
  className?: string;
  segments: Segment[];
  onSegmentChange: (segment: Segment | null) => void;
}) => {
  const [column, setColumn] = useState<string | null>(null);
  const [value, setValue] = useState<string | null>(null);
  const segmentsByColumn = _.groupBy(segments, "segmentColumnName");
  const maxColumnLength = Math.max(18, ...Object.keys(segmentsByColumn).map((column) => column.length));
  const maxSegmentLength = Math.max(16, ...segments.map((segment) => segment.segment.length));
  const hasSegments = segments.length > 0;

  const onSegmentColumnChange = (value: string | null) => {
    setColumn(value);
    setValue(null);
    onSegmentChange(null);
  };

  const onSegmentValueChange = (value: string) => {
    setValue(value);

    const segment = segments.find((segment) => segment.segment === value);
    if (segment) {
      onSegmentChange(segment);
    }
  };

  return (
    <div
      className={cn("flex gap-2", className)}
      style={
        {
          "--max-column-name-length": `${maxColumnLength}ch`,
          "--max-segment-length": `${maxSegmentLength}ch`,
        } as CSSProperties
      }
    >
      <LabeledField label="Segment column">
        <Select
          value={column ?? ""}
          onValueChange={onSegmentColumnChange}
          placeholder="Segment column"
          className="w-[calc(var(--max-column-name-length)+50px)]"
          disabled={!hasSegments}
          onClear={() => onSegmentColumnChange(null)}
        >
          {Object.keys(segmentsByColumn).map((column) => (
            <SelectItem key={column} value={column}>
              {column}
            </SelectItem>
          ))}
        </Select>
      </LabeledField>
      <LabeledField label="Segment value">
        <Select
          value={value ?? ""}
          onValueChange={onSegmentValueChange}
          placeholder="Segment value"
          className="w-[max(var(--max-segment-length)+50px)]"
          disabled={!column}
          onClear={() => onSegmentValueChange("")}
        >
          {column &&
            segmentsByColumn[column]?.map((segment) => (
              <SelectItem key={segment.id} value={segment.segment}>
                {segment.segment}
              </SelectItem>
            ))}
        </Select>
      </LabeledField>
    </div>
  );
};
