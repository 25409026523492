import { useSuspenseQuery } from "@apollo/client";
import { createContext, ReactNode, useContext, useMemo } from "react";

import { gql, ProblemType, SchemaInput } from "@/apis/nannyml";

const getModelContextQuery = gql(/* GraphQL */ `
  query GetModelContext($modelId: Int!) {
    monitoring_model(id: $modelId) {
      id
      name
      problemType
      schema {
        columns {
          name
          columnType
        }
        hasAnalysisTargets
      }
    }
  }
`);

type ModelContext = {
  id?: number;
  name: string;
  problemType: ProblemType;
  schema: SchemaInput;
};

const modelContext = createContext<ModelContext | null>(null);

// Model context provider base props for both existing and pending models
type ModelProviderBaseProps = {
  children?: ReactNode;
};

// Model context provider props for existing models
type ExistingModelProviderProps = {
  modelId: number;
} & ModelProviderBaseProps;

// Model context provider props for pending models
type PendingModelProviderProps = {
  modelId?: undefined;
  name: string;
  problemType: ProblemType;
  schema: SchemaInput;
} & ModelProviderBaseProps;

// Provider that works for both pending and existing models
export const ModelContextProvider = ({ ...props }: ExistingModelProviderProps | PendingModelProviderProps) =>
  props.modelId === undefined ? (
    <PendingModelContextProvider {...props} />
  ) : (
    <ExistingModelContextProvider {...props} />
  );

// Context provider that can be used during model creation before it has an ID
const PendingModelContextProvider = ({ name, problemType, schema, children }: PendingModelProviderProps) => (
  <modelContext.Provider value={{ name, problemType, schema }}>{children}</modelContext.Provider>
);

// Context provider for exisitng models. It will query the model details and provide them to the children
const ExistingModelContextProvider = ({ modelId, children }: ExistingModelProviderProps) => {
  const {
    data: { monitoring_model: model },
  } = useSuspenseQuery(getModelContextQuery, {
    variables: { modelId },
  });

  const ctx = useMemo(
    () =>
      model
        ? {
            id: model.id,
            name: model.name,
            problemType: model.problemType,
            schema: {
              columns: model.schema.columns.map(({ name, columnType }) => ({ name, columnType })),
              hasAnalysisTargets: model.schema.hasAnalysisTargets,
            },
          }
        : null,
    [model]
  );

  return <modelContext.Provider value={ctx}>{children}</modelContext.Provider>;
};

// Hook to access the model context
export const useModelContext = () => {
  const ctx = useContext(modelContext);
  if (!ctx) {
    throw new Error("useModelContext must be used within a ModelContextProvider");
  }
  return ctx;
};
