import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { EditApplicationSettingsInput } from "@/apis/nannyml";
import { editApplicationSettings } from "@/apis/nannyml/mutations/editApplicationSettings/editApplicationSettings";
import { useApplicationConfiguration } from "@/components/ApplicationConfiguration";
import { Wizard, WizardControlButtons, WizardContent, WizardOverview, WizardStep } from "@/components/Wizard";
import { toast } from "@/components/common/Toast/useToast";
import { omitRecursively } from "@/lib/objUtils";
import { WebhookSetup } from "@/pages/ApplicationSetup/Webhook";

import { AuthenticationSetup } from "./Authentication";
import { NotificationSetup } from "./Notifications";
import { UsageStatisticsPermission } from "./UsageStatisticsPermission";
import { Welcome } from "./Welcome";

const steps: WizardStep<EditApplicationSettingsInput>[] = [
  {
    title: "Welcome",
    isCompleted: () => true,
    render: (settings) => <Welcome newInstance={settings.usageStatistics === null} />,
  },
  {
    title: "Authentication",
    subtitle: "Configure how you want to authenticate",
    isCompleted: (settings) => Boolean(settings.auth),
    render: (settings, setSettings) => (
      <AuthenticationSetup
        className="w-full h-full"
        auth={settings.auth ?? null}
        onChange={(auth) => setSettings({ auth })}
      />
    ),
  },
  {
    title: "Notifications",
    subtitle: "Configure receiving email notifications",
    isCompleted: (settings) => Boolean(settings.notifications?.email),
    render: (settings, setSettings) => (
      <NotificationSetup
        className="w-full h-full"
        notifications={settings.notifications?.email ?? null}
        onChange={(email) => {
          setSettings(({ notifications }) => {
            return { notifications: { ...notifications, email } };
          });
        }}
      />
    ),
  },
  {
    title: "Webhooks",
    subtitle: "Configure webhook integration with external parties",
    isCompleted: (settings) => Boolean(settings.notifications?.webhook),
    render: (settings, setSettings) => (
      <WebhookSetup
        className="w-full h-full"
        webhookSettings={settings.notifications?.webhook ?? null}
        onChange={(webhook) =>
          setSettings(({ notifications }) => {
            return { notifications: { ...notifications, webhook } };
          })
        }
      />
    ),
  },
  {
    title: "Permissions",
    subtitle: "Opt in/out of usage statistics",
    isCompleted: (settings) => settings.usageStatistics !== null,
    render: (settings, setSettings) => (
      <UsageStatisticsPermission
        className="flex items-center text-center"
        permissionGranted={settings.usageStatistics ?? null}
        onClickPermissions={(usageStatistics) => setSettings({ usageStatistics })}
      />
    ),
  },
];

export const ApplicationSetup = () => {
  const { maxUploadSize, ...appConfig } = useApplicationConfiguration();
  const navigate = useNavigate();
  const [editApplicationSettingsMutation] = useMutation(editApplicationSettings);

  const onComplete = (settings: EditApplicationSettingsInput) => {
    editApplicationSettingsMutation({
      variables: { settings },
    })
      // Wait for the mutation to update cache before navigating away
      .then(() => new Promise((resolve) => setTimeout(resolve, 0)))
      .then(() => navigate("/"))
      .catch((err) =>
        toast({
          title: "Failed to save application settings.",
          description: err.message,
          variant: "error",
        })
      );
  };

  return (
    <div className="flex items-center justify-center h-full bg-dark">
      <Wizard
        className="rounded-xl border border-gray-600 bg-primaryBg pt-10 items-center overflow-y-auto"
        onComplete={onComplete}
        steps={steps}
        defaultValue={omitRecursively(appConfig, "__typename")}
      >
        <WizardOverview className="mb-10" />
        <WizardContent className="w-4/5" />
        <WizardControlButtons />
      </Wizard>
    </div>
  );
};

ApplicationSetup.url = "/setup";
