import React from "react";
import ReactDOM from "react-dom/client";

import { Divider } from "@/components/Divider";
import { Button } from "@/components/common/Button";

import { Dialog, DialogContent, DialogTitle } from "./Dialog";
import { InfoIcon, TriangleAlertIcon, XCircleIcon } from "lucide-react";

export const renderDialog = <T,>(dialogFn: (resolve: (value: T) => void, reject: any) => JSX.Element): Promise<T> => {
  const container = document.createElement("div");
  const root = ReactDOM.createRoot(container);
  document.body.appendChild(container);

  return (
    new Promise<T>((resolve, reject) => {
      root.render(dialogFn(resolve, reject));
    })
      // Remove dialog & container from DOM once closed
      .finally(() => {
        root.unmount();
        container.remove();
      })
  );
};

type AlertDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  title: String;
  message: React.ReactNode;
  buttonLabel?: String;
  buttonIntent?: Exclude<Parameters<typeof Button>[0]["cva"], undefined>["intent"];
  variant: "info" | "warning" | "error";
};

export const AlertDialog = ({
  isOpen,
  onClose,
  title,
  message,
  variant,
  buttonLabel = "OK",
  buttonIntent = "primary",
}: AlertDialogProps) => (
  <Dialog open={isOpen} onOpenChange={onClose}>
    <DialogContent className="text-pale gap-0 py-3">
      <DialogTitle>{title}</DialogTitle>
      <Divider margin="wide" className="bg-gray-500 -mx-6 w-[calc(100%+48px)]" />
      <div className="flex gap-4 items-center max-w-prose">
        {variant === "error" ? (
          <XCircleIcon className="text-red-500" size={32} aria-hidden="true" />
        ) : variant === "warning" ? (
          <TriangleAlertIcon className="text-warningPale" size={32} aria-hidden="true" />
        ) : variant === "info" ? (
          <InfoIcon className="text-primary" size={32} aria-hidden="true" />
        ) : null}
        {message}
      </div>
      <Divider margin="wide" className="bg-gray-500 -mx-6 w-[calc(100%+48px)]" />
      <div className="flex justify-end gap-3">
        <Button cva={{ intent: buttonIntent, size: "mediumLong" }} onClick={onClose}>
          {buttonLabel}
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);

type AlertOptions = Omit<AlertDialogProps, "isOpen" | "onClose">;

export const alert = (options: AlertOptions) => {
  return renderDialog<void>((resolve) => (
    <AlertDialog isOpen={true} onClose={resolve} {...options} />
  ));
};

type ConfirmDialogProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title: String;
  message: React.ReactNode;
  cancelLabel?: String;
  confirmLabel?: String;
  cancelIntent?: Exclude<Parameters<typeof Button>[0]["cva"], undefined>["intent"];
  confirmIntent?: Exclude<Parameters<typeof Button>[0]["cva"], undefined>["intent"];
};

export const ConfirmDialog = ({
  isOpen,
  onCancel,
  onConfirm,
  title,
  message,
  cancelLabel = "Cancel",
  confirmLabel = "Confirm",
  cancelIntent = "secondary",
  confirmIntent = "primary",
}: ConfirmDialogProps) => (
  <Dialog open={isOpen} onOpenChange={onCancel}>
    <DialogContent className="text-pale gap-0 py-3">
      <DialogTitle>{title}</DialogTitle>
      <Divider margin="wide" className="bg-gray-500 -mx-6 w-[calc(100%+48px)]" />
      {message}
      <Divider margin="wide" className="bg-gray-500 -mx-6 w-[calc(100%+48px)]" />
      <div className="flex justify-end gap-3">
        <Button cva={{ intent: cancelIntent }} onClick={onCancel}>
          {cancelLabel}
        </Button>
        <Button cva={{ intent: confirmIntent }} onClick={onConfirm}>
          {confirmLabel}
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);

type ConfirmOptions = Omit<ConfirmDialogProps, "isOpen" | "onCancel" | "onConfirm">;

export const confirm = (options: ConfirmOptions) => {
  return renderDialog<boolean>((resolve) => (
    <ConfirmDialog isOpen={true} onCancel={() => resolve(false)} onConfirm={() => resolve(true)} {...options} />
  ));
};

type SaveChangesDialogProps = {
  isOpen: boolean;
  onCancel: () => void;
  onDiscard: () => void;
  onSave: () => void;
  title: String;
  message: React.ReactNode;
  cancelLabel?: String;
  discardLabel?: String;
  saveLabel?: String;
  cancelIntent?: Exclude<Parameters<typeof Button>[0]["cva"], undefined>["intent"];
  discardIntent?: Exclude<Parameters<typeof Button>[0]["cva"], undefined>["intent"];
  saveIntent?: Exclude<Parameters<typeof Button>[0]["cva"], undefined>["intent"];
};

export const SaveChangesDialog = ({
  isOpen,
  onCancel,
  onDiscard,
  onSave,
  title,
  message,
  cancelLabel = "Cancel",
  discardLabel = "Discard changes",
  saveLabel = "Save changes",
  cancelIntent = "secondary",
  discardIntent = "reject",
  saveIntent = "primary",
}: SaveChangesDialogProps) => (
  <Dialog open={isOpen} onOpenChange={onCancel}>
    <DialogContent className="text-pale gap-0 py-3">
      <DialogTitle>{title}</DialogTitle>
      <Divider margin="wide" className="bg-gray-500 -mx-6 w-[calc(100%+48px)]" />
      {message}
      <Divider margin="wide" className="bg-gray-500 -mx-6 w-[calc(100%+48px)]" />
      <div className="flex justify-end gap-3">
        <Button cva={{ intent: cancelIntent, size: "mediumLong" }} onClick={onCancel}>
          {cancelLabel}
        </Button>
        <Button cva={{ intent: discardIntent, size: "mediumLong" }} onClick={onDiscard}>
          {discardLabel}
        </Button>
        <Button cva={{ intent: saveIntent, size: "mediumLong" }} onClick={onSave}>
          {saveLabel}
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);

type SaveChangesOptions = Omit<ConfirmDialogProps, "isOpen" | "onCancel" | "onConfirm">;

export const saveChanges = (options: SaveChangesOptions) => {
  return renderDialog<"cancel" | "discard" | "save">((resolve) => (
    <SaveChangesDialog
      isOpen={true}
      onCancel={() => resolve("cancel")}
      onDiscard={() => resolve("discard")}
      onSave={() => resolve("save")}
      {...options}
    />
  ));
};
