import { gql } from "../codegen/__generated";

export const DataSourceHistory = gql(/* GraphQL */ `
  fragment DataSourceHistory on DataSource {
    events {
      eventType
      timestamp
      causedBy {
        name
      }
      nrRows
    }
  }
`);
