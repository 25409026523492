import { useState } from "react";

import { SummaryStatisticsEnableConfig, SummaryStatisticsThresholdConfig } from "@/components/monitoring/RuntimeConfig";
import { thresholdDesc } from "@/formatters/thresholds";

import { EditModelControls, Subtitle, Title, useModelEditor } from "./EditModel";
import { SegmentSelect } from "./SegmentSelect";

export const SummaryStatisticsSettings = () => {
  const { model, config, onRuntimeConfigChange } = useModelEditor();
  const [segmentId, setSegmentId] = useState<number>();

  return (
    <div className="p-4 flex flex-col w-fit max-w-[870px] gap-4">
      <Title>Summary statistics configuration</Title>
      <Subtitle>
        Configure which summary statistics to analyse. These are lightweight statistics that provide insight into the
        data distribution.
      </Subtitle>
      <SummaryStatisticsEnableConfig
        problemType={model.problemType}
        config={config}
        value={model.runtimeConfig}
        onValueChange={onRuntimeConfigChange}
      />
      <Title>Summary statistics thresholds</Title>
      <Subtitle>{thresholdDesc}</Subtitle>
      <SegmentSelect segments={model.segments} onSegmentChange={(segment) => setSegmentId(segment?.id)} />
      <SummaryStatisticsThresholdConfig
        problemType={model.problemType}
        config={config}
        value={model.runtimeConfig}
        onValueChange={onRuntimeConfigChange}
        segmentId={segmentId}
      />
      <EditModelControls className="sticky bottom-0 py-4 -my-4 bg-dark" />
    </div>
  );
};
