import { PerformanceMetric, PerformanceType } from "@/apis/nannyml";
import { ConfusionMatrixComponentsOptions } from "@/domains/performance/performanceComponentsOptions";
import {
  PerformanceMetricOptions,
  PerformanceCalculatorOptions,
  TypePerformanceMetricOptions,
  PerformanceEstimatorOptions,
  TypePerformanceCalculatorOptions,
  TypePerformanceEstimatorOptions,
} from "@/domains/performance/performanceOptions";
import { reverseRecord } from "@/lib/objUtils";

export const PerformanceTypeOptionsMappingWrite = {
  [PerformanceCalculatorOptions.RealizedPerformance]: PerformanceType.Realized,
  [PerformanceEstimatorOptions.Cbpe]: PerformanceType.Cbpe,
  [PerformanceEstimatorOptions.Dle]: PerformanceType.Dle,
  [PerformanceEstimatorOptions.Pape]: PerformanceType.Pape,
} as const;

export const PerformanceTypeOptionsMappingRead = reverseRecord(PerformanceTypeOptionsMappingWrite) as Record<
  PerformanceType,
  TypePerformanceCalculatorOptions | TypePerformanceEstimatorOptions
>;

export const PerformanceMetricOptionsMappingWrite = {
  [PerformanceMetricOptions.ROC_AUC]: PerformanceMetric.RocAuc,
  [PerformanceMetricOptions.F1]: PerformanceMetric.F1,
  [PerformanceMetricOptions.Precision]: PerformanceMetric.Precision,
  [PerformanceMetricOptions.Recall]: PerformanceMetric.Recall,
  [PerformanceMetricOptions.Specificity]: PerformanceMetric.Specificity,
  [PerformanceMetricOptions.Accuracy]: PerformanceMetric.Accuracy,
  [PerformanceMetricOptions.BusinessValue]: PerformanceMetric.BusinessValue,
  [PerformanceMetricOptions.ConfusionMatrix]: PerformanceMetric.ConfusionMatrix,
  [PerformanceMetricOptions.AveragePrecision]: PerformanceMetric.AveragePrecision,
  [PerformanceMetricOptions.MAE]: PerformanceMetric.Mae,
  [PerformanceMetricOptions.MAPE]: PerformanceMetric.Mape,
  [PerformanceMetricOptions.MSE]: PerformanceMetric.Mse,
  [PerformanceMetricOptions.RMSE]: PerformanceMetric.Rmse,
  [PerformanceMetricOptions.MSLE]: PerformanceMetric.Msle,
  [PerformanceMetricOptions.RMSLE]: PerformanceMetric.Rmsle,
} as const;

export const PerformanceMetricOptionsMappingRead = reverseRecord(PerformanceMetricOptionsMappingWrite) as Record<
  PerformanceMetric,
  TypePerformanceMetricOptions
>;

export const MappingResultPerformanceMetricWrite = {
  [PerformanceMetricOptions.ROC_AUC]: "roc_auc",
  [PerformanceMetricOptions.F1]: "f1",
  [PerformanceMetricOptions.Precision]: "precision",
  [PerformanceMetricOptions.Recall]: "recall",
  [PerformanceMetricOptions.Specificity]: "specificity",
  [PerformanceMetricOptions.Accuracy]: "accuracy",
  [PerformanceMetricOptions.BusinessValue]: "business_value",
  [PerformanceMetricOptions.ConfusionMatrix]: "confusion_matrix",
  [PerformanceMetricOptions.AveragePrecision]: "average_precision",
  [PerformanceMetricOptions.MAE]: "mae",
  [PerformanceMetricOptions.MAPE]: "mape",
  [PerformanceMetricOptions.MSE]: "mse",
  [PerformanceMetricOptions.RMSE]: "rmse",
  [PerformanceMetricOptions.MSLE]: "msle",
  [PerformanceMetricOptions.RMSLE]: "rmsle",
};

export const MappingResultPerformanceMetricRead = reverseRecord(MappingResultPerformanceMetricWrite);

export const PerformanceMetricComponentsMappingWrite = {
  ...MappingResultPerformanceMetricWrite,
  [ConfusionMatrixComponentsOptions.TruePositive]: "true_positive",
  [ConfusionMatrixComponentsOptions.TrueNegative]: "true_negative",
  [ConfusionMatrixComponentsOptions.FalsePositive]: "false_positive",
  [ConfusionMatrixComponentsOptions.FalseNegative]: "false_negative",
};

export const PerformanceMetricComponentsMappingRead = reverseRecord(PerformanceMetricComponentsMappingWrite);
