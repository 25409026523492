import { useApolloClient, useMutation } from "@apollo/client";

import { ResultRefInput } from "@/apis/nannyml";
import { tagResult } from "@/apis/nannyml/mutations/tagResult/tagResult";
import { untagResult } from "@/apis/nannyml/mutations/untagResult/untagResult";

export const useTagResult = () => {
  const [addTagMutation] = useMutation(tagResult);
  const [removeTagMutation] = useMutation(untagResult);
  const client = useApolloClient();

  const invalidateCache = ({ modelId }: ResultRefInput) => {
    client.cache.evict({ id: `Model:${modelId}`, fieldName: "results" });
  };

  const addTag = (tagName: string, resultRef: ResultRefInput) => {
    addTagMutation({
      variables: {
        input: {
          resultRef,
          tagName,
        },
      },
    }).then(() => invalidateCache(resultRef));
  };

  const removeTag = (tagName: string, resultRef: ResultRefInput) => {
    removeTagMutation({
      variables: {
        input: {
          resultRef,
          tagName,
        },
      },
    }).then(() => invalidateCache(resultRef));
  };

  const switchTag = (putTag: boolean, tagName: string, resultRef: ResultRefInput) => {
    if (putTag) {
      addTag(tagName, resultRef);
    } else {
      removeTag(tagName, resultRef);
    }
  };

  return {
    addTag,
    removeTag,
    switchTag,
  };
};
