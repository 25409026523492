import { useMutation, useQuery } from "@apollo/client";
import { CopyIcon } from "lucide-react";
import React from "react";

import { Input } from "@/DesignSystem/shadcn/Input";
import { createUserApiToken } from "@/apis/nannyml/mutations/createUserApiToken/createUserApiToken";
import { deleteUserApiToken } from "@/apis/nannyml/mutations/deleteUserApiToken/deleteUserApiToken";
import { getUserApiTokens } from "@/apis/nannyml/queries/getUserApiTokens/getUserApiTokens";
import { DataTable } from "@/components/DataTable";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/Dialog";
import { Button } from "@/components/common/Button";
import { SimpleInput } from "@/components/common/FormElements/SimpleInput/SimpleInput";
import { resolveWithToast, useToast } from "@/components/common/Toast/useToast";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";
import { RequestStateLayout } from "@/components/dashboard/RequestStateLayout/RequestStateLayout";
import { getApiTokensListColumns } from "@/components/settings/ApiTokens/ApiTokensListColumn";
import { useCopyToClipboard } from "@/hooks/clipboard";

export const ApiTokens = () => {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false);
  const [description, setDescription] = React.useState<string | null>(null);
  const [token, setToken] = React.useState<string | undefined>();
  const [wasCopied, setWasCopied] = React.useState(false);
  const copyToClipboard = useCopyToClipboard();

  const { toast } = useToast();
  const { data, loading, error } = useQuery(getUserApiTokens);
  const [createApiTokenMutation] = useMutation(createUserApiToken);
  const [deleteApiTokenMutation] = useMutation(deleteUserApiToken);

  if (loading || error) {
    return (
      <RequestStateLayout
        isLoading={loading}
        hasError={Boolean(error)}
        errorText={error?.message ? `Error: ${error.message}` : undefined}
      />
    );
  }

  const createApiToken = (description: string | null) =>
    createApiTokenMutation({
      variables: {
        input: {
          description,
        },
      },
      refetchQueries: [
        {
          query: getUserApiTokens,
        },
      ],
    });

  const deleteApiToken = (id: string) => {
    const promise = deleteApiTokenMutation({
      variables: {
        input: id,
      },
      refetchQueries: [
        {
          query: getUserApiTokens,
        },
      ],
    });

    resolveWithToast(promise, {
      title: "Deleting API token...",
      successDescription: "✅ API token deleted",
      errorDescription: "❌ API token couldn't be deleted",
      retry: () => deleteApiToken(id),
    });
  };

  const handleCreateApiToken = (e: React.MouseEvent) => {
    e.preventDefault();
    createApiToken(description).then((result) => {
      setToken(result.data?.create_user_api_token?.token);
      setDescription(null);
    });
  };

  const handleCloseCreateDialog = () => {
    // If no token was created, the dialog can safely be closed
    if (!token) {
      setIsCreateDialogOpen(false);
      return;
    }

    // Prevent closing if user hasn't copied the token yet
    if (!wasCopied) {
      toast({
        title: "❌ Token wasn't copied. Closing dialog will make it unavailable.",
        variant: "error",
      });
      return;
    }

    // Close the dialog and clear cached token for security
    setIsCreateDialogOpen(false);
    setToken(undefined);
    setWasCopied(false);
  };

  const handleCopyToken = () => {
    copyToClipboard(token!, {
      successTitle: "✅ Token copied to clipboard",
      errorTitle: "❌ Failed to copy token to clipboard",
    }).then(() => setWasCopied(true));
  };

  return (
    <div className="w-[800px] fcol">
      <div className="flex flex-row mb-2 items-baseline">
        <h2 className="text-2xl font-bold">API tokens</h2>
        <InformationModalChip infoName={"API Token"} />
        <Button
          className="ml-auto"
          onClick={() => setIsCreateDialogOpen(true)}
          cva={{ intent: "primary", size: "small2" }}
        >
          Create API token
        </Button>
      </div>
      <Dialog open={isCreateDialogOpen} onOpenChange={handleCloseCreateDialog}>
        <DialogContent className="text-white space-y-4">
          <DialogHeader>
            <DialogTitle className="text-xl font-bold">Create API token</DialogTitle>
          </DialogHeader>
          <p className="text-sm">
            When you create a new API token we'll show you the token exactly once. Make sure to copy it or you won't be
            able to retrieve its value.
          </p>
          {token ? (
            <div>
              <div className="flex flex-row items-center gap-2">
                <Input className="bg-dark" value={token} disabled={true} />
                <Button onClick={handleCopyToken} cva={{ size: "small2" }}>
                  <CopyIcon />
                </Button>
              </div>
              <p className="text-sm italic pl-2">Make sure to copy the token as we'll only show it to you once.</p>
            </div>
          ) : (
            <form className="flex flex-col gap-4">
              <SimpleInput
                inputClassName="bg-dark"
                containerClassName="gap-1"
                fieldName="apiTokenDescription"
                label="Description"
                value={description ?? ""}
                onChange={setDescription}
                helpText="Provide a description for the token so you know what it's for."
              />
              <Button type="submit" label="Create" cva={{ intent: "primary" }} onClick={handleCreateApiToken} />
            </form>
          )}
        </DialogContent>
      </Dialog>
      <DataTable
        className="odd:[&_tr]:bg-oddBg"
        columns={getApiTokensListColumns({ deleteApiToken })}
        data={data?.current_user?.apiTokens ?? []}
      />
    </div>
  );
};
