import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@radix-ui/react-collapsible";
import { ChevronDownIcon, ChevronUpIcon, XIcon } from "lucide-react";
import React from "react";
import { ImperativePanelHandle } from "react-resizable-panels";

import { Button } from "@/components/common/Button";
import { cn } from "@/lib/utils";

export { type ImperativePanelHandle, Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

export const PanelHeader = ({
  title,
  panelRef,
}: {
  title: string;
  panelRef: React.RefObject<ImperativePanelHandle>;
}) => (
  <div className="flex items-center pb-4">
    <h3 className="text-2xl truncate">{title}</h3>
    <Button className="ml-auto" cva={{ size: "chip", border: "thin" }} onClick={() => panelRef.current?.collapse()}>
      <XIcon width={16} height={16} />
    </Button>
  </div>
);

export const PanelExpandButton = ({
  className,
  children,
  panelRef,
}: {
  className?: string;
  children?: React.ReactNode;
  panelRef: React.RefObject<ImperativePanelHandle>;
}) =>
  !panelRef.current?.isCollapsed() ? null : (
    <Button
      className={cn("dark:border-gray-500 text-white/80 font-light", className)}
      cva={{ size: "small2", border: "thin" }}
      onClick={() => panelRef.current?.expand()}
    >
      {children}
    </Button>
  );

export const PanelItem = ({ title, children }: { title: React.ReactNode; children?: React.ReactNode }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const Icon = isOpen ? ChevronUpIcon : ChevronDownIcon;

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen} className="py-4">
      <div className="flex pb-2 text-slate-200">
        <p className="truncate">{title}</p>
        <CollapsibleTrigger className="ml-auto" asChild>
          <Button cva={{ size: "chip", border: "thin" }}>
            <Icon width={16} height={16} />
          </Button>
        </CollapsibleTrigger>
      </div>
      <CollapsibleContent className="text-slate-400 data-[state=closed]:hidden" forceMount>
        {children}
      </CollapsibleContent>
    </Collapsible>
  );
};
