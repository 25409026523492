import _ from "lodash";

import { useFilter, useFilterConfig } from "@/components/Filters";
import { SortIcon } from "@/components/Icons";
import { Select, SelectItem } from "@/components/Select";
import { Button } from "@/components/common/Button";
import { BayesianSortOrder, BayesianStatus } from "@/constants/bayesian";
import { bayesianSortOrderLabels, bayesianStatusLabels } from "@/formatters/bayesian";

import { useLabeler } from "../Filters/Filters.hooks";

export type FilterBayesianSortOrderItemType = {
  metricName: string;
  status: BayesianStatus;
  latestHdi?: {
    hdiLower: number;
    hdiUpper: number;
  };
};

export type FilterBayesianSortOrderConfigType = {
  sortDirection?: "asc" | "desc";
  sortOrder?: BayesianSortOrder;
};

type SortValueFn = (results: FilterBayesianSortOrderItemType[]) => number | string;

const sorters: Record<BayesianSortOrder, SortValueFn> = {
  [BayesianSortOrder.Status]: ([result]) => result.status,
  [BayesianSortOrder.Metric]: ([result]) => result.metricName,
  [BayesianSortOrder.CurrentHdiWidth]: ([result]) =>
    result.latestHdi ? (result.latestHdi.hdiUpper - result.latestHdi.hdiLower).toPrecision(2) : Number.MAX_VALUE,
};

const defaultSortDirection: Record<BayesianSortOrder, "asc" | "desc"> = {
  [BayesianSortOrder.Status]: "asc",
  [BayesianSortOrder.Metric]: "asc",
  [BayesianSortOrder.CurrentHdiWidth]: "desc",
};

const sortLabelers: Record<BayesianSortOrder, (result: FilterBayesianSortOrderItemType) => string> = {
  [BayesianSortOrder.Status]: (result) => bayesianStatusLabels[result.status],
  [BayesianSortOrder.Metric]: (result) => result.metricName,
  [BayesianSortOrder.CurrentHdiWidth]: (result) =>
    `Current HDI width: ${sorters[BayesianSortOrder.CurrentHdiWidth]([result])}`,
};

export const FilterBayesianSortOrder = (props: { getMetricLabel?: (metricName: string) => string }) => {
  const [{ sortOrder, sortDirection }, setFilterConfig] = useFilterConfig<FilterBayesianSortOrderConfigType>();

  useFilter<FilterBayesianSortOrderItemType, FilterBayesianSortOrderConfigType>(
    (resultGroups, { sortOrder, sortDirection: direction }) => {
      const sorter = sorters[sortOrder ?? BayesianSortOrder.Metric];
      return _.orderBy(resultGroups, sorter, direction);
    }
  );

  useLabeler<FilterBayesianSortOrderItemType, FilterBayesianSortOrderConfigType>(([result], { sortOrder }) => {
    let label = sortLabelers[sortOrder ?? BayesianSortOrder.Metric](result);
    if (sortOrder == BayesianSortOrder.Metric && props.getMetricLabel) {
      label = props.getMetricLabel(result.metricName);
    }
    return label;
  });

  return (
    <div className="flex gap-2">
      <Select
        className="w-40"
        value={sortOrder ?? BayesianSortOrder.Metric}
        onValueChange={(value) =>
          setFilterConfig({
            sortOrder: value as BayesianSortOrder,
            sortDirection: defaultSortDirection[value as BayesianSortOrder],
          })
        }
      >
        {Object.values(BayesianSortOrder).map((option) => (
          <SelectItem key={option} value={option}>
            {bayesianSortOrderLabels[option]}
          </SelectItem>
        ))}
      </Select>
      <Button
        cva={{ size: "small", border: "thin" }}
        onClick={() => setFilterConfig({ sortDirection: sortDirection === "asc" ? "desc" : "asc" })}
      >
        <SortIcon
          direction={sortDirection ?? "asc"}
          type={sortOrder === BayesianSortOrder.CurrentHdiWidth ? "numeric" : "alphabetic"}
        />
      </Button>
    </div>
  );
};
