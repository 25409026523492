import { CSSProperties } from "react";

import { ColumnInput, ColumnType } from "@/apis/nannyml";
import { ComboBox, ComboBoxGroup, ComboBoxItem, ComboBoxSearch } from "@/components/ComboBox";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "@/components/Table";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";

export const ClassMapping = ({
  schema,
  classes,
  onSchemaChange,
}: {
  schema: ColumnInput[];
  classes: string[];
  onSchemaChange: (schema: ColumnInput[]) => void;
}) => {
  const maxClassLength = Math.max(...classes.map((c) => c.length));
  return (
    <div className="flex flex-col gap-4" style={{ "--max-class-length": `${maxClassLength}ch` } as CSSProperties}>
      <Table>
        <TableCaption className="caption-top text-left text-gray-400 mb-4">
          Map predicted probability columns to the matching classes
        </TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead>Column name</TableHead>
            <TableHead className="flex">
              Prediction class name <InformationModalChip infoName="Prediction class name" />
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {schema
            .filter((col) => col.columnType === ColumnType.PredictionScore)
            .map((col) => (
              <TableRow key={col.name}>
                <TableCell>{col.name}</TableCell>
                <TableCell>
                  <ComboBox
                    className="w-[max(calc(var(--max-class-length)+30px),160px)]"
                    values={col.className ? [col.className] : []}
                    placeholder="Select class"
                    onChange={([value]) => {
                      onSchemaChange(schema.map((c) => (c.name === col.name ? { ...c, className: value } : c)));
                    }}
                    required
                    allowMultiple={false}
                  >
                    <ComboBoxSearch />
                    <ComboBoxGroup>
                      {classes.map((c) => (
                        <ComboBoxItem value={c}>{c}</ComboBoxItem>
                      ))}
                    </ComboBoxGroup>
                  </ComboBox>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};
