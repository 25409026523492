import { AuthMode } from "@/apis/nannyml";
import { EmailMode, WebhookMode } from "@/constants/enums";

export const authModeLabels: Record<
  AuthMode,
  {
    label: string;
    description: string;
    longDescription: string;
    recommendation: string;
  }
> = {
  [AuthMode.Anonymous]: {
    label: "No authentication",
    description: "Allow anyone with the link to have unlimited access.",
    longDescription: `Allow anyone with the link to have unlimited access. No user information is stored and
      operations performed by users will be associated with an anonymous user.`,
    recommendation: "Recommended for development and testing using public data.",
  },
  [AuthMode.Local]: {
    label: "Local authentication",
    description: "Require users to log in using a username and password.",
    longDescription: `Require users to log in using a username and password. Usernames and passwords are stored in
      NannyML Cloud and are not shared with any third parties. Operations performed by users will be associated with
      their username.`,
    recommendation: "Recommended when you have a small number of users.",
  },
  [AuthMode.Oidc]: {
    label: "OIDC authentication",
    description: "Require users to log in using their work/school account.",
    longDescription: `Require users to log in using their work/school account. Usernames and passwords are not stored
      in NannyML Cloud. Operations performed by users will be associated with their work/school account.`,
    recommendation: "Recommended for organisations deploying NannyML Cloud to many users.",
  },
};

export const emailModeLabels: Record<
  EmailMode,
  {
    label: string;
    description: string;
    longDescription: string;
    recommendation: string;
  }
> = {
  [EmailMode.Smtp]: {
    label: "SMTP",
    description: "Send email notifications using your SMTP server.",
    longDescription: `Email notifications will be sent to your SMTP server for distribution to registered users. The
      SMTP server must be configured to allow NannyML Cloud to send emails.`,
    recommendation: "Recommended when notifications should not be exposed to a third party.",
  },
  [EmailMode.Saas]: {
    label: "SAAS",
    description: "Send email notifications using service provided by NannyML.",
    longDescription: `Email notifications will be sent to an external email service for distribution to registered
      users. The email service is provided by NannyML. It may retain records of emails sent.`,
    recommendation: "Recommended for easy setup.",
  },
  [EmailMode.Disabled]: {
    label: "Disable notifications",
    description: "Do not send email notifications.",
    longDescription: `Notifications will be disabled. NannyML Cloud will still perform scheduled analyses and provide
      results in the web interface. However, users will not be notified of new results.`,
    recommendation: "Recommended for development and testing.",
  },
};

export const webhookModeLabels: Record<
  WebhookMode,
  {
    label: string;
    description: string;
    longDescription: string;
    recommendation: string;
  }
> = {
  [WebhookMode.Disabled]: {
    label: "Disable webhooks",
    description: "Don't trigger webhooks.",
    longDescription: `Webhooks will be disabled. NannyML Cloud will still perform scheduled analyses and provide
      results in the web interface. However, users will not be notified of new results.`,
    recommendation: "Recommended for development and testing",
  },
  [WebhookMode.Custom]: {
    label: "Custom webhook",
    description: "Trigger a custom webhook.",
    longDescription: `Notifications will trigger sending a POST request to a server located at the configured address. 
      You can provide additional request headers, e.g. for authentication or routing.`,
    recommendation: "Recommended for triggering custom actions in your own environment.",
  },
};
