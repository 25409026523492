import { ConceptShiftMetricOptionsMappingRead } from "@/adapters/conceptShift/conceptShiftMapping";
import { DataQualityChecksMappingRead } from "@/adapters/dataQuality/dataQualityMapping";
import {
  PerformanceMetricComponentsMappingRead,
  PerformanceMetricOptionsMappingRead,
} from "@/adapters/performance/performanceMapping";
import { CalculatorType, MultivariateDriftMethod, UnivariateDriftMethod } from "@/apis/nannyml";
import { ResultKeys } from "@/domains/monitorResults/monitorResults.types";
import { TypeConfusionMatrixComponentsOptions } from "@/domains/performance/performanceComponentsOptions";
import { AnalysisTypeOptions, TypeAnalysisTypeOptions } from "@/domains/plotInputs/PlotInputs.types";
import { ConceptShiftTexts } from "@/formatters/conceptShift/conceptShiftTexts";
import { DataQualityTexts } from "@/formatters/dataQuality/dataQualityTexts";
import {
  ConfusionMatrixAbbreviations,
  ConfusionMatrixTexts,
  PerformanceMetricTexts,
} from "@/formatters/performance/performanceTexts";
import { doubleMapping } from "@/lib/objUtils";
import { capitalizeWords } from "@/lib/stringUtils";
import { isEnumMember } from "@/lib/typesUtils";

import { getMultivariateDriftMethodLabel, getUnivariateDriftMethodLabel } from "../monitoring";

export function getResultKeysUniqueName(resultKeys: ResultKeys) {
  return [resultKeys.analysis, resultKeys.metric, resultKeys.componentName, resultKeys.column].join("-");
}

export const getFormattedComponentName = (componentName: string, abbreviatedComponent?: boolean) => {
  const component = PerformanceMetricComponentsMappingRead[componentName] as TypeConfusionMatrixComponentsOptions;

  const mapped = abbreviatedComponent ? ConfusionMatrixAbbreviations[component] : ConfusionMatrixTexts[component];

  return mapped ?? capitalizeWords(componentName ?? "");
};

export const getMetricName = (metric: string, analysisType?: TypeAnalysisTypeOptions) => {
  const metricNormalized = metric.toUpperCase();

  if (analysisType === AnalysisTypeOptions.CONCEPT_SHIFT) {
    const [, conceptShiftMetricName] = doubleMapping(
      metricNormalized,
      ConceptShiftMetricOptionsMappingRead,
      ConceptShiftTexts
    );

    if (conceptShiftMetricName) {
      return conceptShiftMetricName;
    }
  }

  const [, performanceMetricName] = doubleMapping(
    metricNormalized,
    PerformanceMetricOptionsMappingRead,
    PerformanceMetricTexts
  );

  if (performanceMetricName) {
    return performanceMetricName;
  }

  const [, dataQualityMetricName] = doubleMapping(metricNormalized, DataQualityChecksMappingRead, DataQualityTexts);

  if (dataQualityMetricName) {
    return dataQualityMetricName;
  }

  if (isEnumMember(UnivariateDriftMethod, metricNormalized)) {
    return getUnivariateDriftMethodLabel(metricNormalized);
  }

  if (isEnumMember(MultivariateDriftMethod, metricNormalized)) {
    return getMultivariateDriftMethodLabel(metricNormalized);
  }

  return metric;
};

export function getMetricAndComponentName(
  metric: string,
  componentName?: string | null,
  analysisType?: TypeAnalysisTypeOptions,
  abbreviatedComponent?: boolean
) {
  const formattedMetricName = getMetricName(metric, analysisType);

  const formattedComponentName = getFormattedComponentName(componentName ?? "", abbreviatedComponent);

  const hasComponentName = componentName && componentName !== metric;

  const separator = abbreviatedComponent ? " " : " - ";

  if (hasComponentName) {
    return `${formattedMetricName}${separator}${formattedComponentName}`;
  } else {
    return formattedMetricName;
  }
}

//

// todo: unify this type with `ResultKeys` from `monitorResults.types.ts`
export type NameElements = {
  metric?: string;
  column?: string | null;
  componentName?: string | null;
  analysisType?: TypeAnalysisTypeOptions;
  calculatorType?: CalculatorType;
};

export const getNameWithColumn = (resultInfo: NameElements, defaultColumn?: string) => {
  return [
    resultInfo.column ?? defaultColumn ?? "",
    capitalizeWords(resultInfo.metric ?? ""), // todo: use getMetricName?
  ] as const;
};
