// Reference: https://github.com/uidotdev/usehooks/blob/main/index.js
import { useCallback, useEffect, useSyncExternalStore } from "react";

function dispatchStorageEvent(key: string, newValue: any) {
  window.dispatchEvent(new StorageEvent("storage", { key, newValue }));
}

const getLocalStorageItem = (key: string) => {
  return window.localStorage.getItem(key);
};

const getLocalStorageServerSnapshot = () => {
  throw Error("useLocalStorage is a client-only hook");
};

const useLocalStorageSubscribe = (callback: any) => {
  window.addEventListener("storage", callback);
  return () => window.removeEventListener("storage", callback);
};

const setLocalStorageItem = (key: string, value: any) => {
  const stringifiedValue = JSON.stringify(value);
  window.localStorage.setItem(key, stringifiedValue);
  dispatchStorageEvent(key, stringifiedValue);
};

const removeLocalStorageItem = (key: string) => {
  window.localStorage.removeItem(key);
  dispatchStorageEvent(key, null);
};

export function useLocalStorage<Value>(
  key: string,
  initialValue: Value
): [Value, (v: Value | ((value: Value) => Value)) => void] {
  const getSnapshot = () => getLocalStorageItem(key);

  const store = useSyncExternalStore(useLocalStorageSubscribe, getSnapshot, getLocalStorageServerSnapshot);

  const setState = useCallback(
    (v: Value | ((value: Value) => Value)) => {
      try {
        // @ts-ignore
        const nextState = typeof v === "function" ? v(JSON.parse(store!)) : v;

        if (nextState === undefined || nextState === null) {
          removeLocalStorageItem(key);
        } else {
          setLocalStorageItem(key, nextState);
        }
      } catch (e) {
        console.warn(e);
      }
    },
    [key, store]
  );

  useEffect(() => {
    if (typeof initialValue === "undefined" || initialValue === null) {
      return;
    }

    const encodedCachedValue = getLocalStorageItem(key);
    const cachedValue = encodedCachedValue ? JSON.parse(encodedCachedValue) : null;
    if (cachedValue === null) {
      setLocalStorageItem(key, initialValue);
    } else if (typeof initialValue === "object") {
      if (Object.keys(initialValue).some((key) => !(key in cachedValue))) {
        setLocalStorageItem(key, Object.assign({}, initialValue, cachedValue));
      }
    }
  }, [key, initialValue]);

  return [store ? JSON.parse(store) : initialValue, setState];
}
