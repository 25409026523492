import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";
import { useCallback, useEffect } from "react";

import { FragmentType, RunState, gql, useFragment } from "@/apis/nannyml";
import * as RunStatus from "@/components/RunStatus";
import { useExperimentId, useStartExperimentRun } from "@/hooks/experiment";

const experimentRunStatusFragment = gql(/* GraphQL */ `
  fragment ExperimentRunStatus on ExperimentRun {
    id
    state
    scheduledFor
    startedAt
    completedAt
    ranSuccessfully
  }
`);

const pollExperimentRunStatusQuery = gql(/* GraphQL */ `
  query PollExperimentRunStatus($modelId: Int!) {
    experiment(id: $modelId) {
      latestRun {
        id
        state
        startedAt
        ranSuccessfully
        events {
          eventType
          timestamp
          currentStep
          nrSteps
          description
        }
      }
    }
  }
`);

export const cancelExperimentRunMutation = gql(/* GraphQL */ `
  mutation CancelExperimentRun($runId: Int!) {
    cancel_experiment_run(runId: $runId) {
      id
    }
  }
`);

export const LatestRunStatus = (props: {
  className?: string;
  run: FragmentType<typeof experimentRunStatusFragment> | null;
}) => {
  const run = useFragment(experimentRunStatusFragment, props.run) ?? null;

  if (run?.state === RunState.Running || run?.state === RunState.Cancelling) {
    return <ActiveRunStatus className={props.className} />;
  } else {
    return <InactiveRunStatus className={props.className} run={run} />;
  }
};

const InactiveRunStatus = ({
  className,
  run,
}: {
  className?: string;
  run: ResultOf<typeof experimentRunStatusFragment> | null;
}) => {
  const experimentId = useExperimentId();
  const [startRun, { loading }] = useStartExperimentRun(experimentId);

  return <RunStatus.InactiveRunStatus className={className} run={run} startRun={startRun} isStarting={loading} />;
};

const ActiveRunStatus = ({ className }: { className?: string }) => {
  const modelId = useExperimentId();
  const client = useApolloClient();
  const { data } = useQuery(pollExperimentRunStatusQuery, {
    variables: { modelId: modelId },
    pollInterval: 1000,
  });
  const run = data?.experiment?.latestRun;
  const [cancelExperimentRun, { loading }] = useMutation(cancelExperimentRunMutation);

  const cancelRun = useCallback(() => {
    if (!run?.id) {
      return;
    }
    cancelExperimentRun({ variables: { runId: run.id } });
  }, [run?.id]);

  useEffect(() => {
    if (run?.state === RunState.Completed) {
      // Invalidate the cache for the experiment when the run completes
      client.cache.evict({ id: `Experiment:${modelId}` });
    }
  }, [run?.state]);

  return <RunStatus.ActiveRunStatus className={className} run={run} cancelRun={cancelRun} isCancelling={loading} />;
};
