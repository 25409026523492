import { PlotElements, PlotType } from "@/constants/enums";

export const plotElementLabels: Record<PlotElements, string> = {
  [PlotElements.Alerts]: "Alerts",
  [PlotElements.ConfidenceBands]: "Confidence bands",
  [PlotElements.Legend]: "Legend",
  [PlotElements.Thresholds]: "Thresholds",
  [PlotElements.Grid]: "Grid",
};

export const plotTypeLabels: Record<PlotType, string> = {
  [PlotType.Step]: "Step",
  [PlotType.Line]: "Line",
  [PlotType.Distribution]: "Distribution",
};

export const subplotPerDatasetLabel = "Subplot per dataset";
