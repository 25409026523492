import { Run } from "@/apis/nannyml";
import { isConceptShiftEnabled } from "@/domains/model/modelConditions";
import { ProblemTypeOptions, TypeProblemTypeOptions } from "@/domains/model/modelOptions";
import { MonitorResult } from "@/domains/monitorResults/monitorResults.types";
import { AnalysisTypeOptions } from "@/domains/plotInputs/PlotInputs.types";
import { NameElements } from "@/formatters/results/resultsStrings";
import { any } from "@/lib/listUtils";
import { RecursivePartial } from "@/lib/typesUtilsGQL";

export const calcModelOverview = ({
  model,
  latestRun,
  summaryResults,
  mainResults,
}: {
  model?: {
    id?: number;
    name?: string;
    problemType?: TypeProblemTypeOptions;
  };
  latestRun?: MonitorLastRun;
  summaryResults?: MonitorResult[];
  mainResults?: MonitorResult[];
}): ModelOverviewColumn => {
  const mainResultPerformanceRealized = mainResults?.find(
    (result) => result.keys?.analysis === AnalysisTypeOptions.REALIZED_PERFORMANCE
  );

  const mainResultPerformanceEstimated = mainResults?.find(
    (result) => result.keys?.analysis === AnalysisTypeOptions.ESTIMATED_PERFORMANCE
  );

  const performanceSummaryMetricsRealized =
    summaryResults?.filter((result) => result.keys?.analysis === AnalysisTypeOptions.REALIZED_PERFORMANCE) ?? [];

  const performanceSummaryMetricsEstimated =
    summaryResults?.filter((result) => result.keys?.analysis === AnalysisTypeOptions.ESTIMATED_PERFORMANCE) ?? [];

  const conceptShiftMetrics =
    summaryResults?.filter((result) => result.keys?.analysis === AnalysisTypeOptions.CONCEPT_SHIFT) ?? [];

  const driftSummaryMetrics =
    summaryResults?.filter((result) => result.keys?.analysis === AnalysisTypeOptions.FEATURE_DRIFT) ?? [];

  const dataQualitySummaryMetrics =
    summaryResults?.filter((result) => result.keys?.analysis === AnalysisTypeOptions.DATA_QUALITY) ?? [];

  return {
    modelName: model?.name ?? "",
    modelId: model?.id ?? 0,
    latestRun,

    performanceMainMetricKeys: mainResultPerformanceEstimated?.keys ?? mainResultPerformanceRealized?.keys,

    performanceMainMetricRealizedValue: mainResultPerformanceRealized?.lastDataPoint?.value?.toFixed(2),
    performanceMainMetricRealizedAlerts: mainResultPerformanceRealized?.lastDataPoint?.hasAlert,

    performanceMainMetricEstimatedValue: mainResultPerformanceEstimated?.lastDataPoint?.value?.toFixed(2),
    performanceMainMetricEstimatedAlerts: mainResultPerformanceEstimated?.lastDataPoint?.hasAlert,

    //

    performanceOtherMetricsRealizedResults: performanceSummaryMetricsRealized,
    performanceOtherMetricsRealizedAlerts: resultsHasAnyAlert(performanceSummaryMetricsRealized),

    performanceOtherMetricsEstimatedResults: performanceSummaryMetricsEstimated,
    performanceOtherMetricsEstimatedAlerts: resultsHasAnyAlert(performanceSummaryMetricsEstimated),

    dataDriftResults: driftSummaryMetrics,
    dataDriftAlerts: resultsHasAnyAlert(driftSummaryMetrics),

    dataQualityResults: dataQualitySummaryMetrics,
    dataQualityAlerts: resultsHasAnyAlert(dataQualitySummaryMetrics),

    conceptDriftResults: conceptShiftMetrics,
    conceptDriftAlerts: resultsHasAnyAlert(conceptShiftMetrics),
    conceptDriftEnabled: isConceptShiftEnabled(model?.problemType),
  };
};

function resultsHasAnyAlert(results: MonitorResult[]) {
  return any(results, (item) => Boolean(item.lastDataPoint?.hasAlert), false);
}

type MonitorLastRun = RecursivePartial<{
  state: "SCHEDULED" | "CANCELLING" | "RUNNING" | "COMPLETED";
  ranSuccessfully: boolean;
}>;

export type ModelOverviewColumn = {
  modelName: string;
  modelId: number;
  latestRun?: MonitorLastRun;

  performanceMainMetricKeys?: NameElements;
  performanceMainMetricRealizedValue?: string;
  performanceMainMetricRealizedAlerts?: boolean;
  performanceMainMetricEstimatedValue?: string;
  performanceMainMetricEstimatedAlerts?: boolean;

  performanceOtherMetricsRealizedAlerts: boolean;
  performanceOtherMetricsRealizedResults: MonitorResult[];

  performanceOtherMetricsEstimatedAlerts: boolean;
  performanceOtherMetricsEstimatedResults: MonitorResult[];

  conceptDriftAlerts: boolean;
  conceptDriftResults: MonitorResult[];
  conceptDriftEnabled: boolean;

  dataDriftAlerts: boolean;
  dataDriftResults: MonitorResult[];

  dataQualityAlerts: boolean;
  dataQualityResults: MonitorResult[];
};
