import React from "react";
import { useLocation } from "react-router-dom";
import { createStore, useStore } from "zustand";
import { persist } from "zustand/middleware";

import { PatchStateAction } from "@/lib/typesUtils";

import { FilterContext, FilterFunction, GrouperFunction, LabelFunction } from "./Filters.context";

type BaseFilterProviderProps<T, C> = {
  children: React.ReactNode;
  items: readonly T[];
};

type UncontrolledFilterProviderProps<T, C> = BaseFilterProviderProps<T, C> & {
  defaultFilterConfig?: C;
};

type ControlledFilterProviderProps<T, C> = BaseFilterProviderProps<T, C> & {
  filterConfig: C;
  setFilterConfig: React.Dispatch<React.SetStateAction<C>>;
};

type FilterProviderProps<T, C> = UncontrolledFilterProviderProps<T, C> | ControlledFilterProviderProps<T, C>;

export const FilterProvider = <T, C extends object>(props: FilterProviderProps<T, C>) => {
  const isControlled = "filterConfig" in props && "setFilterConfig" in props;
  const [internalFilterConfig, setInternalFilterConfig] = React.useState<C>(
    (!isControlled && props.defaultFilterConfig) || ({} as C)
  );
  const [filters, setFilters] = React.useState<FilterFunction<T, C>[]>([]);
  const [groupers, setGroupers] = React.useState<GrouperFunction<T, C>[]>([]);
  const [labeler, setLabeler] = React.useState<LabelFunction<T, C> | null>(null);

  const filterConfig = isControlled ? props.filterConfig! : internalFilterConfig;
  const setFilterConfig = isControlled ? props.setFilterConfig! : setInternalFilterConfig;

  // Memoize a function to patch the filter config
  const patchFilterConfig = React.useCallback(
    (value: PatchStateAction<C>) => {
      if (typeof value === "function") {
        setFilterConfig((config) => ({ ...config, ...value(config) }));
      } else {
        setFilterConfig((config) => ({ ...config, ...value }));
      }
    },
    [setFilterConfig]
  );

  // Effect to override filter config from location state
  const location = useLocation();
  React.useEffect(() => {
    if (location.state?.filterConfig) {
      patchFilterConfig(location.state.filterConfig);
    }
  }, [location.state?.filterConfig]);

  // Use active groupers to group results
  const groupedItems: readonly T[][] = React.useMemo(() => {
    const groupedItems = groupers.flatMap((grouper) => grouper(props.items, filterConfig));
    if (groupedItems.length === 0) {
      return props.items.map((item) => [item]);
    }
    return groupedItems;
  }, [props.items, groupers, filterConfig]);

  // Apply active filters to grouped results
  const filteredItems = React.useMemo(
    () => filters.reduce((items, filter) => filter(items, filterConfig), groupedItems),
    [groupedItems, filters, filterConfig]
  );

  // Generate labels for filtered items if available
  const labels = React.useMemo(
    () => labeler && filteredItems.map((items) => labeler(items, filterConfig)),
    [filteredItems, labeler, filterConfig]
  );

  return (
    <FilterContext.Provider
      value={{
        items: props.items,
        filteredItems,
        filters,
        setFilters,
        groupers,
        setGroupers,
        labels,
        labeler,
        setLabeler,
        filterConfig,
        setFilterConfig: patchFilterConfig,
      }}
    >
      {props.children}
    </FilterContext.Provider>
  );
};

type PersistentFilterProviderProps<T, C> = UncontrolledFilterProviderProps<T, C> & {
  storeName: string;
};
type PersistentFilterStore<C> = C & { __setFilterConfig: React.Dispatch<PatchStateAction<C>> };

export const PersistentFilterProvider = <T, C extends object>(props: PersistentFilterProviderProps<T, C>) => {
  const store = React.useMemo(
    () =>
      createStore(
        persist<PersistentFilterStore<C>>(
          (set) => ({
            ...(props.defaultFilterConfig ?? ({} as C)),
            __setFilterConfig: set as React.Dispatch<PatchStateAction<C>>,
          }),
          { name: props.storeName }
        )
      ),
    [props.storeName]
  );

  const filterConfig = useStore(store);

  return (
    <FilterProvider items={props.items} filterConfig={filterConfig} setFilterConfig={filterConfig.__setFilterConfig}>
      {props.children}
    </FilterProvider>
  );
};
