import { AnalysisTypeMappingRead } from "@/adapters/results/PlotsMapping";
import { FragmentType, gql, useFragment } from "@/apis/nannyml";
import { MonitorResult, ResultDataPoint } from "@/domains/monitorResults/monitorResults.types";
import { ThresholdValue, exceedsThreshold } from "@/domains/threshold";
import { calculateResultThreshold } from "@/hooks/monitoring";
import { selectWhere } from "@/lib/typesUtils";

const monitoringResultValueFragment = gql(/* GraphQL */ `
  fragment MonitoringResultValue on Result {
    __typename
    id
    modelId
    analysisType
    calculatorType
    segment {
      id
      segmentColumnName
      segment
    }

    ... on TimeSeriesResult {
      metricName
      columnName
      componentName
      ...TimeSeriesResultThreshold

      lastDataPoint {
        value
      }
    }
  }
`);

export const readResultDataPoint = (value: number | null | undefined, threshold: ThresholdValue): ResultDataPoint => {
  return {
    value: value ?? undefined,
    hasAlert: exceedsThreshold(value, threshold) ?? undefined,
  };
};

export const useMonitorResults = (
  resultFragments: FragmentType<typeof monitoringResultValueFragment>[]
): MonitorResult[] => {
  const results = useFragment(monitoringResultValueFragment, resultFragments).filter(
    selectWhere("__typename", "TimeSeriesResult")
  );
  return results.map((result) => {
    const threshold = calculateResultThreshold(result);
    const lastDataPoint = readResultDataPoint(result?.lastDataPoint?.value, threshold);

    return {
      keys: {
        analysis: AnalysisTypeMappingRead[result?.analysisType!],
        analysisType: AnalysisTypeMappingRead[result?.analysisType!],
        calculatorType: result?.calculatorType ?? undefined,
        metric: result?.metricName ?? undefined,
        column: result?.columnName ?? undefined,
        componentName: result?.componentName ?? undefined,
        segment: result?.segment ?? undefined,
      },

      thresholds: {
        upperThreshold: threshold.upper ?? undefined,
        lowerThreshold: threshold.lower ?? undefined,
      },

      lastDataPoint,
    };
  });
};
