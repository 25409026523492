import { ConceptShiftMetric } from "@/apis/nannyml";
import { ConceptShiftMetricsOptions, TypeConceptShiftMetricsOptions } from "@/domains/conceptShift/conceptShiftOptions";
import { reverseRecord } from "@/lib/objUtils";

export const ConceptShiftMetricOptionsMappingWrite = {
  [ConceptShiftMetricsOptions.ROC_AUC]: ConceptShiftMetric.RocAuc,
  [ConceptShiftMetricsOptions.Precision]: ConceptShiftMetric.Precision,
  [ConceptShiftMetricsOptions.Recall]: ConceptShiftMetric.Recall,
  [ConceptShiftMetricsOptions.F1]: ConceptShiftMetric.F1,
  [ConceptShiftMetricsOptions.Accuracy]: ConceptShiftMetric.Accuracy,
  [ConceptShiftMetricsOptions.Specificity]: ConceptShiftMetric.Specificity,
  [ConceptShiftMetricsOptions.AveragePrecision]: ConceptShiftMetric.AveragePrecision,
  [ConceptShiftMetricsOptions.Magnitude]: ConceptShiftMetric.Magnitude,
};

export const ConceptShiftMetricOptionsMappingRead = reverseRecord(ConceptShiftMetricOptionsMappingWrite) as Record<
  ConceptShiftMetric,
  TypeConceptShiftMetricsOptions
>;

export const ResultMappingConceptShiftMetricWrite = {
  [ConceptShiftMetricsOptions.ROC_AUC]: "roc_auc",
  [ConceptShiftMetricsOptions.F1]: "f1",
  [ConceptShiftMetricsOptions.Precision]: "precision",
  [ConceptShiftMetricsOptions.Recall]: "recall",
  [ConceptShiftMetricsOptions.Accuracy]: "accuracy",
  [ConceptShiftMetricsOptions.Specificity]: "specificity",
  [ConceptShiftMetricsOptions.Magnitude]: "magnitude",
};

export const ResultMappingConceptShiftMetricsRead = reverseRecord(ResultMappingConceptShiftMetricWrite) as Record<
  string,
  TypeConceptShiftMetricsOptions
>;
