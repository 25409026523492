import { ObjectValues } from "@/constants/enums";

export type PlotInputIndex = number | string;

export const AnalysisTypeOptions = {
  REALIZED_PERFORMANCE: "REALIZED_PERFORMANCE",
  ESTIMATED_PERFORMANCE: "ESTIMATED_PERFORMANCE",
  FEATURE_DRIFT: "FEATURE_DRIFT",
  DATA_QUALITY: "DATA_QUALITY",
  CONCEPT_SHIFT: "CONCEPT_SHIFT",
  DISTRIBUTION: "DISTRIBUTION",
  SUMMARY_STATS: "SUMMARY_STATS",
  // virtual analysis types
  MULTIVARIATE_DRIFT: "MULTIVARIATE_DRIFT",
  RCS_ME: "RCS_ME",
} as const;

export type TypeAnalysisTypeOptions = ObjectValues<typeof AnalysisTypeOptions>;
