import { NANNYML_API_ADDRESS } from "@/constants/env";

export type Credentials = {
  username: string;
  password: string;
};

export type AuthResponse = {
  access_token: string;
  token_type: string;
  expires_in: number;
};

const handleResponse = <T>(response: Response): Promise<T> => {
  if (!response.ok) {
    return response.json().then((data) => {
      throw new Error(data.detail || response.statusText);
    });
  }

  return response.json();
};

export const login = (credentials: Credentials): Promise<void> => {
  return fetch(`${NANNYML_API_ADDRESS}auth/login`, {
    method: "POST",
    body: new URLSearchParams(credentials),
  }).then(handleResponse<void>);
};

export const logout = (): Promise<void> => {
  return fetch(`${NANNYML_API_ADDRESS}auth/logout`, {
    method: "POST",
  }).then(handleResponse<void>);
};

export const acquireToken = (): Promise<AuthResponse> => {
  return fetch(`${NANNYML_API_ADDRESS}auth/token`, {
    method: "POST",
  }).then(handleResponse<AuthResponse>);
};
