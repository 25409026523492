import { CheckIcon, ChevronDownIcon } from "lucide-react";
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Chip } from "@/components/Chip";
import { Command, CommandGroup, CommandInput, CommandItem } from "@/components/common/Command/Command";
import { cn } from "@/lib/utils";

import { Popover, PopoverContent, PopoverTrigger } from "./Popover";

const ComboBoxContext = createContext<{
  values: string[];
  onSelectItem: (value: string) => void;
} | null>(null);

export const ComboBox = ({
  className,
  children,
  values,
  onChange,
  placeholder,
  required,
  disabled,
  allowMultiple = true,
  ValueComponent,
}: {
  className?: string;
  children?: ReactNode;
  values: string[];
  onChange: (values: string[]) => void;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  allowMultiple?: boolean;
  // Would ideally render the MultiSelectItem in the trigger rather than using a custom component, but haven't figured
  // out how to do that yet given the fact there's multiple nodes to display. Also the items are only rendered when the
  // popover is open, so the initial value wouldn't be displayed until the popover is opened.
  ValueComponent?: React.ComponentType<{ value: string }>;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [key, setKey] = useState(0);

  // When the values change we need to update the key to clear the state for multi value display. Otherwise we can't
  // correctly detect overflow of the display area.
  useLayoutEffect(() => {
    setKey((k) => k + 1);
  }, [values]);

  const ctx = useMemo(
    () => ({
      values,
      onSelectItem: (value: string) => {
        if (allowMultiple) {
          onChange(values.includes(value) ? values.filter((v) => v !== value) : [...values, value]);
        } else {
          onChange([value]);
          setIsOpen(false);
        }
      },
    }),
    [values, onChange]
  );

  return (
    <ComboBoxContext.Provider value={ctx}>
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger
          className={cn(
            "inline-flex justify-between items-center gap-2 text-sm px-3 h-9",
            "rounded-md border border-gray-500 hover:bg-slate-900",
            "data-[placeholder]:italic data-[placeholder]:text-slate-400",
            "disabled:opacity-50 disabled:hover:bg-transparent",
            "data-[invalid]:border-red-500 data-[invalid]:focus:ring-red-400",
            className
          )}
          data-placeholder={values.length === 0 ? "" : undefined}
          data-invalid={required && values.length === 0 ? "" : undefined}
          disabled={disabled}
        >
          {values.length === 0 ? (
            placeholder
          ) : allowMultiple ? (
            <MultiValueDisplay key={key} values={values} ValueComponent={ValueComponent} />
          ) : (
            <SingleValueDisplay value={values[0]} ValueComponent={ValueComponent} />
          )}
          <ChevronDownIcon className="shrink-0" size={16} strokeWidth={1} />
        </PopoverTrigger>
        <select
          className="hidden"
          aria-hidden
          tabIndex={-1}
          required={required}
          disabled={disabled}
          multiple={allowMultiple}
          value={allowMultiple ? values : values[0]}
          onChange={(e) =>
            onChange(allowMultiple ? Array.from(e.target.selectedOptions).map((o) => o.value) : [e.target.value])
          }
        >
          {
            // If there's no value we need to render an empty option as first value to get invalidation behavior from
            // the select menu. *Just HTML things*
            values.length === 0 ? <option value="" /> : null
          }
          {values.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
        <PopoverContent align="start" className="text-slate-400">
          <Command>{children}</Command>
        </PopoverContent>
      </Popover>
    </ComboBoxContext.Provider>
  );
};

const MultiValueDisplay = ({
  values,
  ValueComponent,
}: {
  values: string[];
  ValueComponent?: React.ComponentType<{ value: string }>;
}) => {
  const [displayCount, setDisplayCount] = useState(values.length);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const checkForOverflow = useCallback((offset: number = 0) => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    let i;
    for (i = 0; i < container.children.length; i++) {
      const child = container.children[i] as HTMLElement;
      if (child.offsetLeft + child.scrollWidth > container.offsetLeft + container.clientWidth) {
        break;
      }
    }
    setDisplayCount(Math.max(i + offset, 0));
  }, []);

  // When the container is rendered we display all items and then check for overflow. The container is stored in a ref
  // so that we can check for overflow when the "more items" chip is rendered.
  const onRenderContainer = useCallback(
    (container: HTMLDivElement | null) => {
      containerRef.current = container;
      checkForOverflow();
    },
    [checkForOverflow]
  );

  // When the "more items" chip is rendered we check for overflow again, but this time we subtract one from the count to
  // make sure the "more items" chip is visible.
  const onRenderMoreItemsChip = useCallback(() => checkForOverflow(-1), [checkForOverflow]);

  return (
    <div ref={onRenderContainer} className="flex items-center gap-2 w-full overflow-hidden">
      {values.slice(0, displayCount).map((value) => (
        <Chip key={value} label={<SingleValueDisplay value={value} ValueComponent={ValueComponent} />} size="small" />
      ))}
      {displayCount < values.length && (
        <Chip ref={onRenderMoreItemsChip} label={`+${values.length - displayCount} more`} size="small" />
      )}
    </div>
  );
};

const SingleValueDisplay = ({
  value,
  ValueComponent,
}: {
  value: string;
  ValueComponent?: React.ComponentType<{ value: string }>;
}) => (ValueComponent ? <ValueComponent value={value} /> : <>{value}</>);

export const ComboBoxItem = ({
  className,
  children,
  value,
  disabled,
}: {
  className?: string;
  children?: ReactNode;
  value: string;
  disabled?: boolean;
}) => {
  const ctx = useContext(ComboBoxContext);
  if (!ctx) {
    throw new Error("MultiSelectItem must be used within a MultiSelect");
  }

  return (
    <CommandItem
      className={cn("relative pr-2 pl-7 py-1 rounded-none", className)}
      // The value received in `onSelect` callback is lowercased. Using the value prop here to work around that
      onSelect={() => ctx.onSelectItem(value)}
      value={value}
      disabled={disabled}
    >
      {ctx.values.includes(value) && <CheckIcon className="absolute left-2 text-highlightDeep" size={12} />}
      {children}
    </CommandItem>
  );
};

export const ComboBoxSearch = ({ className, placeholder }: { className?: string; placeholder?: string }) => (
  <CommandInput placeholder={placeholder ?? "Type to search..."} className={className} />
);

export const ComboBoxGroup = ({ className, children }: { className?: string; children?: ReactNode }) => (
  <CommandGroup className={cn("p-0", className)}>{children}</CommandGroup>
);
