import * as SelectPrimitive from "@radix-ui/react-select";
import { VariantProps, cva } from "class-variance-authority";
import { CheckIcon, ChevronDownIcon, XIcon } from "lucide-react";
import * as React from "react";

import { cn } from "@/lib/utils";

const selectStyle = cva(
  [
    "inline-flex justify-between items-center",
    "rounded-md border border-gray-500 hover:bg-slate-900",
    "disabled:opacity-50 disabled:hover:bg-transparent",
    "data-[placeholder]:italic data-[placeholder]:text-slate-400",
    "data-[invalid]:border-red-500 data-[invalid]:focus:ring-red-400",
  ],
  {
    variants: {
      size: {
        small: ["text-sm", "px-2", "py-1"],
        medium: ["text-sm", "px-3", "py-2"],
        large: ["text-base", "px-4", "py-2"],
      },
    },
    defaultVariants: {
      size: "medium",
    },
  }
);

export type SelectVariantProps = VariantProps<typeof selectStyle>;

const contentStyle = cva(
  [
    "border border-slate-500 rounded-md bg-dark text-slate-400 shadow-xl shadow-slate-800",
    "w-[var(--radix-select-trigger-width)]",
    "max-h-[var(--radix-select-content-available-height)]",
  ],
  {
    variants: {
      size: {
        small: ["text-sm py-1"],
        medium: ["text-sm py-2"],
        large: ["text-base py-3"],
      },
    },
    defaultVariants: {
      size: "medium",
    },
  }
);

const selectItemStyle = cva(["relative flex hover:bg-slate-700 focus:bg-slate-700 outline-0 hover:cursor-pointer"], {
  variants: {
    size: {
      small: ["pr-2 pl-7 py-1"],
      medium: ["pr-4 pl-8 py-1"],
      large: ["pr-6 pl-10 py-2"],
    },
  },
  defaultVariants: {
    size: "medium",
  },
});

const selectItemIndicatorStyle = cva(["absolute left-2 text-highlightDeep"], {
  variants: {
    size: {
      small: ["w-3 h-3 top-2.5"],
      medium: ["w-4 h-4 top-1.5"],
      large: ["w-5 h-5 top-3"],
    },
  },
  defaultVariants: {
    size: "medium",
  },
});

type SelectProps = React.ComponentPropsWithoutRef<typeof SelectPrimitive.Root> &
  VariantProps<typeof selectStyle> & {
    className?: string;
    id?: string;
    placeholder?: string;
    onClear?: () => void;
  };

const SelectContext = React.createContext<VariantProps<typeof selectStyle> | null>(null);

export const Select = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Trigger>, SelectProps>(
  ({ children, className, id, placeholder, size, value, required, onClear, ...props }, ref) => {
    const cva = { size };
    const canClear = !props.disabled && value;
    return (
      <SelectContext.Provider value={cva}>
        <SelectPrimitive.Root value={value} required={required} {...props}>
          <SelectPrimitive.Trigger
            ref={ref}
            id={id}
            className={cn(selectStyle({ size }), className)}
            data-invalid={required && !value ? "" : undefined}
          >
            <SelectPrimitive.Value placeholder={placeholder ?? ""}></SelectPrimitive.Value>
            {onClear && (
              <span
                role="button"
                className={cn(canClear && "hover:bg-gray-600", "rounded-full p-0.5 ml-auto")}
                onClick={(e) => canClear && onClear()}
                // Using `onPointerDown` to disable propagation because that is what radix uses
                onPointerDown={(e) => canClear && e.stopPropagation()}
              >
                <XIcon size={16} strokeWidth={1} />
              </span>
            )}
            <SelectPrimitive.Icon className={cn(!onClear && "ml-2")} asChild>
              <ChevronDownIcon size={16} strokeWidth={1} />
            </SelectPrimitive.Icon>
          </SelectPrimitive.Trigger>
          <SelectPrimitive.Portal>
            <SelectPrimitive.Content position="popper" className={cn(contentStyle(cva))}>
              <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
            </SelectPrimitive.Content>
          </SelectPrimitive.Portal>
        </SelectPrimitive.Root>
      </SelectContext.Provider>
    );
  }
);
Select.displayName = "Select";

export const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ children, className, ...props }, ref) => {
  const cva = React.useContext(SelectContext);
  if (!cva) throw new Error("SelectItem must be used within Select");

  return (
    <SelectPrimitive.Item ref={ref} className={cn(selectItemStyle(cva), className)} {...props}>
      <SelectPrimitive.ItemIndicator className={cn(selectItemIndicatorStyle(cva))} asChild>
        <CheckIcon />
      </SelectPrimitive.ItemIndicator>
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  );
});
SelectItem.displayName = "SelectItem";
