import { cva, VariantProps } from "class-variance-authority";
import React from "react";

import { cn } from "@/lib/utils";

const dividerVariants = cva(["bg-gray-600", "shrink-0"], {
  variants: {
    orientation: {
      horizontal: "h-px w-full",
      vertical: "w-px h-full",
    },
    margin: {
      none: "",
      thin: "",
      default: "",
      wide: "",
    },
  },
  defaultVariants: {
    orientation: "horizontal",
    margin: "default",
  },
  compoundVariants: [
    { orientation: "horizontal", margin: "thin", className: "my-0.5" },
    { orientation: "vertical", margin: "thin", className: "mx-0.5" },
    { orientation: "horizontal", margin: "default", className: "my-2" },
    { orientation: "vertical", margin: "default", className: "mx-2" },
    { orientation: "horizontal", margin: "wide", className: "my-4" },
    { orientation: "vertical", margin: "wide", className: "mx-4" },
  ],
});

export type DividerProps = React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof dividerVariants>;

export const Divider = React.forwardRef<HTMLDivElement, DividerProps>(
  ({ className, orientation, margin, ...props }: DividerProps, ref) => (
    <div ref={ref} className={cn(dividerVariants({ orientation, margin }), className)} {...props} />
  )
);
