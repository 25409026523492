import { ReactNode } from "react";

import { SimpleTooltip } from "@/DesignSystem/nanny/SimpleTooltip/SimpleTooltip";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";
import { InformationModalNames } from "@/formatters/informationModal/informationModalCatalog";
import { cn } from "@/lib/utils";

export const LabeledField = ({
  children,
  className,
  label,
  required = false,
  infoName,
}: {
  children: ReactNode;
  className?: string;
  label: ReactNode;
  required?: boolean | string;
  infoName?: InformationModalNames;
}) => (
  <label className={cn("flex flex-col", className)}>
    <span className="text-slate-400 text-sm mb-0.5">
      {label}
      {required &&
        (typeof required === "string" ? (
          <SimpleTooltip tooltipContent={required}>
            <span className="text-yellow-400"> *</span>
          </SimpleTooltip>
        ) : (
          <span className="text-red-400"> *</span>
        ))}
      {infoName && <InformationModalChip className="inline-block ml-1 text-current" infoName={infoName} />}
    </span>
    {children}
  </label>
);
