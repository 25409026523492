import _ from "lodash";

import { ConceptShiftMetric, ConceptShiftMetricConfigInput, FragmentType, gql, useFragment } from "@/apis/nannyml";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/Table";
import { conceptShiftMetricLabels } from "@/formatters/monitoring";

import { MetricEnableToggle } from "./MetricEnableToggle";
import { ThresholdConfigCells, ThresholdConfigHeaderCells } from "./ThresholdConfig";
import { RuntimeConfigComponentProps, SegmentRuntimeConfigComponentProps } from "./types";

const conceptDriftRuntimeConfigFragment = gql(/* GraphQL */ `
  fragment ConceptDriftRuntimeConfig on RuntimeConfig {
    conceptShiftMetrics {
      metric
      lowerValueLimit
      upperValueLimit
      ...IsSupportedConfig
    }
  }
`);

const useConceptDriftEdit = ({
  config: configFragment,
  value: { conceptShiftMetrics },
  onValueChange,
}: RuntimeConfigComponentProps<FragmentType<typeof conceptDriftRuntimeConfigFragment>>) => {
  const config = useFragment(conceptDriftRuntimeConfigFragment, configFragment);
  const configMetrics = _.keyBy(config.conceptShiftMetrics, "metric");

  const onMetricChange = (metric: ConceptShiftMetric, change: Partial<ConceptShiftMetricConfigInput>) => {
    onValueChange({
      conceptShiftMetrics: (conceptShiftMetrics ?? []).map((m) => (m.metric === metric ? { ...m, ...change } : m)),
    });
  };

  return {
    configMetrics,
    onMetricChange,
  };
};

export const ConceptDriftConfig = (
  props: RuntimeConfigComponentProps<FragmentType<typeof conceptDriftRuntimeConfigFragment>>
) => {
  const { configMetrics, onMetricChange } = useConceptDriftEdit(props);
  return (
    <Table className={props.className}>
      <TableHeader>
        <TableRow>
          <TableHead>Method</TableHead>
          <TableHead>Enabled</TableHead>
          <ThresholdConfigHeaderCells />
        </TableRow>
      </TableHeader>
      <TableBody>
        {props.value.conceptShiftMetrics.map((value) => (
          <TableRow key={value.metric}>
            <TableCell className="whitespace-nowrap">{conceptShiftMetricLabels[value.metric]}</TableCell>
            <TableCell>
              <MetricEnableToggle
                config={configMetrics[value.metric]}
                value={value.enabled ?? false}
                onValueChange={(enabled) => onMetricChange(value.metric, { enabled })}
              />
            </TableCell>
            <ThresholdConfigCells
              config={configMetrics[value.metric]}
              value={value}
              disabled={!value.enabled}
              onValueChange={(change) => onMetricChange(value.metric, change)}
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const ConceptDriftEnableConfig = (
  props: RuntimeConfigComponentProps<FragmentType<typeof conceptDriftRuntimeConfigFragment>>
) => {
  const { configMetrics, onMetricChange } = useConceptDriftEdit(props);
  return (
    <Table className={props.className}>
      <TableHeader>
        <TableRow>
          <TableHead>Method</TableHead>
          <TableHead className="text-center">Enabled</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {props.value.conceptShiftMetrics.map((metric) => (
          <TableRow key={metric.metric}>
            <TableCell className="whitespace-nowrap">{conceptShiftMetricLabels[metric.metric]}</TableCell>
            <TableCell>
              <MetricEnableToggle
                config={configMetrics[metric.metric]}
                value={metric.enabled ?? false}
                onValueChange={(enabled) => onMetricChange(metric.metric, { enabled })}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const ConceptDriftThresholdConfig = (
  props: SegmentRuntimeConfigComponentProps<FragmentType<typeof conceptDriftRuntimeConfigFragment>>
) => {
  const { configMetrics, onMetricChange } = useConceptDriftEdit(props);
  return (
    <Table className={props.className}>
      <TableHeader>
        <TableRow>
          <TableHead>Method</TableHead>
          <ThresholdConfigHeaderCells useSegments />
        </TableRow>
      </TableHeader>
      <TableBody>
        {props.value.conceptShiftMetrics.map((value) => (
          <TableRow key={value.metric}>
            <TableCell className="whitespace-nowrap">{conceptShiftMetricLabels[value.metric]}</TableCell>
            <ThresholdConfigCells
              config={configMetrics[value.metric]}
              segmentId={props.segmentId}
              value={value}
              disabled={!value.enabled}
              onValueChange={(change) => onMetricChange(value.metric, change)}
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
