import { useApolloClient, useMutation } from "@apollo/client";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ColumnInput, CreateExperimentInput, ProductType, gql } from "@/apis/nannyml";
import { DatasetStorageDetails, inspectDataSource } from "@/components/DatasetStorage";
import { SchemaConfiguration, SchemaTable } from "@/components/Schema";
import {
  Wizard,
  WizardCompletionGuard,
  WizardContent,
  WizardControlButtons,
  WizardOverview,
  WizardStep,
  getWizardControls,
} from "@/components/Wizard";
import { columnConfig } from "@/domains/experiment";
import { cn } from "@/lib/utils";

import { ExperimentMetricConfig } from "../../../components/experiment/ExperimentMetricConfig";
import { DataRequirements } from "./DataRequirements";
import { ExperimentDetails } from "./ExperimentDetails";
import { Review } from "./Review";

const inspectExperimentDataSource = gql(/* GraphQL */ `
  query InspectExperimentDataset($input: InspectExperimentDataSourceInput!) {
    inspect_experiment_dataset(input: $input) {
      metrics {
        name
        valid
      }
    }
  }
`);

const createExperimentMutation = gql(/* GraphQL */ `
  mutation CreateExperiment($input: CreateExperimentInput!) {
    create_experiment(input: $input) {
      id
    }
  }
`);

export const AddExperimentWizard = () => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const [head, setHead] = useState<any>();
  const [createExperiment] = useMutation(createExperimentMutation);

  const onSchemaChange =
    (setModelInput: Dispatch<SetStateAction<Partial<CreateExperimentInput>>>) => (columns: ColumnInput[]) =>
      setModelInput(({ dataSource }) => ({ dataSource: { ...dataSource!, columns } }));

  const steps: WizardStep<CreateExperimentInput>[] = useMemo(
    () => [
      {
        title: "Data requirements",
        subtitle: "Find out what data you need to provide",
        isCompleted: () => true,
        render: (settings, setSettings) => <DataRequirements />,
      },
      {
        title: "Experiment details",
        subtitle: "Define details of the experiment",
        isCompleted: (settings) => Boolean(settings.name && settings.experimentType),
        render: (settings, setSettings) => <ExperimentDetails value={settings} onChange={setSettings} />,
      },
      {
        title: "Experiment data",
        subtitle: "Provide starting data for the experiment",
        isCompleted: (settings) => Boolean(settings.dataSource),
        onComplete: (modelInput, setModelInput) =>
          inspectDataSource(
            client,
            modelInput.dataSource!,
            ProductType.Experiment,
            undefined,
            onSchemaChange(setModelInput),
            setHead
          ),
        render: (experimentInput, setExperimentInput) => (
          <DatasetStorageDetails
            name="experiment"
            value={experimentInput.dataSource?.storageInfo ?? null}
            onChange={(storageInfo) =>
              setExperimentInput({
                dataSource: {
                  storageInfo,
                  hasAnalysisData: true,
                  hasReferenceData: false,
                  name: "experiment",
                  columns: [],
                },
              })
            }
          />
        ),
      },
      {
        title: "Configure schema",
        subtitle: "Define columns for the experiment",
        onComplete: ({ dataSource }, setExperimentInput) =>
          client
            .query({
              query: inspectExperimentDataSource,
              variables: {
                input: {
                  storageInfo: dataSource!.storageInfo!,
                  columns: dataSource!.columns,
                },
              },
            })
            .then(({ data }) => {
              const metrics = data.inspect_experiment_dataset.metrics;
              const invalidMetric = metrics.find((metric) => !metric.valid);
              if (invalidMetric) {
                throw new Error(`Configuration for ${invalidMetric.name} is invalid`);
              }

              setExperimentInput({
                dataSource,
                kem: metrics?.[0].name ?? null,
                config: {
                  metrics: metrics.map((metric) => ({ metric: metric.name } as any)),
                },
              });
            }),
        render: (modelInput, setModelInput) => {
          const schema = modelInput.dataSource!.columns!;
          return (
            <div className="self-stretch flex flex-col gap-6 justify-around max-w-full">
              <SchemaConfiguration
                columnConfig={columnConfig}
                schema={schema}
                onSchemaChange={onSchemaChange(setModelInput)}
              />
              <SchemaTable
                columnConfig={columnConfig}
                schema={schema}
                head={head ?? []}
                onSchemaChange={onSchemaChange(setModelInput)}
              />
            </div>
          );
        },
      },
      {
        title: "Metrics",
        subtitle: "Configure metrics to evaluate",
        isCompleted: (settings) => Boolean(settings.config && settings.kem),
        render: (settings, setSettings) => <ExperimentMetricConfig value={settings} onChange={setSettings} />,
      },
      {
        title: "Review",
        subtitle: "Review your experiment settings",
        isCompleted: (_, ctx) => getWizardControls(ctx).arePreviousStepsCompleted(),
        render: (settings, setSettings) => (
          <WizardCompletionGuard>
            <Review settings={settings as CreateExperimentInput} />
          </WizardCompletionGuard>
        ),
      },
    ],
    [client, head]
  );

  const onComplete = (input: CreateExperimentInput) => {
    return createExperiment({ variables: { input } })
      .then((data) => {
        if (data.errors || !data.data) {
          throw new Error("Failed to add experiment: " + data.errors?.[0].message);
        }

        navigate(`/experiment/${data.data.create_experiment.id}`);
      })
      .catch((error) => Promise.reject(`Failed to add experiment: ${error.message}`));
  };

  return (
    <div className="flex h-full bg-dark overflow-y-auto py-4">
      <Wizard
        className={cn(
          "rounded-xl border border-gray-600 bg-primaryBg px-8",
          "m-auto min-h-[70%] h-fit w-4/5 2xl:w-3/5"
        )}
        steps={steps}
        onComplete={onComplete}
      >
        <h3 className="text-2xl text-center">Add experiment</h3>
        <WizardOverview />
        <WizardContent />
        <WizardControlButtons finishLabel="Start experiment" />
      </Wizard>
    </div>
  );
};
