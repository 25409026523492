import { ColumnDef } from "@tanstack/react-table";

import { Button } from "@/components/common/Button";
import { formatISODateTime } from "@/lib/dateUtils";

export type ApiToken = {
  id: string;
  createdAt: string;
  description?: string | null;
};

export type ApiTokenActions = {
  deleteApiToken: (id: string) => void;
};

const Header = ({ className, children }: React.PropsWithChildren<{ className?: string }>) => (
  <div className={`font-bold text-base ${className}`}>{children}</div>
);

export const getApiTokensListColumns = ({ deleteApiToken }: ApiTokenActions): ColumnDef<ApiToken>[] => [
  {
    accessorKey: "createdAt",
    header: () => <Header>Created at</Header>,
    cell: (info) => <span>{formatISODateTime(new Date(info.getValue() as string))}</span>,
    meta: {
      className: "whitespace-nowrap px-4",
    },
  },
  {
    accessorKey: "description",
    header: () => <Header>Description</Header>,
    cell: (info) => <span>{(info.getValue() ?? "No description") as string}</span>,
    meta: {
      className: "w-full px-4",
    },
  },
  {
    id: "actions",
    cell: (props) => (
      <div className="flex flex-row justify-end gap-4 px-4">
        <Button onClick={() => deleteApiToken(props.row.original.id)} cva={{ intent: "reject", size: "small2" }}>
          Delete
        </Button>
      </div>
    ),
    size: 70,
  },
];
