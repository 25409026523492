import { Dispatch, SetStateAction, createContext } from "react";
import { ContentData } from "./Report.utils";

export type ReportSummary = {
    id: number;
    title: string;
    creationDate: string;
};

export type PlotInfo = {
    layout:any
}

export type CurrentReport = {
    id:number,
    title:string,
    contentData: ContentData[]
}

export const ReportContext = createContext<null | {
    reportList:ReportSummary[]
    currentReport:CurrentReport|null
    plotInfo:PlotInfo
    setCurrentReport: Dispatch<SetStateAction<CurrentReport|null>>;
    setPlotInfo:Dispatch<SetStateAction<PlotInfo>>;
}>(null)