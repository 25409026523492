import { useMutation, useSuspenseQuery } from "@apollo/client";
import { PopoverContent, PopoverTrigger } from "@radix-ui/react-popover";
import { ArrowDown, ChevronDown } from "lucide-react";
import { PropsWithChildren, SetStateAction, startTransition, useState } from "react";

import { ReportInput, ResultPlotFragment, TemplateType, gql, useFragment } from "@/apis/nannyml";
import { useParamsModelId } from "@/routes/useParamsModelId";

import { ButtonGroup } from "../ButtonGroup";
import { Popover } from "../Popover";
import { Button } from "../common/Button";
import { toast } from "../common/Toast/useToast";
import { usePlotConfig } from "../monitoring/PlotConfig";
import { CreateReportPopUp, createReportMutation } from "./CreateReport";
import { CurrentReport, PlotInfo, ReportContext, ReportSummary } from "./Report.context";
import { useReportContext } from "./Report.hook";

export const reportSummaryFragment = gql(/* GraphQL */ `
  fragment ReportSummary on Report {
    id
    creationDate
    title
  }
`);

const getReportsQuery = gql(/* GraphQL */ `
  query GetReports($filter: ReportFilterInput!) {
    reports(reportFilter: $filter) {
      ...ReportSummary
    }
  }
`);

const addContentToReportMutation = gql(/* GraphQL */ `
  mutation AddContentToReport($reportId: Int!, $content: String!) {
    add_content_to_report(reportId: $reportId, content: $content) {
      ...ReportSummary
    }
  }
`);

export const ReportContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const modelId = useParamsModelId();
  const { data } = useSuspenseQuery(getReportsQuery, {
    variables: { filter: { modelId: modelId } },
  });

  const reportList = useFragment(reportSummaryFragment, data.reports);

  const reports = [...reportList];

  const [plotInfo, setPlotInfo] = useState<PlotInfo>({ layout: {} });

  const [currentReport, setCurrentReport] = useState<CurrentReport | null>(
    reports.length
      ? {
          id: reports[0].id,
          contentData: [],
          title: reports[0].title,
        }
      : null
  );

  return (
    <ReportContext.Provider
      value={{
        currentReport,
        setCurrentReport,
        reportList: reports,
        plotInfo,
        setPlotInfo,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

export const AddToReport = ({ results }: { results: ResultPlotFragment[] }) => {
  const { reportList, plotInfo: plotInfo, currentReport, setCurrentReport } = useReportContext();
  const plotConfig = usePlotConfig();
  const [addContent, {}] = useMutation(addContentToReportMutation);
  const modelId = useParamsModelId();
  const [createReportDialogOpen, setCreateReportDialogOpen] = useState(false);
  const [saveReport, {}] = useMutation(createReportMutation, {
    update(cache, { data }) {
      if (data) {
        const updatedReport = useFragment(reportSummaryFragment, data.create_report);
        cache.modify({
          fields: {
            reports(existingReports = []) {
              const newReportRef = cache.writeFragment({
                data: updatedReport,
                fragment: reportSummaryFragment,
              });
              return existingReports.concat(newReportRef);
            },
          },
        });
      }
    },
  });

  const pickReport = (report: ReportSummary) => {
    const selectedReport = {
      id: report.id,
      contentData: [],
      title: report.title,
    };

    setCurrentReport(selectedReport);
  };

  const addPlot = () => {
    const currentPlot = {
      label: "",
      key: "ResultPlot",
      args: [],
      id: 0,
      plotOptions: {
        results: results.map((result) => {
          return {
            modelId: result.modelId,
            analysisType: result.analysisType,
            columnName: result.columnName ? result.columnName : "",
            metricName: result.metricName ? result.metricName : "",
            componentName: result.componentName ? result.componentName : "",
            id: result.id,
          };
        }),
        plotConfig,
        layout: plotInfo.layout,
      },
    };

    addContent({
      variables: { reportId: currentReport!.id, content: JSON.stringify(currentPlot) },
    })
      .then(({ data }) => {
        toast({
          title: `Plot added to report: ${currentReport!.title}`,
          variant: "success",
        });
      })
      .catch((error) => {
        toast({
          title: `Failed to add plot to report: ${currentReport!.title}`,
          variant: "error",
        });
      });
  };

  const createReport = (reportName: string, dateRange: [number, number] | null, template: TemplateType | null) => {
    const title = {
      id: 0,
      label: "Title",
      args: ["text-2xl"],
      key: "ContentText",
      data: reportName,
      textOptions: {
        placeholder: "Report Title",
        className: "text-2xl",
        cols: 100,
        rows: 1,
        iconSize: 20,
      },
    };

    saveReport({
      variables: {
        report: {
          title: title.data,
          content: JSON.stringify({ data: [title], metadata: dateRange ? { dateRange: dateRange } : {} }),
          modelId: modelId,
          template: template,
        } as ReportInput,
      },
    }).then(({ data }) => {
      if (data) {
        const newReport = useFragment(reportSummaryFragment, data.create_report);
        setCurrentReport({
          id: newReport.id,
          title: newReport.title,
          contentData: [],
        });
        setCreateReportDialogOpen(false);
      }
    });
  };

  return (
    <>
      {reportList.length ? (
        <ButtonGroup size="small">
          <Button onClick={addPlot}>Add to report</Button>
          <Popover>
            <PopoverTrigger className="flex">
              {currentReport!.title} <ChevronDown size="20" />
            </PopoverTrigger>
            <PopoverContent className="z-10">
              {reportList.map((report) => (
                <Button className="w-full" cva={{ size: "small" }} key={report.id} onClick={() => pickReport(report)}>
                  {report.title}
                </Button>
              ))}
              <Button cva={{ size: "small" }} onClick={() => setCreateReportDialogOpen(true)}>
                Create new report
              </Button>
            </PopoverContent>
          </Popover>
        </ButtonGroup>
      ) : (
        <ButtonGroup>
          <Button cva={{ size: "small" }} onClick={() => setCreateReportDialogOpen(true)}>
            Create new report
          </Button>
        </ButtonGroup>
      )}
      <CreateReportPopUp
        createReport={createReport}
        onDialogOpen={createReportDialogOpen}
        setDialogOpen={setCreateReportDialogOpen}
      />
    </>
  );
};
