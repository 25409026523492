import { AnalysisType } from "@/apis/nannyml";
import { AnalysisTypeOptions } from "@/domains/plotInputs/PlotInputs.types";
import { reverseRecord } from "@/lib/objUtils";

export const AnalysisTypeMappingRead = {
  [AnalysisType.EstimatedPerformance]: AnalysisTypeOptions.ESTIMATED_PERFORMANCE,
  [AnalysisType.RealizedPerformance]: AnalysisTypeOptions.REALIZED_PERFORMANCE,
  [AnalysisType.FeatureDrift]: AnalysisTypeOptions.FEATURE_DRIFT,
  [AnalysisType.DataQuality]: AnalysisTypeOptions.DATA_QUALITY,
  [AnalysisType.ConceptShift]: AnalysisTypeOptions.CONCEPT_SHIFT,
  [AnalysisType.Distribution]: AnalysisTypeOptions.DISTRIBUTION,
  [AnalysisType.SummaryStats]: AnalysisTypeOptions.SUMMARY_STATS,
};

export const AnalysisTypeMappingWrite = {
  ...reverseRecord(AnalysisTypeMappingRead),
  [AnalysisTypeOptions.MULTIVARIATE_DRIFT]: AnalysisType.FeatureDrift,
  [AnalysisTypeOptions.RCS_ME]: AnalysisType.ConceptShift,
};
