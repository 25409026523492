import { useApolloClient, useMutation, useSuspenseQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { gql, useFragment } from "@/apis/nannyml";

export const useEvaluationModelId = () => {
  const { modelId } = useParams();

  if (!modelId) {
    throw new Error("Model ID not set");
  }

  return parseInt(modelId);
};

export const evaluationModelMetadataFragment = gql(/* GraphQL */ `
  fragment EvaluationModelMetadata on EvaluationModel {
    id
    name
    createdAt
    problemType
    hypothesis
    kpm
    classificationThreshold
  }
`);

const getEvaluationModelMetadataQuery = gql(/* GraphQL */ `
  query GetEvaluationModelMetadata($modelId: Int!) {
    evaluation_model(id: $modelId) {
      ...EvaluationModelMetadata
    }
  }
`);

const startEvaluationRunMutation = gql(/* GraphQL */ `
  mutation StartEvaluationRun($modelId: Int!) {
    start_evaluation_model_run(evaluationModelId: $modelId) {
      id
    }
  }
`);

export const useEvaluationModelMetadata = (modelId: number) => {
  const {
    data: { evaluation_model: model },
  } = useSuspenseQuery(getEvaluationModelMetadataQuery, {
    variables: { modelId },
  });

  if (!model) {
    throw new Error(`Model '${modelId}' not found`);
  }

  return useFragment(evaluationModelMetadataFragment, model);
};

export const useStartEvaluationRun = (modelId: number) => {
  const client = useApolloClient();
  return useMutation(startEvaluationRunMutation, {
    variables: { modelId },
    onCompleted: () => {
      // Invalidate the cache for the evaluation model's latest run when the run starts
      client.cache.evict({ id: `EvaluationModel:${modelId}`, fieldName: "latestRun" });
    },
  });
};
