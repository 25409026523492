import * as React from "react";

export interface AuthContextType {
  acquireToken: () => Promise<string | null>;
  logout: () => Promise<void>;
}

const defaultAuthContext: AuthContextType = {
  acquireToken: () => Promise.resolve(null),
  logout: () => Promise.resolve(),
};

export const AuthContext = React.createContext<AuthContextType>(defaultAuthContext);
export const AuthConsumer = AuthContext.Consumer;
