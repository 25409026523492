import { SearchIcon, SearchXIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { Input } from "@/DesignSystem/shadcn/Input";
import { Label } from "@/DesignSystem/shadcn/Label/Label";
import { DataTable } from "@/components/DataTable";
import { Button } from "@/components/common/Button";
import { ModelOverviewColumn } from "@/domains/model/modelOverview";
import { useSearchList } from "@/hooks/useSearchList";
import { cn } from "@/lib/utils";

import { ModelOverviewColumns } from "./ModelsOverviewColumns";

export const ModelsOverviewTable = ({ modelsOverview }: { modelsOverview: ModelOverviewColumn[] }) => {
  const data = (modelsOverview ?? []).sort((a, b) => {
    return a.modelId - b.modelId;
  });

  const { search, setSearch, results } = useSearchList(data, "modelName");

  return (
    <div className="h-full overflow-auto">
      <div className="container fcol gap-4 mx-auto my-4">
        <Label className="relative w-[300px] text-slate-400 cursor-text">
          <SearchIcon className="absolute left-3 top-3" size={16} />
          <Input
            className="pl-9 dark:border-gray-600"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search models..."
          />
        </Label>

        <DataTable
          className={cn(
            "border rounded-md border-gray-600 overflow-visible odd:[&_tr]:bg-oddBg",
            "[&_tr:last-child_td:first-child]:rounded-bl-md [&_tr:last-child_td:last-child]:rounded-br-md"
          )}
          columns={ModelOverviewColumns}
          data={results}
          emptyContent={<NoModelsPlaceholder />}
        />
      </div>
    </div>
  );
};

const NoModelsPlaceholder = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center my-24 gap-4">
      <SearchXIcon size={128} className="text-gray-400" />
      <h1 className="text-2xl">No models found</h1>
      <p className="max-w-prose">Looks like you haven't added any models yet. Add one now!</p>
      <Button className="mt-4" cva={{ intent: "primary" }} onClick={() => navigate("/monitoring/add")}>
        Add model
      </Button>
    </div>
  );
};
