import { ObjectValues } from "@/constants/enums";
import { ProblemTypeOptions } from "@/domains/model/modelOptions";
import {
  BinaryClassificationMetrics,
  ClassificationMetrics,
  PerformanceMetricOptions,
  RegressionMetrics,
  TypePerformanceMetricOptions,
} from "@/domains/performance/performanceOptions";

export const ConfusionMatrixComponentsOptions = {
  TruePositive: "TruePositive",
  TrueNegative: "TrueNegative",
  FalsePositive: "FalsePositive",
  FalseNegative: "FalseNegative",
} as const;

export type TypeConfusionMatrixComponentsOptions = ObjectValues<typeof ConfusionMatrixComponentsOptions>;

export const PerformanceMetricComponents = [
  getSameMetricComponent(PerformanceMetricOptions.ROC_AUC),
  getSameMetricComponent(PerformanceMetricOptions.F1),
  getSameMetricComponent(PerformanceMetricOptions.Precision),
  getSameMetricComponent(PerformanceMetricOptions.Recall),
  getSameMetricComponent(PerformanceMetricOptions.Specificity),
  getSameMetricComponent(PerformanceMetricOptions.Accuracy),
  getSameMetricComponent(PerformanceMetricOptions.BusinessValue),
  getSameMetricComponent(PerformanceMetricOptions.AveragePrecision),

  getSameMetricComponent(PerformanceMetricOptions.MAE),
  getSameMetricComponent(PerformanceMetricOptions.MAPE),
  getSameMetricComponent(PerformanceMetricOptions.MSE),
  getSameMetricComponent(PerformanceMetricOptions.RMSE),
  getSameMetricComponent(PerformanceMetricOptions.MSLE),
  getSameMetricComponent(PerformanceMetricOptions.RMSLE),

  getConfusionMatrixComponent(ConfusionMatrixComponentsOptions.TruePositive),
  getConfusionMatrixComponent(ConfusionMatrixComponentsOptions.TrueNegative),
  getConfusionMatrixComponent(ConfusionMatrixComponentsOptions.FalsePositive),
  getConfusionMatrixComponent(ConfusionMatrixComponentsOptions.FalseNegative),
];

export const ProblemTypeToPerformanceMetricComponents = {
  [ProblemTypeOptions.BinaryClassification]: BinaryClassificationMetrics.flatMap(filterComponentByMetric),
  [ProblemTypeOptions.MulticlassClassification]: ClassificationMetrics.flatMap(filterComponentByMetric),
  [ProblemTypeOptions.Regression]: RegressionMetrics.flatMap(filterComponentByMetric),
} as const;

function filterComponentByMetric(metric: TypePerformanceMetricOptions) {
  return PerformanceMetricComponents.filter((component) => component.metric === metric);
}

function getSameMetricComponent(metric: TypePerformanceMetricOptions) {
  return {
    metric,
    componentName: metric,
  };
}

function getConfusionMatrixComponent(component: TypeConfusionMatrixComponentsOptions) {
  return {
    metric: PerformanceMetricOptions.ConfusionMatrix,
    componentName: component,
  };
}
