import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";
import { useEffect } from "react";

import { FragmentType, RunState, gql, useFragment } from "@/apis/nannyml";
import * as RunStatus from "@/components/RunStatus";
import { useStartMonitoringModelRun } from "@/hooks/monitoring";
import { useParamsModelId } from "@/routes/useParamsModelId";

const monitoringRunStatusFragment = gql(/* GraphQL */ `
  fragment MonitoringRunStatus on Run {
    id
    state
    scheduledFor
    startedAt
    completedAt
    ranSuccessfully
  }
`);

const pollMonitoringModelRunStatusQuery = gql(/* GraphQL */ `
  query PollMonitoringModelRunStatus($modelId: Int!) {
    monitoring_model(id: $modelId) {
      latestRun {
        id
        state
        startedAt
        ranSuccessfully
        events {
          eventType
          timestamp
          currentStep
          nrSteps
          description
        }
      }
    }
  }
`);

export const cancelMonitoringModelRunMutation = gql(/* GraphQL */ `
  mutation CancelMonitoringModelRun($modelId: Int!) {
    stop_monitoring_model_run(modelId: $modelId) {
      id
    }
  }
`);

export const LatestRunStatus = (props: {
  className?: string;
  run: FragmentType<typeof monitoringRunStatusFragment> | null;
}) => {
  const run = useFragment(monitoringRunStatusFragment, props.run) ?? null;

  if (run?.state === RunState.Running || run?.state === RunState.Cancelling) {
    return <ActiveRunStatus className={props.className} />;
  } else {
    return <InactiveRunStatus className={props.className} run={run} />;
  }
};

const InactiveRunStatus = ({
  className,
  run,
}: {
  className?: string;
  run: ResultOf<typeof monitoringRunStatusFragment> | null;
}) => {
  const modelId = useParamsModelId();
  const [startRun, { loading }] = useStartMonitoringModelRun(modelId);

  return <RunStatus.InactiveRunStatus className={className} run={run} startRun={startRun} isStarting={loading} />;
};

const ActiveRunStatus = (props: { className?: string }) => {
  const modelId = useParamsModelId();
  const client = useApolloClient();
  const { data } = useQuery(pollMonitoringModelRunStatusQuery, {
    variables: { modelId: modelId },
    pollInterval: 1000,
  });
  const run = data?.monitoring_model?.latestRun;
  const [cancelRun, { loading }] = useMutation(cancelMonitoringModelRunMutation, {
    variables: { modelId },
  });

  useEffect(() => {
    if (run?.state === RunState.Completed) {
      // Invalidate the cache for the model when the run completes
      client.cache.evict({ id: `Model:${modelId}` });
    }
  }, [run?.state]);

  return (
    <RunStatus.ActiveRunStatus className={props.className} run={run} cancelRun={cancelRun} isCancelling={loading} />
  );
};
