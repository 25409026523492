import { Alert } from "@/components/Alert";

export const DataRequirements = () => (
  <div className="space-y-4 max-w-[900px] w-full">
    <p>
      This wizard will guide you through the process for creating a new model. If you're not familiar with NannyML's
      concept of a reference and analysis period, please read{" "}
      <a className="underline" href="https://docs.nannyml.com/cloud/model-monitoring/data-preparation">
        the data requirements documentation
      </a>{" "}
      first.
    </p>
    <p className="pt-4">Brief overview of data requirements:</p>
    <ul className="list-disc pl-4">
      <li>The same column names must be used in reference, analysis and target datasets.</li>
      <li>All datasets must have a single identifier column to uniquely identify the row.</li>
      <li>The reference and analysis datasets must have a timestamp column.</li>
      <li>Targets are optional for the analysis period, but some algorithms will be disabled if not available.</li>
    </ul>
    <Alert severity="info" title="Note">
      This wizard sets up a model and imports the data. It does not set up automatic synchronization with the selected
      data source. Once the model is created, you can add more analysis data using the SDK or model settings page for
      monitoring model performance over time.
    </Alert>
  </div>
);
