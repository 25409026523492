import { useSuspenseQuery } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";
import { SearchXIcon } from "lucide-react";

import { Text } from "@/DesignSystem/basic/Text/Text";
import { gql } from "@/apis/nannyml";
import { useExperimentId } from "@/hooks/experiment";

import { LatestRunStatus } from "./LatestRunStatus";
import { MetricCard } from "./MetricCard";

const getExperimentSummaryQuery = gql(/* GraphQL */ `
  query GetExperimentSummary($experimentId: Int!) {
    experiment(id: $experimentId) {
      id
      name
      results {
        metric
        ...ExperimentMetricResultSummary
      }
      latestRun {
        ...ExperimentRunStatus
      }
    }
  }
`);

export const ExperimentSummary = () => {
  const experimentId = useExperimentId();
  const {
    data: { experiment },
  } = useSuspenseQuery(getExperimentSummaryQuery, {
    variables: { experimentId },
  });

  if (!experiment) {
    return <div>Experiment not found!</div>;
  }

  return (
    <div className="p-4 h-full flex flex-col gap-4">
      <Text size="large" stroke="boldLight">
        Summary
      </Text>
      <LatestRunStatus run={experiment.latestRun ?? null} />
      {experiment.results.length > 0 ? (
        <SummaryContent experimentId={experimentId} results={experiment.results} />
      ) : (
        <NoResultsPlaceholder />
      )}
    </div>
  );
};

const SummaryContent = ({
  experimentId,
  results,
}: {
  experimentId: number;
  results: NonNullable<ResultOf<typeof getExperimentSummaryQuery>["experiment"]>["results"];
}) => (
  <>
    <Text size="medium2" className="pb-1 border-b border-gray-600">
      Performance
    </Text>
    <div className="flex flex-wrap gap-4">
      {results.map((result) => (
        <MetricCard key={result.metric} result={result} to={`/experiment/${experimentId}/metrics`} />
      ))}
    </div>
  </>
);

const NoResultsPlaceholder = () => (
  <div className="flex-grow h-full flex flex-col justify-center items-center gap-4 text-gray-400">
    <SearchXIcon size="128" />
    <p>Looks like there are no results available yet for this experiment.</p>
    <p>Once the first run is completed, you will see the results here.</p>
  </div>
);
