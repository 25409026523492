import { useSuspenseQuery } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";
import { useParams } from "react-router-dom";

import { gql, useFragment } from "@/apis/nannyml";
import { FilterMetrics, FilterTags, PersistentFilterProvider } from "@/components/Filters";
import { LabeledField } from "@/components/LabeledField";
import { PlotView } from "@/components/PlotView";
import { PanelItem } from "@/components/ResizablePanel";
import { FilterBayesianSortOrder } from "@/components/bayesian";
import { FilterBayesianStatus } from "@/components/bayesian";
import {
  BayesianPlotConfigContextProvider,
  BayesianPlotElementsConfig,
  BayesianPlotTypesConfig,
} from "@/components/bayesian/PlotConfig";
import { ExperimentMetricResultPlot } from "@/components/experiment";
import { performanceMetricLabels } from "@/formatters/monitoring";
import { useExperimentId } from "@/hooks/experiment";

const experimentResultMetricFilterFragment = gql(/* GraphQL */ `
  fragment ExperimentResultMetricFilterFragment on ExperimentResultMetric {
    metricName: metric
    status
    tags
    latestHdi {
      hdiLower
      hdiUpper
    }
  }
`);

const getExperimentResultsQuery = gql(/* GraphQL */ `
  query GetExperimentResults($experimentId: Int!) {
    experiment(id: $experimentId) {
      results {
        metric
        ...ExperimentResultMetricPlotFragment
        ...ExperimentResultMetricFilterFragment
      }
    }
  }
`);

export const ExperimentResultsRoute = ({ resultView }: { resultView: string }) => {
  const experimentId = useExperimentId();

  const {
    data: { experiment },
  } = useSuspenseQuery(getExperimentResultsQuery, {
    variables: { experimentId },
  });

  if (!experiment) {
    throw new Error("Model not found");
  }

  const results = useFragment(experimentResultMetricFilterFragment, experiment.results);

  return (
    <BayesianPlotConfigContextProvider storeName={resultView}>
      <PersistentFilterProvider storeName={`ExperimentResultFilterStore.${resultView}`} items={results}>
        <PlotView
          autoSaveId="ExperimentResultView"
          filters={
            <>
              <PanelItem title="Metrics">
                <FilterMetrics metricLabels={performanceMetricLabels} />
              </PanelItem>
              <PanelItem title="Experiment status">
                <FilterBayesianStatus />
              </PanelItem>
              <PanelItem title="Tags">
                <FilterTags />
              </PanelItem>
            </>
          }
          toolbar={
            <LabeledField label="Sort by" className="mr-auto">
              <FilterBayesianSortOrder />
            </LabeledField>
          }
          config={
            <>
              <PanelItem title="Plots">
                <BayesianPlotTypesConfig />
              </PanelItem>
              <PanelItem title="Plot elements">
                <BayesianPlotElementsConfig />
              </PanelItem>
            </>
          }
          PlotComponent={ExperimentResultGroupPlot}
          plotHeight={540}
          getKey={(result) => result.metric}
        />
      </PersistentFilterProvider>
    </BayesianPlotConfigContextProvider>
  );
};

export const ExperimentResultGroupPlot = (props: {
  className?: string;
  results: NonNullable<ResultOf<typeof getExperimentResultsQuery>["experiment"]>["results"];
}) => <ExperimentMetricResultPlot className={props.className} result={props.results[0]} />;
