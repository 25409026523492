import { Dispatch, SetStateAction, useId } from "react";

import { Input } from "@/DesignSystem/shadcn/Input";
import { Label } from "@/DesignSystem/shadcn/Label/Label";
import { CreateEvaluationModelInput, ProblemType, EvaluationHypothesis } from "@/apis/nannyml";
import { Select, SelectItem } from "@/components/Select";
import { SimpleInput } from "@/components/common/FormElements/SimpleInput/SimpleInput";
import { evaluationHypothesisLabels } from "@/formatters/evaluation";
import { problemTypeLabels } from "@/formatters/models";

export const ModelDetails = ({
  value,
  onChange,
}: {
  value: Partial<CreateEvaluationModelInput>;
  onChange: Dispatch<SetStateAction<Partial<CreateEvaluationModelInput>>>;
}) => {
  const idProblemType = useId();
  const idHypothesis = useId();
  const idThreshold = useId();

  return (
    <div className="flex flex-col gap-8 min-w-[55ch]">
      <div className="flex flex-col gap-2">
        <Label htmlFor={idProblemType} className="text-lg">
          What is your problem type?
        </Label>
        <Select
          id={idProblemType}
          value={value.problemType}
          onValueChange={(problemType) => onChange({ problemType: problemType as ProblemType })}
        >
          <SelectItem value={ProblemType.BinaryClassification}>
            {problemTypeLabels[ProblemType.BinaryClassification]}
          </SelectItem>
        </Select>
      </div>
      <div className="flex flex-col gap-2">
        <Label htmlFor={idHypothesis} className="text-lg">
          What is your evaluation hypothesis?
        </Label>
        <Select
          id={idHypothesis}
          value={value.hypothesis}
          onValueChange={(hypothesis) => onChange({ hypothesis: hypothesis as EvaluationHypothesis })}
          placeholder="Select hypothesis"
        >
          {Object.values(EvaluationHypothesis).map((hypothesis) => (
            <SelectItem key={hypothesis} value={hypothesis}>
              {evaluationHypothesisLabels[hypothesis]}
            </SelectItem>
          ))}
        </Select>
      </div>
      <div className="flex flex-col gap-2">
        <Label htmlFor={idThreshold} className="text-lg">
          What is the classification threshold of your model?
        </Label>
        <Input
          id={idThreshold}
          value={value.classificationThreshold ?? ""}
          onChange={(e) => onChange({ classificationThreshold: parseFloat(e.target.value) })}
          onBlur={(e) => e.target.reportValidity()}
          placeholder="Define the threshold your model uses to return a positive prediction"
          className="hide-arrows"
          type="number"
          min="0.01"
          max="1"
          step="any"
        />
      </div>
      <SimpleInput
        label="What shall we call your model?"
        value={value.name ?? ""}
        onChange={(name) => onChange({ name })}
        labelClassName="text-lg"
        placeholder="Provide a memorable name for your model"
      />
    </div>
  );
};
