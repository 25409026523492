import { useSuspenseQuery } from "@apollo/client";
import { Suspense } from "react";

import { gql } from "@/apis/nannyml";
import { DataSourceOverview } from "@/components/DataSource";
import { RequestStateLayout } from "@/components/dashboard/RequestStateLayout/RequestStateLayout";
import { useModelContext } from "@/components/monitoring/ModelContext";

const getMonitoringModelData = gql(/* GraphQL */ `
  query GetMonitoringModelData($modelId: Int!) {
    monitoring_model(id: $modelId) {
      id
      dataSources {
        ...DataSourceDetails
      }
    }
  }
`);

export const ModelDataSettings = ({ className }: { className?: string }) => (
  <div className="p-6 flex flex-col gap-1">
    <h3 className="text-2xl font-semibold">Data</h3>
    <span className="text-sm text-gray-400 mb-6">Add data to the data sources associated with the model</span>
    <Suspense fallback={<RequestStateLayout isLoading loaderText="Loading data sources..." />}>
      <ModelDataSettingsInner className={className} />
    </Suspense>
  </div>
);

const ModelDataSettingsInner = ({ className }: { className?: string }) => {
  const { id: modelId } = useModelContext();
  if (!modelId) {
    throw new Error("Model ID is required to fetch model data");
  }

  const { data } = useSuspenseQuery(getMonitoringModelData, { variables: { modelId } });
  if (!data.monitoring_model) {
    throw new Error(`Model with id ${modelId} not found`);
  }

  return <DataSourceOverview className={className} dataSources={data.monitoring_model?.dataSources} />;
};
