import _ from "lodash";
import { FileSlidersIcon } from "lucide-react";
import React from "react";
import Plot from "react-plotly.js";
import { useResizeDetector } from "react-resize-detector";

import { BayesianPlotDataset, BayesianPlotElements, BayesianPlotType, BayesianStatus } from "@/constants/bayesian";
import * as colors from "@/constants/colors";
import { getHdiLabel, getHdiWidthLabel, getRopeLabel, nrSignificantDigits } from "@/formatters/bayesian";
import { bayesianFillColors, bayesianTraceColors } from "@/formatters/bayesian";
import { getValueRange } from "@/lib/listUtils";
import { isNullish } from "@/lib/typesUtils";
import { cn } from "@/lib/utils";

import { BayesianPlotConfig, useBayesianPlotConfig } from "../PlotConfig";

export type BayesianResult = {
  metric: string;
  status: BayesianStatus;
  hdiWidthReached: boolean;
  experimentDistribution: DistributionDataPoint[];
  referenceDistribution?: DistributionDataPoint[];
  hdiData: HdiDataPoint[];
  config: {
    ropeLowerBound?: number | null;
    ropeUpperBound?: number | null;
    hdiWidth?: number | null;
  };
};

type DistributionDataPoint = {
  x: number;
  y: number;
};

type HdiDataPoint = {
  nrObservations: number;
  hdiLower: number;
  hdiUpper: number;
  cut: number;
};

type AugmentedHdiDataPoint = HdiDataPoint & {
  hdiWidthReached: boolean;
  status: BayesianStatus;
};

export type BayesianPlotCustomization = {
  getMetricName: (metric: string) => string;
  evaluationDatasetName: string;
  TitleComponent: React.FC<{ result: BayesianResult }>;
};

export const BayesianResultPlot = React.memo(
  ({
    className,
    result,
    customization,
  }: {
    className?: string;
    result: BayesianResult;
    customization: BayesianPlotCustomization;
  }) => {
    const { TitleComponent } = customization;
    const { width, ref } = useResizeDetector({ refreshMode: "debounce", refreshRate: 100 });
    const plotConfig = useBayesianPlotConfig();

    return (
      <div className={className}>
        <TitleComponent result={result} />
        <div ref={ref} className="whitespace-nowrap">
          {plotConfig.types.includes(BayesianPlotType.Progression) && width && (
            <Plot
              data={getHdiPlotTraces(result, plotConfig, customization)}
              layout={getHdiPlotLayout(
                result,
                plotConfig,
                customization,
                width ? width / plotConfig.types.length : undefined
              )}
              config={{ displayModeBar: false }}
            />
          )}
          {plotConfig.types.includes(BayesianPlotType.Distribution) && width && (
            <Plot
              data={getDistributionPlotTraces(result, plotConfig, customization)}
              layout={getDistributionPlotLayout(
                result,
                plotConfig,
                customization,
                width ? width / plotConfig.types.length : undefined
              )}
              config={{ displayModeBar: false }}
            />
          )}
          {plotConfig.types.length === 0 && (
            <div
              className={cn(
                "h-[448px] flex flex-col gap-4 items-center justify-center mt-2 text-gray-400",
                "rounded-xl border border-dashed border-gray-600"
              )}
            >
              <FileSlidersIcon size={96} />
              <p>No plot formats selected</p>
            </div>
          )}
        </div>
      </div>
    );
  }
);

/**
 * Generate a plot trace for HDI data
 * @param name Name to use for the trace
 * @param data HDI data to be plotted
 * @returns
 */
const getHdiPlotTraces = (
  result: BayesianResult,
  plotConfig: BayesianPlotConfig,
  customization: BayesianPlotCustomization
): Partial<Plotly.PlotData>[] => {
  const traces: Partial<Plotly.PlotData>[] = [];

  if (plotConfig.datasets.includes(BayesianPlotDataset.Evaluation) && result.hdiData.length > 0) {
    const data = result.hdiData.map((dp) => {
      const hdiWidthReached = result.config.hdiWidth ? dp.hdiUpper - dp.hdiLower < result.config.hdiWidth : false;
      return {
        ...dp,
        hdiWidthReached,
        status:
          !hdiWidthReached || isNullish(result.config.ropeLowerBound) || isNullish(result.config.ropeUpperBound)
            ? BayesianStatus.Ongoing
            : dp.hdiLower >= result.config.ropeLowerBound && dp.hdiUpper <= result.config.ropeUpperBound
            ? BayesianStatus.Accepted
            : dp.hdiLower > result.config.ropeUpperBound || dp.hdiUpper < result.config.ropeLowerBound
            ? BayesianStatus.Rejected
            : BayesianStatus.Ongoing,
      };
    });

    const groups = [[data[0]]];
    data.reduce((prev, curr) => {
      groups.at(-1)?.push(curr);
      if (curr.status !== prev.status || curr.hdiWidthReached !== prev.hdiWidthReached) {
        groups.push([curr]);
      }
      return curr;
    });

    const [hoverTemplate, getHoverTemplateData] = generateHdiTraceHoverTemplate(result, customization);

    traces.push(
      ...groups.flatMap((group) => {
        const { status, hdiWidthReached } = group[0];
        return [
          {
            mode: "lines+markers",
            line: {
              color: bayesianTraceColors[status],
            },
            hovertemplate: hoverTemplate,
            customdata: group.map(getHoverTemplateData),
            showlegend: false,
            x: group.map((dp) => dp.nrObservations),
            y: group.map((dp) => dp.hdiLower),
            type: "scatter",
          },
          {
            name: hdiWidthReached
              ? `95% HDI of ${customization.getMetricName(result.metric)} (${status})`
              : `95% HDI of ${customization.getMetricName(result.metric)} (HDI width not reached)`,
            legendgroup: "hdi",
            mode: "lines+markers",
            line: {
              color: bayesianTraceColors[status],
            },
            fill: "tonexty",
            fillcolor: bayesianFillColors[status],
            fillpattern: {
              shape: hdiWidthReached ? "" : "/",
            },
            hovertemplate: hoverTemplate,
            customdata: group.map(getHoverTemplateData),
            showlegend: true,
            x: group.map((dp) => dp.nrObservations),
            y: group.map((dp) => dp.hdiUpper),
            type: "scatter",
          },
        ] as const;
      })
    );
  }

  if (plotConfig.elements.includes(BayesianPlotElements.Rope)) {
    traces.push({
      name: "ROPE",
      legendgroup: "rope",
      mode: "lines",
      line: {
        color: colors.thresholdLineColor,
        dash: "dash",
      },
      hovertemplate: `Region Of Practical Equivalence (<b style="color:${colors.thresholdLineColor}">ROPE</b>)<br />
        ${getRopeLabel(result.config, result.config)}
        <extra></extra>`,
      customdata: [],
      showlegend: true,
      x: [
        0,
        ...result.hdiData.map((dp) => dp.nrObservations),
        null,
        0,
        ...result.hdiData.map((dp) => dp.nrObservations),
      ],
      y: [
        ...Array(result.hdiData.length + 1).fill(result.config.ropeLowerBound),
        null,
        ...Array(result.hdiData.length + 1).fill(result.config.ropeUpperBound),
      ],
      xaxis: "x",
      yaxis: "y",
    });
  }

  // Disable duplicate legend entries
  const legendEntries = new Set();
  traces.forEach((trace) => {
    trace.showlegend &&= !legendEntries.has(trace.name);

    if (trace.showlegend) {
      legendEntries.add(trace.name);
    }
  });

  return traces;
};

const getDistributionPlotTraces = (
  result: BayesianResult,
  plotConfig: BayesianPlotConfig,
  customization: BayesianPlotCustomization
): Partial<Plotly.PlotData>[] => {
  const [evalHoverTemplate, getEvalHoverTemplateData] = generateDistributionTraceHoverTemplate(
    result,
    false,
    colors.analysisLineColor,
    customization
  );
  const [refHoverTemplate, getRefHoverTemplateData] = generateDistributionTraceHoverTemplate(
    result,
    true,
    colors.comparisonLineColorAnalysis,
    customization
  );
  const [minY, maxY] = getValueRange(
    result.experimentDistribution.concat(result?.referenceDistribution ?? []),
    (dp) => [dp.y, dp.y]
  );

  const traces: Partial<Plotly.PlotData>[] = [];

  if (plotConfig.datasets.includes(BayesianPlotDataset.Reference) && result.referenceDistribution) {
    traces.push({
      name: "Reference posterior",
      legendgroup: "posterior",
      mode: "lines",
      line: {
        color: colors.comparisonLineColorAnalysis,
      },
      hovertemplate: refHoverTemplate,
      customdata: result.referenceDistribution.map(getRefHoverTemplateData),
      showlegend: true,
      fill: "tozeroy",
      fillcolor: colors.comparisonReferenceConfidenceBandColor,
      x: result.referenceDistribution.map((dp) => dp.x),
      y: result.referenceDistribution.map((dp) => dp.y),
      type: "scatter",
    });
  }

  if (plotConfig.datasets.includes(BayesianPlotDataset.Evaluation)) {
    traces.push({
      name: `${customization.evaluationDatasetName} posterior`,
      legendgroup: "posterior",
      mode: "lines",
      line: {
        color: colors.analysisLineColor,
      },
      hovertemplate: evalHoverTemplate,
      customdata: result.experimentDistribution.map(getEvalHoverTemplateData),
      showlegend: true,
      fill: "tozeroy",
      fillcolor: colors.analysisConfidenceBandColor,
      x: result.experimentDistribution.map((dp) => dp.x),
      y: result.experimentDistribution.map((dp) => dp.y),
      type: "scatter",
    });

    const hdi = result.hdiData.at(-1);
    if (hdi && result.experimentDistribution.length > 0) {
      traces.push({
        name: `${customization.evaluationDatasetName} 95% HDI`,
        legendgroup: "hdi",
        mode: "lines",
        line: {
          color: colors.analysisLineColor,
          dash: "dash",
        },
        hoverinfo: "skip",
        showlegend: true,
        x: [hdi.hdiLower, hdi.hdiUpper],
        y: [hdi.cut, hdi.cut],
      });
    }
  }

  if (plotConfig.elements.includes(BayesianPlotElements.Rope)) {
    traces.push({
      name: "ROPE",
      legendgroup: "rope",
      mode: "lines",
      line: {
        color: colors.thresholdLineColor,
        dash: "dash",
      },
      hoverinfo: "skip",
      showlegend: true,
      x: [
        result.config.ropeLowerBound ?? null,
        result.config.ropeLowerBound ?? null,
        null,
        result.config.ropeUpperBound ?? null,
        result.config.ropeUpperBound ?? null,
      ],
      y: [minY, maxY, null, minY, maxY],
    });
  }

  return traces;
};

/**
 * Generates Plotly hover template for an HDI trace
 * @param result Result to generate hover information for
 * @returns A template string and a function to generate hover `customdata` input
 */
const generateHdiTraceHoverTemplate = (
  result: BayesianResult,
  customization: BayesianPlotCustomization
): [string, (dataPoint: AugmentedHdiDataPoint) => (string | number)[]] => [
  `<b>%{customdata[0]}</b> &nbsp; &nbsp; %{customdata[1]}<br />
    HDI: <b>%{customdata[2]}</b> (%{customdata[3]} observations)<br />
    HDI width: %{customdata[4]} (%{customdata[5]} required)
    <extra></extra>`,
  (dp: AugmentedHdiDataPoint) => [
    customization.getMetricName(result.metric),
    `<b style="color:${bayesianTraceColors[dp.status]}">${dp.status}</b>`,
    getHdiLabel(dp, result.config),
    dp.nrObservations,
    dp.hdiWidthReached ? "Reached" : "Not reached",
    getHdiWidthLabel(result.config),
  ],
];

/**
 * Generates Plotly hover template for a distribution trace
 * @param result Result to generate hover information for
 * @param metricColor
 * @returns A template string and a function to generate hover `customdata` input
 */
const generateDistributionTraceHoverTemplate = (
  result: BayesianResult,
  isReference: boolean,
  metricColor: string,
  customization: BayesianPlotCustomization
): [string, (dataPoint: DistributionDataPoint) => (string | number)[]] => {
  const distributionRef = `<b style="color:${metricColor}">${
    isReference ? "Reference" : customization.evaluationDatasetName
  }</b>`;
  return [
    `<b>%{customdata[0]}</b> &nbsp; &nbsp; %{customdata[1]}<br />
      Value: %{customdata[2]}
      Probability density: %{customdata[3]}
      <extra></extra>`,
    (dp: DistributionDataPoint) => [
      customization.getMetricName(result.metric),
      distributionRef,
      dp.x.toPrecision(nrSignificantDigits),
      dp.y.toPrecision(nrSignificantDigits),
    ],
  ];
};

/**
 * Get plot layout for an HDI plot
 * @param width Width of the plot
 * @returns Plotly layout
 */
const getHdiPlotLayout = (
  result: BayesianResult,
  config: BayesianPlotConfig,
  customization: BayesianPlotCustomization,
  width?: number
): Partial<Plotly.Layout> =>
  _.merge({}, defaultPlotLayout, {
    showlegend: config.elements.includes(BayesianPlotElements.Legend),
    width,
    xaxis: {
      title: {
        text: "Number of observations",
      },
      range: result.hdiData.length && [0, result.hdiData.at(-1)!.nrObservations * 1.05],
      showgrid: config.elements.includes(BayesianPlotElements.Grid),
    },
    yaxis: {
      title: {
        text: `${customization.getMetricName(result.metric)}`,
      },
      showgrid: config.elements.includes(BayesianPlotElements.Grid),
    },
  });

/**
 * Get plot layout for a distribution plot
 * @param width Width of the plot
 * @returns Plotly layout
 */
const getDistributionPlotLayout = (
  result: BayesianResult,
  config: BayesianPlotConfig,
  customization: BayesianPlotCustomization,
  width?: number
): Partial<Plotly.Layout> => {
  const xMin = _.min([
    result.experimentDistribution[0]?.x,
    result.referenceDistribution?.[0]?.x,
    result.config.ropeLowerBound,
  ]);
  const xMax = _.max([
    result.experimentDistribution.at(-1)?.x,
    result.referenceDistribution?.at(-1)?.x,
    result.config.ropeUpperBound,
  ]);

  return _.merge({}, defaultPlotLayout, {
    showlegend: config.elements.includes(BayesianPlotElements.Legend),
    width,
    hovermode: "x unified",
    xaxis: {
      title: {
        text: customization.getMetricName(result.metric),
      },
      range: xMin && xMax && [xMin * (xMin > 0 ? 0.95 : 1.05), xMax * 1.05],
      showgrid: config.elements.includes(BayesianPlotElements.Grid),
    },
    yaxis: {
      title: {
        text: "Probability density",
      },
      showgrid: config.elements.includes(BayesianPlotElements.Grid),
    },
  });
};

const defaultPlotLayout: Partial<Plotly.Layout> = {
  autosize: true,
  paper_bgcolor: "transparent",
  plot_bgcolor: "transparent",
  legend: {
    orientation: "h",
    traceorder: "grouped",
    itemclick: false,
    itemdoubleclick: false,
    y: -0.15,
  },
  hovermode: "closest",
  hoverlabel: {
    align: "left",
    bgcolor: "black",
    font: {
      color: "white",
    },
  },
  xaxis: {
    linecolor: colors.lineColor,
    gridcolor: colors.gridColor,
    griddash: "dash",
    zeroline: false,
    mirror: true,
    title: {
      standoff: 15,
    },
  },
  yaxis: {
    linecolor: colors.lineColor,
    gridcolor: colors.gridColor,
    griddash: "dash",
    zeroline: false,
    mirror: true,
    title: {
      standoff: 5,
    },
  },
  margin: {
    t: 24,
    r: 32,
    b: 36,
  },
  font: {
    color: "white",
  },
  grid: {
    xgap: 0.12,
  },
};
