import { useQuery } from "@apollo/client";
import { Loader2Icon, XCircleIcon } from "lucide-react";
import { Dispatch, SetStateAction } from "react";

import { Chunking, CreateModelInput, RuntimeConfigInput, gql } from "@/apis/nannyml";
import { MonitoringRuntimeConfig } from "@/components/monitoring";
import { useModelContext } from "@/components/monitoring/ModelContext";

const getDefaultRuntimeConfig = gql(/* GraphQL */ `
  query GetDefaultMonitoringConfig($input: GetDefaultMonitoringRuntimeConfigInput!) {
    get_default_monitoring_runtime_config(input: $input) {
      ...MonitoringRuntimeConfigDetails
    }
  }
`);

export const MetricConfig = ({
  modelInput,
  onModelInputChange,
  chunking,
  nrOfRows,
  classes,
}: {
  modelInput: Pick<CreateModelInput, "runtimeConfig" | "kpm">;
  onModelInputChange: Dispatch<SetStateAction<Partial<CreateModelInput>>>;
  chunking: Chunking;
  nrOfRows: number | null;
  classes?: string[];
}) => {
  const { problemType, schema } = useModelContext();
  const { data, loading, error } = useQuery(getDefaultRuntimeConfig, {
    variables: {
      input: {
        problemType,
        schema,
        chunking: chunking,
        nrOfRows: nrOfRows,
      },
    },
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full gap-2">
        <span>Generating defaults for you...</span>
        <Loader2Icon className="animate-spin" size={20} />
      </div>
    );
  } else if (error) {
    return (
      <div className="flex items-center justify-center h-full gap-2">
        <XCircleIcon className="text-red-500" />
        <span>Failed to generate smart defaults: {error.message}</span>
      </div>
    );
  }

  const config = data!.get_default_monitoring_runtime_config;

  return (
    <MonitoringRuntimeConfig
      problemType={problemType}
      classes={classes}
      config={config}
      kpm={modelInput.kpm}
      value={modelInput.runtimeConfig}
      onKpmChange={(kpm) => onModelInputChange({ kpm })}
      onValueChange={(value) =>
        onModelInputChange(({ runtimeConfig, ...rest }) => ({
          ...rest,
          runtimeConfig: { ...runtimeConfig, ...(value as RuntimeConfigInput) },
        }))
      }
    />
  );
};
