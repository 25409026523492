import { useSuspenseQuery } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";
import { SearchXIcon } from "lucide-react";

import { Text } from "@/DesignSystem/basic/Text/Text";
import { gql } from "@/apis/nannyml";
import { useEvaluationModelId } from "@/hooks/evaluation";

import { LatestRunStatus } from "./LatestRunStatus";
import { MetricCard } from "./MetricCard";

const getEvaluationModelSummaryQuery = gql(/* GraphQL */ `
  query GetEvaluationModelSummary($modelId: Int!) {
    evaluation_model(id: $modelId) {
      id
      name
      results {
        metric
        ...EvaluationPerformanceResultSummary
      }
      latestRun {
        ...EvaluationRunStatus
      }
    }
  }
`);

export const EvaluationModelSummary = () => {
  const modelId = useEvaluationModelId();
  const {
    data: { evaluation_model: model },
  } = useSuspenseQuery(getEvaluationModelSummaryQuery, {
    variables: { modelId },
  });

  if (!model) {
    return <div>Model not found!</div>;
  }

  return (
    <div className="p-4 h-full flex flex-col gap-4">
      <Text size="large" stroke="boldLight">
        Summary
      </Text>
      <LatestRunStatus run={model.latestRun ?? null} />
      {model.results.length > 0 ? (
        <SummaryContent modelId={modelId} results={model.results} />
      ) : (
        <NoResultsPlaceholder />
      )}
    </div>
  );
};

const SummaryContent = ({
  modelId,
  results,
}: {
  modelId: number;
  results: NonNullable<ResultOf<typeof getEvaluationModelSummaryQuery>["evaluation_model"]>["results"];
}) => (
  <>
    <Text size="medium2" className="pb-1 border-b border-gray-600">
      Performance
    </Text>
    <div className="flex flex-wrap gap-4">
      {results.map((result) => (
        <MetricCard key={result.metric} result={result} to={`/evaluation/${modelId}/performance`} />
      ))}
    </div>
  </>
);

const NoResultsPlaceholder = () => (
  <div className="flex-grow h-full flex flex-col justify-center items-center gap-4 text-gray-400">
    <SearchXIcon size="128" />
    <p>Looks like there are no results available yet for this evaluation model.</p>
    <p>Once the first run is completed, you will see the results here.</p>
  </div>
);
