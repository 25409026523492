import { useEffect } from "react";

import { Label } from "@/DesignSystem/shadcn/Label/Label";
import { S3AuthenticationMode, S3StorageInput } from "@/apis/nannyml";
import { Select, SelectItem } from "@/components/Select";
import { SimpleInput } from "@/components/common/FormElements/SimpleInput/SimpleInput";
import { InformationModalChip } from "@/components/dashboard/InformationModal/InformationModalChip";
import { s3AuthenticationModeLabels } from "@/formatters/dataSources";
import { cn } from "@/lib/utils";

export const AmazonS3 = ({
  className,
  value,
  onChange,
  reference,
}: {
  className?: string;
  value: Partial<S3StorageInput> | null | undefined;
  onChange: (value: Partial<S3StorageInput>) => void;
  reference?: Partial<S3StorageInput> | null;
}) => {
  useEffect(() => {
    // Copy reference values if value is not set
    if (!value && reference) {
      const { uri, ...rest } = reference;
      onChange(rest);
    }
    // Default to IAM role if authentication mode is not set
    else if (!value?.authenticationMode) {
      onChange({ ...value, authenticationMode: S3AuthenticationMode.Integrated });
    }
  }, []);

  return (
    <div className={cn("flex flex-col gap-6 min-w-[55ch]", className)}>
      <SimpleInput
        label="S3 URI"
        value={value?.uri ?? ""}
        onChange={(uri) => onChange({ ...value, uri })}
        labelClassName="text-lg"
        placeholder="e.g. s3://my-bucket/my-data.csv"
        required
      />
      <Label className="flex flex-col gap-2 font-normal">
        <div className="flex">
          <span className="text-lg font-medium">Authentication mode</span>
          <InformationModalChip infoName="S3 authentication mode" />
        </div>
        <Select
          defaultValue={S3AuthenticationMode.Integrated}
          value={value?.authenticationMode}
          onValueChange={(authMode) => onChange({ ...value, authenticationMode: authMode as S3AuthenticationMode })}
          placeholder="How do you want to authenticate?"
        >
          {Object.values(S3AuthenticationMode).map((authMode) => (
            <SelectItem key={authMode} value={authMode}>
              {s3AuthenticationModeLabels[authMode]}
            </SelectItem>
          ))}
        </Select>
      </Label>
      {value?.authenticationMode === S3AuthenticationMode.AccessKey && (
        <>
          <SimpleInput
            label="Access key ID"
            value={value.awsAccessKeyId ?? ""}
            onChange={(awsAccessKeyId) => onChange({ ...value, awsAccessKeyId })}
            labelClassName="text-lg"
            placeholder="Enter the ID for your access key."
          />
          <SimpleInput
            label="Secret access key"
            value={value.awsSecretAccessKey ?? ""}
            onChange={(awsSecretAccessKey) => onChange({ ...value, awsSecretAccessKey })}
            labelClassName="text-lg"
            placeholder="Enter the secret access key matching the ID."
          />
        </>
      )}
    </div>
  );
};

export const amazonS3Summary = (value: S3StorageInput) => value.uri;
