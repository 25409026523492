import { DependencyList, useContext, useEffect } from "react";

import { FilterContext, FilterContextType, FilterFunction, GrouperFunction, LabelFunction } from "./Filters.context";

export const useFilterContext = <T, C = object>(): FilterContextType<T, C> => {
  const ctx = useContext(FilterContext);

  if (!ctx) {
    throw new Error("useFilterContext must be used within a FilterContextProvider");
  }

  return ctx as unknown as FilterContextType<T, C>;
};

export const useFilterConfig = <C>() => {
  const ctx = useFilterContext<any, C>();
  return [ctx.filterConfig, ctx.setFilterConfig] as const;
};

export const useFilter = <T, C>(filter: FilterFunction<T, C>, deps?: DependencyList) => {
  const { setFilters } = useFilterContext<T, C>();

  useEffect(() => {
    // Add filter to the list of filters
    setFilters((filters) => [...filters, filter]);

    // Remove filter from the list of filters on unmount
    // Specifically removing just a single entry here in case the same filter is applied multiple times
    return () => {
      setFilters((filters) => {
        const idx = filters.indexOf(filter);
        if (idx === -1) {
          return filters;
        }

        return [...filters.slice(0, idx), ...filters.slice(idx + 1)];
      });
    };
  }, deps ?? []);
};

export const useGrouper = <T, C>(grouper: GrouperFunction<T, C>, deps?: DependencyList) => {
  const { setGroupers } = useFilterContext<T, C>();

  useEffect(() => {
    // Add grouper to the list of groupers
    setGroupers((groupers) => [...groupers, grouper]);

    // Remove grouper from the list of groupers on unmount
    // Specifically removing just a single entry here in case the same grouper is applied multiple times
    return () => {
      setGroupers((groupers) => {
        const idx = groupers.indexOf(grouper);
        if (idx === -1) {
          return groupers;
        }

        return [...groupers.slice(0, idx), ...groupers.slice(idx + 1)];
      });
    };
  }, deps ?? []);
};

export const useLabeler = <T, C>(labeler: LabelFunction<T, C>, deps?: DependencyList) => {
  const { setLabeler } = useFilterContext<T, C>();

  useEffect(() => {
    setLabeler((prev: LabelFunction<T, C> | null) => {
      if (prev !== null) {
        throw new Error("A label function is already active. Cannot set multiple labelers in the same context");
      }
      return labeler;
    });

    return () => {
      setLabeler(null);
    };
  }, deps ?? []);
};
