import { useQuery } from "@apollo/client";
import { Loader2Icon } from "lucide-react";
import { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ApplicationSettingsDetails, useFragment } from "@/apis/nannyml";
import { getApplicationSettings } from "@/apis/nannyml/queries/getApplicationSettings/getApplicationSettings";
import { ApplicationSetup } from "@/pages/ApplicationSetup";
import { ErrorPage } from "@/routes/ErrorPage";

import { ApplicationConfigurationContext } from "./ApplicationConfiguration.context";

export const ApplicationConfigurationProvider = ({ children }: PropsWithChildren<{}>) => {
  const navigate = useNavigate();
  const { data, loading, error } = useQuery(getApplicationSettings);
  const applicationSettings = useFragment(ApplicationSettingsDetails, data?.application_settings);

  useEffect(() => {
    if (!loading && !error && (applicationSettings?.usageStatistics ?? null) === null) {
      // If the user has not yet configured the application, redirect to the setup page
      if (location.pathname !== ApplicationSetup.url) {
        navigate(ApplicationSetup.url);
      }
    }
  });

  if (loading) {
    return (
      <div className={"w-full h-full flex flex-col items-center justify-center gap"}>
        <Loader2Icon className="animate-spin text-highlightDeep" size={36} />
        <p>Connecting to your NannyML cloud instance...</p>
      </div>
    );
  } else if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <ApplicationConfigurationContext.Provider value={applicationSettings ?? null}>
      {children}
    </ApplicationConfigurationContext.Provider>
  );
};
