import { ObjectValues } from "@/constants/enums";

export const DataQualityMetricsOptions = {
  MissingValues: "Missing Values",
  UnseenValues: "Unseen Values",
} as const;

export type TypeDataQualityMetricsOptions = ObjectValues<typeof DataQualityMetricsOptions>;

export const DataQualityMetricsOptionsValues = Object.values(DataQualityMetricsOptions);
