import { gql } from "@/apis/nannyml";

export const tagResult = gql(/* GraphQL */ `
  mutation TagResult($input: TagResultInput!) {
    tag_monitoring_result(input: $input) {
      id
      tags
    }
  }
`);
