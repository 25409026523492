export const isNumber = (value: unknown): value is number => {
  return typeof value === "number";
};

export const parseNullableFloat = (value: string | null): number | null => {
  if (value === null) {
    return null;
  }
  const parsed = parseFloat(value);
  return isNaN(parsed) ? null : parsed;
};
