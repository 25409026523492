import { DataQualityMetric } from "@/apis/nannyml";
import { DataQualityMetricsOptions } from "@/domains/dataQuality/dataQualityOptions";
import { reverseRecord } from "@/lib/objUtils";

export const DataQualityChecksMappingWrite = {
  [DataQualityMetricsOptions.MissingValues]: DataQualityMetric.MissingValues,
  [DataQualityMetricsOptions.UnseenValues]: DataQualityMetric.UnseenValues,
};

export const DataQualityChecksMappingRead = reverseRecord(DataQualityChecksMappingWrite);

// MappingResultPerformanceMetricWrite
export const DataQualityMetricMappingWrite = {
  [DataQualityMetricsOptions.MissingValues]: "missing_values",
  [DataQualityMetricsOptions.UnseenValues]: "unseen_values",
};

export const DataQualityMetricMappingRead = reverseRecord(DataQualityMetricMappingWrite);
