export const any = <ListItem>(list: ListItem[], getValue?: (item: ListItem) => boolean, valueOnEmpty?: boolean) => {
  return list.length === 0 && valueOnEmpty !== undefined
    ? valueOnEmpty
    : list.reduce((acc, item) => {
        const value = getValue ? getValue(item) : Boolean(item);
        return acc || value;
      }, false);
};

export const all = <ListItem>(list: ListItem[], getValue?: (item: ListItem) => boolean, valueOnEmpty?: boolean) => {
  return list.length === 0 && valueOnEmpty !== undefined
    ? valueOnEmpty
    : list.reduce((acc, item) => {
        const value = getValue ? getValue(item) : Boolean(item);
        return acc && value;
      }, true);
};

export const removeIndex = <T>(list: T[], index: number) => {
  return list.slice(0, index).concat(list.slice(index + 1));
};

export const nums = (length: number, start: number = 0) => Array.from({ length }, (_, i) => (i + start).toString());

export const toggleValue = (currentValues: string[], value: string) => {
  const valueIndex = currentValues.findIndex((i) => i === value);

  if (valueIndex > -1) {
    return removeIndex(currentValues, valueIndex);
  } else {
    return currentValues.concat([value]);
  }
};

export const removeItems = <T>(list: T[], pred: (item: T) => boolean) => {
  let newList = [];

  for (let item of list) {
    if (!pred(item)) {
      newList.push(item);
    }
  }

  return newList;
};

export const excludeItems = <T>(list: T[], items: T[]) => {
  return removeItems(list, (item) => items.includes(item));
};

/**
 * Get value range from the given list
 * @param items List to get the value range for
 * @param getMinValue Function to get the minimum value from an item
 * @param getMaxValue Function to get the maximum value from an item
 * @param seed Initial value for the range
 * @returns Range of values for the given result as a tuple of min and max
 */
export const getValueRange = <T>(
  items: T[],
  getValue: (item: T) => [number, number] = (item: T) => [item, item] as unknown as [number, number],
  seed: [number, number] = [Number.MAX_VALUE, -Number.MAX_VALUE]
): [number, number] => {
  return items.reduce(([min, max], item) => {
    const [itemMin, itemMax] = getValue(item);
    return [Math.min(min, itemMin ?? Number.MAX_VALUE), Math.max(max, itemMax ?? -Number.MAX_VALUE)] as [
      number,
      number
    ];
  }, seed);
};
