import { useApolloClient, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

import { gql } from "@/apis/nannyml";

const startExperimentRunMutation = gql(/* GraphQL */ `
  mutation StartExperimentRun($experimentId: Int!) {
    start_experiment_run(experimentId: $experimentId) {
      id
    }
  }
`);

export const useExperimentId = () => {
  const { experimentId } = useParams();

  if (!experimentId) {
    throw new Error("Experiment ID not set");
  }

  return parseInt(experimentId);
};

export const useStartExperimentRun = (experimentId: number) => {
  const client = useApolloClient();
  return useMutation(startExperimentRunMutation, {
    variables: { experimentId },
    onCompleted: () => {
      // Invalidate the cache for the evaluation model's latest run when the run starts
      client.cache.evict({ id: `Experiment:${experimentId}`, fieldName: "latestRun" });
    },
  });
};
