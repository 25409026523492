import { Info } from "lucide-react";

import { Button } from "@/components/common/Button";
import { useInformationModal } from "@/components/dashboard/InformationModal/InformationModalProvider";
import { InformationModalNames } from "@/formatters/informationModal/informationModalCatalog";
import { cn } from "@/lib/utils";

export const InformationModalChip = ({
  infoName,
  className,
  size = 16,
}: {
  infoName: InformationModalNames;
  className?: string;
  size?: number;
}) => {
  const { openInfo } = useInformationModal();

  const onClick = () => {
    openInfo(infoName);
  };

  return (
    <Button
      tabIndex={-1}
      preLabel={<Info size={size} />}
      cva={{ intent: "contrast" }}
      className={cn("bg-transparent rounded-full w-fit h-fit p-1 mx-2", className)}
      onClick={onClick}
    />
  );
};
