import { cn } from "@/lib/utils";

export const PlotPlaceholder = ({ className, children }: { className?: string; children?: React.ReactNode }) => (
  // Height is set based on 450 px - 8 px of vertical padding
  <div
    className={cn(
      "w-full h-[434px] flex items-center justify-center border border-gray-700 rounded-lg my-2",
      className
    )}
  >
    {children}
  </div>
);
