import { cva, VariantProps } from "class-variance-authority";
import { ReactNode } from "react";

import { cn } from "@/lib/utils";

export const TextStyles = cva([""], {
  variants: {
    size: {
      xSmall: "text-xs",
      small: "text-sm",
      medium: "text-base leading-5",
      medium2: "text-lg leading-6",
      large: "text-2xl leading-7",
      xLarge: "text-3xl leading-10",
    },
    stroke: {
      thin: "font-light",
      base: "font-normal",
      boldLight: "font-semibold",
      bold: "font-extrabold",
    },
    intent: {
      link: "text-inherit",
      neutral: "text-pale",
      error: "text-alert",
    },
  },
  compoundVariants: [
    {
      size: "large",
      stroke: "bold",
      className: "font-black",
    },
  ],
  defaultVariants: {
    size: "medium",
    stroke: "base",
    intent: "neutral",
  },
});

export type TextStylesType = VariantProps<typeof TextStyles>;

export const Text = ({
  className,
  children,
  ...cva
}: { className?: string; children?: ReactNode } & TextStylesType) => {
  return <p className={cn(TextStyles(cva), className)}>{children}</p>;
};
