import { isNullish } from "@/lib/typesUtils";

/**
 * Represents the data input for thresholding
 */
export type ThresholdInput = {
  mean: number | null;
  stdev: number | null;
};

/**
 * Represents a threshold configuration that can be used to calculate threshold values
 */
export type ThresholdConfig =
  | { __typename?: "ConstantThreshold"; lower: number | null; upper: number | null }
  | { __typename?: "StandardDeviationThreshold"; stdLowerMultiplier: number | null; stdUpperMultiplier: number | null };

/**
 * Represents a threshold value with lower and upper bounds
 */
export type ThresholdValue = {
  lower: number | null;
  upper: number | null;
};

/**
 * Represents a type that has a threshold value
 */
export type WithThreshold<T extends object> = T & { threshold: ThresholdValue };

/**
 * Represents a type that has an alert flag
 */
type WithAlertInner<T> = T extends Array<infer I>
  ? (I & { hasAlert: boolean | null })[]
  : T & { hasAlert: boolean | null };
export type WithAlert<T, K extends keyof T | null = null> = K extends keyof T
  ? Omit<T, K> & { [Key in K]: WithAlertInner<T[Key]> }
  : WithAlertInner<T>;

/**
 * Calculate the threshold values based on the given result and config
 * @param result Result to calculate the threshold for
 * @param config Threshold configuration to use
 * @returns The calculated threshold values
 */
export const calculateThreshold = (
  result: { mean: number | null; stdev: number | null },
  {
    threshold,
    upperValueLimit,
    lowerValueLimit,
  }: {
    upperValueLimit: number | null;
    lowerValueLimit: number | null;
    threshold: ThresholdConfig | null;
  }
): ThresholdValue => {
  switch (threshold?.__typename) {
    case "ConstantThreshold":
      return {
        lower: threshold.lower === null ? null : Math.max(lowerValueLimit ?? -Number.MAX_VALUE, threshold.lower),
        upper: threshold.upper === null ? null : Math.min(upperValueLimit ?? Number.MAX_VALUE, threshold.upper),
      };
    case "StandardDeviationThreshold":
      if (result.mean === null || result.stdev === null) {
        return {
          lower: null,
          upper: null,
        };
      }

      return {
        lower:
          threshold.stdLowerMultiplier === null
            ? null
            : Math.max(lowerValueLimit ?? -Number.MAX_VALUE, result.mean - threshold.stdLowerMultiplier * result.stdev),
        upper:
          threshold.stdUpperMultiplier === null
            ? null
            : Math.min(upperValueLimit ?? Number.MAX_VALUE, result.mean + threshold.stdUpperMultiplier * result.stdev),
      };
    case undefined:
      return {
        lower: null,
        upper: null,
      };
  }
};

/**
 * Checks if value exceeds threshold
 * @param value Value to check
 * @param threshold Threshold value to check against
 * @returns True if the value exceeds the threshold, false otherwise
 */
export const exceedsThreshold = (value: number | null | undefined, threshold: ThresholdValue): boolean | null => {
  if (isNullish(value)) {
    return null;
  }

  return (threshold.lower !== null && value < threshold.lower) || (threshold.upper !== null && value > threshold.upper);
};

/**
 * Checks if value exceeds upper threshold
 * @param value Value to check
 * @param threshold Threshold value to check against
 * @returns True if the value exceeds the upper threshold, false otherwise
 */
export const exceedsUpperThreshold = (value: number | null | undefined, threshold: ThresholdValue): boolean | null => {
  if (isNullish(value)) {
    return null;
  }

  return threshold.upper !== null && value > threshold.upper;
};

/**
 * Checks if value exceeds lower threshold
 * @param value Value to check
 * @param threshold Threshold value to check against
 * @returns True if the value exceeds the upper threshold, false otherwise
 */
export const exceedsLowerThreshold = (value: number | null | undefined, threshold: ThresholdValue): boolean | null => {
  if (isNullish(value)) {
    return null;
  }

  return threshold.lower !== null && value < threshold.lower;
};
