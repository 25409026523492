import { gql } from "../codegen/__generated";

export const ApplicationSettingsDetails = gql(/* GraphQL */ `
  fragment ApplicationSettingsDetails on ApplicationSettings {
    auth {
      mode
      oidcProviders {
        audience
        authority
        issuer
        redirectUri
      }
    }
    notifications {
      email {
        enabled
        smtp {
          host
          port
          username
          password
          sender
          useTls
        }
        resend {
          apiKey
          sender
        }
      }
      webhook {
        enabled
        address
        additionalHeaders {
          key
          value
        }
      }
    }
    usageStatistics
    maxUploadSize
  }
`);
