import { useState } from "react";

import { ConceptDriftEnableConfig, ConceptDriftThresholdConfig } from "@/components/monitoring/RuntimeConfig";
import { thresholdDesc } from "@/formatters/thresholds";

import { EditModelControls, Subtitle, Title, useModelEditor } from "./EditModel";
import { SegmentSelect } from "./SegmentSelect";

export const ConceptDriftSettings = () => {
  const { model, config, onRuntimeConfigChange } = useModelEditor();
  const [segmentId, setSegmentId] = useState<number>();

  return (
    <div className="p-4 flex flex-col w-fit max-w-[900px] gap-4">
      <Title>Concept drift configuration</Title>
      <Subtitle>
        Configure which concept drift metrics to analyse. Various <i>delta</i> metrics are available to measure the
        impact of concept drift on model performance.
      </Subtitle>
      <ConceptDriftEnableConfig
        className="max-w-[400px]"
        problemType={model.problemType}
        config={config}
        value={model.runtimeConfig}
        onValueChange={onRuntimeConfigChange}
      />
      <Title>Concept drift thresholds</Title>
      <Subtitle>{thresholdDesc}</Subtitle>
      <SegmentSelect
        className="mb-4"
        segments={model.segments}
        onSegmentChange={(segment) => setSegmentId(segment?.id)}
      />
      <ConceptDriftThresholdConfig
        problemType={model.problemType}
        config={config}
        value={model.runtimeConfig}
        onValueChange={onRuntimeConfigChange}
        segmentId={segmentId}
      />
      <EditModelControls className="sticky bottom-0 py-4 -my-4 bg-dark" />
    </div>
  );
};
