import { VariantProps, cva } from "class-variance-authority";
import { XIcon } from "lucide-react";
import { ReactNode, forwardRef } from "react";

import { cn } from "@/lib/utils";

const style = cva("inline-flex rounded-full items-center gap-2 w-fit", {
  variants: {
    size: {
      small: "text-sm py-1 px-3",
      medium: "text-base py-1 px-3",
      large: "text-lg py-2 px-4",
    },
    variant: {
      filled: "bg-slate-700",
      outlined: "outline outline-1 outline-slate-400",
    },
    clickable: {
      true: "hover:cursor-pointer hover:bg-slate-600/50",
      false: "hover:cursor-default",
    },
  },
  defaultVariants: {
    size: "medium",
    variant: "filled",
  },
});

export type ChipProps = VariantProps<typeof style> & {
  className?: string;
  label: ReactNode;
  onDelete?: () => void;
  icon?: ReactNode;
};

type ChipDivProps = ChipProps & { onClick?: never };
type ChipButtonProps = ChipProps & { onClick: () => void };

export const Chip = forwardRef<HTMLDivElement | HTMLButtonElement, ChipDivProps | ChipButtonProps>(
  ({ className, label, onClick, onDelete, icon, variant, size, ...props }, ref) => {
    const Component = onClick ? "button" : "div";
    return (
      <Component
        ref={ref as any}
        className={cn(style({ size, variant, clickable: Boolean(onClick) }), className)}
        onClick={onClick}
        {...props}
      >
        {icon && <span className="shrink-0">{icon}</span>}
        <span className="truncate">{label}</span>
        {onDelete && (
          <button
            className="p-0.5 rounded-full border border-gray-400 hover:bg-slate-600/50 shrink-0"
            onClick={(e) => {
              e.preventDefault();
              onDelete();
            }}
          >
            <XIcon size={12} />
          </button>
        )}
      </Component>
    );
  }
);
