import { useRef } from "react";

import { InputWithClearButton } from "@/DesignSystem/shadcn/Input/Input";
import { parseNullableFloat } from "@/lib/numbersUtils";

export const RopeInput = ({
  min,
  max,
  lowerBound,
  upperBound,
  disabled,
  supportsAutomatic,
  onChange,
}: {
  min?: number;
  max?: number;
  lowerBound: number | null;
  upperBound: number | null;
  disabled?: boolean;
  supportsAutomatic?: boolean;
  onChange: (lowerBound: number | null, upperBound: number | null) => void;
}) => {
  const lowerBoundRef = useRef<HTMLInputElement>(null);
  const upperBoundRef = useRef<HTMLInputElement>(null);

  const validateInput = (element: HTMLInputElement) => {
    const lowerBound = parseNullableFloat(lowerBoundRef.current!.value);
    const upperBound = parseNullableFloat(upperBoundRef.current!.value);

    // Ensure both lower & upper are set or neither are set
    if (supportsAutomatic && (lowerBound === null) !== (upperBound === null) && !element.value) {
      const [filled, unfilled] = lowerBound === null ? ["upper", "lower"] : ["lower", "upper"];
      element.setCustomValidity(
        `The ${unfilled} bound must be set because the ${filled} bound has been configured with a custom value.
        Clear the ${filled} bound if you want to use automated defaults.`
      );
    }
    // Ensure upper bound is larger than lower bound
    else if (lowerBound !== null && upperBound !== null && upperBound <= lowerBound) {
      element.setCustomValidity("Upper bound must be larger than lower bound.");
    } else {
      lowerBoundRef.current!.setCustomValidity("");
      upperBoundRef.current!.setCustomValidity("");
    }

    return [lowerBound, upperBound];
  };

  const handleChange = (element: HTMLInputElement) => {
    const [lowerBound, upperBound] = validateInput(element);

    // Propagate changes to parent component
    onChange(lowerBound, upperBound);
  };

  const handleBlur = (element: HTMLInputElement) => {
    validateInput(element);

    // Report any validity issues to user
    element.reportValidity();
  };

  return (
    <div className="flex items-center gap-2">
      <InputWithClearButton
        ref={lowerBoundRef}
        type="number"
        min={min}
        max={max}
        step="any"
        required={!supportsAutomatic || lowerBound !== null || upperBound !== null}
        placeholder={supportsAutomatic ? "Automatic" : "Lower bound"}
        value={lowerBound ?? ""}
        onChange={(e) => handleChange(e.target)}
        onBlur={(e) => handleBlur(e.target)}
        disabled={disabled}
        className="hide-arrows"
      />
      <span>-</span>
      <InputWithClearButton
        ref={upperBoundRef}
        type="number"
        min={min}
        max={max}
        step="any"
        required={!supportsAutomatic || lowerBound !== null || upperBound !== null}
        placeholder={supportsAutomatic ? "Automatic" : "Upper bound"}
        value={upperBound ?? ""}
        onChange={(e) => handleChange(e.target)}
        onBlur={(e) => handleBlur(e.target)}
        disabled={disabled}
        className="hide-arrows"
      />
    </div>
  );
};
